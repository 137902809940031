import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../general/Button";
import * as ApiRequest from "../../apiStructure";

import toast from "react-hot-toast";
import {
  NEW_ACCOUNT_ACTIVATION,
  SIGN_UP,
  ENTER_VALID_ACTIVATION_CODE,
  SUCCESSFULLY_SIGNED_UP,
  THANKS_AGAIN_FOR_CHOOSING,
  SUCCESS,
  MAIL_REGEX,
  PASSWORD_TOO_SHORT,
  PASSWORD_LENGTH,
} from "../../Constant";
import ActivateNewAccount from "./ActivateNewAccount";
import { initialActivateError, initialActivateUser } from '../utils/commonVariable';

const Activate = () => {
  const logo_img = "/images/logo.webp"
  const [code, setCode] = useState("");
  const [error, setError] = useState({
    ...initialActivateError
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activatingProducts, setActivatingProducts] = useState(null);
  const [activatingSubscriptions, setActivatingSubscriptions] = useState(null);
  const [successSignup, setSuccessSignup] = useState(false);
  const mailRegex = MAIL_REGEX;

  const [formData, setFormData] = useState({
    ...initialActivateUser
  });

  //submit a code
  const handleSubmit = async () => {
    setLoading(true);

    if (isCodeValid) {
      if (validateSignup()) {
        await signupApi();
      }
      setLoading(false);
    } else {
      if (validateCode(code)) {
        await checkCodeApi();
      }
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        var submitButtton = document.getElementById("submitButtton");
        submitButtton.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  //Signup Api
  const signupApi = async () => {
    try {
      setSuccessSignup(false);
      let res = await ApiRequest.signup({
        ...formData,
        activated_at: new Date(),
      });
      if (res.data.success) {
        setSuccessMessage(res?.data?.data?.success_message);
        toast.success(SUCCESSFULLY_SIGNED_UP);
        setSuccessSignup(true);
      }
    } catch (e) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  //checking Code Api 
  const checkCodeApi = async () => {
    try {
      let res = await ApiRequest.checkCode(code);
      if (res.data.success) {
        setIsCodeValid(true);
        let data = (res?.data?.data).products_to_activate;
        setActivatingProducts(data);
        setActivatingSubscriptions((res?.data?.data).subscriptions_to_activate);
        setFormData({
          ...formData,
          code: code,
        });
      }
    } catch (e) {
      setIsCodeValid(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  //Code validation
  const validateCode = (value) => {
    if (!value) {
      setError({ ...error, code: ENTER_VALID_ACTIVATION_CODE });
      setLoading(false);
      return false;
    } else {
      setError({ ...error, code: "" });
      return true;
    }
  };

  //Signup validation
  const validateSignup = () => {
    let keys = Object.keys(formData);
    let oldError = error;
    let validate = Object.values(formData).map((value, i) => {
      if (!value) {
        oldError = { ...oldError, [keys[i]]: `${keys[i]} is missing` };
        setLoading(false);
        return false;
      } else {
        if (
          keys[i].toString() === "email" &&
          !mailRegex.test(value)
        ) {
          oldError = { ...oldError, [keys[i]]: `${keys[i]} is invalid` };
          setLoading(false);
          return false;
        } else {
          oldError = { ...oldError, [keys[i]]: "" };
          if (formData.password !== formData.retype_password || formData.email !== formData.confirm_email) {
            if (formData.password.length < PASSWORD_LENGTH) {
              oldError = {
                ...oldError,
                password: PASSWORD_TOO_SHORT,
              };
            }
            if (formData.password !== formData.retype_password) {
              oldError = {
                ...oldError,
                retype_password: `Password is not matching!`,
              };
              setLoading(false);
            }
            if (formData.email !== formData.confirm_email) {
              oldError = { ...oldError, confirm_email: `Email is not matching!` };
              setLoading(false);
            }
            return false;
          } else {
            oldError = { ...oldError, [keys[i]]: "" };
            return true;
          }
        }
      }
    });
    setError(oldError);
    return validate.includes(false) ? false : true;
  };

  //get Activated products
  const getActivatedProducts = () => {
    return activatingProducts?.filter((prodFilter) => {
      return prodFilter.activeCount > 0;
    }).map((product, idx) => {
      const appString = product?.apps
        ?.map((app, i) => {
          return app.isChecked ? app?.title : null;
        })
        .filter((a) => a)
        .join(", ")
        .toString();
      const featuresString = product?.features
        ?.map((feature, i) => {
          return feature?.isChecked ? feature?.title : null;
        })
        .filter((a) => a)
        .join(", ")
        .toString();
      const finalString =
        appString && featuresString
          ? `${appString}, ${featuresString}`
          : appString
            ? appString
            : featuresString
              ? featuresString
              : "";
      return (
        <React.Fragment key={idx}>
          <div>
            {product?.title}&nbsp; : &nbsp;{finalString}
          </div>
        </React.Fragment>
      );
    })
  }
  //get Activated subscriptions
  const getActivatedSubscriptions = () => {
    return activatingSubscriptions.map((element, idx) => {
      return (
        <React.Fragment key={idx}>
          <div>
            {element?.title}
          </div>
        </React.Fragment>
      );
    })
  }

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "email" || e.target.name === "confirm_email") {
      value = e.target.value.replace(/ /g, "");
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };
  return (
    <section className="min-h-screen bg-blue-200 flex justify-center items-center p-4 xl:p-16">
      <div
        className="card bg-blue-50 w-full lg:w-3/4 xl:w-2/5 p-4 text-center shadow-xl rounded-xl"
        style={{ minHeight: "470px" }}
      >
        <img
          className="block mx-auto my-4"
          src={logo_img}
          alt="Thoughtcast Logo"
        />

        <h1 className="text-4xl font-bold mt-4 mb-8">
          {" "}
          {isCodeValid ? SIGN_UP : NEW_ACCOUNT_ACTIVATION}
        </h1>

        {successSignup ? (
          <div>
            <p className="text-3xl font-semibold mt-4 mb-8">{SUCCESS}</p>
            <p>{successMessage}</p>
            <p>{THANKS_AGAIN_FOR_CHOOSING}</p>
            <div className="flex justify-center mt-8">
              <NavLink to="/">
                <Button
                  text="Back To Login"
                  bgColor="bg-blue-200"
                  textColor="text-blue-600"
                  small={true}
                />
              </NavLink>
            </div>
          </div>
        ) : (
          <ActivateNewAccount getActivatedProducts={getActivatedProducts} getActivatedSubscriptions={getActivatedSubscriptions} isCodeValid={isCodeValid} activatingSubscriptions={activatingSubscriptions} activatingProducts={activatingProducts}
            formData={formData} handleChange={handleChange} error={error} loading={loading} code={code} setCode={setCode}
            handleSubmit={handleSubmit} />
        )}

      </div>
    </section>
  );
};

export default Activate;
