import React from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Toggle from "../general/Toggle";
import Button from "../general/Button";

const ProductTable = ({
  products, selectAll, selectNone, selectRow, handleEdit, displayProductForm, displaySubForm, noData, selectedProducts
}) => {
  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader
              child={
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) =>
                    e.target.checked ? selectAll() : selectNone()
                  }
                  checked={
                    selectedProducts.length &&
                    selectedProducts.length === products.length
                  }
                />
              }
              sortable={false}
            />
            <TableHeader text="Product Name" sortable={false} />
            <TableHeader text="Product Apps" sortable={false} />
            <TableHeader text="Product Features" sortable={false} />
            <TableHeader text="Visible" sortable={false} />
            <TableHeader text="Action" sortable={false} />
          </tr>
        </thead>
        <tbody>
          {products && products.length
            ? products.map((product) => (
              product?.title !== "Subscriptions" ?
                <tr key={product.id} className="p-16">
                  <TableData
                    child={
                      product.type !== "subscription" ?
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={(e) => selectRow(e, product.id)}
                          checked={
                            selectedProducts.findIndex(
                              (id) => id === product.id
                            ) !== -1
                              ? true
                              : false

                          }
                        /> : null
                    }
                  />
                  <TableData text={product.title || "N/A"} />
                  <TableData
                    text={
                      product.type === "product"
                        ? product.apps.length && product.apps.length
                          ? product.apps.map(
                            (app, i) =>
                              app.title +
                              (i !== product.apps.length - 1 ? ", " : "")
                          )
                          : "N/A"
                        : "all"
                    }
                  />
                  <TableData
                    text={
                      product.type === "product"
                        ? product.features && product.features.length
                          ? product.features.map(
                            (app, i) =>
                              app.title +
                              (i !== product.features.length - 1
                                ? ", "
                                : "")
                          )
                          : "N/A"
                        : "all"
                    }
                  />
                  <TableData
                    child={
                      <Toggle
                        isChecked={
                          product.type === "product"
                            ? product.available
                            : product.product.available
                        }
                        onChange={(e) => { handleEdit(product, e) }}
                      />
                    }
                  />
                  <TableData
                    child={
                      <Button
                        text="Edit"
                        small={true}
                        bottomMargin={false}
                        fn={() => {
                          if (product.type === "product") {
                            displayProductForm(product);
                          }
                          if (product.type === "subscription") {
                            displaySubForm(product);
                          }
                        }}
                      />
                    }
                  />
                </tr>
                : ''
            ))
            : noData()}
        </tbody>
      </table>
    </>
  )
}
export default ProductTable;