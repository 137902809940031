import React from "react";
import Toggle from "./Toggle";
import { ACTIVATED_ON, CODE_USED, DATE_ADDED, DATE_OF_LAST_USE, NOTES } from "../../Constant";
import moment from "moment";

// Mui Import
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const App = ({ app,
  handleActive,
  handleNotesChange,
  isDisabled,
  handleAppFeatureDepriacted
}) => {


  return (
    <div className="card p-4 w-60 mr-4 mb-4 bg-blue-50 shadow rounded d-flex">
      <div className="flex justify-between">
        <h5 className="text-xl mb-4 font-bold">{app?.data?.title}&nbsp; &nbsp; &nbsp;
          {app?.is_active_by_subscription === true ? (
            <div style={{ float: 'right', color: 'darkblue' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 118.04 122.88"
                className="h-6 w-6 inline-block mr-2 subs-svg"
                fill="blue"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" />
              </svg></div>) : ''}
          {app?.is_activated_by_admin === true || app?.is_activated_by_code === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {" "}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>) : ''}
        </h5>
        <select
          style={{ color: app?.hide_from_user == "active" ? "green" : app?.hide_from_user == "deactive" ? "red" : "grey", fontWeight: "bolder" }}
          value={app?.hide_from_user ? app.hide_from_user : 'global'}
          onChange={(event) =>
            handleAppFeatureDepriacted(
              app,
              "app",
              event
            )
          }
        >
          <option value="global" style={{ color: "grey", fontWeight: "bolder" }}> Global</option>
          <option value="active" style={{ color: "green", fontWeight: "bolder" }}> Visible</option>
          <option value="deactive" style={{ color: "red", fontWeight: "bolder" }}> Hidden</option>
        </select>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) {
            handleActive(app, "app", app?.active);
          }
        }}
      >
        <Toggle
          label={app.active ? "Active" : ""}
          isdisabled={isDisabled}
          isChecked={app.active}
          labelBeforeButton={false}
        />
      </div>

      <p className="my-4">
        {ACTIVATED_ON}  :
        <span className="ml-2 font-semibold">
          {
            app?.active ? typeof (app?.activated_on) === "string" ? app?.activated_on ? `${moment(new Date(app?.activated_on)).format("MMM D, YYYY")} @ ${new Date(app?.activated_on).toLocaleTimeString()}` :
              `${moment(new Date()).format("MMM D, YYYY")} @ ${new Date().toLocaleTimeString()}` : app?.activated_on?._seconds ? `${moment(new Date(app?.activated_on?._seconds * 1000)).format("MMM D, YYYY")} @ ${new Date(app?.activated_on?._seconds * 1000).toLocaleTimeString()}` :
              `${moment(new Date()).format("MMM D, YYYY")} @ ${new Date().toLocaleTimeString()}` : ""
          }

        </span>
      </p>
      <p className="my-4">
        {CODE_USED}:<span className="ml-2 font-semibold">{app?.code_used}</span>
      </p>
      <p className="my-4">
        {DATE_ADDED} :
        <span className="ml-2 font-semibold">

          {typeof (app?.last_used) === "string"
            ? app?.last_used ? `${moment(new Date(app?.last_used)).format("MMM D, YYYY")
              } @ ${new Date(app?.last_used).toLocaleTimeString()
              }` : `N/A`
            : app?.last_used?._seconds ? `${moment(new Date(
              app?.last_used?._seconds * 1000
            )).format("MMM D, YYYY")
              } @ ${new Date(
                app?.last_used?._seconds * 1000
              ).toLocaleTimeString()
              }` : `N/A`}

        </span>
      </p>

      <label className="block my-1" htmlFor="">
        {NOTES}
      </label>
      <textarea
        className="w-full my-2 block rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
        placeholder="Add your comments here"
        value={app?.notes}
        onChange={(e) => handleNotesChange(e, app, "app")}
      ></textarea>
    </div>
  );
};

const Feature = ({ feature, handleActive, handleNotesChange, isDisabled, handleAppFeatureDepriacted }) => {

  return (
    <div className="card p-4 w-60 mr-4 mb-4 bg-blue-50 shadow rounded">
      <div className="flex justify-between">
        <h5 className="text-xl mb-4 font-bold">{feature?.data?.title}
          &nbsp; &nbsp; &nbsp;
          {feature?.is_active_by_subscription === true ? (
            <div style={{ float: 'right', color: 'darkblue' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 118.04 122.88"
                className="h-6 w-6 inline-block mr-2 subs-svg"
                fill="blue"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" />
              </svg></div>) : ''}
          {feature?.is_activated_by_admin === true || feature?.is_activated_by_code === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {" "}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>) : ''}
        </h5>
        <select
          style={{ color: feature?.hide_from_user == "active" ? "green" : feature?.hide_from_user == "deactive" ? "red" : "grey", fontWeight: "bolder" }}
          value={feature?.hide_from_user ? feature.hide_from_user : 'global'}
          onChange={(event) =>
            handleAppFeatureDepriacted(
              feature,
              "feature",
              event
            )
          }
        >
          <option value="global" style={{ color: "grey", fontWeight: "bolder" }}> Global</option>
          <option value="active" style={{ color: "green", fontWeight: "bolder" }}> Visible</option>
          <option value="deactive" style={{ color: "red", fontWeight: "bolder" }}> Hidden</option>
        </select>

      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) {
            handleActive(feature, "feature", feature?.active);
          }
        }}
      >
        <Toggle
          isdisabled={isDisabled}
          label={feature.active ? "Active" : ""}
          isChecked={feature.active}
          labelBeforeButton={false}
        />
      </div>

      <p className="my-4">
        {ACTIVATED_ON}
        <span className="ml-2 font-semibold">
          {feature?.active ? typeof (feature?.activated_on) === "string"
            ? feature?.activated_on ? `${moment(new Date(feature?.activated_on)).format("MMM D, YYYY")
              } @ ${new Date(feature?.activated_on).toLocaleTimeString()
              }` : `${moment(new Date()).format("MMM D, YYYY")} @ ${new Date().toLocaleTimeString()}`
            : feature?.activated_on?._seconds ? `${moment(new Date(
              feature?.activated_on?._seconds * 1000
            )).format("MMM D, YYYY")
              } @ ${new Date(
                feature?.activated_on?._seconds * 1000
              ).toLocaleTimeString()
              }` : `${moment(new Date()).format("MMM D, YYYY")} @ ${new Date().toLocaleTimeString()}` : ""}
        </span>
      </p>
      <p className="my-4">
        {CODE_USED}
        <span className="ml-2 font-semibold">{feature?.code_used}</span>
      </p>
      <p className="my-4">
        {DATE_OF_LAST_USE}
        <span className="ml-2 font-semibold">
          {typeof (feature?.last_used) === "string"
            ? feature?.last_used ? `${moment(new Date(feature?.last_used)).format("MMM D, YYYY")} @ ${new Date(feature?.last_used).toLocaleTimeString()}` : `N/A` : feature?.last_used?._seconds ?
              `${moment(new Date(feature?.last_used?._seconds * 1000)).format("MMM D, YYYY")} @ ${new Date(feature?.last_used?._seconds * 1000).toLocaleTimeString()}` : `N/A`}

        </span>
      </p>
      <label className="block my-1" htmlFor="">
        {NOTES}
      </label>
      <textarea
        className="w-full my-2 block rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
        placeholder="Add your comments here"
        value={feature?.notes}
        onChange={(e) => handleNotesChange(e, feature, "feature")}
      ></textarea>
    </div >
  );
};

const UserFormProduct = ({
  user,
  userProduct,
  handleActiveToggler,
  handleNotesChange,
  handleChangeDepricated,
  handleAppFeatureDepriacted,
  subscriptionData,
  isEdit,
  userActiveProducts,
}) => {

  return (
    <div>
      <div className="mb-4 shadow p-4">
        <select
          style={{ position: "absolute", zIndex: "999999", marginLeft: "190px", marginTop: "18px", color: userProduct?.hide_from_user == "active" ? "green" : userProduct?.hide_from_user == "deactive" ? "red" : "grey", fontWeight: "bolder" }}
          value={userProduct?.hide_from_user ? userProduct?.hide_from_user : 'global'}
          onChange={(event) => {
            if (user) {
              handleChangeDepricated(userProduct.id, event);
            } else {
              handleChangeDepricated(userProduct.id, event);
            }
          }}
        >
          <option value="global" style={{ color: "grey", fontWeight: "bolder" }}> Global</option>
          <option value="active" style={{ color: "green", fontWeight: "bolder" }}> Visible</option>
          <option value="deactive" style={{ color: "red", fontWeight: "bolder" }}> Hidden</option>
        </select>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>
              <header className="flex items-baseline">
                <h5 className="font-bold text-2xl mb-4 mr-4">
                  {userProduct?.data?.title}{" "}
                </h5>


              </header>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* App Card Grid */}
              <div className="w-full flex flex-wrap">
                {userProduct?.apps?.map((app, ida) => {
                  return !app.hide && (
                    <React.Fragment key={ida}>
                      <App
                        app={app}
                        handleActive={handleActiveToggler}
                        handleNotesChange={handleNotesChange}
                        handleAppFeatureDepriacted={handleAppFeatureDepriacted}
                      />
                    </React.Fragment>
                  );
                })}

                {/* Feature Card Grid */}
                {userProduct?.features?.map((feature, idf) => {

                  return !feature.hide && (

                    <React.Fragment key={idf}>
                      <Feature
                        feature={feature}
                        handleActive={handleActiveToggler}
                        handleNotesChange={handleNotesChange}
                        handleAppFeatureDepriacted={handleAppFeatureDepriacted}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div >
  );
};

export default UserFormProduct;