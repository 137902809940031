import { createSlice } from "@reduxjs/toolkit";
import { GetApiKeys, GenerateApiKey, DeleteApikey } from "../../services";
import toast from "react-hot-toast";
import { SUCCESSFULLY_DELETED_PRODUCT, SUCCESSFULLY_GENERATED_API } from "../../Constant";

const paginatedApikeys = (apiKeys, pageNumber, apiKeysCount) => {
  return apiKeys.slice(
    (pageNumber - 1) * apiKeysCount,
    pageNumber * apiKeysCount
  );
};

export const apiKeySlice = createSlice({
  name: "apikeys",
  initialState: {
    list: [],
    pageNumber: 1,
    apiKeysCount: 10,
    paginatedApikeys: [],
    succesResponseProduct: false,
    loading: false,
    typeStatus: "All",
    generateLoading: false,
  },
  reducers: {
    getPaginatedApikeys: (state) => {
      state.paginatedApikeys = paginatedApikeys(
        state.list,
        state.pageNumber,
        state.apiKeysCount
      );
    },
    deleteapiKeys: (state, action) => {
      state.list = state.list.filter(
        (apiKey) => !action.payload.includes(apiKey.id)
      );
      return state;
    },
    setapiKeysCount: (state, action) => {
      if (action.payload > 0) {
        state.apiKeysCount = action.payload;
        state.pageNumber = 1;
        apiKeySlice.caseReducers.getPaginatedApikeys(state);
      }
    },
    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        apiKeySlice.caseReducers.getPaginatedApikeys(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(state.list.length / state.apiKeysCount);
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        apiKeySlice.caseReducers.getPaginatedApikeys(state);
      }
    },
    setPageNumber: (state, action) => {
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(state.list.length / state.apiKeysCount) + 1
      ) {
        state.pageNumber = action.payload;
        apiKeySlice.caseReducers.getPaginatedApikeys(state);
      }
    },
  },
  extraReducers: {
    [GetApiKeys.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    [GetApiKeys.pending]: (state, action) => {
      state.list = [];
      state.loading = true;
    },
    [GetApiKeys.rejected]: (state, action) => {
      state.list = [];
      state.loading = false;
    },
    [GenerateApiKey.fulfilled]: (state, action) => {
      state.generateLoading = false;

      if (action.payload && action.payload.success) {
        state.list.push(action.payload.data);
        state.pageNumber = 1;
        state.paginatedApikeys = paginatedApikeys(
          state.list,
          state.pageNumber,
          state.apiKeysCount
        );

        toast.success(SUCCESSFULLY_GENERATED_API);
      }
    },
    [GenerateApiKey.pending]: (state, action) => {
      state.generateLoading = true;
    },
    [GenerateApiKey.rejected]: (state, action) => {
      state.generateLoading = false;
    },
    [DeleteApikey.fulfilled]: (state, action) => {
      if (action.payload && action.payload.success) {
        state.list = state.list.filter(
          (apiKey) => !action.payload.data.email.includes(apiKey.email)
        );
        state.pageNumber = 1;
        state.paginatedApikeys = paginatedApikeys(
          state.list,
          state.pageNumber,
          state.apiKeysCount
        );
        toast.success(SUCCESSFULLY_DELETED_PRODUCT);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  initApiKeys,
  setapiKeysCount,
  deleteapiKeys,
  getPaginatedApikeys,
} = apiKeySlice.actions;

export default apiKeySlice.reducer;
