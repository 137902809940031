export const WELCOME_MSG = "welcome to ULS";
export const USER_NOT_FOUND = "User not found";
export const FIREBASE_ID_TOKEN = "Firebase ID token";
export const NO_TOKEN_ACCESS_DENIED = "No token,access denied";
export const PASSWORD_RESET_EMAIL_SENT = "Password Reset Email Sent - Check Your Inbox";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const PASSWORD_RESET_SUCCESFUL = "Password Reset Successfull";
export const FILE_TOO_LARGE = "File is too large";
export const DATA_IS_NOT_AVAILABLE = "Sorry, Data is not available!"
export const DATA_IS_NOT_FOUND = "Sorry, Data is not found !";
export const PRODUCTS_ACTIVATED = "Products Activated";
export const ACTIVATED_BY = "Activated By";
export const DATE_ADDED = "Date Added";
export const DATE_ACTIVATED = "Date Activated";
export const DELETE_SELECTED_PRODUCTS = "Delete Selected Products";
export const DELETE_SELECTED_PRODUCT = "Delete Selected Product";
export const CANNOT_DELETE_SUBSCRIPTION = "You can't delete subscription, please unselect the subscription";
export const PLEASE_ACTIVE_BUTTON_TEXT = "Please enter active/inactive product button text.";
export const PLEASE_ACTIVE_PRODUCT_TEXT = "Please enter active/inactive product text."
export const TEMPORARY_DISABLE = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
export const INVALID_USERNAME_PASSWORD = "Username or password is incorrect, please try again or try to reset your password";
export const NUMBER_OF_CODES_LESS = "Number of Codes is can't be less than 2";
export const CLEAR_PREFIX = "Clear prefix to generate new code !";
export const CODE_NOT_FOUND = "Code not found, please try again.";
export const ADD_MINIMUN_FIVE_CHAR = "You must have to add minimum 5 character.";
export const PLEASE_ADD_CODE = "Please add code or generate random code.";
export const VIEW_INACTIVE_CODE = "View Inactive Code";
export const EDIT_MULTIPLE_CODE = "Edit Multiple Code";
export const EDIT_CODE = "Edit Code";
export const ADD_CODE = "Add Code";
export const REACTIVE = "Reactivate";
export const SAVE = "Save";
export const PLEASE_ENTER_PRODUCTNAME = "Please enter product name.";
export const ATLEAST_ONEAPP_ONEFEATURE = "You have atleast one app and one feature."
export const REPEATING_ID_NOT_ALLOWED = "Repeating id not allowed !!";
export const PHOTO_TOO_BIG = "photo size too big, please reduce to under 2mb";
export const RESET_PASS_SUCCEFULLY = "Reset password email send successfully";
export const EMAIL_NOT_FOUND = "Email not found, please check user information.";
export const LINK_IS_EMPTY = "Link is Empty!";
export const YOU_CANNOT_ACTIVATE_PRODUCT = "you can't activate the product. this product is not avaiable for all the user";
export const ACTIVATED_ON = "Activated on :";
export const CODE_USED = "Code Used :";
export const DATE_OF_LAST_USE = "Date of last use :";
export const NOTES = "Notes";
export const PAGE_NUM = "Page Number";
export const USERS = "Users";
export const USER_ACCOUNT = "User Accounts"
export const ADMINS = "Admins";
export const CODES = "Codes";
export const PRODUCTS = "Products";
export const API_KEYS = "API Keys";
export const SETTINGS = "Settings";
export const NAME = "Name";
export const ACTIVE_URL = "Active Url";
export const INACTIVE_URL = "Inactive URL";
export const MAILCHIMP_TAG = "Mailchimp/Shopify Tag";
export const SHOPIFY_SKU_NOT = "Shopify SKU(s) - Do Not Fulfill";
export const SHOPIFY_SKU = "Shopify SKU(s) - Fulfill";
export const ENTER_VALID_ACTIVATION_CODE = "Please enter a valid activation code";
export const SIGN_UP = "Sign Up";
export const NEW_ACCOUNT_ACTIVATION = "New Account Activation";
export const THANKS_AGAIN_FOR_CHOOSING = "Thanks again for choosing ThoughtCast Magic!";
export const SUCCESS = "Success!";
export const SUCCESSFULLY_SIGNED_UP = "successfully signed up";
export const ACTIVATING_FOLLOWING_PRODUCT = "You are Activating the Following Products:";
export const ACTIVATING_FOLLOWING_SUBSCRIPTION = "You are Activating the Following Subscriptions:";
export const ACTIVATING_FOLLOWING_SUBSCRIPTION_PRODUCT = "You are Activating the Following:";
export const ALL_FIELD_ARE_REQ = "All fields are required !!";
export const YOUR_CODE_ACTIVE = "Your Code is Active!";
export const ENTER_ACTIVATION_NEW_ACCOUNT = "Enter activation code below to setup a new account";
export const NO_PRODUCT_TO_ACTIVATE = "No Products to Activate.";
export const NO_SUBSCRIPTION_TO_ACTIVATE = "No Subscriptions to Activate.";
export const NEED_HELP_EMAIL_US = "Need help? Email us!";
export const REMINDER = "Reminder: This system is for NEW ACCOUNTS ONLY!"
export const IF_YOU_HAVE_ACCOUNT_LOGIN = 'If you already have a ThoughtCast Magic Account, please press "Back to Login"';
export const AND_LOGIN_TO_ACTIVATE_YOUR_CODE = "and log in to activate your code!"
export const PLEASE_ENTER_A_VALID_PASS = "Please enter valid password";
export const ENTER_A_VALID_EMAIL = "Please enter valid email";
export const PASSWORD_DOESNT_MATCH = "Passwords Do Not Match";
export const CHANGE_PASSWORD = "Change Password";
export const CODE_SUCCESSFUL_ACTIVATE = "code successfull activated";
export const CODE_IS_NOT_VALID = "Code is not valid";
export const CODE_VALIDATION_FAILED = "Code validation failed";
export const DASHBOARD = "Dashboard";
export const USER_PORTAL = "User Portal";
export const ACTIVATE_A_CODE = "Activate a Code";
export const SUBSCRIPTION_ACTIVE = "Subscription Active";
export const SUBSCRIPTION_INACTIVE = "Subscription Inactive";
export const EXPIRATION_DATE = "Expiration Date";
export const FORGET_PASSWORD = "Forgot Password";
export const ENTER_EMAIL_FOR_PASS_RESET_TOKEN = "Enter your email below to send password reset token";
export const ENTER_A_NUMERIC_VALUE = "You must have to enter numeric value.";
export const ENTER_3_OR_4_DIGIT_NUM = "You must have to enter 3 or 4 digit number."
export const PROVIDE_A_VALID_CARD_NUM = "Please provide a valid Card number!";
export const PROVIDE_A_VALID_CVV_NUM = "Please provide a valid cvv number!"
export const PROVIDE_A_VALID_MONTH = "Please provide a valid Month!";
export const PROVIDE_A_VALID_YEAR = "Please provide a valid Year!";
export const SUBSCRIPTION_DONE = "Subscription successfully done.";
export const PROVIDE_A_ADDRESS = "Please provide a Address!"
export const SUCCESSFULLY_ADDED_ADMIN = "successfully added admin";
export const SUCCESSFULLY_DELETED_USER = "successfully deleted user";
export const SUCCESSFULLY_UPDATED_USER = "successfully updated admin";
export const SUCCESSFULLY_GENERATED_API = "successfully generated API key";
export const SUCCESSFULLY_DELETED_PRODUCT = "Successfully deleted Product";
export const SUCCESSFULLY_ADDED_PRODUCT = "successfully added product";
export const SUCCESSFULLY_ADDED_CODE = "successfully added code";
export const SUCCESSFULLY_UPDATED_CODE = "successfully updated code";
export const ERROR_WHILE_EDIT_CODE = "Error while edit code";
export const SUCCESSFULLY_REACTIVED_CODE = "successfully reactived code";
export const ERROR_WHILE_ADDING_PRODUCT = "Error while adding product";
export const SUCCESSFULLY_UPDATED_PRODUCT = "successfully updated product";
export const UPDATED_SUBSCRIPTION = "successfully updated subscription";
export const CANCEL_SUBSCRIPTION = "Subscription successfully canceled.";
export const NOT_FOUND = "Not Found";
export const PAGE_NOT_FOUND = "Page Not Found";
export const SELECT_STATUS = "Select Status";
export const SELECT_EMAIL = "Select email";
export const ADMIN = "Admin";
export const SUPER_ADMIN = "Super Admin";
export const ADMIN_ACCOUNT = "Admin Accounts"
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const STATUS = "Status";
export const VISIBLE = "Visible";
export const STORE_BUTTON_TEXT = "Please enter store button text.";
export const SETTING_EDITED = "Setting successfully Edited.";
export const CODE_READY_FOR_ACTIVATION = "code ready for activation";
export const PURCHASE_LOCATION = "Purchase Location";
export const NUMBER_OF_CODES = "Number of Codes";
export const GENERATED_CODES = "Generated codes";
export const CODE_PREFIX = "Code Prefix";
export const FIRST_NAME = "First Name:"
export const LAST_NAME = "Last Name:"
export const FIRST_NAME_L = "First Name"
export const LAST_NAME_L = "Last Name"
export const EMAIL = "Email :";
export const EMAIL_LABEL = "Email";
export const EMAIL_IS_INVALID = "email is invalid";
export const CODE = "Code";
export const CODE_LENGTH = "Code Length";
export const PRODUCTS_TO_ACTIVATE = "Products to Activate";
export const SUBSCRIPTION = "Subscription";
export const EDIT_SUBSCRIPTION = "Edit Subscription";
export const DO_YOU_WANT_TO = "Do you want to";
export const ACTIVATING_PRODUCT = "Activating Products :";
export const ROLE_IS_NOT_DEFINED = "role is not defined!";
export const MAIL_REGEX = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const DESCRIPTION = "Description";
export const LEARN_MORE = "Learn More Link";
export const PRODUCT = "Product";
export const PRODUCT_NAME = "Product Name";
export const PRODUCT_ID = "Product Id";
export const USER_PORTAL_STORE_BUTTON_LINK = "User Portal - Store button Link:";
export const USER_PORTAL_STORE_BUTTON_TEXT = "User Portal - Store button Text:";
export const USER_PORTAL_ACTIVE_PRODUCT_BUTTON_TEXT = "User Portal - Active Product Button Text:";
export const USER_PORTAL_INACTIVE_PRODUCT_BUTTON_TEXT = "User Portal - Inactive Product Button Text:";
export const USER_PORTAL_ACTIVE_PRODUCT_TEXT = "User Portal - Active Product Text:";
export const USER_PORTAL_INACTIVE_PRODUCT_TEXT = "User Portal - Inactive Product Text:";
export const USER_PORTAL_PRODUCT_DISPLAY_ORDER = "User Portal - Product Display Order:";
export const USER_PORTAL_PROMOTIONAL_TEXT = "User Portal - Promotional Text:";
export const USER_PORTAL_PROMOTIONAL_TEXT_FONT = "User Portal - Promotional Text - Font Size:";
export const NEW_ACCOUNT_SUCCESS_MSG = "New Account - Success Message:"
export const CODES_BULK_CODES = "Codes - Bulk Codes - Default Number of Codes:"
export const CODES_LENGTH = "Codes - Default Code Length:"
export const CODES_CHARACTER = "Codes - Code Characters:"
export const CODES_PURCHASE_LOC = "Codes - Default Purchase Location:"
export const API_KEYS_SWAGGER = "API keys - Swagger docs location:"
export const NEW_USER_PLAN = "New User: Default susbcription plan";
export const VALID_SUBSCRIPTION_DATE = "Please Select a valid subscription Date";
export const SELECT_SUBSCRIPTION_DATE = "Please Select a subscription Date";
export const PASSWORD = "Password";
export const ADMIN_TYPE = "Admin type";
export const ACCOUNT_NOTES = "Account Notes";
export const COMMENTS = "Comments";
export const ACTIVE_HISTORY = "Activation History";
export const PER_PAGE = "Per Page";
export const DELETE_SELECTED_USER = "Delete Selected User";
export const DELETE_SELECTED_USERS = "Delete Selected Users";
export const VISAREGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
export const MASTERCARD_REGEX = /^(?:5[1-5][0-9]{14})$/;
export const AMEXP_REGEX = /^(?:3[47][0-9]{13})$/;
export const DISCOVER_REGEX = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
export const CHECK_MONTH_REGEX = /^([1-9]|[0][1-9]|[0-1][0-2])$/;
export const MERGE_USER = "Merge User"
export const USER_NOT_FOUND_ = "user-not-found";
export const WRONG_PASSWORD = "wrong-password";
export const TOO_MANY_REQ = "too-many-requests";
export const ADD_SUBSCRIPTION = "Add Subscription"
export const DELETE_SELECTED_SUBSCRIPTION = "Delete Selected Subscription"
export const DELETE_SELECTED_SUBSCRIPTIONS = "Delete Selected Subscriptions"
export const EDIT_SUBSCRIPTION_USER_PORTAL_INFO = "Edit Subscription User Portal Info"
export const SUBSCRIPTIONS = "Subscriptions"
export const SUBSCRIPTION_NAME = "Subscription Name"
export const SUBSCRIPTION_APPS = "Subscription Apps"
export const SUBSCRIPTION_FEATURES = "Subscription Features"
export const SUBSCRIPTION_ACTION = "Action"
export const HELP = "Help";
export const HELP_EMAIL = "support@thoughtcastmagic.com";
export const HELP_EMAIL_SUBJECT = "Owner Portal Help"
export const PASSWORD_LENGTH = 6;
export const PASSWORD_TOO_SHORT = `Password too short - Please make sure it is ${PASSWORD_LENGTH} characters or longer`;
export const OWNER_PORTAL = "Owner Portal";
export const SEARCH = "Search";
export const SUCCESSFULLY_UPDATED_SUBSCRIPTION = "successfully updated subscription";
export const SUCCESSFULLY_CREATED_SUBSCRIPTION = "successfully created subscription";
export const ERROR_WHILE_ADDING_SUBSCRIPTION = "Error while adding subscription";
export const SUCCESSFULLY_ADDED_SUBSCRIPTION = "successfully added subscription";
export const SUCCESSFULLY_DELETED_SUBSCRIPTION = "Successfully deleted subscription";
export const SHOW_PRICE_COMPARISONS_PER = "Show Price Comparisons Per"
export const PRICE_TO_SHOW_IN_OWNERS_PORTAL = "Price to Show in Owners Portal"
export const SUCCESSFULLY_UPDATED_SUBSCRIPTION_USER_PORTAL_INFO = "successfully updated subscription user portal info";
export const PLEASE_ENTER_TITLE = "Please enter title"
export const PLEASE_ENTER_DESCRIPTION = "Please enter description"
export const SUBSCRIPTION_PRICE_OPTION_FOR = "Subscription Price Options For"
export const SUBSCRIBE_NOW = "Subscribe Now"
export const CHANGE_RENWAWL_LENGTH = "Change Renewal Length"
export const EXPIRED = "Expired"
export const SELECT_TIME = "Select Time"
export const WELCOME = "Welcome"
export const RENEWS_ON = "Renews on"
export const AUTO_RENEW = "Auto Renew?"
