import React from "react";
import * as ApiRequest from "../../apiStructure";
import {
  EMAIL_LABEL,
  EMAIL_NOT_FOUND,
  FIRST_NAME_L,
  LAST_NAME_L,
  PASSWORD,
  RESET_PASS_SUCCEFULLY,
} from "../../Constant";
import moment from "moment";
import Button from "../general/Button";
import toast from "react-hot-toast";

const PersonalDetails = ({ handleChange, formData, error, user }) => {
  return (
    <>
      <div className="shadow p-4 w-1/2">
        {/* First Name */}
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {FIRST_NAME_L} <span className="text-red-600">*</span>
          </label>
          <div className="flex-row w-1/2 mb-4">
            <input
              type="text"
              name="firstname"
              autoFocus
              placeholder="John"
              onChange={handleChange}
              value={formData?.firstname}
              className=" w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  text-blue-900 font-semibold"
            />
            <small className="text-red-600 font-semibold">
              {error?.firstname}
            </small>
          </div>
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {LAST_NAME_L} <span className="text-red-600">*</span>
          </label>
          <div className="flex-row w-1/2 mb-4">
            <input
              type="text"
              name="lastname"
              placeholder="Doe"
              onChange={handleChange}
              value={formData?.lastname}
              className="w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            />
            <small className="text-red-600 font-semibold">
              {error?.lastname}
            </small>
          </div>
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {EMAIL_LABEL} <span className="text-red-600">*</span>
          </label>
          <div className="flex-row w-1/2 mb-4">
            <input
              type="email"
              name="email"
              autoComplete="off"
              placeholder="example@email.com"
              onChange={(e) => {
                handleChange(e);
              }}
              value={formData?.email}
              className="w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            />
            <small className="text-red-600 font-semibold">{error.email}</small>
          </div>
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {PASSWORD} <span className="text-red-600"></span>
          </label>
          <div className="flex-row w-1/2 mb-4">
            <input
              type="password"
              name="password"
              autoComplete="new-password"
              className="w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
              onChange={handleChange}
            />
            <small className="text-red-600 font-semibold">
              {error.password}
            </small>
          </div>
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            Actions API Token<span className="text-red-600"></span>
          </label>
          <div className="flex-row w-1/2 mb-4">
            <input
              type="text"
              name="app_listening_id"
              autoComplete="new-password"
              value={
                formData?.app_listening_id ? formData?.app_listening_id : ""
              }
              className="w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
              onChange={handleChange}
            />
            <small className="text-red-600 font-semibold">
              {error.app_listening_id}
            </small>
          </div>
        </div>
        {user && (
          <React.Fragment>
            <p className="font-semibold">
              Account created on{" "}
              {moment(
                user.created_at._seconds * 1000 +
                  user.created_at._nanoseconds / 1000000
              ).format("MMM D, YYYY") || ""}
            </p>
            <div className="my-4">
              <Button
                text="Send Password Reset Email"
                small={true}
                fn={() => {
                  if (user?.email) {
                    ApiRequest.forgotPassword(user?.email)
                      .then((res) => {
                        if (res?.data?.success) {
                          toast.success(RESET_PASS_SUCCEFULLY);
                        }
                      })
                      .catch((err) => {});
                  } else {
                    toast.error(EMAIL_NOT_FOUND);
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default PersonalDetails;
