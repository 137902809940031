import React from "react";
const Button = ({
  small = false,
  text,
  textColor = "text-white",
  bgColor = "bg-blue-600",
  bottomMargin = true,
  fn,
  id,
  fontSemiBold = false,
  disabled = false,
  otherClass,
  styleInline
}) => {
  return (
    <button
      className={`block ${disabled ? "bg-gray-400" : bgColor} ${textColor} ${
        small ? "px-2 py-1" : "px-4 py-2"
      } ${
        bottomMargin ? "mb-4" : ""
      } rounded shadow focus:border-transparent focus:outline-none ${
        fontSemiBold ? "font-semibold" : ""
      } ${otherClass}`}
      style={styleInline ? styleInline : {}}
      onClick={fn}
      id={id}
      disabled={disabled}
    >
      {String(text).charAt(0)?.toUpperCase() + String(text)?.slice(1)}
    </button>
  );
};

export default Button;
