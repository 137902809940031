import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import Button from "../general/Button";
import { Signout } from "../auth/firebase";
import { ADMINS, API_KEYS, CODES, PRODUCTS, SETTINGS, SUBSCRIPTIONS, USERS } from "../../Constant";


const Sidebar = ({ role }) => {
  const logo_img = "/images/logo_white.png"


  let match = useRouteMatch();
  return (
    <aside className="w-2/12 p-16 min-h-screen bg-blue-500">
      <img src={logo_img} alt="Thoughtcast Logo" />
      <ul className="text-white text-xl">
        <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/users`}>{USERS}</NavLink>
        </li>
        {role === 1 && <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/manage`}>{ADMINS}</NavLink>
        </li>}
        <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/codes`}>{CODES}</NavLink>
        </li>
        {role === 1 && <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/products`}>{PRODUCTS}</NavLink>
        </li>}
        {role === 1 && <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/subscriptions`}>{SUBSCRIPTIONS}</NavLink>
        </li>}
        {role === 1 && <li className="my-8 sidebar-nav-list">
          <NavLink to={`${match.url}/apikeys`}>{API_KEYS}</NavLink>
        </li>}
        {role === 1 &&
          <li className="my-8 sidebar-nav-list">
            <NavLink to={`${match.url}/settings`}>{SETTINGS}</NavLink>
          </li>

        }

        <li className="my-8 sidebar-nav-list">
          <Button
            text="Logout"
            bgColor="bg-blue-300"
            textColor="text-red-900"
            bottomMargin={false}
            small={true}
            fn={() => Signout()}
            otherClass="text-sm font-semibold"
          />
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
