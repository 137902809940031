import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ApiStructure from "./apiStructure";
import construct from "./firestore/Constructor";

const {
  adminConverter,
  userConverter,
  productConverter,
  codeConverter,
  subscriptionConverter,
  subscriptionDataConverter,
  subscriptionInfoConverter
} = construct;

/////////////////////////////////////////////////
//------------ users api calls ----------------//
/////////////////////////////////////////////////

export const GetUsers = createAsyncThunk("users/getUsers", async () => {
  const response = await ApiStructure.getAllUsers();
  return userConverter.fromFirestore(response.data.data);
});

export const CreateUser = createAsyncThunk("users/createUser", async (data) => {
  const res = await ApiStructure.addUser(userConverter.toFirestore(data));
  if (res) {
    return res?.data?.data;
  } else {
  }
});

export const EditUser = createAsyncThunk("users/editUser", async (data) => {
  const res = await ApiStructure.updateUser(
    userConverter.updateToFirestore(data),
    data.id
  );
  if (res.data?.success) {
    return res.data?.data;
  }
});

export const DeleteUserApi = createAsyncThunk(
  "users/deleteUser",
  async (idArray) => {
    const res = await ApiStructure.deleteUser(idArray);
    if (res) {
      return res
    }
  }
);

export const MergeUserApi = createAsyncThunk(
  "users/mergeUser",
  async (data) => {
    const res = await ApiStructure.mergeUser(
      userConverter.mergeUserToFirestore(data)
    );
    if (res?.data?.success) {
      return { data: res.data?.data, userId: data.user_id };
    }
  }
);
export const deprecateProductUserApi = createAsyncThunk(
  "users/deprecateProductUser",
  async (data) => {
    const res = await ApiStructure.deprecateProductUser(
      userConverter.deprecateProductUser(data)
    );
    return res;
  }
);

/////////////////////////////////////////////////
//------------ admin api calls ----------------//
/////////////////////////////////////////////////

export const GetAdmins = createAsyncThunk("admin/getAdmins", async () => {
  const response = await ApiStructure.getAllAdmins();
  return adminConverter.fromFirestore(response.data.data);
});

export const CreateAdmin = createAsyncThunk(
  "admin/createAdmin",
  async (data) => {
    const res = await ApiStructure.addUser(adminConverter.toFirestore(data));
    return res?.data;
  }
);

export const EditAdmin = createAsyncThunk("admin/editAdmin", async (data) => {
  const res = await ApiStructure.updateUser(
    adminConverter.updateToFirestore(data),
    data.id
  );
  if (res.data?.success) {
    return res.data?.data;
  }
});

export const DeleteAdminApi = createAsyncThunk(
  "admin/deleteAdmin",
  async (idArray) => {
    const res = await ApiStructure.deleteUser(idArray);
    // if(res){
    return res
    // }
  }
);

/////////////////////////////////////////////////
//----------- products api calls --------------//
/////////////////////////////////////////////////

export const GetProducts = createAsyncThunk(
  "products/getProducts",
  async () => {
    const response = await ApiStructure.getAllProducts();
    const productsList = productConverter.fromFirestore(
      response?.data?.data?.products
    );

    // let subscription = subscriptionConverter.fromFirestore(
    //   response?.data?.data?.subscription
    // );

    if (productsList) {
      return productsList;
    }
    return [];
  }
);

export const CreateProduct = createAsyncThunk(
  "products/createProduct",
  async (data) => {
    const res = await ApiStructure.addProduct(
      productConverter.toFirestore(data)
    );
    if (res && res.data && res.data.success) {
      return res.data;
    } else {
      return false;
    }
  }
);

export const EditProduct = createAsyncThunk(
  "products/editProduct",
  async (data) => {
    const res = await ApiStructure.updateProduct(
      productConverter.updateToFirestore(data),
      data.id
    );
    if (res && res.data && res.data.success) {
      return data;
    } else {
      return false;
    }
  }
);

export const DeleteProductApi = createAsyncThunk(
  "products/deleteProduct",
  async (idArray) => {
    const res = await ApiStructure.deleteProduct(idArray);
    return { ...res.data, id: idArray };
  }
);

/////////////////////////////////////////////////
//------------ codes api calls ----------------//
/////////////////////////////////////////////////

export const GetCodes = createAsyncThunk("codes/getCodes", async () => {
  const response = await ApiStructure.getAllCodes();
  return codeConverter.fromFirestore(response.data.data);
});

export const CreateCodes = createAsyncThunk(
  "codes/createCodes",
  async (data) => {
    const res = await ApiStructure.addCode(codeConverter.toFirestore(data));
    return res.data;
  }
);

export const EditCodes = createAsyncThunk("codes/editCode", async (data) => {
  const res = await ApiStructure.updateCode(
    data.type === "single"
      ? codeConverter.updateToFirestore(data)
      : data.type === "Reactivate"
        ? codeConverter.ReactiveToFirestore(data)
        : codeConverter.updateMultipleToFirestore(data)
  );
  return res.data;
});
export const ReactiveCodes = createAsyncThunk(
  "codes/ReactiveCodes",
  async (data) => {
    const res = await ApiStructure.updateCode(
      codeConverter.ReactiveToFirestore(data)
    );
    return res.data;
  }
);

export const DeleteCodeApi = createAsyncThunk(
  "codes/deleteCode",
  async (idArray) => {
    const res = await ApiStructure.deleteCode(idArray);
    if (res) { }
  }
);
export const codeHistoryApi = createAsyncThunk(
  "codeInfo/codeHistory",
  async (id) => {
    const res = await ApiStructure.codeHistory(id);
    if (res?.data?.success) {
      return res.data;
    }
  }
);
export const codeActivation = createAsyncThunk(
  "codeInfo/codeActivation",
  async (data) => {
    const res = await ApiStructure.codeActivation(
      codeConverter.activateToFirestore(data)
    );
    if (res?.data?.success) {
      return res.data;
    }
  }
);

////////////////////////////////////////////////////////////
//---------------Update Subscription RELATED--------------//
////////////////////////////////////////////////////////////


export const AddNewSubscription = createAsyncThunk(
  "subscriptions/addSubscription",
  async (data) => {
    console.log("oooooooooooooooooooooooooooooooooooooooooooooo")
    const res = await ApiStructure.addSubscription(
      data
    );
    if (res && res.data && res.data.success) {
      return res.data;
    } else {
      return false;
    }
  }
);

export const EditSubscriptions = createAsyncThunk(
  "subscriptions/editSubscriptions",
  async (data) => {
    console.log("oooooooooooooooooooooooooooooooooooooooooooooo", data)
    const res = await ApiStructure.editSubscription(
      data.data,
      data.id
    );
    if (res && res.data && res.data.success) {
      return res.data;
    } else {
      return false;
    }
  }
);

export const UpdateSubscription = createAsyncThunk(
  "products/updateSubscription",
  async (data) => {
    const res = await ApiStructure.updateSubscription(
      data.id,
      subscriptionConverter.updateToFirestore(data.data)
    );
    if (res?.data?.success) {
      return data;
    }
  }
);
export const cancelSubscription = createAsyncThunk(
  "users/cancelSubscription",
  async (data) => {
    const res = await ApiStructure.cancelSubscription(data);
    return res;
  }
);


/////////////////////////////////////////////////
//------------ apikeys api calls ----------------//
/////////////////////////////////////////////////

export const DeleteApikey = createAsyncThunk("apikeys/deleteApiKey", async (email) => {
  const response = await ApiStructure.deleteApiKeys(email);
  return response.data;
});

export const GenerateApiKey = createAsyncThunk("apikeys/generateApiKey", async (email) => {
  const response = await ApiStructure.generateApiKey(email);
  return response.data;
});

export const GetApiKeys = createAsyncThunk("apikeys/getApiKeys", async () => {
  const response = await ApiStructure.getAllApikeys();
  return response.data.data;
});



/////////////////////////////////////////////////
//----------- subscriptions api calls --------------//
/////////////////////////////////////////////////

export const GetSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async () => {
    const response = await ApiStructure.getAllSubscriptions();
    console.log("RESPOMMMMM", response)
    const subscriptionsList = subscriptionConverter.fromFirestore(
      response?.data?.data?.subscriptions
    );

    // let subscription = subscriptionConverter.fromFirestore(
    //   response?.data?.data?.subscriptions
    // );

    if (subscriptionsList) {
      const newData = [...subscriptionsList];
      return newData;
    }
    return [];
  }
);

export const CreateSubscription = createAsyncThunk(
  "subscriptions/createSubscription",
  async (data) => {
    const res = await ApiStructure.addSubscription(
      subscriptionConverter.toFirestore(data)
    );
    if (res && res.data && res.data.success) {
      return res.data;
    } else {
      return false;
    }
  }
);

export const EditSubscription = createAsyncThunk(
  "subscriptions/editSubscription",
  async (data) => {
    const res = await ApiStructure.updateSubscriptionData(
      subscriptionDataConverter.updateToFirestore(data),
      data.id
    );
    if (res && res.data && res.data.success) {
      return data;
    } else {
      return false;
    }
  }
);

export const DeleteSubscriptionApi = createAsyncThunk(
  "subscriptions/deleteSubscription",
  async (idArray) => {
    const res = await ApiStructure.deleteSubscription(idArray);
    return { ...res.data, id: idArray };
  }
);

export const UpdateUserSubscription = createAsyncThunk(
  "subscriptions/updateUserSubscription",
  async (data) => {
    const res = await ApiStructure.updateUserSubscription(
      data
    );
    if (res && res.data && res.data.success) {

      return res.data;
    } else {
      return res.data;
    }
  }
);


/////////////////////////////////////////////////
//----------- subscription info api calls --------------//
/////////////////////////////////////////////////

export const GetSubscriptionInfo = createAsyncThunk(
  "subscriptions/getSubscriptionInfo",
  async () => {
    const response = await ApiStructure.getSubscriptionInfo();
    const subscriptionsInfo = subscriptionInfoConverter.fromFirestore(
      response?.data?.data
    );

    // let subscription = subscriptionConverter.fromFirestore(
    //   response?.data?.data?.subscriptions
    // );

    if (subscriptionsInfo) {
      const newData = [...subscriptionsInfo];
      return newData;
    }
    return [];
  }
);


export const EditSubscriptionInfo = createAsyncThunk(
  "subscriptions/editSubscriptionInfo",
  async (data) => {
    const res = await ApiStructure.editSubscriptionInfo(
      data.data,
      data.id
    );
    if (res && res.data && res.data.success) {
      return data;
    } else {
      return false;
    }
  }
);

export const getUserActiveProducts = createAsyncThunk(
  "users/active-products",
  async (id) => {
    const res = await ApiStructure.getUserActiveProducts(id);
    if (res?.data?.success) {
      return res.data;
    }
  }
);