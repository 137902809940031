import React from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Button from "../general/Button";

const SubFormTable = ({
  noData, handlepricingOptionSubmit,
  activeinputValue,
  handlepricing_optionChange,
  setActiveIndexEdit,
  activeIndexEdit,
  formData,
  setActiveinputValue
}) => {
  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500 mt-5">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader text="Time Option" sortable={false} />
            <TableHeader text="Price" sortable={false} />
            <TableHeader text="Edit?" sortable={false} />
          </tr>
        </thead>
        <tbody>
          {formData.pricing_options && formData.pricing_options.length
            ? formData.pricing_options.map((pricing_option, index) => (
              <tr className="p-16" key={index}>
                <TableData
                  child={
                    <div className="w-1/2">
                      {pricing_option.time_option}
                    </div>
                  }
                />

                <TableData
                  child={
                    <>
                      <input
                        type="number"
                        id={`pricing_option-${index}`}
                        placeholder="Price"
                        className="w-1/2 m-0 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                        name="pricing_option"
                        key={index}
                        onChange={(e) => {
                          handlepricing_optionChange(e, index);
                        }}
                        readOnly={index !== activeIndexEdit}
                        value={
                          index === activeIndexEdit
                            ? activeinputValue
                            : pricing_option.price
                        }
                      />
                    </>
                  }
                />

                <TableData
                  child={
                    index === activeIndexEdit ? (
                      <div className="flex">
                        <Button
                          text="Cancel"
                          small={true}
                          bottomMargin={false}
                          fn={() => {
                            setActiveIndexEdit(-1);
                            setActiveinputValue(0);
                          }}
                        />

                        <Button
                          text="Save"
                          otherClass={"ml-2"}
                          small={true}
                          bottomMargin={false}
                          fn={() => {
                            handlepricingOptionSubmit(index);
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        text="Edit"
                        small={true}
                        bottomMargin={false}
                        fn={() => {
                          setActiveinputValue(pricing_option.price);
                          setActiveIndexEdit(index);
                          var textbox = document.getElementById(
                            `pricing_option-${index}`
                          );
                          textbox.focus();
                        }}
                      />
                    )
                  }
                />
              </tr>
            ))
            : noData()}
        </tbody>
      </table>
    </>
  )
}
export default SubFormTable;