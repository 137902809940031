import React from "react";
import toast from "react-hot-toast";
import { LINK_IS_EMPTY } from "../../Constant";
import Button from "../general/Button";
import { InstructionFrame } from "../users/InstructionFrame";
const UserAppCard = ({ app, status, btnText }) => {
  const logo = "/images/logo.webp";
  const [ifOpen, setIfOpen] = React.useState(false);
  return (
    <div
      className={`app_card w-full bg-blue-100 p-4 border-opacity-20 rounded border-2 border-${app?.active ? "green" : "red"
        }-500 shadow my-4 mx-auto md:mx-0 md:mr-4`}
    >
      {ifOpen ? (
        <InstructionFrame
          activeURL={app?.data?.active_url}
          cancelInstructionFrame={() => {
            setIfOpen(false);
          }}
        />
      ) : null}
      <header className="sm:flex-col md:flex-row flex-wrap items-center">
        <img
          className="w-12 h-12 border object-cover border-blue-500 rounded shadow block my-2 mr-4"
          src={app?.data?.icon || logo}
          alt=""
        />
        <h3 className="font-semibold text-xl">{app?.data?.title}</h3>
      </header>
      <p
        className={`my-4 font-semibold text-${app?.active ? app.code == "Subscription" ? "blue" : "green" : "red"
          }-900`}
      >
        {app?.active ? (

          app.code == "Subscription" ? (
            //Download from: https://uxwing.com/recurring-subscription-icon/
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 118.04 122.88"
              className="h-6 w-6 inline-block mr-2 subs-svg"
              fill="blue"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" />
            </svg>
          ) :
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {" "}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline-block mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {" "}
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
        {app.code == "Subscription" ? 'Subscription' : status}
      </p>
      <Button
        text={btnText}
        small={true}
        fn={() => {
          if (app?.active) {
            if (!app.data.active_url) {
              toast.error(LINK_IS_EMPTY)
            } else {
              setIfOpen(true);
            }
          } else {
            let url = app?.data?.inactive_url;
            url = url.includes("http") ? url : "http://" + url
            window.open(url);
          }
        }}
        bottomMargin={false}
        otherClass={"mt-8 block w-full py-2 px-2"}
        bgColor={app?.active ? "bg-blue-500" : "bg-blue-700"}
      />
      {/* </a> */}
    </div >
  );
};

export default UserAppCard;
