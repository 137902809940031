import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaginatedSubscriptions,
  setsubscriptionCount,
  goToNextPage,
  goToPreviousPage,
  setPageNumber,
  changeTypeStatus,
} from "../../features/admin/subscriptionSlice";
import toast from "react-hot-toast";
import Button from "../general/Button";
import Popup from "../general/Popup";
import SubscriptionForm from "../forms/SubscriptionForm";
import SubForm from "../forms/SubForm";
import SubscriptionInfoForm from "../forms/SubscriptionInfoForm";
import {
  DeleteSubscriptionApi,
  GetSubscriptions,
  EditSubscription,
  GetSubscriptionInfo,
} from "../../services";
import Loader from "../utils/Loader";
import Pagination from "../layout/Pagination";
import ItemPerPage from "../layout/ItemPerPage";
import { Helmet } from "react-helmet";
import {
  ACTIVE,
  CANNOT_DELETE_SUBSCRIPTION,
  DATA_IS_NOT_AVAILABLE,
  INACTIVE,
  SUBSCRIPTIONS,
  SELECT_STATUS,
  STATUS,
  ADD_SUBSCRIPTION,
  DELETE_SELECTED_SUBSCRIPTION,
  DELETE_SELECTED_SUBSCRIPTIONS,
  EDIT_SUBSCRIPTION_USER_PORTAL_INFO,
} from "../../Constant";
import SubscriptionTable from "./SubscriptionTable";
let isGetApiCall = false;
let isUpdate = false;
const Subscriptions = () => {
  // State
  const subscriptions = useSelector(
    (state) => state.subscriptions.list
  );
  const activeSubscriptions = useSelector(
    (state) => state.subscriptions
  );
  //To get inactive subscriptions
  const inactiveSubscriptions = useSelector(
    (state) => state.subscriptions.paginatedSubscription
  );
  const typeStatus = useSelector((state) => state.subscriptions.typeStatus);
  const totalSubscriptions = useSelector((state) => state.subscriptions.list);
  const subscriptionCount = useSelector(
    (state) => state.subscriptions.subscriptionCount
  );
  const pageNumber = useSelector((state) => state.subscriptions.pageNumber);
  const loading = useSelector((state) => state.subscriptions.loading);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [showSubscriptionInfoForm, setShowSubscriptionInfoForm] =
    useState(false);
  const [showSubForm, setShowSubForm] = useState(false);
  const [subscriptionToChange, setsubscriptionToChange] = useState(null);
  const [disablePagination, setDisablePagination] = useState(false);
  const [maxPages, setmaxPages] = useState(0);
  const [maxSubscriptionPerPage] = useState(10);
  const [OpenFilter, setOpenFilter] = useState(false);
  const subscriptionInfo = useSelector((state) => state.subscriptions.info);
  const [visibleSubscriptionsCount, setVisibleSubscriptionsCount] = useState(0)
  const [subscriptionToEdit, setSubscriptionToEdit] = useState({});
  // Dispatch
  const dispatch = useDispatch();
  //For active/inactive subscriptions
  useEffect(() => {
    const totalSubscriptionsData =
      typeStatus === "Active"
        ? activeSubscriptions
        : typeStatus === "Inactive"
          ? inactiveSubscriptions
          : totalSubscriptions;
    const mPNum = Math.ceil(totalSubscriptionsData.length / subscriptionCount);
    setmaxPages(mPNum);
  }, [
    totalSubscriptions,
    typeStatus,
    subscriptionCount,
    activeSubscriptions,
    inactiveSubscriptions,
  ]);

  //getting visible subscriptions count
  useEffect(() => {

    const visibleSubscriptions = subscriptions.filter((subscription) => subscription.available === true)
    setVisibleSubscriptionsCount(visibleSubscriptions.length)
  }, [subscriptions])


  //dispatch action for get subscriptions info
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetSubscriptionInfo());
      //   isGetApiCall = true;
    };
    getData();

    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelSubscriptionForm();
      }
    });
  }, [dispatch]);
  //dispatch action for subscriptions
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetSubscriptions());
      isGetApiCall = true;
    };
    getData();
  }, [dispatch, isUpdate]);

  useEffect(() => {
    if (isGetApiCall) {
      const getData = async () => {
        dispatch(getPaginatedSubscriptions());
        isGetApiCall = false;
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isGetApiCall]);

  // Methods
  const selectRow = (e, subscriptionId) => {
    if (e.target.checked) {
      setSelectedSubscriptions([...selectedSubscriptions, subscriptionId]);
      setDisablePagination(true);
    } else {
      setSelectedSubscriptions(
        selectedSubscriptions.filter((id) => id !== subscriptionId)
      );
      setDisablePagination(false);
    }
  };

  //SelectAll Subscription
  const selectAll = () => {
    setSelectedSubscriptions(subscriptions.map((p) => p.id));
    setDisablePagination(true);
  };
  // select None subscription
  const selectNone = () => {
    setSelectedSubscriptions([]);
    setDisablePagination(false);
  };
  //If data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
  //delete selected subscription
  const deleteSelected = () => {
    dispatch(DeleteSubscriptionApi(selectedSubscriptions));
    setShowPopup(false);
    setSelectedSubscriptions([]);
    setDisablePagination(false);
  };
  //edit selected subscription
  const handleEdit = (subscription, e) => {
    e.preventDefault();
    dispatch(
      EditSubscription({
        ...subscription,
        available: e.target.checked,
      })
    );
  };

  const updateSubscriptionList = () => {
    isUpdate = !isUpdate;
  }
  //To cancel popup
  const clearPopup = () => {
    setShowPopup(false);
  };
  //To cancel Subscription form
  const cancelSubscriptionForm = () => {
    setShowSubscriptionForm(false);
    setShowSubscriptionInfoForm(false);
    setsubscriptionToChange(null);
  };

  //To cancel SubSubscription form
  const cancelSubForm = () => {
    setShowSubForm(false);
    setsubscriptionToChange(null);
  };

  const cancelSubscriptionInfoForm = () => {
    setShowSubscriptionInfoForm(false);
    //setsubscriptionToChange(null);
  };

  //To displaySubscriptionform
  const displaySubscriptionForm = (subscription) => {
    if (subscription) {
      let temp = subscription;
      let products = temp.products.map((item) => ({
        ...item,
        value: item.title,
        label: item.title,
      }));
      let appList = temp.apps.map((item) => ({
        ...item,
        value: item.title,
        label: item.title,
      }));
      let featureList = temp.features.map((item) => ({
        ...item,
        value: item.title,
        label: item.title,
      }));
      temp.products = products;
      temp.apps = appList;
      temp.features = featureList;
      setSubscriptionToEdit({ ...temp });
      setShowSubscriptionForm(true);
      //  setsubscriptionToChange(subscription);
    }
    setShowSubscriptionForm(true);
  };

  //to displaysubform
  const displaySubForm = (subscription) => {
    // if (subscription) {
    //   setsubscriptionToChange(subscription);
    // }
    // setShowSubForm(true);
  };

  const OnStatusChange = (status) => {
    if (!loading) {
      dispatch(changeTypeStatus(status));
      setOpenFilter(false);
    }
  };

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>{SUBSCRIPTIONS}</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-blue-600 mb-8">{SUBSCRIPTIONS}</h1>
      {/* Popup */}
      {showPopup ? (
        <Popup
          yesFn={deleteSelected}
          noFn={clearPopup}
          text={
            selectedSubscriptions.length > 1
              ? DELETE_SELECTED_SUBSCRIPTIONS
              : DELETE_SELECTED_SUBSCRIPTION
          }
        />
      ) : null}
      {/* Subscription Form */}
      {showSubscriptionForm ? (
        <SubscriptionForm
          // subscription={"Subscription"}
          subscriptionEdit={subscriptionToEdit}
          cancelSubscriptionForm={cancelSubscriptionForm}
          updateSubscriptionList={updateSubscriptionList}
        />
      ) : null}
      {showSubForm ? (
        <SubForm
          subscription={subscriptionToChange}
          cancelSubForm={cancelSubForm}
        />
      ) : null}
      {showSubscriptionInfoForm ? (
        <SubscriptionInfoForm
          subscriptionInfo={subscriptionInfo}
          subscriptions={subscriptions}
          cancelSubscriptionInfoForm={cancelSubscriptionInfoForm}
          visibleSubscriptionsCount={visibleSubscriptionsCount}
          subscriptionLoading={loading}
        />
      ) : null}

      {/* Controls */}
      <div className="mb-8 flex justify-between">
        <div className="w-9/12">
          {!disablePagination ? (
            <ItemPerPage
              itemCount={subscriptionCount}
              maxItemsPerPage={maxSubscriptionPerPage}
              label={SUBSCRIPTIONS}
              setCount={setsubscriptionCount}
            />
          ) : null}
        </div>
        <div className="w-4/12">
          <Button
            text={ADD_SUBSCRIPTION}
            fn={() => {
              setSubscriptionToEdit({})
              setShowSubscriptionForm(true)
            }}
          />
          <Button
            text={
              selectedSubscriptions.length > 1
                ? DELETE_SELECTED_SUBSCRIPTIONS
                : DELETE_SELECTED_SUBSCRIPTION
            }
            bgColor="bg-red-900"
            fn={() => {
              if (selectedSubscriptions.includes("CqGqGd2uVyA0RfT5nVyJ")) {
                toast.error(CANNOT_DELETE_SUBSCRIPTION);
              } else {
                setShowPopup(true);
              }
            }}
            disabled={selectedSubscriptions.length === 0}
          />

          <Button
            text={EDIT_SUBSCRIPTION_USER_PORTAL_INFO}
            fn={() => setShowSubscriptionInfoForm(true)}
          />
          <div>
            <p>{STATUS}</p>
            <div
              style={{ zIndex: "1" }}
              className="w-2/3 select-none bg-blue-200 cursor-pointer font-medium relative"
            >
              <div
                onClick={() => {
                  if (!loading) {
                    setOpenFilter(!OpenFilter);
                  }
                }}
                className="px-4 py-2 text-lg border mt-1 border-blue-500 rounded font-medium flex justify-between"
              >
                <div>{typeStatus === "All" ? "Select Status" : typeStatus}</div>
                <div>▼</div>
              </div>
              {OpenFilter ? (
                <div
                  style={{ marginTop: "-3px" }}
                  className="w-full absolute border mt-1 bg-blue-200 border-blue-500"
                >
                  <div
                    onClick={() => {
                      OnStatusChange("All");
                    }}
                    className={`px-4 py-2 ${typeStatus === "All" ? "bg-blue-400" : ""
                      }`}
                  >
                    {SELECT_STATUS}
                  </div>
                  <div
                    onClick={() => {
                      OnStatusChange("Active");
                    }}
                    className={`px-4 py-2 ${typeStatus === "Active" ? "bg-blue-400" : ""
                      }`}
                  >
                    {ACTIVE}
                  </div>
                  <div
                    onClick={() => {
                      OnStatusChange("Inactive");
                    }}
                    className={`px-4 py-2 ${typeStatus === "Inactive" ? "bg-blue-400" : ""
                      }`}
                  >
                    {INACTIVE}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Data */}
          <SubscriptionTable
            selectAll={selectAll}
            selectNone={selectNone}
            selectedSubscriptions={selectedSubscriptions}
            subscriptions={subscriptions}
            selectRow={selectRow}
            handleEdit={handleEdit}
            displaySubscriptionForm={displaySubscriptionForm}
            displaySubForm={displaySubForm}
            noData={noData}
          />

          {!disablePagination ? (
            <Pagination
              prevFn={() => dispatch(goToPreviousPage())}
              nextFn={() => dispatch(goToNextPage())}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              maxPages={maxPages}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Subscriptions;
