import React from "react";
import Activate from "../components/users/Activate";
import Dashboard from "../components/users/Dashboard";
import Login from "../components/users/Login";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";


const User = ({role}) => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.url}/`}>
        <Login />
      </Route>
      <Route path={`${match.url}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${match.url}/activate`}>
        <Activate />
      </Route>
      <Route path={`${match.url}/login`}>
        <Login />
      </Route>

      <Redirect to={`${match.url}/login`} />
    
      
    </Switch>
  );
};

export default User;
