import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeleteUserApi, GetUsers } from "../../services";
import {
  sortByFirstName,
  sortByLastName,
  sortByEmail,
  sortByActivationDate,
  filterByKeyword,
  getPaginatedUsers,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setUserCount,
  deleteUsers,
} from "../../features/admin/userListSlice";
import Button from "../general/Button";
import Pagination from "../layout/Pagination";
import Search from "../layout/Search";
import ItemPerPage from "../layout/ItemPerPage";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Popup from "../general/Popup";
import UserForm from "../forms/UserForm";
import MergeUserForm from "../forms/MergeUserForm";
import Loader from "../utils/Loader";
import moment from "moment";
import { Helmet } from "react-helmet";
import { DATA_IS_NOT_AVAILABLE, DATA_IS_NOT_FOUND, DELETE_SELECTED_USER, DELETE_SELECTED_USERS, USERS, USER_ACCOUNT } from "../../Constant";

let paginationReduxCall = false;

const UserAccounts = () => {
  // State
  const users = useSelector((state) => state.users.paginatedUsers);
  const totalUsers = useSelector((state) => state.users.list);
  const filterKeyword = useSelector((state) => state.users.filterKeyword);
  const loading = useSelector((state) => state.users.loading);
  const userCount = useSelector((state) => state.users.userCount);
  const pageNumber = useSelector((state) => state.users.pageNumber);
  const [maxPages, setmaxPages] = useState(0);
  const [maxUsersPerPage,] = useState(10);

  useEffect(() => {
    if (typeof filterKeyword === "string") {
      if (filterKeyword.length > 0) {
        let regExp = new RegExp(`^${filterKeyword}`, `i`);
        const filterSearchData = totalUsers.filter(
          (user) =>
            user?.firstname?.match(regExp) || user?.lastname?.match(regExp) || user.email.match(regExp)
        );
        const mPNum = Math.ceil(filterSearchData.length / userCount);
        setmaxPages(mPNum);
      } else {
        const mPNum = Math.ceil(totalUsers.length / userCount);
        setmaxPages(mPNum);
      }
    } else {
      const mPNum = Math.ceil(totalUsers.length / userCount);
      setmaxPages(mPNum);
    }
  }, [totalUsers, userCount, filterKeyword, maxUsersPerPage]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [disablePagination, setDisablePagination] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [mergeUserForm, setMergeUserForm] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [changeUser, setChangeUser] = useState(null);

  // Dispatch
  const dispatch = useDispatch();

  //To get registered users
  // useEffect(() => {
  //   const getData =  () => {
  //     dispatch(GetUsers());
  //     dispatch(getPaginatedUsers());
  //   };
  //   getData();
  // }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetUsers());
      paginationReduxCall = true
      // dispatch(getPaginatedUsers());
    };
    getData();
    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelUserForm();
      }
    });
  }, [dispatch])

  useEffect(() => {
    if (paginationReduxCall) {
      const getData = () => {
        // dispatch(GetUsers());
        dispatch(getPaginatedUsers());
        paginationReduxCall = false
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paginationReduxCall])

  // Methods
  const selectRow = (e, user) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, user?.id]);
      setSelectedUsersData([...selectedUsersData, user]);
      setDisablePagination(true);
    } else {
      setSelectedUsers(selectedUsers.filter((idu) => idu !== user?.id));
      setSelectedUsersData(selectedUsersData.filter((u) => u?.id !== user?.id));
      setDisablePagination(false);
    }
  };
  //select users
  const selectAll = () => {
    setSelectedUsers(users.map((u) => u.id));
    setSelectedUsersData(users);
    setDisablePagination(true);
  };
  //if not selected users
  const selectNone = () => {
    setSelectedUsers([])
    setDisablePagination(false);
  };
  //if data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {filterKeyword ? DATA_IS_NOT_FOUND : DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
  //delete selected users
  const deleteSelected = () => {
    dispatch(deleteUsers(selectedUsers));
    dispatch(DeleteUserApi(selectedUsers));
    setShowPopup(false);
    setSelectedUsers([]);
    setDisablePagination(false);
  };
  //clear popup
  const clearPopup = () => {
    setShowPopup(false);
  };
  //To cancelUserForm 
  const cancelUserForm = () => {
    setShowUserForm(false);
    setChangeUser(null);
    setSelectedUsers([]);
    setSelectedUsersData([]);
    setDisablePagination(false);
  };
  //Cancel MergeUserForm
  const cancelMergeUserForm = () => {
    setMergeUserForm(false);
    setSelectedUsers([]);
    setSelectedUsersData([]);
    setDisablePagination(false);
  };
  //To displayUserForm
  const displayUserForm = (user) => {
    setShowUserForm(true);
    setChangeUser(user);
  };

  useEffect(() => {
    if (users && showUserForm && changeUser) {
      const filterData = users.filter((user) => user.id === changeUser.id);
      if (filterData) {
        setChangeUser(filterData[0]);
      }
    }
  }, [users, changeUser, showUserForm]);

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>{USERS}</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-blue-600 mb-8">{USER_ACCOUNT}</h1>
      {/* Popup */}
      {showPopup ? (
        <Popup
          yesFn={deleteSelected}
          noFn={clearPopup}
          width="w-1/3"
          text={
            selectedUsers.length === 1 ? "Delete Selected User" : "Delete Selected Users"
          }
        />
      ) : null}
      {mergeUserForm ? (
        <MergeUserForm
          selectedUsers={selectedUsersData}
          cancelUserForm={cancelMergeUserForm}
        />
      ) : null}
      {/* UserForm */}
      {showUserForm ? (
        <UserForm
          user={changeUser}
          cancelUserForm={cancelUserForm}
          isAdmin={false}
        />
      ) : null}
      {/* Controls */}
      <div className="mb-8 flex justify-between">
        <div className="w-9/12">
          <Search
            placeholder="Search User Accounts"
            fn={filterByKeyword}
            clearFn={filterByKeyword}
          />
          {!disablePagination ? (
            <ItemPerPage
              itemCount={userCount}
              maxItemsPerPage={maxUsersPerPage}
              label="Users"
              setCount={setUserCount}
            />
          ) : null}
        </div>
        <div className="w-3/12">
          <Button text="Add User" fn={() => setShowUserForm(true)} />
          <Button
            text={
              selectedUsers.length === 1 ? DELETE_SELECTED_USER : DELETE_SELECTED_USERS
            }
            bgColor="bg-red-900"
            fn={() => setShowPopup(true)}
            disabled={selectedUsers.length === 0}
          />
          {/* <Button
            text="Merge Selected Users"
            bgColor="bg-green-900"
            fn={() => setMergeUserForm(true)}
            disabled={selectedUsers.length <= 1}
          /> */}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Data */}
          <table className="table-auto w-full text-left border border-blue-500">
            <thead className="bg-blue-500 text-white p-16">
              <tr>
                <TableHeader
                  child={
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={(e) =>
                        e.target.checked ? selectAll() : selectNone()
                      }
                      checked={
                        users.length && selectedUsers.length === users.length
                      }
                    />
                  }
                  sortable={false}
                />
                <TableHeader text="First Name" fn={sortByFirstName} />
                <TableHeader text="Last Name" fn={sortByLastName} />
                <TableHeader text="Email" fn={sortByEmail} />
                <TableHeader text="Date Activated" fn={sortByActivationDate} />
                <TableHeader text="Action" sortable={false} />
              </tr>
            </thead>
            <tbody>
              {users.length
                ? users.map((user) => (
                  <tr key={user.id} className="p-16">
                    <TableData
                      child={
                        <input
                          type="checkbox"
                          name={user.id}
                          key={user.id}
                          onChange={(e) => selectRow(e, user)}
                          checked={
                            selectedUsers.findIndex(
                              (id) => id === user.id
                            ) !== -1 ? true : false
                          }
                        />
                      }
                    />
                    <TableData text={user.firstname || "N/A"} onClick={() => displayUserForm(user)} />
                    <TableData text={user.lastname || "N/A"} onClick={() => displayUserForm(user)} />
                    <TableData text={user.email || "N/A"} onClick={() => displayUserForm(user)} />
                    <TableData
                      text={
                        user?.activated_at
                          ? typeof user?.activated_at === "string"
                            ? moment(user.activated_at).format("MMM D, YYYY")
                            : "N/A"
                          : "N/A"
                      }
                      onClick={() => displayUserForm(user)}
                    />
                    <TableData
                      child={
                        <Button
                          text="Edit"
                          small={true}
                          bottomMargin={false}
                          fn={() => displayUserForm(user)}
                        />
                      }
                    />
                  </tr>
                ))
                : noData()}
            </tbody>
          </table>
          {/* Pagination */}
          {!disablePagination ? (
            <Pagination
              prevFn={() => dispatch(goToPreviousPage())}
              nextFn={() => dispatch(goToNextPage())}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              maxPages={maxPages}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default UserAccounts;
