import React, { useState, useRef } from "react";
import Button from "../general/Button";
import Popup from "../general/Popup";
import { uploadImage } from "../../apiStructure";
import { Eye, EyeOff } from "../utils/SVG";
import toast from "react-hot-toast";
import { ACTIVE_URL, INACTIVE_URL, MAILCHIMP_TAG, NAME, PHOTO_TOO_BIG, SHOPIFY_SKU, SHOPIFY_SKU_NOT } from "../../Constant";

const useForceUpdate = () => {
  const [, setValue] = useState(0); // integer state

  return () => setValue((value) => value + 1); // update the state to force render
};

const AppFeatureCard = ({
  type = "app",
  idx,
  id,
  deleteFn,
  title,
  data,
  ChangeFn,
  duplicateFn,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const inputFile = useRef(null);
  const forceUpdate = useForceUpdate();
  const clearPopup = () => {
    setShowPopup(false);
  };

  const logo = "/images/logo.webp";
  //For request a photo upload URL
  const uploadFileAndgetURL = async (data) => {
    const res = await uploadImage(data);
    if (res?.data?.publicUrl) {
      ChangeFn(false, idx, "iconUploaded");
      ChangeFn(res.data.publicUrl, idx, "image");
      forceUpdate();
    }
  };

  //Photo validation and calling url to upload
  const handleChangeFile2 = () => {
    let fileUploadData = inputFile.current.files[0];
    let Data = new FormData();

    if (fileUploadData.size > 2e6) {
      toast.error(PHOTO_TOO_BIG);
      return false;
    }
    else if (inputFile && "current" in inputFile && inputFile.current.files) {
      Data.append("image", fileUploadData);
      uploadFileAndgetURL(Data);
    }
  }

  //delete card
  const deleteCard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    deleteFn(id);
    setShowPopup(false);
  };

  return (
    <>
      {/* Popup */}
      {showPopup ? (
        <Popup
          width="w-1/2 top-4"
          yesFn={deleteCard}
          noFn={clearPopup}
          text={`Delete ${type}`}
        />
      ) : null}
      <div className="shadow p-4">
        <div className="flex justify-between items-center mb-8">
          <h3 className="text-2xl font-bold">{title}</h3>
          <div className="flex items-center">
            <div className="mr-4">
              <Button
                text="duplicate"
                fn={() => {
                  if (duplicateFn) {
                    duplicateFn(data, idx);
                  }
                }}
                // fontSemiBold={true}
                bottomMargin={false}
              />
            </div>
            {/* deprecated */}
            <div className="mr-4">
              {data?.deprecated ? (
                <div
                  style={{ color: "#b91c1c" }}
                  onClick={() => {
                    ChangeFn(false, idx, "deprecated");
                  }}
                  className="text-red-700"
                >
                  <EyeOff stroke={"currentColor"} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    ChangeFn(true, idx, "deprecated");
                  }}
                  style={{ color: "#047857" }}
                  className="text-green-700"
                >
                  <Eye stroke={"currentColor"} />
                </div>
              )}
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-red-900 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex items-center mb-4">
          <p className="font-semibold mr-2">Icon</p>

          <img
            src={data.icon || logo}
            className="w-12 h-12 object-cover border border-black rounded mr-8"
            alt=""
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={handleChangeFile2}
            accept="image/png, image/jpg, image/jpeg, image/webp"
            style={{ display: "none" }}
          />
          <Button
            text="Change Icon"
            fn={() => {
              // `current` points to the mounted file input element
              inputFile.current.click();
            }}
            small={true}
            bottomMargin={false}
          />
        </div>

        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {NAME}
          </label>
          <input
            type="text"
            name="title"
            defaultValue={data.title}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder={title}
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>

        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {type === "app" && "App ID"}
            {type === "feature" && "Feature ID"}
          </label>
          <input
            type="text"
            name="custom_id"
            defaultValue={data.custom_id}
            onChange={(e) => ChangeFn(e, idx)}
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>

        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {ACTIVE_URL}
          </label>
          <input
            type="text"
            name="active_url"
            defaultValue={data.active_url}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder="https://www.thoughtcastmagic.com/active_url"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {INACTIVE_URL}
          </label>
          <input
            type="text"
            name="inactive_url"
            defaultValue={data.inactive_url}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder="https://www.thoughtcastmagic.com/inactive_url"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {MAILCHIMP_TAG}
          </label>
          <input
            type="text"
            name="mailchip_tag"
            defaultValue={data.mailchip_tag}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder="Mailchimp/Shopify Tag 1"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {SHOPIFY_SKU}
          </label>
          <input
            type="text"
            name="shopify_SKU"
            defaultValue={data.shopify_SKU}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder="ProductSku1, ProductSku2"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {SHOPIFY_SKU_NOT}
          </label>
          <input
            type="text"
            name="shopify_unfulfill_SKU"
            defaultValue={data?.shopify_unfulfill_SKU || ""}
            onChange={(e) => ChangeFn(e, idx)}
            placeholder="ProductSku1, ProductSku2"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
      </div>
    </>
  );
};

export default AppFeatureCard;
