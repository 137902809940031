import React from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Toggle from "../general/Toggle";
import Button from "../general/Button";
import { VISIBLE,EDIT_SUBSCRIPTION, SUBSCRIPTION_APPS, SUBSCRIPTION_FEATURES, SUBSCRIPTION_NAME } from "../../Constant";

const SubscriptionTable = ({
  subscriptions, selectAll, selectNone, selectRow, handleEdit, displaySubscriptionForm, displaySubForm, noData, selectedSubscriptions
}) => {
  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader
              child={
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) =>
                    e.target.checked ? selectAll() : selectNone()
                  }
                  checked={
                    selectedSubscriptions.length &&
                    selectedSubscriptions.length === subscriptions.length
                  }
                />
              }
              sortable={false}
            />
            <TableHeader text={SUBSCRIPTION_NAME} sortable={false} />
            <TableHeader text={SUBSCRIPTION_APPS} sortable={false} />
            <TableHeader text={SUBSCRIPTION_FEATURES} sortable={false} />
            <TableHeader text={VISIBLE} sortable={false} />
            <TableHeader text={EDIT_SUBSCRIPTION} sortable={false} />
          </tr>
        </thead>
        <tbody>
          
          {subscriptions && subscriptions.length
            ? subscriptions.map((subscription) => (
              <tr key={subscription.id} className="p-16">
                <TableData
                  child={
                   
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        onChange={(e) => selectRow(e, subscription.id)}
                        checked={
                          selectedSubscriptions.findIndex(
                            (id) => id === subscription.id
                          ) !== -1
                            ? true
                            : false

                        }
                      /> 
                  }
                />
                {console.log(subscription,"subscription")}
                <TableData text={subscription.title || "N/A"} />
                <TableData
                  text={
                    subscription.apps.length
                      ?  subscription.apps.map(
                          (app, i) =>
                            app.title +
                            (i !== subscription.apps.length - 1 ? ", " : "")
                        )
                        : "N/A"
                  }
                />
                <TableData
                  text={
                    subscription.features.length
                      ? subscription.features.map(
                          (feature, i) =>
                            feature.title +
                            (i !== subscription.features.length - 1
                              ? ", "
                              : "")
                        )
                        : "N/A"
                  }
                />
                <TableData
                  child={
                    <Toggle
                      value={subscription.available}
                      isChecked={subscription.available?true:false}
                      onChange={(e) => { handleEdit(subscription, e)}}
                    />
                  }
                />
                <TableData
                  child={
                    <Button
                      text="Edit"
                      small={true}
                      bottomMargin={false}
                      fn={() => {
                        if (subscription.type === "subscription") {
                          displaySubscriptionForm(subscription);
                        }
                        if (subscription.type === "subscription") {
                          displaySubForm(subscription);
                        }
                      }}
                    />
                  }
                />
              </tr>
            ))
            : noData()}
        </tbody>
      </table>
    </>
  )
}
export default SubscriptionTable;