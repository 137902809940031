import React from "react";
import { ACTIVATING_PRODUCT, DO_YOU_WANT_TO } from "../../Constant";
import Button from "./Button";

const Popup = ({ width = "w-1/4", btnDisabled, yesFn, noFn, text, yesBtnText = "Yes", codeProducts, activeBtn, codeDuration }) => {

  return (
    <div
      className={`${width} fixed w-20 z-50 mx-auto inset-x-0 -translate-x-1/2 -trnslate-y-1/2 bg-white rounded-xl shadow-xl overflow-hidden `}
    >
      <div className="popup_header bg-blue-500 text-white font-bold p-4 flex justify-between">
        <h3>{text}?</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={noFn}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="popup_body p-8 font-semibold text-xl">
        <h3>{DO_YOU_WANT_TO} {text}?</h3>
      </div>

      <div className="popup_body px-8 pb-5 font-semibold text-xl">
        <h3>  {codeDuration && codeDuration !== "None" ? `Subscription : ${codeDuration}` : ""}  </h3>
      </div>


      {codeProducts && codeProducts.filter((prodFilter) => prodFilter.activeCount > 0).length > 0 && (
        <>
          <div className="popup_body px-8 pb-2 font-semibold text-xl">
            <h4>{ACTIVATING_PRODUCT} </h4>
          </div>
          <div className="popup_body px-8 py-2 font-semibold text-xl">
            <div>
              <span>
                {
                  codeProducts
                    ?.filter((prodFilter) => {
                      return prodFilter.activeCount > 0;
                    })
                    .map((product, idx) => {
                      const appString = product?.apps
                        ?.map((app, i) => {
                          return app.isChecked ? app?.title : null;
                        })
                        .filter((a) => a)
                        .join(", ")
                        .toString();
                      const featuresString = product?.features
                        ?.map((feature, i) => {
                          return feature?.isChecked ? feature?.title : null;
                        })
                        .filter((a) => a)
                        .join(", ")
                        .toString();
                      const finalString =
                        appString && featuresString
                          ? `${appString}, ${featuresString}`
                          : appString
                            ? appString
                            : featuresString
                              ? featuresString
                              : "";
                      return (
                        <React.Fragment key={idx}>
                          <div>
                            {product?.title}&nbsp; : &nbsp;{finalString}
                          </div>
                        </React.Fragment>
                      );
                    })

                }
              </span>
            </div>



          </div>

        </>

      )}
      <div className="py-4 mx-auto popup_action flex justify-center">
        <Button
          text={yesBtnText?.charAt(0).toUpperCase() + yesBtnText?.slice(1)}
          disabled={btnDisabled || activeBtn}
          fontSemiBold={true}
          fn={yesFn}
          otherClass="mr-1"
        />
        <Button otherClass="ml-1" bgColor="bg-red-900" text="No" fontSemiBold={true} fn={noFn} />
      </div>
    </div>
  );
};

export default Popup;
