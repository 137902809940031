import React from "react";
import {
  API_KEYS_SWAGGER, CODES_BULK_CODES, CODES_CHARACTER, CODES_LENGTH,
  CODES_PURCHASE_LOC, NEW_ACCOUNT_SUCCESS_MSG, USER_PORTAL_ACTIVE_PRODUCT_BUTTON_TEXT,
  USER_PORTAL_ACTIVE_PRODUCT_TEXT, USER_PORTAL_INACTIVE_PRODUCT_BUTTON_TEXT, USER_PORTAL_INACTIVE_PRODUCT_TEXT,
  USER_PORTAL_PRODUCT_DISPLAY_ORDER, USER_PORTAL_PROMOTIONAL_TEXT, USER_PORTAL_PROMOTIONAL_TEXT_FONT,
  USER_PORTAL_STORE_BUTTON_LINK, USER_PORTAL_STORE_BUTTON_TEXT
} from "../../Constant";
import DraggableContent from "./DraggableContent";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "4px 8px",
  margin: `0 -${grid}px 0 0`,
  height: "fit-content",
  border: isDragging ? "2px solid #1e3a8a" : "2px solid #000000",
  // change background colour if dragging
  background: isDragging ? "#3b82f6" : "lightgrey",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  borderBottom: isDraggingOver ? "1px solid #1e3a8a" : "1px solid #000000",
  display: "flex",
  padding: grid,
});

//Form for Settings
export const SettingForm = ({
  AccountSetting,
  setAccountSetting,
  displayOrder,
  setDislayOrder,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountSetting({
      ...AccountSetting,
      [name]:
        name === "default_number_of_case" && name === "default_code_length"
          ? Number(value)
          : value,
    });
  };
  //drag-end 
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      displayOrder,
      result.source.index,
      result.destination.index
    );

    if (newItems) {
      setDislayOrder(newItems);
    }
  };

  return (
    <>
      <div>
        {/* User Portal - Store button Link: */}
        <div className="flex items-center mb-2">
          <label
            className="block mr-4 font-semibold"
            htmlFor="store_button_link"
          >
            {USER_PORTAL_STORE_BUTTON_LINK}
          </label>
          <input
            type="text"
            autoFocus
            name="store_button_link"
            onChange={handleInputChange}
            value={AccountSetting.store_button_link}
            placeholder="Enter Store button Link here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/*  User Portal - Store button Text: */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="store_button_text"
          >
            {USER_PORTAL_STORE_BUTTON_TEXT}
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={AccountSetting.store_button_text}
            name="store_button_text"
            placeholder="Enter Store button Text here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/*  User Portal - Active Product Button Text: */}
        <div className="flex items-center mb-2">
          <label
            className="block mr-4 font-semibold"
            htmlFor="active_product_button_text"
          >
            {USER_PORTAL_ACTIVE_PRODUCT_BUTTON_TEXT}
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={AccountSetting.active_product_button_text}
            name="active_product_button_text"
            placeholder="Enter Active Product Button Text here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* User Portal - Inactive Product Button Text: */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="inactive_product_button_text"
          >
            {USER_PORTAL_INACTIVE_PRODUCT_BUTTON_TEXT}
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={AccountSetting.inactive_product_button_text}
            name="inactive_product_button_text"
            placeholder="Enter Inactive Product Button Text here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/*  User Portal - Active Product Text: */}
        <div className="flex items-center mb-2">
          <label
            className="block mr-4 font-semibold"
            htmlFor="active_product_text"
          >
            {USER_PORTAL_ACTIVE_PRODUCT_TEXT}
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={AccountSetting.active_product_text}
            name="active_product_text"
            placeholder="Enter Active Product Text here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* User Portal - Inactive Product Text: */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="inactive_product_text"
          >
            {USER_PORTAL_INACTIVE_PRODUCT_TEXT}
          </label>
          <input
            type="text"
            onChange={handleInputChange}
            value={AccountSetting.inactive_product_text}
            name="inactive_product_text"
            placeholder="Enter Inactive Product Text here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* User Portal - Product Display Order -- remain to add */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="inactive_product_text"
          >
            {USER_PORTAL_PRODUCT_DISPLAY_ORDER}
          </label>
          <DraggableContent onDragEnd={onDragEnd} getItemStyle={getItemStyle} getListStyle={getListStyle} displayOrder={displayOrder} />
        </div>
        {/* User Portal - Promotional text */}
        <div className="flex mb-6">
          <label
            className="block mt-4 mr-4 font-semibold"
            htmlFor="promotional_text"
          >
            {USER_PORTAL_PROMOTIONAL_TEXT}
          </label>
          <textarea
            name="promotional_text"
            onChange={handleInputChange}
            value={AccountSetting.promotional_text}
            className="w-1/2 my-2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            placeholder="Enter Promotional text here..."
            rows="8"
          />
        </div>
        {/* User Portal - Promotional text - font size */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="promotional_text_font_size"
          >
            {USER_PORTAL_PROMOTIONAL_TEXT_FONT}
          </label>
          <input
            type="number"
            onChange={handleInputChange}
            value={AccountSetting.promotional_text_font_size}
            name="promotional_text_font_size"
            className="w-1/6 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* New Account - Promotional text */}
        <div className="flex mb-6">
          <label
            className="block mt-4 mr-4 font-semibold"
            htmlFor="success_message"
          >
            {NEW_ACCOUNT_SUCCESS_MSG}
          </label>
          <textarea
            name="success_message"
            value={AccountSetting.success_message}
            onChange={handleInputChange}
            className="w-1/2 my-2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            placeholder="Enter Success Message here..."
            rows="8"
          />
        </div>
        {/* Codes - Bulk Codes - Default number of codes:*/}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="default_number_of_case"
          >
            {CODES_BULK_CODES}
          </label>
          <input
            type="number"
            value={AccountSetting.default_number_of_case}
            name="default_number_of_case"
            onChange={handleInputChange}
            className="w-1/6 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* Codes  - Default code length:*/}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="default_code_length"
          >
            {CODES_LENGTH}
          </label>
          <input
            type="number"
            value={AccountSetting.default_code_length}
            name="default_code_length"
            onChange={handleInputChange}
            className="w-1/6 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* Codes  - code characters: */}
        <div className="flex items-center mb-6">
          <label className="block mr-4 font-semibold" htmlFor="code_characters">
            {CODES_CHARACTER}
          </label>
          <input
            type="text"
            name="code_characters"
            value={AccountSetting.code_characters}
            onChange={handleInputChange}
            placeholder="Enter Code Characters here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* Codes  - Default Purchase Location: */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="default_purchase_location"
          >
            {CODES_PURCHASE_LOC}
          </label>
          <input
            type="text"
            name="default_purchase_location"
            value={AccountSetting.default_purchase_location}
            onChange={handleInputChange}
            placeholder="Enter Default Purchase Location here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* API keys - swagger_docs_link*/}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="default_purchase_location"
          >
            {API_KEYS_SWAGGER}
          </label>
          <input
            type="text"
            name="swagger_docs_link"
            value={AccountSetting.swagger_docs_link}
            onChange={handleInputChange}
            placeholder="Enter Default Purchase Location here..."
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* { Stripe SDK KEYS} */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="stripe_test_publishable_key"
          >
            Stripe Mode:
          </label>
          <select
            type="text"
            required
            name="stripe_mode"
            value={AccountSetting.stripe_mode}
            onChange={handleInputChange}
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          >
            <option value="">Select</option>
            <option value="test" > Test</option>
            <option value="live" > Live</option>
          </select>
        </div>
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="stripe_test_publishable_key"
          >
            Stripe Test Publishable Key:
          </label>
          <input
            type="text"
            required
            name="stripe_test_publishable_key"
            value={AccountSetting.stripe_test_publishable_key}
            onChange={handleInputChange}
            placeholder="Stripe test publishable key"
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="stripe_test_secret_key"
          >
            Stripe Test Secret Key:
          </label>
          <input
            type="text"
            required
            name="stripe_test_secret_key"
            value={AccountSetting.stripe_test_secret_key}
            onChange={handleInputChange}
            placeholder="Stripe test secret key"
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        {/* Stripe Live */}
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="stripe_live_publishable_key"
          >
            Stripe Live Publishable Key:
          </label>
          <input
            type="text"
            required
            name="stripe_live_publishable_key"
            value={AccountSetting.stripe_live_publishable_key}
            onChange={handleInputChange}
            placeholder="Stripe live publishable key"
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
        <div className="flex items-center mb-6">
          <label
            className="block mr-4 font-semibold"
            htmlFor="stripe_live_secret_key"
          >
            Stripe Live Secret Key:
          </label>
          <input
            type="text"
            required
            name="stripe_live_secret_key"
            value={AccountSetting.stripe_live_secret_key}
            onChange={handleInputChange}
            placeholder="Stripe live secret key"
            className="w-1/2 block rounded-lg px-2 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          />
        </div>
      </div>
    </>
  );
};
