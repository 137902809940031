import toast, { Toaster } from "react-hot-toast";
import instance from "./instance";
import * as RequestApi from "./RequestApi";
import { history } from "../history";
import {
  FIREBASE_ID_TOKEN,
  NO_TOKEN_ACCESS_DENIED,
  USER_NOT_FOUND,
  SOMETHING_WENT_WRONG,
  PASSWORD_RESET_EMAIL_SENT,
  PASSWORD_RESET_SUCCESFUL,
  FILE_TOO_LARGE,
  CODE_READY_FOR_ACTIVATION,
} from "../Constant";

/////////////////////////////////////////////
//--------------AUTH RELATED---------------//
/////////////////////////////////////////////

const getToken = () => {
  try {
    let localstorage = localStorage.getItem("uls");
    const token =
      localstorage && JSON.parse(localstorage) && JSON.parse(localstorage).token
        ? JSON.parse(localstorage).token
        : "";
    return token;
  } catch (e) {
    return "";
  }
};

const manageFireBaseToken = (err, type) => {
  const errorValue =
    err?.response?.data?.error ||
    err?.response?.data?.data ||
    err.message ||
    SOMETHING_WENT_WRONG;
  if (
    typeof errorValue === "string" &&
    (errorValue.includes(USER_NOT_FOUND) ||
      errorValue.includes(FIREBASE_ID_TOKEN) ||
      errorValue.includes(NO_TOKEN_ACCESS_DENIED))
  ) {
    localStorage.removeItem("uls");
    history.push("/");
  }
  if (type) {
  } else {
    toast.error(errorValue);
  }
};

export const login = async (token, refreshToken = "") => {
  try {
    const response = await instance.post(
      RequestApi.authRequest.login,
      { refreshToken: refreshToken },
      {
        headers: {
          token: token,
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      }
    );

    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const refreshToken = async () => {
  try {
    const response = await instance.post(
      RequestApi.authRequest.refreshToken,
      null,
      {
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      }
    );

    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const logout = async (refreshToken = "") => {
  try {
    const response = await instance.post(
      RequestApi.authRequest.logout,
      { refreshToken: refreshToken },
      {
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      }
    );

    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await instance.post(
      RequestApi.authRequest.forgotPassword,
      { email: email },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    if (response?.data?.success) {
      toast.success(PASSWORD_RESET_EMAIL_SENT);
      return true;
    }
    return response;
  } catch (err) {
    const errorValue =
      err?.response?.data?.error ||
      err?.response?.data?.data ||
      err.message ||
      SOMETHING_WENT_WRONG;
    toast.error(errorValue);
    return false;
  }
};

export const changePassword = async (body) => {
  try {
    const response = await instance.post(
      RequestApi.authRequest.changePassword,
      body
    );
    if (response?.data?.success) {
      toast.success(PASSWORD_RESET_SUCCESFUL);
      return true;
    }
    return response;
  } catch (err) {
    const errorValue =
      err?.response?.data?.error ||
      err?.response?.data?.data ||
      err.message ||
      SOMETHING_WENT_WRONG;
    toast.error(errorValue);
    return false;
  }
};

export const checkCode = async (code, hideSuccess = false) => {
  try {
    const response = await instance.get(
      `${RequestApi.authRequest.checkCode}?code=${code}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    if (response?.data?.success) {
      if (!hideSuccess) {
        toast.success(CODE_READY_FOR_ACTIVATION);
      }
    }
    return response;
  } catch (e) {
    manageFireBaseToken(e);
  }
};

export const signup = async (data) => {
  try {
    const response = await instance.post(RequestApi.authRequest.signup, data, {
      headers: {
        token: getToken(),
      },
    });

    return response;
  } catch (e) {
    manageFireBaseToken(e);
  }
};

/////////////////////////////////////////////
//--------------USERS RELATED--------------//
/////////////////////////////////////////////

export const getAllUsers = async () => {
  try {
    const response = await instance.get(RequestApi.userRequest.getAllUsers, {
      headers: {
        token: getToken(),
      },
    });
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getAllAdmins = async () => {
  try {
    const response = await instance.get(RequestApi.userRequest.getAllAdmins, {
      headers: {
        token: getToken(),
      },
    });

    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getUser = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.userRequest.getUser}?id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const addUser = async (data) => {
  try {
    const response = await instance.post(RequestApi.userRequest.addUser, data, {
      headers: {
        token: getToken(),
      },
    });
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const updateUser = async (data, id) => {
  try {
    const response = await instance.post(
      `${RequestApi.userRequest.updateUser}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const deleteUser = async (ids) => {
  try {
    const response = await instance.post(
      `${RequestApi.userRequest.deleteMultipleUser}`,
      {
        id: ids,
      },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const mergeUser = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.userRequest.mergeMultipleUser}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const deprecateProductUser = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.userRequest.deprecateProduct}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const subToggle = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.userRequest.subToggle}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

/////////////////////////////////////////////
//-------------PRODUCTS RELATED------------//
/////////////////////////////////////////////

export const getAllProducts = async () => {
  try {
    const response = await instance.get(
      RequestApi.productRequest.getAllProducts,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getProduct = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.productRequest.getProduct}?id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const addProduct = async (data) => {
  try {
    const response = await instance.post(
      RequestApi.productRequest.addProduct,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const deleteProduct = async (ids) => {
  try {
    const response = await instance.post(
      `${RequestApi.productRequest.deleteMultipleProduct}`,
      {
        id: ids,
      },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const updateProduct = async (data, id) => {
  try {
    const response = await instance.post(
      `${RequestApi.productRequest.updateProduct}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const checkProductId = async (id) => {
  try {
    const data = {
      id: id,
    };
    const response = await instance.post(
      RequestApi.productRequest.checkProductId,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

/////////////////////////////////////////////
//---------------CODE RELATED--------------//
/////////////////////////////////////////////

export const getAllCodes = async () => {
  try {
    const response = await instance.get(RequestApi.codeRequest.getAllCodes, {
      headers: {
        token: getToken(),
      },
    });
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getCode = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.productRequest.getProduct}?id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const addCode = async (data) => {
  try {
    const response = await instance.post(
      RequestApi.codeRequest.addMultiCodes,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );

    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const redeemCode = async (data) => {
  try {
    const response = await instance.post(
      RequestApi.codeRequest.redeemCode,
      {
        code: data,
      },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const deleteCode = async (ids) => {
  try {
    const response = await instance.post(
      `${RequestApi.codeRequest.deleteMultiCodes}`,
      {
        id: ids,
      },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const updateCode = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.codeRequest.updateMultiCodes}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const codeHistory = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.codeHistoryRequest.codeHistory}?user_id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (error) {
    manageFireBaseToken(error);
  }
};
export const codeActivation = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.codeHistoryRequest.codeActivation}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (error) {
    manageFireBaseToken(error);
  }
};

///////////////////////////////////////////////////////
//---------------AccountSetting RELATED--------------//
///////////////////////////////////////////////////////

export const updateSetting = async (data, id) => {
  try {
    const response = await instance.post(
      `${RequestApi.AccountSettingRequest.updateSetting}?setting_id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );

    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const updateDisplayOrder = async (data, id) => {
  try {
    const response = await instance.post(
      `${RequestApi.AccountSettingRequest.updateDisplayOrder}?admin_id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const getSetting = async () => {
  try {
    const response = await instance.get(
      `${RequestApi.AccountSettingRequest.getSetting}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getDisplayOrderSetting = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.AccountSettingRequest.getDisplayOrder}?admin_id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

////////////////////////////////////////////////////////////
//---------------Update Subscription RELATED--------------//
////////////////////////////////////////////////////////////

export const updateSubscription = async (id, data) => {
  try {
    const response = await instance.post(
      `${RequestApi.productRequest.updateSubscription}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

//////////////////////////////////////////////////////
//---------------Upload Image RELATED---------------//
//////////////////////////////////////////////////////

export const uploadImage = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.uploadImageRequest.uploadImage}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    console.log(JSON.stringify(err));
    if (err.code === 413) {
      Toaster.error(FILE_TOO_LARGE);
    } else {
      manageFireBaseToken(err);
    }
  }
};

export const checkOutApi = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.checkoutApi}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const cancelSubscription = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.cancelSubscription}?user_id=${data}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

///////////////////////////////////////////////////////
//---------------API keys RELATED--------------//
///////////////////////////////////////////////////////

export const getAllApikeys = async () => {
  try {
    const response = await instance.get(
      `${RequestApi.apiKeysRequest.getApiKeys}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, false);
  }
};

export const deleteApiKeys = async (email) => {
  try {
    const body = {
      email,
    };
    const response = await instance.post(
      `${RequestApi.apiKeysRequest.deleteApiKey}`,
      body,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, false);
  }
};

export const generateApiKey = async (email) => {
  try {
    const body = {
      email,
    };
    const response = await instance.post(
      `${RequestApi.apiKeysRequest.generateApiKey}`,
      body,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, false);
  }
};

/////////////////////////////////////////////
//-------------SUBSCRIPTION RELATED------------//
/////////////////////////////////////////////

export const getAllSubscriptions = async () => {
  try {
    const response = await instance.get(
      RequestApi.subscriptionRequest.getAllSubscriptions,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err, true);
  }
};

export const getSubscription = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.subscriptionRequest.getSubscription}?id=${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const addSubscription = async (data) => {
  try {
    const response = await instance.post(
      RequestApi.subscriptionRequest.addSubscription,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
// `${RequestApi.subscriptionRequest.updateSubscription}?id=${id}`,
// RequestApi.subscriptionRequest.editSubscription
export const editSubscription = async (data, id) => {
  try {
    console.log("ID issssssssss", id);
    const response = await instance.post(
      `${RequestApi.subscriptionRequest.editSubscription}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const deleteSubscription = async (ids) => {
  try {
    const response = await instance.post(
      `${RequestApi.subscriptionRequest.deleteMultipleSubscription}`,
      {
        id: ids,
      },
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

// export const updateSubscription = async (data, id) => {
//   try {
//     const response = await instance.post(
//       `${RequestApi.subscriptionRequest.updateSubscription}?id=${id}`,
//       data,
//       {
//         headers: {
//           token: getToken(),
//         },
//       }
//     );
//     return response;
//   } catch (err) {
//     manageFireBaseToken(err);
//   }
// };

export const checkSubscriptionId = async (id) => {
  try {
    const data = {
      id: id,
    };
    const response = await instance.post(
      RequestApi.subscriptionRequest.checkSubscriptionId,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateSubscriptionData = async (data, id) => {
  console.log("data", data);
  try {
    const response = await instance.post(
      `${RequestApi.subscriptionRequest.updateSubscription}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const getSubscriptionInfo = async (data, id) => {
  console.log("data", data);
  try {
    const response = await instance.get(
      `${RequestApi.subscriptionRequest.info}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const editSubscriptionInfo = async (data, id) => {
  try {
    const response = await instance.post(
      `${RequestApi.subscriptionRequest.infoUpdate}?id=${id}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const updateUserSubscription = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.subscriptionRequest.updateUserSubscription}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const createSetupIntent = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.createSetupIntent}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const listSetupIntent = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.listSetupIntent}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const removeSetupIntent = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.removeSetupIntent}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const updateDefaultPaymentMethod = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.updateDefaultPaymentMethod}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
export const getDefaultPaymentMethod = async (data) => {
  try {
    const response = await instance.post(
      `${RequestApi.makePaymentRequest.getDefaultPaymentMethod}`,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};

export const getUserActiveProducts = async (id) => {
  try {
    const response = await instance.get(
      `${RequestApi.userRequest.getAllActiveProducts}/${id}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (error) {
    manageFireBaseToken(error);
  }
};

export const resetAppsListeningToken = async (data) => {
  try {
    const response = await instance.post(
      RequestApi.userRequest.resetAppsListeningToken,
      data,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response;
  } catch (err) {
    manageFireBaseToken(err);
  }
};
