import React from 'react'

const Loader = () => {
  return (
    <div className="justify-center flex mt-16">
      <div className="w-4 h-4 absolute">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75"></span>
      </div>
    </div>
  )
}

export default Loader
