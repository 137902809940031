import React from 'react';
import {SUBSCRIPTION } from '../../Constant';

const SubscriptionFormHeader = ({ isEdit,isSubscription, handleSubmit, submitting, resetValue, iconUpload, loading, appiconUpload, cancelProductForm, featuresiconUpload, }) => {
  return (
    <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
      <h3 className="text-2xl">{isEdit ? "Edit" : "Add"} {SUBSCRIPTION}</h3>
    </div>
  )
}

export default SubscriptionFormHeader