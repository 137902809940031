import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SearchBar from "material-ui-search-bar";

const Search = ({ placeholder, fn, clearFn, setSeachKeyWord }) => {
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (keyword.length) {
      dispatch(fn(keyword));
    } else {
      dispatch(clearFn());
    }
  }, [keyword, fn, clearFn, dispatch]);

  useEffect(() => {
    if (setSeachKeyWord) {
      setSeachKeyWord(keyword);
    }
  }, [setSeachKeyWord, keyword]);

  return (
    <div className="mb-4 w-full">
      <SearchBar
        autoComplete="off"
        name="search"
        id="searchKeyword"
        className="w-11/12 block rounded-lg px-4 py-2 text-blue-900 font-semibold"
        value={keyword}
        onChange={(value) => setKeyword(value)}
        placeholder={placeholder}
        onCancelSearch={() => setKeyword("")}
      />
    </div>
  );
};

export default Search;
