import React from "react";
import {
  ACTIVATING_FOLLOWING_SUBSCRIPTION_PRODUCT,
  ALL_FIELD_ARE_REQ, YOUR_CODE_ACTIVE,
  NO_SUBSCRIPTION_TO_ACTIVATE,
  ENTER_ACTIVATION_NEW_ACCOUNT,
  NO_PRODUCT_TO_ACTIVATE,
  NEED_HELP_EMAIL_US, REMINDER,
  IF_YOU_HAVE_ACCOUNT_LOGIN,
  AND_LOGIN_TO_ACTIVATE_YOUR_CODE,
} from "../../Constant";
import { NavLink } from "react-router-dom";
import Button from "../general/Button";


const ActivateNewAccount = ({
  getActivatedProducts, getActivatedSubscriptions, isCodeValid, activatingSubscriptions, activatingProducts, formData, handleChange, error, handleSubmit, loading, code, setCode,
}) => {
  return (
    <>
      <div>
        <p className="my-4 font-semibold text-xl">
          {isCodeValid
            ? YOUR_CODE_ACTIVE
            : ENTER_ACTIVATION_NEW_ACCOUNT}
        </p>
        {isCodeValid && (activatingSubscriptions || activatingProducts) && <p>{ACTIVATING_FOLLOWING_SUBSCRIPTION_PRODUCT}</p>}
        {activatingSubscriptions && (
          <p>
            <p>Subscriptions:</p>
            {getActivatedSubscriptions().length !== 0 ? getActivatedSubscriptions() : <p className="text-red-600 font-semibold">{NO_SUBSCRIPTION_TO_ACTIVATE}</p>}
          </p>
        )}
        {activatingProducts && (
          <p>
            <p>Products:</p>
            {getActivatedProducts().length !== 0 ? getActivatedProducts() : <p className="text-red-600 font-semibold">{NO_PRODUCT_TO_ACTIVATE}</p>}
          </p>
        )}
        {isCodeValid ? (
          <div>
            <small className="text-red-600 font-semibold">
              {ALL_FIELD_ARE_REQ}
            </small>{" "}
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="text"
                placeholder="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                autoFocus
              />
              {error.firstname ? (
                <small className="text-red-600 font-semibold">
                  {error.firstname}
                </small>
              ) : null}
            </div>
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="text"
                name="lastname"
                placeholder="Last Name"
                value={formData.lastname}
                onChange={handleChange}
              />
              {error.lastname ? (
                <small className="text-red-600 font-semibold">
                  {error.lastname}
                </small>
              ) : null}
            </div>
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="text"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              {error.email ? (
                <small className="text-red-600 font-semibold">
                  {error.email}
                </small>
              ) : null}
            </div>
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="text"
                placeholder="Confirm Email"
                name="confirm_email"
                value={formData.confirm_email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              {error.confirm_email ? (
                <small className="text-red-600 font-semibold">
                  {error.confirm_email}
                </small>
              ) : null}
            </div>
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              {error.password ? (
                <small className="text-red-600 font-semibold">
                  {error.password}
                </small>
              ) : null}
            </div>
            <div className="my-4">
              <input
                className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                type="password"
                name="retype_password"
                placeholder="Confirm Password"
                value={formData.retype_password}
                onChange={handleChange}
              />
              {error.retype_password ? (
                <small className="text-red-600 font-semibold">
                  {error.retype_password}
                </small>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <input
              className={`w-full ${isCodeValid ? "bg-gray-100 text-gray-500" : "text-blue-600"
                }  md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  font-semibold`}
              type="text"
              placeholder="1234567"
              value={code}
              disabled={isCodeValid}
              onChange={(e) => setCode(e.target.value)}
              autoFocus
            />
            {error.code ? (
              <small className="text-red-600 font-semibold">
                {error.code}
              </small>
            ) : null}
          </div>
        )}

        <div className="w-9/12 md:w-2/5 lg:w-1/2 mx-auto flex justify-around items-center mt-8 mb-4">
          <NavLink to="/">
            <Button
              text="Back To Login"
              bgColor="bg-blue-200"
              textColor="text-blue-600"
              small={true}
            />
          </NavLink>
          <div className={`${isCodeValid ? "ml-6" : ""}`}>
            <Button
              id="submitButtton"
              disabled={loading}
              text={isCodeValid ? "Create Account" : "Activate"}
              small={true}
              fn={handleSubmit}
            />
          </div>
        </div>
        <a
          className="text-blue-600 my-4"
          href="mailto:support@thoughtcastmagic.com?subject=New Account Creation Help"
        >
          {NEED_HELP_EMAIL_US}
        </a>
        {isCodeValid ? (
          <div className="mt-6 mb-6">
            <p>{REMINDER}</p>
            <p>
              {IF_YOU_HAVE_ACCOUNT_LOGIN}
            </p>
            <p>{AND_LOGIN_TO_ACTIVATE_YOUR_CODE}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
export default ActivateNewAccount;