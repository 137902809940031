import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { CreateProduct, EditProduct } from "../../services";
import { uploadImage } from "../../apiStructure";
import { succesResponseMethod } from "../../features/admin/productSlice";
import { initialAppFeature, productFormData } from '../utils/commonVariable';
import {
  PLEASE_ENTER_PRODUCTNAME, ATLEAST_ONEAPP_ONEFEATURE,
  REPEATING_ID_NOT_ALLOWED, SOMETHING_WENT_WRONG, PHOTO_TOO_BIG,
} from "../../Constant";
import ProductFormHeader from "./PrductFormHeader";
import ProductFormDetails from "./ProductFormDetails";

const useForceUpdate = () => {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
};

const ProductForm = ({ product, cancelProductForm }) => {
  // let storage = JSON;
  const inputFile = useRef(null);
  const succesResponseProduct = useSelector(
    (state) => state.products.succesResponseProduct
  );
  const generateId = useCallback((len) => {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }, [])
  const loading = useSelector((state) => state.products.loadingSubmit);
  const forceUpdate = useForceUpdate();
  const [apps, setApps] = useState([
    {
      id: new Date().getTime().toString() + generateId(10).toString(),
      custom_id: new Date().getTime().toString() + generateId(10).toString(),
      title: "App 1",
      ...initialAppFeature
    },
  ]);
  const [features, setFeatures] = useState([
    {
      id: new Date().getTime().toString() + generateId(10).toString(),
      custom_id: new Date().getTime().toString() + generateId(10).toString(),
      title: "Feature 1",
      ...initialAppFeature
    },
  ]);
  const [submitting, setSubmitting] = useState(false);
  const [appFeatureUpdated, setappFeatureUpdated] = useState(false);
  const [iconUpload, setIconUpload] = useState(false);
  const [appiconUpload, setAppIconUpload] = useState(false);
  const [featuresiconUpload, setFeaturesIconUpload] = useState(false);
  const [formData, setFormData] = useState({
    product_id: new Date().getTime().toString() + generateId(10).toString(),
    ...productFormData
  });
  // const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let appIUDonetemp = false;
    const appIUDone = Array.from(apps);
    appIUDone.map((dataV) => {
      if (dataV.iconUploaded) {
        appIUDonetemp = true;
        return true;
      }
      return false;
    });
    setAppIconUpload(appIUDonetemp);
    let featuresIUDonetemp = false;
    const featuresIUDone = Array.from(features);
    featuresIUDone.map((dataV) => {
      if (dataV.iconUploaded) {
        featuresIUDonetemp = true;
        return true;
      }
      return false;
    });
    setFeaturesIconUpload(featuresIUDonetemp);
    setappFeatureUpdated(false);
  }, [apps, features, appFeatureUpdated]);

  const resetValue = useCallback(() => {
    setFormData({
      product_id: new Date().getTime().toString() + generateId(10).toString(),
      ...productFormData
    });
    setFeatures([
      {
        id: new Date().getTime().toString() + generateId(10).toString(),
        custom_id: new Date().getTime().toString() + generateId(10).toString(),
        ...initialAppFeature
      },
    ]);
    setApps([
      {
        id: new Date().getTime().toString() + generateId(10).toString(),
        custom_id: new Date().getTime().toString() + generateId(10).toString(),
        ...initialAppFeature
      },
    ]);
  }, [generateId]);

  useEffect(() => {
    if (succesResponseProduct) {
      dispatch(succesResponseMethod(false));
      cancelProductForm();
      resetValue();
    }
  }, [succesResponseProduct, cancelProductForm, dispatch, resetValue]);



  //check apps and feature in product
  useEffect(() => {
    if (product) {
      const deepCloneProduct = JSON.parse(JSON.stringify(product));
      setFormData(deepCloneProduct);
      setApps(deepCloneProduct.apps);
      setFeatures(deepCloneProduct.features);
    }
  }, [product]);

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  const addApp = () => {
    let currentApps = apps;
    currentApps.push({
      id: new Date().getTime().toString() + generateId(10).toString(),
      custom_id: new Date().getTime().toString() + generateId(10).toString(),
      title: "New App",
      ...initialAppFeature
    });
    setApps([...currentApps]);
  };

  //Add new feature
  const addFeatures = () => {
    let currentFeatures = features;
    currentFeatures.push({
      id: new Date().getTime().toString() + generateId(10).toString(),
      custom_id: new Date().getTime().toString() + generateId(10).toString(),
      title: "New Feature",
      ...initialAppFeature
    });
    setFeatures([...currentFeatures]);
  };

  //handle submit of form for app and feature
  const handleSubmit = async () => {
    if (!formData.title) {
      toast.error(PLEASE_ENTER_PRODUCTNAME);
      return false;
    }

    if (!(apps.length || features.length)) {
      toast.error(ATLEAST_ONEAPP_ONEFEATURE);
      return false;
    }

    if (apps.length || features.length) {
      submitDispatch({
        ...formData,
        apps: apps,
        features: features,
      });
      setFormData({
        ...formData,
        apps: apps,
        features: features,
      });
    }
  };

  //for validation of App Ids and Feature Ids
  const submitDispatch = async (formData) => {
    setSubmitting(true);
    let alreadyExistId = [];
    if (product) {
      product.apps.forEach((apt) => {
        if (!alreadyExistId.includes(apt.custom_id)) {
          alreadyExistId.push(apt.custom_id);
        }
      });
      product.features.forEach((fat) => {
        if (!alreadyExistId.includes(fat.custom_id)) {
          alreadyExistId.push(fat.custom_id);
        }
      });
    }
    let checkUnique = [];

    if (product && formData.product_id !== product.product_id) {
      checkUnique.push(formData.product_id);
    }

    if (!product) {
      checkUnique.push(formData.product_id);
    }

    formData.apps.forEach((app) => {
      if (!alreadyExistId.includes(app.custom_id)) {
        checkUnique.push(app.custom_id);
      }
    });

    formData.features.forEach((feature) => {
      if (!alreadyExistId.includes(feature.custom_id)) {
        checkUnique.push(feature.custom_id);
      }
    });

    let validateIds = [];

    const errId = alreadyExistId.filter(
      (item, index) => alreadyExistId.indexOf(item) !== index
    );
    const errUniqId = checkUnique.filter(
      (item, index) => checkUnique.indexOf(item) !== index
    );

    if (validateIds.length > 0) {
      toast.error("id: " + validateIds.join(" \n id : ") + " already exist ");
    } else if ((product && errId.length > 0) || errUniqId.length > 0) {
      toast.error(REPEATING_ID_NOT_ALLOWED);
    } else {
      if (product) {
        dispatch(EditProduct(formData));
      } else {
        dispatch(CreateProduct(formData));
      }
    }
    setSubmitting(false);
    // clearPopup()
  };

  //changes in app
  const handleAppChange = async (e, idx, type) => {
    let data = apps;
    if (type === "image") {
      data.splice(idx, 1, {
        ...data[idx],
        icon: e,
      });
      setApps(data);
    } else if (type === "deprecated") {
      data.splice(idx, 1, {
        ...data[idx],
        deprecated: e,
      });
      setApps(data);
    } else if (type === "iconUploaded") {
      data.splice(idx, 1, {
        ...data[idx],
        iconUploaded: e,
      });
      setApps(data);
    } else {
      data.splice(idx, 1, {
        ...data[idx],
        [e.target.name]: e.target.value,
      });
      setApps(data);
    }
    setappFeatureUpdated(true);
    forceUpdate();
  };
  //changes in Features
  const handleFeatureChange = (e, idx, type) => {
    let data = features;
    if (type === "image") {
      data.splice(idx, 1, {
        ...data[idx],
        icon: e,
      });
      setFeatures(data);
    } else if (type === "deprecated") {
      data.splice(idx, 1, {
        ...data[idx],
        deprecated: e,
      });
      setFeatures(data);
    } else if (type === "iconUploaded") {
      data.splice(idx, 1, {
        ...data[idx],
        iconUploaded: e,
      });
      setFeatures(data);
    } else {
      data[idx] = {
        ...data[idx],
        [e.target.name]: e.target.value,
      };
      setFeatures(data);
    }
    setappFeatureUpdated(true);
    forceUpdate();
  };

  //request To upload a Photo
  const uploadFileAndgetURL = async (data) => {
    if (data) {
      const res = await uploadImage(data);
      if (res && res.data && res.data.publicUrl) {
        setFormData({ ...formData, icon: res.data.publicUrl });
      }
      setIconUpload(false);
    } else {
      toast.error(SOMETHING_WENT_WRONG);
      setIconUpload(false);
    }
  };

  //Validation of photo
  const handleChangeFile2 = () => {
    let fileUploadData = inputFile.current.files[0];
    let Data = new FormData();

    if (fileUploadData.size > 2e6) {
      toast.error(PHOTO_TOO_BIG);
      return false;
    }
    else {
      setIconUpload(true);
      Data.append("image", fileUploadData);
      uploadFileAndgetURL(Data);
    }
  }

  //handle activation of toggel
  const handleToggle = (e) => {
    let target = e.target;
    setFormData({
      ...formData,
      available: target.checked,
      deprecated: target.checked === true ? false : true,
    });
  };
  return (
    <div
      className=" bg-black bg-opacity-20  z-10 overflow-hidden absolute top-0 left-0 h-full w-full"
      onClick={() => {
        cancelProductForm();
        resetValue();
      }}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="mw-75 h-full-custom inset-x-0 z-10 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ProductFormHeader product={product} cancelProductForm={cancelProductForm} resetValue={resetValue} submitting={submitting}
          iconUpload={iconUpload} appiconUpload={appiconUpload} featuresiconUpload={featuresiconUpload} handleSubmit={handleSubmit}
          loading={loading} />

        <ProductFormDetails features={features} generateId={generateId} setFeatures={setFeatures} apps={apps} setApps={setApps}
          formData={formData} handleToggle={handleToggle} handleChangeFile2={handleChangeFile2} setFormData={setFormData} addApp={addApp}
          inputFile={inputFile} addFeatures={addFeatures} handleAppChange={handleAppChange} handleFeatureChange={handleFeatureChange}
          cancelProductForm={cancelProductForm} resetValue={resetValue} loading={loading} submitting={submitting} iconUpload={iconUpload}
          appiconUpload={appiconUpload} featuresiconUpload={featuresiconUpload} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default ProductForm;
