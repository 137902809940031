import React from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Button from "../general/Button";
import {
  sortByFirstName,
  sortByLastName,
  sortByEmail,
  sortById,
} from "../../features/admin/adminListSlice";
import moment from "moment";

const TableComponent = ({ selectAll, selectNone, selectRow, displayUserForm, selectedAdmins, noData, admins }) => {
  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader
              child={
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) =>
                    e.target.checked ? selectAll() : selectNone()
                  }
                  checked={
                    admins.length && selectedAdmins.length === admins.length
                  }
                />
              }
              sortable={false}
            />
            <TableHeader text="First Name" fn={sortByFirstName} />
            <TableHeader text="Last Name" fn={sortByLastName} />
            <TableHeader text="Email" fn={sortByEmail} />
            <TableHeader text="Date Activated" fn={sortById} />
            <TableHeader text="Role" sortable={false} />
            <TableHeader text="Action" sortable={false} />
          </tr>
        </thead>
        <tbody>
          {admins.length
            ? admins.map((admin) => (
              <tr key={admin.id} className="p-16">
                <TableData
                  child={
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={(e) => selectRow(e, admin.id)}
                      checked={
                        selectedAdmins.findIndex(
                          (id) => id === admin.id
                        ) !== -1 ? true : false
                      }
                    />
                  }
                />
                <TableData text={admin.firstname || "N/A"} onClick={() => displayUserForm(admin)} />
                <TableData text={admin.lastname || "N/A"} onClick={() => displayUserForm(admin)} />
                <TableData text={admin.email || "N/A"} onClick={() => displayUserForm(admin)} />
                <TableData
                  text={
                    admin.activated_at
                      ? typeof admin.activated_at === "string"
                        ? moment(admin.activated_at).format("MMM D, YYYY")
                        : "N/A"
                      : "N/A"
                  }
                  onClick={() => displayUserForm(admin)}
                />
                <TableData
                  text={Number(admin.role) === 1 ? "Super Admin" : "Admin"}
                  onClick={() => displayUserForm(admin)}
                />
                <TableData
                  child={
                    <Button
                      text="Edit"
                      small={true}
                      bottomMargin={false}
                      fn={() => displayUserForm(admin)}
                    />
                  }
                />
              </tr>
            ))
            : noData()}
        </tbody>
      </table>
    </>
  )
}

export default TableComponent;