import { createSlice } from "@reduxjs/toolkit";
import { codeHistoryApi, codeActivation } from "../../services";

export const codeInfoSlice = createSlice({
  name: "codeinformation",
  initialState: {
    loading: false,
    list: [],
  },
  extraReducers: {
    [codeHistoryApi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data) {
        state.loading = false;
        state.list = action.payload.data;
      }
    },
    [codeHistoryApi.pending]: (state, action) => {
      state.loading = true;
      state.list = [];
    },
    [codeHistoryApi.rejected]: (state, action) => {
      state.loading = false;
      state.list = [];
    },
    [codeActivation.fulfilled]: (state, action) => {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code_history
      ) {
        state.loading = false;
        state.list = action.payload.data.code_history || [];
      }else{
        state.loading = false;
      }
    },
    [codeActivation.pending]: (state, action) => {
      state.loading = true;
    },
    [codeActivation.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default codeInfoSlice.reducer;
