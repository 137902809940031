import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CreateUser,
  CreateAdmin,
  EditUser,
  EditAdmin,
  GetProducts,
  codeHistoryApi,
  GetSubscriptions,
  //getUserActiveProducts,
} from "../../services";
import Converter from "../../firestore/Constructor";
import toast from "react-hot-toast";
import { succesResponseMethodAdmin } from "../../features/admin/adminListSlice";
import { succesResponseMethod } from "../../features/admin/userListSlice";
import {
  DATA_IS_NOT_AVAILABLE,
  PASSWORD_LENGTH,
  PASSWORD_TOO_SHORT,
  SELECT_SUBSCRIPTION_DATE,
  VALID_SUBSCRIPTION_DATE,
} from "../../Constant";
import UserFormHeader from "./UserFormHeader";
import UserFormDetails from "./UserFormDetails";
import { getUserActiveProducts, getAllProducts } from "../../apiStructure";

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

function randomString() {
  //define a variable consisting alphabets in small and capital letter
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  //specify the length for the new string
  var lenString = 7;
  var randomstring = "";

  //loop to select a new character in each iteration
  for (var i = 0; i < lenString; i++) {
    var rnum = Math.floor(Math.random() * characters.length);
    randomstring += characters.substring(rnum, rnum + 1);
  }

  return randomstring;
}

const UserForm = ({ user, cancelUserForm, isAdmin }) => {
  useEffect(() => {}, [user]);
  const [tableData, setTableData] = useState([]);
  const subscriptions = useSelector((state) => state.subscriptions.list);
  const loadingSubscriptionList = useSelector(
    (state) => state.subscriptions.loading
  );

  const [formData, setformData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    account_notes: "",
    id: "",
    role: 2,
    purchased: [],
    subscriptionAddData: [],
    products_to_activate: [],
    is_hidden_subscription: false,
    app_listening_id: randomString(),
  });

  const forceuUpdate = useForceUpdate();
  const [subscriptionData, setSubscriptionData] = useState({
    active: false,
    activated_at: "",
    expiration_date: new Date(),
    comments: "",
  });
  const [error, setError] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const [ChangeByAdmin, setChangeByAdmin] = useState(false);
  const [userProducts, setUserProducts] = useState(null);
  const [userProductsOriginal, setUserProductsOriginal] = useState(null);
  const [productToActive, setProductToActive] = useState([]);
  const [isSubEdit, setSubEdit] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const loadingUser = useSelector((state) => state.users.loadingUser);
  const loadingAdmin = useSelector((state) => state.admins.loadingAdmin);
  const codeInfo = useSelector((state) => state.codeinformation.list);
  const [userActiveProducts, setUserActiveProducts] = useState([]);

  const codeInfoloading = useSelector((state) => state.codeinformation.loading);
  const succesResponseUser = useSelector(
    (state) => state.users.succesResponseUser
  );
  // const [value, onChange] = useState(new Date());
  const succesResponseAdmin = useSelector(
    (state) => state.admins.succesResponseAdmin
  );
  let isPaymentCancel = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  let products = useSelector((state) => state.products.list);
  const productLoading = useSelector((state) => state.products.loading);

  // useEffect(() => {
  //   const getData = async () => {
  //     await dispatch(GetProducts());
  //   };
  //   getData();
  // }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetSubscriptions());
    };
    getData();
  }, [dispatch]);

  //for admin request
  useEffect(() => {
    if (isAdmin) {
      if (succesResponseAdmin) {
        dispatch(succesResponseMethodAdmin(false));
        cancelUserForm();
      }
    } else {
      if (succesResponseUser) {
        dispatch(succesResponseMethod(false));
        cancelUserForm();
      }
    }
  }, [
    succesResponseUser,
    isAdmin,
    succesResponseAdmin,
    cancelUserForm,
    dispatch,
  ]);

  // //for product request
  // useEffect(() => {
  //   if (products?.length === 0) {
  //     const getData = async () => {
  //       await dispatch(GetProducts());
  //     };
  //     getData();
  //   }
  // }, [products?.length]);

  //If user is admin
  useEffect(() => {
    if (user && !isAdmin) {
      const getDataCI = async () => {
        await dispatch(codeHistoryApi(user.id));
      };
      getDataCI();
    }
  }, [user, isAdmin, dispatch]);

  useEffect(async () => {
    if (user) {
      const pro = await getAllProducts();
      products = pro.data.data.products;
      setProductToActive(products);
      const productsData = Converter.productConverter.toEditUserForm(
        user?.purchased,
        products
      );
      const res = await getUserActiveProducts(user.id);
      const userActiveProducts1 = res.data.data?.products;
      const hideFromuser =
        res.data.data?.user[0]?.hide_from_user !== "" &&
        res.data.data?.user[0]?.hide_from_user !== null
          ? res.data.data?.user[0]?.hide_from_user
          : [];
      productsData?.map((product, idx) => {
        product?.apps?.map((app, ida) => {
          userActiveProducts1.map((a, i) => {
            a.apps.map((b, j) => {
              if (b.id == app.id && b.active === true) {
                if (b.is_active_by_subscription) {
                  productsData[idx].apps[ida].is_active_by_subscription =
                    b.is_active_by_subscription;
                }
                if (b.is_activated_by_admin) {
                  productsData[idx].apps[ida].is_activated_by_admin =
                    b.is_activated_by_admin;
                }
                if (b.is_activated_by_code) {
                  productsData[idx].apps[ida].is_activated_by_code =
                    b.is_activated_by_code;
                }

                productsData[idx].apps[ida].deprecated = b.deprecated;
                if (
                  b?.is_activated_by_admin === true ||
                  b?.is_activated_by_code === true
                ) {
                  productsData[idx].apps[ida].active = b.active;
                  productsData[idx].apps[ida].last_used = b.last_used;
                  productsData[idx].apps[ida].code_used = b.code;
                  productsData[idx].apps[ida].activated_on._seconds =
                    b.activated_on._seconds;
                }
              }
            });
          });
          hideFromuser?.map((a, i) => {
            if (product.id == a.id) {
              productsData[idx].hide_from_user = a.hide_from_user
                ? a.hide_from_user
                : "global";
            }
            a.apps.map((b, j) => {
              if (b.id == app.id) {
                productsData[idx].apps[ida].hide_from_user = b.hide_from_user
                  ? b.hide_from_user
                  : "global";
              }
            });
          });
        });
        product?.features?.map((app, ida) => {
          userActiveProducts1.map((a, i) => {
            a.features.map((b, j) => {
              if (b.id == app.id && b.active === true) {
                if (b.is_active_by_subscription) {
                  productsData[idx].features[ida].is_active_by_subscription =
                    b.is_active_by_subscription;
                }
                if (b.is_activated_by_admin) {
                  productsData[idx].features[ida].is_activated_by_admin =
                    b.is_activated_by_admin;
                }
                if (b.is_activated_by_code) {
                  productsData[idx].features[ida].is_activated_by_code =
                    b.is_activated_by_code;
                }

                productsData[idx].features[ida].deprecated = b.deprecated;
                if (
                  b?.is_activated_by_admin === true ||
                  b?.is_activated_by_code === true
                ) {
                  productsData[idx].features[ida].active = b.active;
                  productsData[idx].features[ida].last_used = b.last_used;
                  productsData[idx].features[ida].code_used = b.code;
                  productsData[idx].features[ida].activated_on._seconds =
                    b.activated_on._seconds;
                }
              }
            });
          });
          hideFromuser?.map((a, i) => {
            if (product.id == a.id) {
              productsData[idx].hide_from_user = a.hide_from_user
                ? a.hide_from_user
                : "global";
            }
            a.features.map((b, j) => {
              if (b.id == app.id) {
                productsData[idx].features[ida].hide_from_user =
                  b.hide_from_user ? b.hide_from_user : "global";
              }
            });
          });
        });
      });
      if (user.userSubscriptions) {
        const u = {
          ...user,
          userSubscriptions: res.data.data?.subscription,
        };
        setTableData(u.userSubscriptions);
      }
      setUserProducts(productsData);
      setUserProductsOriginal(productsData);
      forceuUpdate();
    } else {
      if (!productLoading) {
        const pro = await getAllProducts();
        products = pro.data.data.products;
        setProductToActive(products);
        setUserProducts(Converter.productConverter.toAddUserForm(products));
        setUserProductsOriginal(
          Converter.productConverter.toAddUserForm(products)
        );
      }
      forceuUpdate();
    }
    // if we add forceUpdate as dependency than this useEffect goes into infinite loop
    // eslint-disable-next-line
  }, []);

  // validation form
  const validateForm = () => {
    let oldError = error;
    const requiredFields = user
      ? ["firstname", "lastname", "email"]
      : ["firstname", "lastname", "email"];
    const validate = requiredFields.map((ele, idx) => {
      if (!formData[ele]) {
        setError({
          ...oldError,
          [ele]: `${ele} is required`,
        });
        oldError = {
          ...oldError,
          [ele]: `${ele} is required`,
        };
        return false;
      } else if (ele === "password" && formData[ele].length < PASSWORD_LENGTH) {
        setError({
          ...oldError,
          [ele]: `${PASSWORD_TOO_SHORT}`,
        });
        oldError = {
          ...oldError,
          [ele]: `${PASSWORD_TOO_SHORT}`,
        };
        return false;
      } else {
        setError({
          ...oldError,
          [ele]: "",
        });
        oldError = {
          ...oldError,
          [ele]: "",
        };
        return true;
      }
    });
    let tableDataError = false;
    if (tableData.length > 0) {
      let tableDatavalidated = JSON.parse(JSON.stringify(tableData));
      tableDatavalidated = tableDatavalidated.map((item) => {
        const itemData = item;
        const error = {};

        if (!item.subscription_group_id)
          error["subscription_group_id"] = "Select Subscription";
        if (!item.subscription_prod_id)
          error["subscription_prod_id"] = "Select Subscription Duration";
        if (Object.keys(error).length > 0) tableDataError = true;

        itemData["error"] = error;
        return itemData;
      });

      setTableData([...tableDatavalidated]);
    }

    if (validate.includes(true) && !tableDataError) return true;
    else return false;
  };

  useEffect(() => {
    if (user) {
      setformData({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        id: user.id,
        role: user.role,
        account_notes: user.account_notes,
        is_hidden_subscription: user.is_hidden_subscription,
        userSubscriptions: user.userSubscriptions,
        customer_id: user?.customer_id ? user.customer_id : null,
        app_listening_id: user?.app_listening_id
          ? user.app_listening_id
          : randomString(),
      });
      if (user.userSubscriptions) setTableData(user.userSubscriptions);

      if (user.subscription_data) {
        setSubscriptionData(user.subscription_data);
      }
    }

    return () => {
      setformData({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        id: null,
        is_hidden_subscription: false,
        app_listening_id: randomString(),
      });
    };
  }, [user, isAdmin]);

  useEffect(() => {
    if (products?.length > 0 && user?.purchased) {
      const filterData = products.filter(
        (prod) => prod.type === "product" && prod.available
      );
      const purchasedUserData = user?.purchased;

      setSubscription(products.find((prod) => prod.type === "subscription"));
      let data = filterData
        ? filterData.map((p, i) => {
            const matchDataPU = purchasedUserData.filter((datapusd) => {
              return datapusd.id === p.id;
            })[0];
            const ActiveData = matchDataPU ? matchDataPU.activeCount : 0;
            return {
              id: p.id,
              title: p.title,
              activeCount: ActiveData || 0,
              apps:
                p.apps?.map((a, i) => {
                  const appPUData = matchDataPU?.apps.filter((adata) => {
                    return a.id === adata.id;
                  })[0];
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: appPUData?.active || false,
                  };
                }) || [],
              features:
                p.features?.map((a, i) => {
                  const appPUData = matchDataPU?.features.filter((adata) => {
                    return a.id === adata.id;
                  })[0];
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: appPUData?.active || false,
                  };
                }) || [],
            };
          })
        : [];
      setProductToActive(data);
    } else if (products?.length > 0) {
      const filterData = products.filter(
        (prod) => prod.type === "product" && prod.available
      );
      setSubscription(products.find((prod) => prod.type === "subscription"));
      let data = filterData
        ? filterData.map((p, i) => {
            return {
              id: p.id,
              title: p.title,
              activeCount: 0,
              apps:
                p.apps?.map((a, i) => {
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: false,
                  };
                }) || [],
              features:
                p.features?.map((a, i) => {
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: false,
                  };
                }) || [],
            };
          })
        : [];
      setProductToActive(data);
    }
  }, [products, user]);
  useEffect(() => {
    if (products?.length > 0 && isSubEdit) {
      const filterData = products.filter(
        (prod) => prod.type === "product" && prod.available
      );
      let data = filterData
        ? filterData.map((p, i) => {
            return {
              id: p.id,
              title: p.title,
              activeCount: subscriptionData.active
                ? Number(p.apps.length || 0) + Number(p.features.length || 0) ||
                  0
                : 0,
              apps:
                p.apps?.map((a, i) => {
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: subscriptionData.active ? true : false,
                  };
                }) || [],
              features:
                p.features?.map((a, i) => {
                  return {
                    id: a.id,
                    title: a.title,
                    isChecked: subscriptionData.active ? true : false,
                  };
                }) || [],
            };
          })
        : [];
      setProductToActive(data);
    }
  }, [subscriptionData, products, userProducts, isSubEdit]);

  useEffect(() => {
    if (userProducts && isSubEdit) {
      setSubEdit(false);
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          apps:
            p.apps?.map((ap, i) => {
              return {
                ...ap,
                code_used:
                  user && ap.active && ap.code_used
                    ? ap.code_used
                    : "Subscription",
                active: subscriptionData.active,
              };
            }) || [],
          features:
            p.features?.map((ft, i) => {
              return {
                ...ft,
                code_used:
                  user && ft.active && ft.code_used
                    ? ft.code_used
                    : "Subscription",
                active: subscriptionData.active,
              };
            }) || [],
        };
      });
      setUserProducts(userPro);
      setUserProductsOriginal(userPro);
    }
  }, [subscriptionData, isSubEdit, user, userProducts]);

  //Select subscription date
  const handlesubmit = () => {
    if (subscriptionData.active) {
      if (subscriptionData.expiration_date) {
        if (
          moment(subscriptionData.expiration_date).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD")
        ) {
          toast.error(VALID_SUBSCRIPTION_DATE);
          return false;
        }
      } else {
        toast.error(SELECT_SUBSCRIPTION_DATE);
        return false;
      }
    }

    const productList =
      userProductsOriginal === null
        ? JSON.parse(JSON.stringify([...userProducts]))
        : JSON.parse(JSON.stringify([...userProductsOriginal]));
    const userProductList = [...productList].reduce((result, prod) => {
      const appsProd = prod.apps.reduce((result, prodApps) => {
        delete prodApps.hide;
        result.push(prodApps);
        return result;
      }, []);

      const featuresProd = prod.features.reduce((result, prodFeature) => {
        delete prodFeature.hide;
        result.push(prodFeature);
        return result;
      }, []);

      prod.apps = appsProd;
      prod.features = featuresProd;

      result.push(prod);
      return result;
    }, []);
    setformData((state) => {
      return {
        ...state,
        subscriptionData: subscriptionData,
        products_to_activate: productToActive,
        changes_by_admin: ChangeByAdmin,
        purchased: Converter.productConverter.filterPurchased(userProductList),
      };
    });

    let tableDataToSend = tableData.map((data) => {
      const subscriptionData = JSON.parse(JSON.stringify(data));
      if (subscriptionData?.error) delete subscriptionData.error;

      return subscriptionData;
    });

    const dataToSend = {
      ...formData,
      subscriptionData: subscriptionData,
      products_to_activate: productToActive,
      changes_by_admin: ChangeByAdmin,
      purchased: Converter.productConverter.filterPurchased(userProductList),
      userSubscriptions: tableDataToSend,
    };
    // update the user susbscription array
    if (user) {
      if (tableDataToSend.length > 0) {
        user = {
          ...user,
          userSubscriptions: tableDataToSend,
        };
      }
    }
    if (validateForm()) {
      submitDispatch(dataToSend);
    }
  };

  //dispatch action
  const submitDispatch = (formData) => {
    if (isAdmin) {
      user ? dispatch(EditAdmin(formData)) : dispatch(CreateAdmin(formData));
    } else {
      user ? dispatch(EditUser(formData)) : dispatch(CreateUser(formData));
    }
  };

  //IF data is not available
  const noData = (additionalRows = 5) => (
    <tr>
      {(function (rows, i, len) {
        while (++i <= len) {
          if (parseInt(len / 2) == i)
            rows.push(
              <td
                key={`${i}-nodata`}
                className="p-16 text-center font-bold text-lg"
              >
                {DATA_IS_NOT_AVAILABLE}
              </td>
            );
          else rows.push(<td key={`${i}-nodata`} />);
        }
        return rows;
      })([], 0, additionalRows)}
    </tr>
  );

  return (
    <div
      className="bg-black z-10 overflow-hidden content-center bg-opacity-20 absolute top-0 left-0 h-full w-full"
      onClick={cancelUserForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="mw-75 h-full-custom inset-x-0 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <UserFormHeader
          user={user}
          isAdmin={isAdmin}
          cancelUserForm={cancelUserForm}
          loadingUser={loadingUser}
          loadingAdmin={loadingAdmin}
          handlesubmit={handlesubmit}
        />

        <UserFormDetails
          userActiveProducts={userActiveProducts}
          setUserActiveProducts={setUserActiveProducts}
          userProducts={userProducts}
          setUserProducts={setUserProducts}
          userProductsOriginal={userProductsOriginal}
          setUserProductsOriginal={setUserProductsOriginal}
          productToActive={productToActive}
          setProductToActive={setProductToActive}
          setChangeByAdmin={setChangeByAdmin}
          setformData={setformData}
          formData={formData}
          subscription={subscription}
          user={user}
          error={error}
          isAdmin={isAdmin}
          isPaymentCancel={isPaymentCancel}
          setSubEdit={setSubEdit}
          subscriptionData={subscriptionData}
          codeInfo={codeInfo}
          setSubscriptionData={setSubscriptionData}
          noData={noData}
          productLoading={productLoading}
          codeInfoloading={codeInfoloading}
          cancelUserForm={cancelUserForm}
          loadingUser={loadingUser}
          loadingAdmin={loadingAdmin}
          handlesubmit={handlesubmit}
          tableData={tableData}
          setTableData={setTableData}
          loadingSubscriptionList={loadingSubscriptionList}
          subscriptions={subscriptions}
        />
      </div>
    </div>
  );
};

export default UserForm;
