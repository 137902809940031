import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MergeUserApi } from "../../services";
import Button from "../general/Button";
import { succesResponseMethod } from "../../features/admin/userListSlice";
import { FIRST_NAME_L, LAST_NAME_L, MERGE_USER, SELECT_EMAIL } from "../../Constant";
import {initialMergeUser} from '../utils/commonVariable';
const MergeUserForm = ({ cancelUserForm, isAdmin, selectedUsers }) => {
  const [formData, setformData] = useState({...initialMergeUser, role: isAdmin ? 2 : 3,});

  const succesResponseUser = useSelector(
    (state) => state.users.succesResponseUser
  );

  const loadingUserMerge = useSelector((state) => state.users.loadingUserMerge);

  const dispatch = useDispatch();
  useEffect(() => {
    if (succesResponseUser) {
      dispatch(succesResponseMethod(false));
      cancelUserForm();
    }
  }, [succesResponseUser, cancelUserForm, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //For get selected users data
  useEffect(() => {
    if (selectedUsers && selectedUsers.length > 0) {
      setformData({
        firstname: selectedUsers[0].firstname,
        lastname: selectedUsers[0].lastname,
        email: selectedUsers[0].email,
        id: selectedUsers[0].id,
        activated_at: selectedUsers[0].activated_at,
        user_id: selectedUsers ? selectedUsers.map((user) => user.id) : [],
      });
    }

    return () => {
      setformData({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        activated_at: null,
        id: null,
        user_id: [],
      });
    };
  }, [selectedUsers]);

  //To handle selected user's data
  const handleSelect = (e) => {
    let userData = selectedUsers.filter((user) => user.id === e.target.value);
    if (userData) {
      setformData({
        ...formData,
        email: userData[0].email,
        firstname: userData[0].firstname,
        lastname: userData[0].lastname,
        id: userData[0].id,
      });
    }
  };

  //dispatch action on sunmit click
  const handlesubmit = () => {
    dispatch(MergeUserApi(formData));
  };

  //handle entered data in form
  const handleChange = (e) => {
    let target = e.target;
    setformData({
      ...formData,
      [target.name]: target.value,
    });
  };


  return (
    <div
      className="bg-black overflow-hidden content-center bg-opacity-20 absolute top-0 left-0 h-full w-full"
      onClick={cancelUserForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="w-3/4 h-5/12 inset-x-0 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
          <h3 className="text-2xl">{MERGE_USER}</h3>

          <div className="w-1/5 flex justify-end">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              otherClass="mr-2"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelUserForm}
            />
            <Button
              text="Save"
              fn={handlesubmit}
              disabled={loadingUserMerge}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
        <div className="popup_body px-8 pt-8 pb-16">
          {/* User Details */}
          <div className="w-full mb-4">
            <div className="flex">
              <div className="shadow p-4 w-3/2">
                {/* Email */}
                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {SELECT_EMAIL}
                  </label>
                  <select
                    className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                    onChange={handleSelect}
                    autoFocus
                    value={formData.id}
                  >
                    {selectedUsers?.map((user, idx) => {
                      return (
                        <option
                          key={idx}
                          value={user?.id}
                          id={user?.id}
                          name="selected_email"
                        >
                          {user?.email}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {FIRST_NAME_L}
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    placeholder="John"
                    onChange={handleChange}
                    value={formData.firstname}
                    className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  />
                </div>

                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {LAST_NAME_L}
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Doe"
                    onChange={handleChange}
                    value={formData.lastname}
                    className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-16 mb-4">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={() => {
                cancelUserForm();
              }}
              otherClass="mr-4"
            />
            <Button
              text="Save"
              fn={handlesubmit}
              disabled={loadingUserMerge}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergeUserForm;
