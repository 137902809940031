import React, { useEffect } from "react";
import { SettingForm } from "../forms/SettingForm";
import Button from "../general/Button";
import * as ApiStructure from "../../apiStructure";
import construct from "../../firestore/Constructor";
import toast from "react-hot-toast";
import Helmet from "react-helmet";
import {
  PLEASE_ACTIVE_BUTTON_TEXT,
  PLEASE_ACTIVE_PRODUCT_TEXT,
  SETTINGS,
  SETTING_EDITED,
  STORE_BUTTON_TEXT
} from "../../Constant";
import { initialAccountSetting } from '../utils/commonVariable';

const Settings = () => {
  const [AccountSetting, setAccountSetting] = React.useState(initialAccountSetting);

  const [displayOrder, setDislayOrder] = React.useState([]);
  const [isSubmit, setIsSubmit] = React.useState(false);
  useEffect(() => {
    getSettingApi()
  }, [])

  //To get Settings Api
  const getSettingApi = async () => {
    const settingRes = await ApiStructure.getSetting();
    if (settingRes?.data?.data) {
      const setting = settingRes?.data?.data;
      const ULSlocalStorage = localStorage.getItem("uls");
      const JSONData = JSON.parse(ULSlocalStorage);
      const newData = {
        ...JSONData,
        setting: {
          ...JSONData.setting,
          ...setting,
        },
      };
      setAccountSetting(newData.setting);
      localStorage.setItem("uls", JSON.stringify(newData));
    }
  }

  //On saving setting
  const onSaveSettings = async () => {
    if (!AccountSetting.store_button_text) {
      toast.error(STORE_BUTTON_TEXT);
      return false;
    }
    if (
      !AccountSetting.active_product_button_text ||
      !AccountSetting.inactive_product_button_text
    ) {
      toast.error(PLEASE_ACTIVE_BUTTON_TEXT);
      return false;
    }
    if (
      !AccountSetting.active_product_text ||
      !AccountSetting.inactive_product_text
    ) {
      toast.error(PLEASE_ACTIVE_PRODUCT_TEXT);
      return false;
    }
    if (!AccountSetting.stripe_test_publishable_key || !AccountSetting.stripe_test_secret_key || !AccountSetting.stripe_live_publishable_key
      || !AccountSetting.stripe_live_secret_key) {
      toast.error("Please add stripe configuration details");
      return false;
    }
    setIsSubmit(true);
    try {
      const ULSlocalStorage = localStorage.getItem("uls");
      const JSONData = JSON.parse(ULSlocalStorage);
      const arrayOFid =
        displayOrder.length > 0
          ? displayOrder.map((data) => {
            return data.id;
          })
          : [];

      let resProductResponse = await ApiStructure.updateDisplayOrder(
        { product_id: arrayOFid },
        JSONData.id
      );
      const res = await ApiStructure.updateSetting(
        construct.SettingConverter.updateToFirestore(AccountSetting),
        AccountSetting.id
      );
      if (res?.data?.success || resProductResponse?.data?.success) {
        const ULSlocalStorage = localStorage.getItem("uls");
        const JSONData = JSON.parse(ULSlocalStorage);
        const newData = {
          ...JSONData,
          setting: {
            ...JSONData.setting,
            ...AccountSetting,
          },
        };
        localStorage.setItem("uls", JSON.stringify(newData));
        toast.success(SETTING_EDITED);
        setIsSubmit(false);
      }
    } catch (err) {
    }
  };

  React.useEffect(() => {
    const ULSlocalStorage = localStorage.getItem("uls");
    const JSONData = JSON.parse(ULSlocalStorage);
    if (JSONData) {
      getProductDetail(JSONData.id);
    }
  }, []);

  //To getProducts details
  const getProductDetail = async (id) => {
    try {
      const res = await ApiStructure.getDisplayOrderSetting(id);
      if (res && res.data && res.data.data) {
        setDislayOrder(res.data.data);
      }
    } catch (e) {
    }
  };

  React.useEffect(() => {
    const ULSlocalStorage = localStorage.getItem("uls");
    const JSONData = JSON.parse(ULSlocalStorage);
    if ("setting" in JSONData) {
      const dataSetting = JSONData.hasOwnProperty("setting")
        ? JSONData.setting
        : {};
      if (dataSetting) {
        setAccountSetting(dataSetting);
      }
    }
  }, []);

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>
          {SETTINGS}
        </title>
      </Helmet>
      <div className="flex justify-between mb-8">
        <div>
          <h1 className="text-3xl font-bold text-blue-600 mb-8">Settings</h1>
        </div>
        <div>
          <Button
            text="Save"
            disabled={isSubmit}
            fn={
              !isSubmit
                ? onSaveSettings
                : () => {
                  return null;
                }
            }
          />
        </div>
      </div>
      <hr className="border border-black mb-6" />
      {/* Setting Form  */}
      <SettingForm
        AccountSetting={AccountSetting}
        setAccountSetting={setAccountSetting}
        displayOrder={displayOrder}
        setDislayOrder={(data) => {
          setDislayOrder(data);
        }}
      />
    </div>
  );
};

export default Settings;
