import React from "react";
import {
  CLEAR_PREFIX,
  CODE,
  CODE_LENGTH,
  CODE_PREFIX,
  EMAIL,
  FIRST_NAME,
  GENERATED_CODES,
  LAST_NAME,
  NOTES,
  NUMBER_OF_CODES,
  NUMBER_OF_CODES_LESS,
  PRODUCTS_TO_ACTIVATE,
  PURCHASE_LOCATION,
  SUBSCRIPTION,
} from "../../Constant";
import Button from "../general/Button";
import toast from "react-hot-toast";
import Loader from "../utils/Loader";

const SubCodeForm = ({
  code,
  prefix,
  codeLength,
  setFormData,
  formData,
  numberOfCodes,
  setCodeError,
  handleLengthChange,
  handleChange,
  setNumerOfCodes,
  codeError,
  readOnly,
  productLoading,
  p2a,
  renderApps,
  onGoingApiCall,
  cancelCodeForm,
  getFooter,
  isApiCallStart,
  forceuUpdate,
  handleSubmit,
  settingData,
  oldPrefix,
  setOldPrefix,
  multiCodes,
  bulkMode,
  setBulkMode,
  setPrefix,
  subscriptions,
  loadingSubscriptionList,
  isCodeAdd
}) => {
  //generate random code
  const generateRandomCode = () => {
    let codeArray = [];
    let generatedCode = prefix;
    let length = 1;
    if (bulkMode) {
      length = numberOfCodes;
    }

    codeArray = Array.from({ length: length }).map((i, e) => {
      var textArray = settingData.code_characters.split("");

      if (bulkMode) {
        if (codeLength - generatedCode.length < 2) {
          code = oldPrefix;
        } else {
          setOldPrefix(generatedCode);
          code = generatedCode;
        }
      } else {
        code = generatedCode;
      }

      if (generatedCode === "" || bulkMode) {
        while (code.length < codeLength) {
          var randomNumber = Math.floor(
            Math.random(codeLength) * textArray.length
          );
          code += textArray[randomNumber];
        }
      }

      return code;
    });

    setPrefix(codeArray[0] || "");
    setFormData({
      ...formData,
      code: codeArray,
    });
  };
  return (
    <div>
      <div className="popup_body px-8 pt-8 pb-16">
        {
          <div className="mb-4">
            <div className="shadow p-4">
              {!multiCodes && !code ? (
                <>
                  <div className="flex justify-center mb-4">
                    <Button
                      text="Single Code"
                      fn={() => {
                        setBulkMode(false)
                        setPrefix("")
                      }}
                      bgColor={bulkMode ? "bg-gray-400" : "bg-blue-500"}
                    />
                    <Button
                      text="Bulk Codes"
                      otherClass="ml-2"
                      fn={() => {
                        setBulkMode(true)
                        setPrefix("")
                      }}
                      bgColor={bulkMode ? "bg-blue-500" : "bg-gray-400"}
                    />
                  </div>

                  <div className="flex justify-between items-center mb-8">
                    <div className="flex items-center">
                      <label className="block mr-4 font-semibold" htmlFor="">
                        {CODE}
                      </label>
                      <input
                        type="text"
                        placeholder="123456"
                        value={prefix}
                        autoFocus
                        onChange={(e) => {
                          e.preventDefault();
                          if (bulkMode) {
                            if (e.target.value.length < codeLength - 1) {
                              setPrefix(e.target.value);
                              setFormData({
                                ...formData,
                                codePrefix: e.target.value,
                              });
                            }
                          } else {
                            setPrefix(e.target.value);
                            setFormData({
                              ...formData,
                              codePrefix: e.target.value,
                              code: [e.target.value],
                            });
                          }
                        }}
                        className="block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                      />
                      {!bulkMode && (
                        <Button
                          text="Clear"
                          small
                          disabled={prefix === ""}
                          bottomMargin={false}
                          otherClass="mr-10 ml-10"
                          fn={() => {
                            setPrefix("");
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <Button
                        text="Generate Random Code"
                        small
                        bottomMargin={false}
                        fn={() => {
                          if (Number(numberOfCodes) < 2 && bulkMode) {
                            setCodeError("");
                            toast.error(NUMBER_OF_CODES_LESS);
                          } else {
                            if (prefix.length === codeLength && !bulkMode) {
                              setCodeError(CLEAR_PREFIX);
                            } else {
                              setCodeError("");
                            }
                            console.log(
                              formData.code.filter(
                                (a, i) => formData.code.indexOf(a) !== i
                              )
                            );
                            generateRandomCode();
                            while (
                              formData.code.filter(
                                (a, i) => formData.code.indexOf(a) !== i
                              ).length > 0
                            ) {
                              generateRandomCode();
                            }
                          }
                        }}
                      />
                    </div>

                    <div className="flex items-center">
                      <label
                        className="mr-4 text-blue-600 font-bold"
                        htmlFor="codeLength"
                      >
                        {bulkMode ? "Random" : ""} {CODE_LENGTH}
                      </label>
                      <div>
                        <input
                          className="rounded-lg px-1 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                          type="number"
                          name=""
                          value={codeLength}
                          onChange={handleLengthChange}
                          id="codeLength"
                          step="1"
                          min="1"
                          max="25"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-8">
                    <div className="flex items-center">
                      <label className="block mr-4 font-semibold" htmlFor="">
                        {PURCHASE_LOCATION}
                      </label>
                      <input
                        type="text"
                        name="purchase_location"
                        value={formData.purchase_location}
                        onChange={handleChange}
                        placeholder="thoughtcastmagic.com"
                        className="block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                      />
                    </div>

                    {bulkMode ? (
                      <div className="flex items-center">
                        <label
                          className="mr-4 text-blue-600 font-bold"
                          htmlFor="bulkCodeNumber"
                        >
                          {NUMBER_OF_CODES}
                        </label>
                        <input
                          className="rounded-lg px-1 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                          type="number"
                          name="numberOfCodes"
                          id="bulkCodeNumber"
                          value={numberOfCodes}
                          onChange={(e) => {
                            e.preventDefault();
                            setNumerOfCodes(e.target.value);
                          }}
                          step="5"
                          min="5"
                          max="100"
                        />
                      </div>
                    ) : null}
                  </div>
                  {
                    <div className="block mr-4 font-semibold shadow text-center">
                      <br />
                      <h2>{GENERATED_CODES}</h2>
                      <br />
                      <div className="ml-2 font-semibold break-words">
                        {formData.code.map(
                          (code, i) =>
                            code + (i !== formData.code.length - 1 ? ",  " : "")
                        )}
                      </div>
                      <br />
                      <span className=" text-red-500 text-xs w-full font-bold">
                        {codeError && formData.code.length > 0 && codeError}
                      </span>
                    </div>
                  }
                </>
              ) : null}
              {code && !multiCodes && !readOnly ? (
                <div className="flex items-center">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {CODE_PREFIX}
                  </label>
                  <input
                    type="text"
                    value={prefix}
                    autoFocus
                    onChange={(e) => {
                      setPrefix(e.target.value);
                      const changeCodePre = formData.code
                        ? typeof formData.code[0] === "string"
                          ? formData.code[0].replace(
                            formData.codePrefix,
                            e.target.value
                          )
                          : ""
                        : "";
                      setFormData({
                        ...formData,
                        code: changeCodePre ? [changeCodePre] : formData.code,
                        codePrefix: e.target.value,
                      });
                    }}
                    placeholder="1234456"
                    className="block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                  />
                </div>
              ) : null}
              {code && readOnly ? (
                <div>
                  <p className="mb-2 flex">
                    {bulkMode ? "codes :" : "code :"}
                    <span className="ml-2 font-semibold">
                      {formData.code && formData.code.map((code, i) => code)}
                    </span>
                  </p>
                  <p className="mb-2">
                    {FIRST_NAME}{" "}
                    <span className="ml-2 font-semibold">
                      {code?.firstname}
                    </span>
                  </p>
                  <p className="mb-2">
                    {LAST_NAME}{" "}
                    <span className="ml-2 font-semibold">{code?.lastname}</span>
                  </p>
                  <p className="mb-2">
                    {EMAIL}{" "}
                    <span className="ml-2 font-semibold">
                      {code?.activated_by}
                    </span>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        }
        <div className="mb-4">
          <div className="shadow p-4" style={{ minHeight: "200px" }}>
            <h3 className="text-xl font-bold mb-4">{PRODUCTS_TO_ACTIVATE}</h3>

            <div className="shadow p-4 w-1/1">
              {loadingSubscriptionList || productLoading ? <div className="h-20">
                <Loader />
              </div> : (<>
                <div className="flex justify-between pb-4">
                  <label className="fblock mr-4 font-semibold">
                    {SUBSCRIPTION} :
                  </label>
                  <div className="w-1/2">
                    <select
                      value={formData.subscription_group_id}
                      name="subscription_group_id"
                      onChange={(event) => handleChange(event)}
                      className="w-1/2"
                    >
                      <option value="">Select Subscription</option>
                      {subscriptions &&
                        subscriptions.length &&
                        subscriptions.map(
                          (subscription, subsIndex) =>
                          (
                            <option
                              key={`${subsIndex}-subscription`}
                              value={subscription.id}
                            >
                              {subscription.title}
                            </option>
                          )
                        )}
                    </select>
                    <small className="text-red-600 font-semibold flex">
                      {formData?.error?.subscription_group_id}
                    </small>
                  </div>
                </div>
                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    Length :
                  </label>

                  <>
                    <div className="w-1/2">
                      {subscriptions &&
                        subscriptions.length &&
                        subscriptions.map(
                          (subscription, subscPricingIndex) =>
                            formData.subscription_group_id ==
                            subscription.id &&
                            subscription.pricing_options.length && (
                              <>
                                {" "}
                                <select
                                  value={formData.subscription_prod_id}
                                  name="subscription_prod_id"
                                  key={`${subscPricingIndex}-subscription-pricing`}
                                  onChange={(event) =>
                                    handleChange(event)
                                  }
                                  className="w-1/2"
                                >
                                  <option key="select-time-key" value="">Select Time</option>
                                  {subscription.pricing_options.length &&
                                    subscription.pricing_options.map(
                                      (pricing_option, pIndex) => (
                                        <option
                                          key={`${pIndex}-subscription-option-pricing`}
                                          value={pricing_option.stripe_product_id}
                                        >
                                          {pricing_option.time}{" "}
                                          {pricing_option.time_unit}
                                          {pricing_option.time > 1 ? "s" : ""}
                                        </option>
                                      )
                                    )}
                                </select>
                                <small className="text-red-600 font-semibold flex">
                                  {formData?.error?.subscription_prod_id}
                                </small>
                              </>
                            )
                        )}
                    </div>
                  </>
                </div>
              </>)}
            </div>
            {/* Product 1 */}
            {productLoading ? <Loader /> : p2a && renderApps()}
          </div>
        </div>
        <div className="mb-16">
          <div className="shadow p-4">
            <div>
              <label className="block font-semibold mb-2 text-lg" htmlFor="">
                {NOTES}
              </label>
              <textarea
                type="text"
                disabled={readOnly ? true : false}
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Enter notes here..."
                className="w-full block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
              >
                {code ? code.description : ""}
              </textarea>
            </div>
          </div>
        </div>

        <div className="flex custom-fix justify-center mt-16 mb-4">
          <Button
            text="Cancel"
            bgColor="bg-blue-100"
            fontSemiBold={true}
            bottomMargin={false}
            textColor="text-black"
            fn={cancelCodeForm}
            otherClass="mr-4"
          />
          {!readOnly || isCodeAdd === true ?
            <Button
              text={getFooter()}
              disabled={(onGoingApiCall || loadingSubscriptionList || productLoading)}
              fn={() => {
                if (isApiCallStart) {
                  isApiCallStart = false;
                  forceuUpdate();
                  handleSubmit();
                }
              }}
              fontSemiBold={true}
              bottomMargin={false}
            />
            : ''}
        </div>
      </div>
    </div>
  );
};

export default SubCodeForm;
