import React from "react";
import { useHistory } from "react-router";
import Button from "../components/general/Button";
import { Helmet } from "react-helmet";
import { NOT_FOUND, PAGE_NOT_FOUND } from "../Constant";

const NotFound = () => {
  const history = useHistory();
  return (
    <section className="min-h-screen bg-gray-300  flex justify-center items-center p-4 xl:p-16">
      <Helmet>
        <title>
          {NOT_FOUND}
        </title>
      </Helmet>
      <div className="card bg-blue-50 w-full lg:w-3/4 xl:w-2/5 p-4 xl:p-16 text-center shadow-xl rounded-xl">
        <h1 className="text-5xl font-bold text-blue-600">404</h1>
        <h2 className="text-3xl mt-3 font-bold text-blue-600">{PAGE_NOT_FOUND}</h2>
        <Button
          text="Go Back"
          otherClass="block mx-auto mt-8"
          fn={() => history.push('/')}
        />
      </div>
    </section>
  );
};

export default NotFound;
