import axios from "axios";

//get token to store cookies
const getToken = () => {
  try {
    let localstorage = localStorage.getItem("uls");
    const token =
      localstorage && JSON.parse(localstorage) && JSON.parse(localstorage).token
        ? JSON.parse(localstorage).token
        : "";
    return token;
  } catch (e) {
    return "";
  }
};

//request to BaseURL 
axios.defaults.headers.common["token"] = getToken();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  // baseURL: "http://192.168.29.168:9090/api",
  // baseURL: "http://localhost:9090/api",
  // baseURL: "http://192.168.29.158:9090/api",
  // baseURL: "https://thoughtcastowners.com/api/v1",
  baseURL: process.env.REACT_APP_BASE_URL,
});


export default instance;