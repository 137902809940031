import React from "react";

export const InstructionFrame = ({ cancelInstructionFrame, activeURL }) => {
  React.useEffect(() => {
    // window.scrollTo(0, 0)
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div
        className="bg-black bg-opacity-20 overflow-hidden fixed top-0 left-0 h-full w-full"
        onClick={cancelInstructionFrame}
        data-keyboard="false"
        data-backdrop="static"
      >
        <div
          onClick={cancelInstructionFrame}
          className="absolute text-3xl z-20 right-5 top-2 md:hidden"
        >
          x
        </div>
        <div
          className="w-full h-full md:h-5/6 md:w-5/6 inset-x-0 z-10 -translate-x-1/2 my-2 p-0 md:my-4 mx-auto absolute top-0  bg-white rounded-xl shadow-xl instruction-model"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <iframe
            src={
              activeURL.includes("http") ? activeURL : `https://${activeURL}`
            }
            title="React JS"

            style={{
              height: "100%",
              width: "100%",
              borderRadius: "10px",
              padding: "0px !important",
            }}
          />
        </div>
      </div>
    </>
  );
};
