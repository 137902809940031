import { configureStore } from "@reduxjs/toolkit";
import adminListReducer from "./features/admin/adminListSlice";
import codeSliceReducer from "./features/admin/codeSlice";
import productSliceReducer from "./features/admin/productSlice";
import userListReducer from "./features/admin/userListSlice";
import codeInfoReducer from "./features/admin/codeInfoSlice";
import apiKeySlice from "./features/admin/apiKeySlice";
import subscriptionSliceReducer from "./features/admin/subscriptionSlice";

export default configureStore({
  reducer: {
    users: userListReducer,
    admins: adminListReducer,
    products: productSliceReducer,
    codes: codeSliceReducer,
    codeinformation: codeInfoReducer,
    apikeys:apiKeySlice,
    subscriptions:subscriptionSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
