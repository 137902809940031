import React, { useEffect, useState } from "react";
import Button from "../general/Button";
import { Signout } from "../auth/firebase";
import Converter from "../../firestore/Constructor";
import { useSelector, useDispatch } from "react-redux";
import {
  GetProducts,
  GetSubscriptionInfo,
  GetSubscriptions,
} from "../../services";
import Loader from "../utils/Loader";
import Popup from "../general/Popup";
import * as ApiRequest from "../../apiStructure";
import * as authRequest from "../../apiStructure";
import { getUser } from "../auth/firebase";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import {
  CODE_IS_NOT_VALID,
  CODE_SUCCESSFUL_ACTIVATE,
  CODE_VALIDATION_FAILED,
  DASHBOARD,
} from "../../Constant";
import Demo from "./Demo";
import { showDateToShow } from "../utils/commonFunction";
import CardPaymentModal from "../utils/Modal/CardPaymentModal";

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [userProducts, setUserProducts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [apiCalling, setApiCalling] = useState(false);
  const [redeemCodeUpdate, setRedeemCodeUpdate] = useState(true);
  const [code, setCode] = useState("");
  const [isSubScriptionA, setIsSubScriptionA] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const products = useSelector((state) => state.products);
  const [showModalCheckout, setShowModalCheckout] = useState(false);
  const [IsupdateSubScription, setIsupdateSubScription] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [codeProducts, setcodeProducts] = useState();
  const [activeBtn, setactiveBtn] = useState(false);
  const [codeDuration, setcodeDuration] = useState();
  const subscriptionInfo = useSelector((state) => state.subscriptions.info);

  const dispatch = useDispatch();
  const logo_img_white = "/images/logo_white.png";
  const subscriptions = useSelector((state) => state.subscriptions.list);
  const loadingSubscriptionList = useSelector(
    (state) => state.subscriptions.loading
  );
  const [userSubscriptions, setUserSubscriptions] = useState();

  //get products
  useEffect(() => {
    if (products) {
      const filterDataProduct =
        products && products.list
          ? products.list.filter((data) => {
            return data.type === "subscription";
          })
          : [];
      if (filterDataProduct && filterDataProduct.length) {
        setIsSubScriptionA(true);
        setSubscriptionData(filterDataProduct[0]);
      } else {
        setIsSubScriptionA(false);
        setSubscriptionData({});
      }
    }
  }, [products]);

  //redeemCode
  useEffect(() => {
    if (redeemCodeUpdate || IsupdateSubScription) {
      if (!(products.list.length > 0)) {
        dispatch(GetProducts());
      }
      const initialize = async () => {
        try {
          setPageLoading(true);
          const res = await getUser();
          if (res) {
            setData(res);
            setPageLoading(false);
            setRedeemCodeUpdate(false);
            setIsupdateSubScription(false);
          }
        } catch (e) { }
      };
      initialize();
    }
  }, [
    redeemCodeUpdate,
    IsupdateSubScription,
    dispatch,
    products?.list?.length,
  ]);

  useEffect(() => {
    if (products?.list?.length > 0) {
      let newPurchased = [];
      if (data?.purchased.length > 0) {
        newPurchased = data?.purchased;
      }
      for (let i = 0; i < products?.list?.length; i++) {
        const indexValue = newPurchased.findIndex(b => b.id == products?.list[i].id);
        if ((indexValue !== undefined || indexValue !== "") && indexValue >= 0) {
          if (!newPurchased[indexValue].apps.length > 0) {
            newPurchased[indexValue].apps = products?.list[i].apps;
          } else {
            for (let j = 0; j < products?.list[i].apps.length; j++) {
              const indexValueApp = newPurchased[indexValue].apps.findIndex(b => b.id == products?.list[i].apps[j].id);
              if ((indexValueApp !== undefined || indexValueApp !== "") && indexValueApp >= 0) {
              } else {
                newPurchased[indexValue].apps.push(products?.list[i].apps[j]);
              }

            }
          }
          if (!newPurchased[indexValue].features.length > 0) {
            newPurchased[indexValue].features = products?.list[i].features;
          } else {
            for (let k = 0; k < products?.list[i].features.length; k++) {
              const indexValueFeature = newPurchased[indexValue].features.findIndex(b => b.id == products?.list[i].features[k].id);
              if ((indexValueFeature !== undefined || indexValueFeature !== "") && indexValueFeature >= 0) {
              } else {
                newPurchased[indexValue].features.push(products?.list[i].features[k]);
              }
            }
          }
        }
        newPurchased.push(products?.list[i]);
      }
      if (newPurchased.length == 0) {
        newPurchased = products?.list;
      }
      if (data?.hide_from_user) {
        for (let l = 0; l < data?.hide_from_user.length; l++) {
          const indexValue = newPurchased.findIndex(b => b.id == data?.hide_from_user[l].id);
          if ((indexValue !== undefined || indexValue !== "") && indexValue >= 0) {
            if (data?.hide_from_user[l].hide_from_user !== "global" && data?.hide_from_user[l].hide_from_user !== undefined && data?.hide_from_user[l].hide_from_user !== false) {
              newPurchased[indexValue].deprecated = data?.hide_from_user[l].hide_from_user == undefined || data?.hide_from_user[l].hide_from_user == false ? false : data?.hide_from_user[l].hide_from_user == "active" ? false : true;
            }
            const indexValueP = products?.list.findIndex(b => b.id == newPurchased[indexValue].id);
            if (newPurchased[indexValue].deprecated == undefined) {
              if ((indexValueP !== undefined || indexValueP !== "") && indexValueP >= 0) {
                newPurchased[indexValue].deprecated = products?.list[indexValueP].deprecated;
              }
            }
            newPurchased[indexValue].hide_from_user = data?.hide_from_user[l].hide_from_user;

            for (let m = 0; m < data?.hide_from_user[l].apps.length; m++) {
              const indexValueApp = newPurchased[indexValue].apps.findIndex(b => b.id == data?.hide_from_user[l].apps[m].id);
              if ((indexValueApp !== undefined || indexValueApp !== "") && indexValueApp >= 0) {
                if (data?.hide_from_user[l].apps[m].hide_from_user !== "global" && data?.hide_from_user[l].apps[m].hide_from_user !== undefined && data?.hide_from_user[l].apps[m].hide_from_user !== false) {
                  newPurchased[indexValue].apps[indexValueApp].deprecated = data?.hide_from_user[l].apps[m].hide_from_user == undefined || data?.hide_from_user[l].apps[m].hide_from_user == false ? false : data?.hide_from_user[l].apps[m].hide_from_user == "active" ? false : true;
                }
                if (newPurchased[indexValue].apps[indexValueApp].deprecated == undefined) {
                  const indexValueAP = products?.list[indexValueP].apps.findIndex(b => b.id == newPurchased[indexValue].apps[indexValueApp].id);
                  if ((indexValueAP !== undefined || indexValueAP !== "") && indexValueAP >= 0) {
                    newPurchased[indexValue].apps[indexValueApp].deprecated = products?.list[indexValueP].apps[indexValueAP].deprecated;
                  }
                }
                newPurchased[indexValue].apps[indexValueApp].hide_from_user = data?.hide_from_user[l].apps[m].hide_from_user;
              }
            }

            for (let n = 0; n < data?.hide_from_user[l].features.length; n++) {
              const indexValueFeature = newPurchased[indexValue].features.findIndex(b => b.id == data?.hide_from_user[l].features[n].id);
              if ((indexValueFeature !== undefined || indexValueFeature !== "") && indexValueFeature >= 0) {
                if (data?.hide_from_user[l].features[n].hide_from_user !== "global" && data?.hide_from_user[l].features[n].hide_from_user !== undefined && data?.hide_from_user[l].features[n].hide_from_user !== false) {
                  newPurchased[indexValue].features[indexValueFeature].deprecated = data?.hide_from_user[l].features[n].hide_from_user == undefined || data?.hide_from_user[l].features[n].hide_from_user == false ? false : data?.hide_from_user[l].features[n].hide_from_user == "active" ? false : true;
                }
                if (newPurchased[indexValue].features[indexValueFeature].deprecated == undefined) {
                  const indexValueFP = products?.list[indexValueP].features.findIndex(b => b.id == newPurchased[indexValue].features[indexValueFeature].id);
                  if ((indexValueFP !== undefined || indexValueFP !== "") && indexValueFP >= 0) {
                    newPurchased[indexValue].features[indexValueFeature].deprecated = products?.list[indexValueP].features[indexValueFP].deprecated;
                  }
                }
                newPurchased[indexValue].features[indexValueFeature].hide_from_user = data?.hide_from_user[l].features[n].hide_from_user;
              }
            }
          }
        }
      }


      const EditProductCon = Converter.productConverter.toEditUserForm(
        newPurchased,
        products?.list,
        true
      );
      const filterDataCon = EditProductCon
        ? EditProductCon.filter((data, index) => {
          return !data.deprecated
        })
        : [];
      setUserProducts(filterDataCon);
    }
  }, [products, data]);

  // Getting user subscription list

  useEffect(() => {
    setUserSubscriptions(
      subscriptionInfo[0]?.user_subscriptions
        ? subscriptionInfo[0]?.user_subscriptions
        : []
    );
  }, [subscriptionInfo]);

  //Api call for redeemCode
  const apiCallForRedeemCode = async () => {
    setApiCalling(true);
    try {
      const res = await ApiRequest.redeemCode(code);
      if (res?.data?.success) {
        setShowPopup(false);
        setRedeemCodeUpdate(true);
        setCode("");
        setcodeProducts(null);
        toast.success(CODE_SUCCESSFUL_ACTIVATE);
      }
    } catch (e) {
    } finally {
      setApiCalling(false);
    }
  };

  const handleChange = (e) => {
    setCode(e.target.value);
  };
  const handlePlanChange = (
    e,
    index,
    subscription_group_id,
    uSubsc,
    checkbox = false
  ) => {
    //setCode(e.target.value);
    const uSubscData = { ...uSubsc };
    let subscriptionPrice = [];
    for (const subs of subscriptions) {

      if (subs.id === subscription_group_id) {
        if (subs.pricing_options.length) {
          const compairData = !checkbox
            ? e.target.value
            : uSubscData["subscription_prod_id"];
          subscriptionPrice = subs?.pricing_options.filter((data) => {

            return data.stripe_product_id === compairData;
          });

        }
      }
    }
    if (subscriptionPrice.length) {
      if (
        uSubscData.id &&
        e.target.value !== uSubscData["subscription_prod_id"]
      ) {
        uSubscData["is_plan_changed"] = true;
      } else {
        // for auto subscription true
        uSubscData["auto_subscription"] = true;
      }
      if (
        uSubscData.id &&
        checkbox
      ) {
        uSubscData["auto_subscription_changed"] = true;
      }
      uSubscData["auto_subscription"] = checkbox
        ? e.target.checked
        : uSubscData["auto_subscription"];
      uSubscData["expiration_date"] = showDateToShow(
        subscriptionPrice[0].time_unit,
        subscriptionPrice[0].time
      );
      uSubscData["status"] = "User";
      uSubscData["subscription_group_id"] = subscription_group_id;
      uSubscData["subscription_prod_id"] = !checkbox
        ? e.target.value
        : uSubscData["subscription_prod_id"];
      uSubscData["subscription_name"] =
        subscriptionPrice[0].stripe_product_name;
      uSubscData["is_updated"] = true;
      const userDetail = JSON.parse(localStorage.getItem("uls"));
      uSubscData["user_id"] = userDetail.id;


      const userSubscriptionsList = [...userSubscriptions];
      if (index !== undefined) userSubscriptionsList[index] = uSubscData;
      else userSubscriptionsList.push(uSubscData);

      setUserSubscriptions([...userSubscriptionsList]);
    }
  };

  //handle Activate
  const handleActivate = async () => {
    try {
      if (validateCode()) {
        setShowPopup(true);
        setactiveBtn(true);
        const coproducts = await authRequest.checkCode(code, true);
        if (coproducts?.data.success) {
          const products = coproducts.data.data.products_to_activate;
          setcodeProducts(products);
          const duration = coproducts.data.data.duration;
          setcodeDuration(duration);
          setactiveBtn(false);
        } else {
          toast.error(CODE_IS_NOT_VALID);
        }
      } else {
        toast.error(CODE_VALIDATION_FAILED);
      }
    } catch (e) { }
  };

  const validateCode = () => {
    if (code) {
      return true;
    } else {
      return false;
    }
  };

  const clearPopup = () => {
    setShowPopup(false);
    setcodeProducts(null);
  };

  // Get subscription info
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetSubscriptionInfo());
    };
    getData();
  }, [dispatch]);

  // get subscriptions list
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetSubscriptions());
    };
    getData();
  }, [dispatch]);

  return (
    <section className="min-h-screen bg-blue-200">
      <Helmet>
        <title>{DASHBOARD}</title>
      </Helmet>
      {showPopup && (
        <Popup
          btnDisabled={apiCalling}
          width="w-3/5 mt-36"
          yesBtnText="Activate"
          yesFn={apiCallForRedeemCode}
          noFn={clearPopup}
          text={`Redeem code : " ${code} "`}
          codeProducts={codeProducts}
          activeBtn={activeBtn}
          codeDuration={codeDuration}
        />
      )}
      <div className="bg-blue-500 p-2 shadow-lg mb-4">
        <div className="flex justify-between flex-wrap items-center w-full lg:w-3/4 xl:w-1/2 mx-auto">
          <div>
            <img
              className="h-28 block mx-auto"
              src={logo_img_white}
              alt="Thoughtcast Logo"
            />
          </div>
          <Button
            text="Logout"
            bgColor="bg-blue-300"
            textColor="text-red-900"
            fn={() => Signout()}
            bottomMargin={false}
            otherClass="font-semibold"
          />
        </div>
      </div>
      {pageLoading ? (
        <div className="mt-auto mb-auto">
          <Loader />
        </div>
      ) : (
        <div>
          <Demo
            handleActivate={handleActivate}
            handleChange={handleChange}
            data={data}
            code={code}
            subscriptionData={subscriptionData}
            isSubScriptionA={isSubScriptionA}
            products={products}
            userProducts={userProducts}
            setShowModalCheckout={setShowModalCheckout}
            setSelectedPlan={setSelectedPlan}
            subscriptionInfo={subscriptionInfo}
            subscriptions={subscriptions}
            loadingSubscriptionList={loadingSubscriptionList}
            handlePlanChange={handlePlanChange}
            userSubscriptions={userSubscriptions}
            setUserSubscriptions={setUserSubscriptions}
          />
          <div class="footer-terms-of-service py-3 text-center">
            <div class="container-fluid">
              <a href="https://www.thoughtcastmagic.com/terms-of-service" target="_blank"> Terms of Service</a>
            </div>
          </div>
        </div>

      )}
      {showModalCheckout ? (
        <CardPaymentModal
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setShowModal={setShowModalCheckout}
          setIsupdateSubScription={setIsupdateSubScription}
        />
      ) : (
        ""
      )}



    </section>

  );
};

export default Dashboard;
