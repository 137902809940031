import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaginatedCodes,
  getActiveCodes,
  deleteCodes,
  filterByKeyword,
  setCodeCount,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setCodeType,
} from "../../features/admin/codeSlice";
import { CSVLink } from "react-csv";
import Button from "../general/Button";
import Pagination from "../layout/Pagination";
import Search from "../layout/Search";
import ItemPerPage from "../layout/ItemPerPage";
import Popup from "../general/Popup";
import CodeForm from "../forms/CodeForm";
import { GetCodes, DeleteCodeApi, GetSubscriptions } from "../../services";
import Loader from "../utils/Loader";
import { Helmet } from "react-helmet";
import {
  CODES,
  DATA_IS_NOT_AVAILABLE,
  DATA_IS_NOT_FOUND,
} from "../../Constant";
import TableComponent2 from "./TableComponent2";

const Codes = () => {
  // State
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [selectedCodesForCsv, setSelectedCodesForCsv] = useState([]);

  const [disablePagination, setDisablePagination] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [IsCodeAdd, setIsCodeAdd] = useState(false);
  const [showActiveCodes, setShowActiveCodes] = useState(true);

  const codes = useSelector((state) => state.codes.paginatedCodes);
  const activeCodes = useSelector((state) => state.codes.active);
  const inactiveCodes = useSelector((state) => state.codes.inactive);
  const codeCount = useSelector((state) => state.codes.codeCount);
  const filterKeyword = useSelector((state) => state.codes.filterKeyword);
  const pageNumber = useSelector((state) => state.codes.pageNumber);
  const loading = useSelector((state) => state.codes.loading);

  const [maxPages, setmaxPages] = useState(0);
  const [maxCodesPerPage,] = useState(10);
  const subscriptions = useSelector((state) => state.subscriptions.list);

  // Dispatch
  const dispatch = useDispatch();

  //to find active code
  useEffect(() => {
    const TotalCodesData = showActiveCodes ? activeCodes : inactiveCodes;
    if (typeof filterKeyword === "string") {
      if (filterKeyword.length > 0) {
        let regExp = new RegExp(`^${filterKeyword}`, `i`);
        const filterSearchData = TotalCodesData.filter((code) =>
          code.code.match(regExp)
        );

        const mPNum = Math.ceil(filterSearchData.length / codeCount);
        setmaxPages(mPNum);
      } else {
        const mPNum = Math.ceil(TotalCodesData.length / codeCount);
        setmaxPages(mPNum);
      }
    } else {
      const mPNum = Math.ceil(TotalCodesData.length / codeCount);
      setmaxPages(mPNum);
    }
  }, [showActiveCodes, activeCodes, inactiveCodes, codeCount, filterKeyword]);

  useEffect(() => {
    if (selectedCodes) {
      const CsvDataMap = selectedCodes.map((data) => {
        return [data];
      });
      setSelectedCodesForCsv(CsvDataMap ? CsvDataMap : []);
    } else {
      setSelectedCodesForCsv([]);
    }
  }, [selectedCodes]);

  //TO find active codes
  useEffect(() => {
    if (showActiveCodes) {
      dispatch(setCodeType("active"));
    } else {
      dispatch(setCodeType("inactive"));
    }
  }, [dispatch, showActiveCodes]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetCodes());
      dispatch(getPaginatedCodes());
      dispatch(getActiveCodes());
      await dispatch(GetSubscriptions());
    };
    getData();

    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelCodeForm();
      }
    });
  }, [dispatch]);

  // Methods
  const selectRow = (e, codeId) => {
    if (e.target.checked) {
      setSelectedCodes([...selectedCodes, codeId]);
      setDisablePagination(true);
    } else {
      setSelectedCodes(selectedCodes.filter((id) => id !== codeId));
      if (selectedCodes.length > 1) {
        setDisablePagination(false);
      }
    }
  };

  const selectAll = () => {
    setSelectedCodes(codes.map((u) => u.id));
    setDisablePagination(true);
  };
  const selectNone = () => {
    setSelectedCodes([]);
    setDisablePagination(false);
  };
  //If data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {filterKeyword
          ? DATA_IS_NOT_FOUND
          : DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  //delete selected code
  const deleteSelected = () => {
    dispatch(deleteCodes(selectedCodes));
    dispatch(DeleteCodeApi(selectedCodes));
    setShowPopup(false);
    setSelectedCodes([]);
    setDisablePagination(false);
  };
  //clear popup
  const clearPopup = () => {
    setShowPopup(false);
  };
  //To cancleCodeForm
  const cancelCodeForm = () => {
    setShowCodeForm(false);
    setIsCodeAdd(false);
    setSelectedCodes([]);
  };
  //To displayCodeForm
  const displayCodeForm = () => {
    setShowCodeForm(true);
  };

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>
          {CODES}
        </title>
      </Helmet>
      <h1 className="text-3xl font-bold text-blue-600 mb-8">Codes</h1>
      {/* Popup */}
      {showPopup ? (
        <Popup
          yesFn={deleteSelected}
          noFn={clearPopup}
          text={
            selectedCodes.length > 1
              ? "Delete Selected Codes"
              : "Delete Selected Code"
          }
        />
      ) : null}
      {/* UserForm */}
      {showCodeForm ? (
        <CodeForm
          code={
            selectedCodes.length === 1 && !IsCodeAdd ? selectedCodes[0] : null
          }
          multiCodes={
            selectedCodes.length > 1 && !IsCodeAdd ? selectedCodes : null
          }
          cancelCodeForm={cancelCodeForm}
          readOnly={!showActiveCodes ? true : false}
          isCodeAdd={IsCodeAdd}

        />
      ) : null}

      {/* Controls */}
      <div className="mb-8 flex justify-between">
        <div className="w-9/12">
          <Search
            placeholder="Search Code"
            fn={filterByKeyword}
            clearFn={filterByKeyword}
          />
          {!disablePagination ? (
            <ItemPerPage
              itemCount={codeCount}
              maxItemsPerPage={maxCodesPerPage}
              label="Codes"
              setCount={setCodeCount}
            />
          ) : null}
        </div>
        <div className="w-3/12">
          <Button
            text={"Add code"}
            fn={() => {
              setShowCodeForm(true);
              setIsCodeAdd(true);
            }}
          />
          <Button
            text={
              selectedCodes.length > 1
                ? "Delete Selected Codes"
                : "Delete Selected Code"
            }
            bgColor="bg-red-900"
            fn={() => setShowPopup(true)}
            disabled={selectedCodes.length === 0}
          />
          {/* <button> */}
          <CSVLink data={selectedCodesForCsv} filename="bsedata.csv">
            <Button
              text="Export Selected Codes to CSV"
              bgColor="bg-green-900"
              fn={() => {
              }}
              disabled={selectedCodes.length === 0}
            />
          </CSVLink>
          {/* </button> */}
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          text="Active"
          disabled={loading}
          fn={() => setShowActiveCodes(true)}
          bgColor={showActiveCodes ? "bg-blue-500" : "bg-gray-400"}
        />
        <Button
          text="Inactive"
          otherClass="ml-2"
          disabled={loading}
          fn={() => setShowActiveCodes(false)}
          bgColor={showActiveCodes ? "bg-gray-400" : "bg-blue-500"}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Data */}
          <TableComponent2 selectAll={selectAll} selectNone={selectNone} codes={codes} selectedCodes={selectedCodes}
            showActiveCodes={showActiveCodes} displayCodeForm={displayCodeForm}
            noData={noData} setSelectedCodes={setSelectedCodes} selectRow={selectRow} subscriptions={subscriptions}
          />

          {/* Pagination */}
          {!disablePagination ? (
            <Pagination
              prevFn={() => dispatch(goToPreviousPage())}
              nextFn={() => dispatch(goToNextPage())}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              maxPages={maxPages}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Codes;
