import React from "react";
import { useDispatch } from "react-redux";
import { PER_PAGE } from "../../Constant";

const ItemPerPage = ({ label, itemCount, maxItemsPerPage, setCount }) => {
  const dispatch = useDispatch();

  const items = [10, 20, 40, 100, 200];
  return (
    <div className="flex items-center">
      <label className="mr-4 text-blue-600 font-bold" htmlFor="itemPerPage">
        {label} {PER_PAGE}
      </label>
      <select
        className="rounded-lg px-2 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
        onChange={(e) => {
          dispatch(setCount(parseInt(e.target.value)));
        }}
        value={itemCount}
      >
        {items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <option value={item}>{item}</option>
            </React.Fragment>
          )
        })}

      </select>
    </div>
  );
};

export default ItemPerPage;
