import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../general/Button";
import Toggle from "../general/Toggle";
import {
  EditSubscriptionInfo,
  GetSubscriptionInfo,
} from "../../services";
import { uploadImage } from "../../apiStructure";
import toast from "react-hot-toast";
import { succesResponseMethod } from "../../features/admin/subscriptionSlice";
import {
  DATA_IS_NOT_AVAILABLE,
  DESCRIPTION,
  EDIT_SUBSCRIPTION_USER_PORTAL_INFO,
  LEARN_MORE,
  NAME,
} from "../../Constant";
import { initialSubscriptionInfoFormData } from "../utils/commonVariable";

const SubscriptionInfoForm = ({
  cancelSubscriptionInfoForm,
  subscriptionInfo,
  subscriptions,
  visibleSubscriptionsCount,
  subscriptionLoading
}) => {
  const logo = "/images/logo.webp";
  // Dispatch
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    //  console.log(subscriptionInfo, "subscriptionInfo1", subscriptionInfo[0]);
    if (subscriptionInfo.length) {
      setIsVisibleSubscription(subscriptionInfo[0]?.available || false);
      setFormData({
        name: subscriptionInfo[0].name,
        description: subscriptionInfo[0].description,
        available: subscriptionInfo[0].available,
        learn_more: subscriptionInfo[0].learn_more,
        pricing_options: subscriptionInfo[0].pricing_options,
        icon: subscriptionInfo[0].icon,
        // subscription_pricing_options_visibility: subscriptionInfo[0].subscription_pricing_options_visibility,
        subscription_pricing_options_visibility:
          [...subscriptionInfo[0].subscription_pricing_options_visibility],
        selected_subscription: "All",
        show_price_comparisons_per:
          subscriptionInfo[0].show_price_comparisons_per,
        price_to_show_in_owners_portal:
          subscriptionInfo[0].price_to_show_in_owners_portal,
      });
    }
  }, [subscriptionInfo]);

  const succesResponseSubscription = useSelector(
    (state) => state.subscriptions.succesResponseSubscription
  );
  const inputFile = useRef(null);
  const [isVisibleSubscription, setIsVisibleSubscription] = useState(false);
  const [activeIndexEdit, setActiveIndexEdit] = useState(-1);
  const [activeinputValue, setActiveinputValue] = useState(0);
  // const [pricing_options,] = useState([
  //   {
  //     price: "",
  //     time_option: "",
  //   },
  // ]);
  const [formData, setFormData] = useState({
    ...initialSubscriptionInfoFormData,
  });



  //form data input handle
  const handleChange = (e) => {
    let target = e.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    console.log(formData, "formData");
  };

  //pricing options
  const handlepricingOptionSubmit = (index) => {
    if (!activeinputValue) {
      toast.error("Please enter Price value for selected plan.");
      return false;
    }
    const oldValue = [...formData];
    if (oldValue) {
      oldValue.pricing_options[index].price = Number(activeinputValue);
      setFormData(oldValue);
    }
    setActiveIndexEdit(-1);
  };
  //pricing option change
  const handleplanVisibilityChange = (
    e,
    subscription_id,
    pricing_option_stripe_product_id,
    index, pIndex
  ) => {
    const oldValue = JSON.parse(JSON.stringify(formData));
    console.log(oldValue, "oldValue")
    if (oldValue.subscription_pricing_options_visibility.length) {
      if (e.target.checked)
        for (const oldValueIndex in oldValue.subscription_pricing_options_visibility) {
          if (
            oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
            subscription_id
            ] === undefined ||
            !oldValue.subscription_pricing_options_visibility[oldValueIndex]?.[
              subscription_id
            ].includes(pricing_option_stripe_product_id)
          ) {
            if (
              oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
              subscription_id
              ] !== undefined
            ) {
              oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
                subscription_id
              ].push(pricing_option_stripe_product_id);
            } else {
              let indexOfItem = null;
              for (const oldValueIndexIn in oldValue.subscription_pricing_options_visibility) {
                const pricingObjKeys = Object.keys(
                  oldValue.subscription_pricing_options_visibility[
                  oldValueIndexIn
                  ]
                );
                if (pricingObjKeys.includes(subscription_id)) {
                  indexOfItem = oldValueIndexIn;
                }
              }

              if (indexOfItem) {
                if (
                  !oldValue?.subscription_pricing_options_visibility[
                    indexOfItem
                  ]?.[subscription_id].includes(pricing_option_stripe_product_id)
                )
                  oldValue?.subscription_pricing_options_visibility[
                    indexOfItem
                  ]?.[subscription_id].push(pricing_option_stripe_product_id);
              } else {
                oldValue.subscription_pricing_options_visibility.push({
                  [subscription_id]: [pricing_option_stripe_product_id],
                });
              }
            }
          }
          // else if (!e.target.checked) {
          //   const indexOf = oldValue?.subscription_pricing_options_visibility[
          //     oldValueIndex
          //   ]?.[subscription_id].indexOf(pricing_option_stripe_product_id);
          //   console.log("asdasdasd", indexOf, pricing_option_stripe_product_id)
          //   oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
          //     subscription_id
          //   ].splice(indexOf, 1);
          // }
        }

      if (!e.target.checked) {
        let dataIndex = -1
        let oldValueIndexFinal = -1
        for (const oldValueIndex in oldValue.subscription_pricing_options_visibility) {
          if (dataIndex === -1)
            dataIndex = (oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
              subscription_id
            ]) ? oldValue?.subscription_pricing_options_visibility[oldValueIndex]?.[
              subscription_id
            ].indexOf(pricing_option_stripe_product_id) : dataIndex;
          if (dataIndex > -1) {
            oldValueIndexFinal = parseInt(oldValueIndex)
            break;
          }
        }

        if (dataIndex > -1) {
          oldValue?.subscription_pricing_options_visibility[oldValueIndexFinal]?.[
            subscription_id
          ].splice(dataIndex, 1);
        }

      }
    } else if (e.target.checked) {
      oldValue.subscription_pricing_options_visibility.push({
        [subscription_id]: [pricing_option_stripe_product_id],
      });
    }

    setFormData({
      ...oldValue,
    });
  };

  const resetValue = useCallback(() => {
    setFormData({
      ...initialSubscriptionInfoFormData,
    });
  }, []);

  useEffect(() => {
    if (succesResponseSubscription) {
      dispatch(succesResponseMethod(false));
      cancelSubscriptionInfoForm();
      resetValue();
    }
  }, [
    succesResponseSubscription,
    cancelSubscriptionInfoForm,
    dispatch,
    resetValue,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //on submit form
  const handleSubmit = async () => {
    setLoadingSubmit(true)
    await dispatch(
      EditSubscriptionInfo({
        data: formData,
        id: subscriptionInfo[0].id,
      })
    );

    await dispatch(
      GetSubscriptionInfo()
    );
    setLoadingSubmit(false)
    cancelSubscriptionInfoForm()
  };

  //Toggle activation
  const handleToggle = (e) => {
    let target = e.target;
    setIsVisibleSubscription(target.checked);
    setFormData({
      ...formData,
      available: target.checked,
    });
  };

  //request upload a photo of app and feature
  const uploadFileAndgetURL = async (data) => {
    const res = await uploadImage(data);
    if (res.data.publicUrl) {
      setFormData({ ...formData, icon: res.data.publicUrl });
    }
  };

  //validation for photo
  const handleChangeFile = () => {
    if (inputFile && "current" in inputFile && inputFile.current.files) {
      const fileUploadData = inputFile.current.files[0];
      const Data = new FormData();
      Data.append("image", fileUploadData);
      uploadFileAndgetURL(Data);
    }
  };

  //IF data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
  return (
    <div
      className=" bg-black bg-opacity-20 overflow-hidden absolute top-0 left-0 h-full w-full"
      onClick={cancelSubscriptionInfoForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="w-3/4 h-full-custom inset-x-0 z-10 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
          <h3 className="text-2xl">{EDIT_SUBSCRIPTION_USER_PORTAL_INFO}</h3>
          {/* <div className="w-1/5 flex justify-end">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              otherClass="mr-2"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelSubscriptionInfoForm}
            />
            <Button
              text="Save"
              fn={handleSubmit}
              fontSemiBold={true}
              bottomMargin={false}
              disabled={loadingSubmit || subscriptionLoading}
            />
          </div> */}
        </div>
        <div className="popup_body px-8 pt-8 pb-16">
          {/* Subscription Details */}
          <div className="mb-4">
            <div className="shadow p-4">
              <div className="flex justify-between">
                <Toggle
                  label="Visible?"
                  onChange={handleToggle}
                  isChecked={isVisibleSubscription}
                />
              </div>
              <div className="flex justify-between">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {NAME}
                </label>
                <input
                  type="text"
                  placeholder="Subscriptions"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
              <div className="flex justify-between mt-4">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {DESCRIPTION}
                </label>
                <textarea
                  type="text"
                  autoFocus
                  placeholder="Enter description here..."
                  className="w-1/2 block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  onChange={handleChange}
                  value={formData.description}
                  name="description"
                ></textarea>
              </div>
              <div className="flex justify-between">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {LEARN_MORE}
                </label>
                <input
                  type="text"
                  placeholder="https://www.thoughtcastmagic.com/subscriptionone"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="learn_more"
                  onChange={handleChange}
                  value={formData.learn_more}
                />
              </div>
              <div className="flex items-center mb-5">
                <p className="font-semibold mr-2">Icon</p>
                <img
                  src={formData.icon || logo}
                  className="w-12 h-12 object-cover border border-black rounded mr-8"
                  alt=""
                />
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={handleChangeFile}
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  style={{ display: "none" }}
                />

                <Button
                  text="Change Icon"
                  fn={() => {
                    // `current` points to the mounted file input element
                    inputFile.current.click();
                  }}
                  // onClick={handleChangeImg}
                  small={true}
                  bottomMargin={false}
                />
              </div>

              {/* <hr></hr>
              <div className="flex justify-between mt-5">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {SHOW_PRICE_COMPARISONS_PER}
                </label>

                <select
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="show_price_comparisons_per"
                  value={formData.show_price_comparisons_per}
                  onChange={handleChange}
                >
                  <option value={"None"}>None</option>
                  <option value={"1 day"}>1 day</option>
                  <option value={"1 week"}>1 week</option>
                  <option value={"1 month"}>1 month</option>
                  <option value={"1 year"}>1 year</option>
                </select>
              </div> */}


              {/* {!subscriptions.length ? (
                <div className="h-20">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-between mt-5">{console.log(visibleSubscriptionsCount, "visibleSubscriptionsCount")}
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {SUBSCRIPTION_PRICE_OPTION_FOR}
                  </label>

                  <select
                    className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                    name="selected_subscription"
                    value={formData.selected_subscription}
                    onChange={handleChange}
                  >
                    <option value={"All"}>All</option>
                    {subscriptions &&
                      subscriptions.map((subscription) => {
                        return (
                          subscription.available && (
                            <>
                              <option value={subscription.id}>
                                {subscription.title}
                              </option>
                            </>
                          )
                        );
                      })}
                  </select>
                </div>
              )} */}
              {/* <SubscriptionInfoFormTable
                noData={noData}
                handlepricingOptionSubmit={handlepricingOptionSubmit}
                activeinputValue={activeinputValue}
                handleplanVisibilityChange={handleplanVisibilityChange}
                setActiveIndexEdit={setActiveIndexEdit}
                activeIndexEdit={activeIndexEdit}
                formData={formData}
                subscriptions={subscriptions}
                visibleSubscriptionsCount={visibleSubscriptionsCount}
              /> */}
            </div>
          </div>

          <div className="flex custom-fix justify-center mt-16 mb-4">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelSubscriptionInfoForm}
              otherClass="mr-4"
            />
            <Button
              text="Save"
              disabled={loadingSubmit || subscriptionLoading}
              fn={handleSubmit}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfoForm;
