import React from "react";
import Sidebar from "../components/layout/Sidebar";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import AdminAccounts from "../components/admin/AdminAccounts";
import Settings from "../components/admin/Settings";
import Products from "../components/admin/Products";
import Subscriptions from "../components/admin/Subscriptions";
import Codes from "../components/admin/Codes";
import UserAccounts from "../components/admin/UserAccounts";
import Apikey from "../components/admin/Apikey";

const Admin = (props) => {
  let match = useRouteMatch();
  return ( 
    <main className="flex">
      <Sidebar role={props.role} />
      <section className="w-10/12 min-h-screen bg-blue-200">
        <Switch>
          {props.role === 1 && <Route path={`${match.url}/manage`}>
            <AdminAccounts />
          </Route>
          }
          <Route path={`${match.url}/users`}>
            <UserAccounts />
          </Route>
          <Route path={`${match.url}/codes`}>
            <Codes />
          </Route>
          <Route path={`${match.url}/apikeys`}>
            <Apikey />
          </Route>
          <Route path={`${match.url}/products`}>
            <Products />
          </Route>
          <Route path={`${match.url}/settings`}>
            <Settings />
          </Route>
          <Route path={`${match.url}/subscriptions`}>
            <Subscriptions />
          </Route>
          <Redirect to={`${match.url}/users`} />
        </Switch>
      </section>
    </main>
  );
};

export default Admin;
