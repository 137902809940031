import React, { useEffect } from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Button from "../general/Button";
import { Eye, EyeOff } from "../utils/SVG";
import { useDispatch } from "react-redux";
import { toggleSubsVisibility } from "../../features/admin/userListSlice";
import { subToggle } from "../../apiStructure/index";
import toast from "react-hot-toast";
import { getPaginatedSubscriptions } from "../../features/admin/subscriptionSlice";
import moment from "moment";

import { showDateToShow } from "../utils/commonFunction";

let isGetApiCall = false;

const AdminSubscriptionDetails = ({
  subscription,
  formData,
  setformData,
  user,
  handleSubscriptionData,
  isPaymentCancel,
  setSubEdit,
  setSubscriptionData,
  subscriptionData,
  noData,
  tableData,
  setTableData,
  subscriptions,
}) => {
  const dispatch = useDispatch();


  useEffect(() => {
    if (isGetApiCall) {
      const getData = async () => {
        dispatch(getPaginatedSubscriptions());
        isGetApiCall = false;
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isGetApiCall]);

  //Disabled or enabled a toggle
  const ToggleStatus = async () => {
    if (subscription?.product?.available) {
      const data = {
        user_id: formData.id,
        is_hidden: !formData.is_hidden_subscription,
      };
      setformData({
        is_hidden_subscription: !formData.is_hidden_subscription,
      });
      try {
        if (user) {
          const res = await subToggle(data);
          dispatch(toggleSubsVisibility(res.data.data));
          toast.success("Subscription Visibility Updated Successfully");
        }
      } catch (e) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Subscription is globally disabled");
    }
  };
  useEffect(() => {
    handleSubscriptionData(tableData);
  }, [tableData]);
  const addRow = () => {
    const temp = JSON.parse(JSON.stringify(tableData));

    temp.push({
      subscription_name: "",
      expiration_date: "",
    });
    setTableData([...temp]);
  };
  const deleteRow = (index) => {
    let temp = JSON.parse(JSON.stringify(tableData));
    if (!temp[index]?.id) temp.splice(index, 1);
    else temp[index]["is_deleted"] = true;
    setTableData([...temp]);
  };

  const handleChange = async (event, id, key, subscription_group_id = "") => {
    let temp = JSON.parse(JSON.stringify(tableData));
    if (key === "expiration_date") {
      temp[id][key] = event;
    }
    if (key === "auto_subscription") {
      temp[id]["auto_subscription_changed"] = true;
      temp[id][key] = temp[id][key] ? !temp[id][key] : true;
    } else {
      temp[id][key] = event.target.value;
      temp[id]["status"] = "Admin";
    }
    if (key === "subscription_group_id") {
      temp[id]["expiration_date"] = "";
      temp[id]["subscription_prod_id"] = "";
    }
    if (key === "subscription_prod_id") {
      if (temp[id]["subscription_id"] !== undefined)
        temp[id]["is_plan_changed"] = true;
      temp[id]["expiration_date"] = "";

      if (subscriptions && subscriptions.length)
        subscriptions.map((subscription) => {
          if (
            subscription_group_id.subscription_group_id == subscription.id &&
            subscription.pricing_options.length
          )
            subscription.pricing_options.map((pricing_option) => {
              if (temp[id]["expiration_date"] === "")
                temp[id]["expiration_date"] =
                  event.target.value === pricing_option.stripe_product_id
                    ? showDateToShow(
                      pricing_option.time_unit,
                      pricing_option.time
                    )
                    : "";
            });
        });
    }

    setTableData([...temp]);
    //handleSubscriptionData(temp);
  };

  const handleDate = (expiration_date) => {
    let exp_date = '';
    exp_date = expiration_date.split("/");
    exp_date = new Date(`${exp_date[1]}/${exp_date[0]}/${exp_date[2]}`);
    return moment(exp_date).format("MMM D, YYYY");
  };

  return (
    <>
      <div className="w-full mb-4">
        <div className="flex ">
          <div className="font-bold text-2xl mr-4 mb-4">
            <h3 className="font-semibold text-2xl ">Subscription</h3>
          </div>
          <div
            style={{ color: "#047857", paddingTop: "7px" }}
            className="text-green-700"
            onClick={() => {
              ToggleStatus();
            }}
          >
            {formData?.is_hidden_subscription ||
              !subscription?.product?.available ? (
              <EyeOff
                stroke={
                  subscription?.product?.available ? "currentColor" : "gray"
                }
              />
            ) : (
              <Eye
                stroke={
                  subscription?.product?.available ? "currentColor" : "gray"
                }
              />
            )}
          </div>
        </div>

        <div className="shadow p-4 ">
          <Button
            text="Add Subscription"
            small={true}
            bottomMargin={false}
            fn={() => {
              addRow();
            }}
          />
          <table className="table-auto w-full text-left border border-blue-500 mt-5">
            <thead className="bg-blue-500 text-white p-16">
              <tr>
                <TableHeader text="Subscription Name" sortable={false} />
                <TableHeader text="Time Option" sortable={false} />
                <TableHeader text="Expiration Date" sortable={false} />
                <TableHeader text="Status" sortable={false} />
                <TableHeader text="Rebill on" sortable={false} />
                <TableHeader text="Delete" sortable={false} />
              </tr>
            </thead>
            <tbody>
              {tableData.length
                ? tableData.map(
                  (item, itemIndex) =>
                    (item.is_deleted === undefined || item.is_deleted === false) && (
                      <tr key={`${itemIndex}-tr`}>
                        <TableData
                          child={
                            <div className="w-1/2">
                              <select
                                value={item.subscription_group_id}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    itemIndex,
                                    "subscription_group_id"
                                  )
                                }
                              >
                                <option value="">Select Subscription</option>
                                {subscriptions &&
                                  subscriptions.length &&
                                  subscriptions.map(
                                    (subscription, subsIndex) =>
                                    (
                                      <option
                                        key={`${subsIndex}-subscription`}
                                        value={subscription.id}
                                      >
                                        {subscription.title}
                                      </option>
                                    )
                                  )}
                              </select>
                              <small className="text-red-600 font-semibold">
                                {item?.error?.subscription_group_id}
                              </small>
                            </div>
                          }
                        />
                        <TableData
                          child={
                            <div className="w-1/2">
                              {subscriptions &&
                                subscriptions.length &&
                                subscriptions.map(
                                  (subscription, subscPricingIndex) =>
                                    item.subscription_group_id ==
                                    subscription.id &&
                                    subscription.pricing_options.length && (
                                      <>
                                        {" "}
                                        <select
                                          disabled={item.subscription_id && item.subscription_id !== "" ? true : false}
                                          value={item.subscription_prod_id}
                                          key={`${subscPricingIndex}-subscription-pricing`}
                                          onChange={(event) =>
                                            handleChange(
                                              event,
                                              itemIndex,
                                              "subscription_prod_id",
                                              {
                                                subscription_group_id:
                                                  item.subscription_group_id,
                                                subscription_prod_id:
                                                  item.subscription_prod_id,
                                              }
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Time
                                          </option>
                                          {subscription.pricing_options
                                            .length &&
                                            subscription.pricing_options.map(
                                              (pricing_option, pIndex) => (
                                                <option
                                                  key={`${pIndex}-subscription-option-pricing`}
                                                  value={
                                                    pricing_option.stripe_product_id
                                                  }
                                                >
                                                  {pricing_option.time}{" "}
                                                  {pricing_option.time_unit}
                                                  {pricing_option.time > 1
                                                    ? "s"
                                                    : ""}
                                                </option>
                                              )
                                            )}
                                        </select>
                                        <small className="text-red-600 font-semibold">
                                          {item?.error?.subscription_prod_id}
                                        </small>
                                      </>
                                    )
                                )}
                            </div>
                          }
                        />
                        <TableData child={item?.expiration_date ? handleDate(item?.expiration_date) : 'N/A'} />
                        <TableData child={item?.status} />
                        <TableData
                          child={
                            item?.expiration_date ? (
                              item.auto_subscription === true ? (
                                <p className="my-4 font-semibold text-green-900" style={{ textAlign: "center" }}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></p>
                              ) : (
                                <p className="my-4 font-semibold text-red-900" style={{ textAlign: "center" }}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></p>
                              )) : (
                              ""
                            )
                          }
                        />
                        <TableData
                          child={
                            <div className="w-1/2 icon_btn relative">
                              <Button
                                text=""
                                small={true}
                                bottomMargin={false}
                                fn={() => {
                                  deleteRow(itemIndex);
                                }}
                              />
                              <i className="fa-solid fa-trash-can"></i>
                            </div>
                          }
                        />
                      </tr>
                    )
                )
                : noData(6)}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminSubscriptionDetails;
