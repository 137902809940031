import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../general/Button";
import Toggle from "../general/Toggle";
import { EditProduct, UpdateSubscription } from "../../services";
import { uploadImage } from "../../apiStructure";
import toast from "react-hot-toast";
import { succesResponseMethod } from "../../features/admin/productSlice";
import { ACTIVE_URL, DATA_IS_NOT_AVAILABLE, DESCRIPTION, EDIT_SUBSCRIPTION, INACTIVE_URL, LEARN_MORE, MAILCHIMP_TAG } from "../../Constant";
import SubFormTable from "./SubFormTable";
import {initialSubFormData} from '../utils/commonVariable'

const SubForm = ({ cancelSubForm, product }) => {
  const logo = "/images/logo.webp";
  useEffect(() => {
    if (product) {
      setIsVisibleProduct(product?.product.available || false);
      setFormData({
        description: product.description,
        available: product.available,
        learn_more: product.learn_more,
        pricing_options: product.pricing_options,
        active_url: product.active_url,
        inactive_url: product.inactive_url,
        mailchip_tag: product.mailchip_tag,
        icon: product.icon,
      });
    }
  }, [product]);

  // Dispatch
  const dispatch = useDispatch();
  const succesResponseProduct = useSelector(
    (state) => state.products.succesResponseProduct
  );
  const inputFile = useRef(null);
  const [isVisibleProduct, setIsVisibleProduct] = useState(false);
  const [activeIndexEdit, setActiveIndexEdit] = useState(-1);
  const [activeinputValue, setActiveinputValue] = useState(0);
  // const [pricing_options,] = useState([
  //   {
  //     price: "",
  //     time_option: "",
  //   },
  // ]);
  const [formData, setFormData] = useState({
   ...initialSubFormData
  });

  //form data input handle
  const handleChange = (e) => {
    let target = e.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  //pricing options
  const handlepricingOptionSubmit = (index) => {
    if (!activeinputValue) {
      toast.error("Please enter Price value for selected plan.");
      return false;
    }
    const oldValue = formData;
    if (oldValue) {
      oldValue.pricing_options[index].price = Number(activeinputValue);
      setFormData(oldValue);
    }
    setActiveIndexEdit(-1);
  };
  //pricing option change
  const handlepricing_optionChange = (e) => {
    setActiveinputValue(e.target.value);
  };

  const resetValue = useCallback(() => {
    setFormData({
      ...initialSubFormData
    });
  }, [])

  useEffect(() => {
    if (succesResponseProduct) {
      dispatch(succesResponseMethod(false));
      cancelSubForm();
      resetValue();
    }
  }, [succesResponseProduct, cancelSubForm, dispatch, resetValue]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //on submit form
  const handleSubmit = () => {
    if (product?.product.available !== isVisibleProduct) {
      dispatch(
        EditProduct({
          ...product?.product,
          available: isVisibleProduct,
        })
      );
    }
    dispatch(
      UpdateSubscription({
        data: formData,
        id: product.id,
      })
    );
  };

  //Toggle activation
  const handleToggle = (e) => {
    let target = e.target;
    setIsVisibleProduct(target.checked);
    setFormData({
      ...formData,
      available: target.checked,
    });
  };

  //request upload a photo of app and feature
  const uploadFileAndgetURL = async (data) => {
    const res = await uploadImage(data);
    if (res.data.publicUrl) {
      setFormData({ ...formData, icon: res.data.publicUrl });
    }
  };

  //validation for photo
  const handleChangeFile = () => {
    if (inputFile && "current" in inputFile && inputFile.current.files) {
      const fileUploadData = inputFile.current.files[0];
      const Data = new FormData();
      Data.append("image", fileUploadData);
      uploadFileAndgetURL(Data);
    }
  };

  //IF data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
  return (
    <div
      className=" bg-black bg-opacity-20 overflow-hidden absolute top-0 left-0 h-full w-full"
      onClick={cancelSubForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="w-3/4 h-5/6 inset-x-0 z-10 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
          <h3 className="text-2xl">{EDIT_SUBSCRIPTION}</h3>
          <div className="w-1/5 flex justify-end">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              otherClass="mr-2"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelSubForm}
            />
            <Button
              text="Save"
              fn={handleSubmit}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
        <div className="popup_body px-8 pt-8 pb-16">
          {/* Product Details */}
          <div className="mb-4">
            <div className="shadow p-4">
              <div className="flex justify-between">
                <Toggle
                  label="Visible?"
                  onChange={handleToggle}
                  isChecked={isVisibleProduct}
                />
              </div>
              <div className="flex justify-between mt-4">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {DESCRIPTION}
                </label>
                <textarea
                  type="text"
                  autoFocus
                  placeholder="Enter description here..."
                  className="w-1/2 block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  onChange={handleChange}
                  value={formData.description}
                  name="description"
                ></textarea>
              </div>
              <div className="flex justify-between">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {LEARN_MORE}
                </label>
                <input
                  type="text"
                  placeholder="https://www.thoughtcastmagic.com/productone"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="learn_more"
                  onChange={handleChange}
                  value={formData.learn_more}
                />
              </div>
              <div className="flex items-center mb-5">
                <p className="font-semibold mr-2">Icon</p>
                <img
                  src={formData.icon || logo}
                  className="w-12 h-12 object-cover border border-black rounded mr-8"
                  alt=""
                />
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={handleChangeFile}
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  style={{ display: "none" }}
                />

                <Button
                  text="Change Icon"
                  fn={() => {
                    // `current` points to the mounted file input element
                    inputFile.current.click();
                  }}
                  // onClick={handleChangeImg}
                  small={true}
                  bottomMargin={false}
                />
              </div>

              <hr></hr>
              <SubFormTable noData={noData} handlepricingOptionSubmit={handlepricingOptionSubmit} activeinputValue={activeinputValue}
                handlepricing_optionChange={handlepricing_optionChange} setActiveIndexEdit={setActiveIndexEdit} activeIndexEdit={activeIndexEdit} formData={formData}
              />
              <div className="flex justify-between mt-5">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {ACTIVE_URL}
                </label>
                <input
                  type="text"
                  placeholder="https://www.thoughtcastmagic.com/productone"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="active_url"
                  onChange={handleChange}
                  value={formData.active_url}
                />
              </div>
              <div className="flex justify-between">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {INACTIVE_URL}
                </label>
                <input
                  type="text"
                  placeholder="https://www.thoughtcastmagic.com/productone"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="inactive_url"
                  onChange={handleChange}
                  value={formData.inactive_url}
                />
              </div>
              <div className="flex justify-between">
                <label className="block mr-4 font-semibold" htmlFor="">
                  {MAILCHIMP_TAG}
                </label>
                <input
                  type="text"
                  placeholder="Mailchimp/Shopify Tag 1"
                  className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                  name="mailchip_tag"
                  onChange={handleChange}
                  value={formData.mailchip_tag}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-16 mb-4">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelSubForm}
              otherClass="mr-4"
            />
            <Button
              text="Save"
              fn={handleSubmit}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubForm;
