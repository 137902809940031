const authRequest = {
  login: "/auth/login",
  logout: "/auth/logout",
  signup: "/auth/sign-up",
  refreshToken: "/auth/refresh-token",
  forgotPassword: "/auth/forgot-password",
  checkCode: "/open/check-code",
  changePassword: "auth/reset-password",
};

const userRequest = {
  getAllUsers: "/users",
  getAllAdmins: "/users/get-admins",
  getUser: "/user",
  addUser: "/users/add-user",
  updateUser: "users/update-user",
  deleteMultipleUser: "/delete-multiple-user",
  mergeMultipleUser: "/merge-selected-user",
  deprecateProduct: "/users/deprecate-user-product",
  subToggle: "/toggle-subs-visibility",
  getAllActiveProducts: "/users/active-products",
  resetAppsListeningToken: "/users/resetAppsListeningToken",
};

const productRequest = {
  getAllProducts: "/products",
  getProduct: "/product",
  addProduct: "/add-product",
  updateProduct: "/update-product",
  deleteMultipleProduct: "/delete-multiple-product",
  updateSubscription: "/update-subscription-plan",
  checkProductId: "/check-product-id",
};

const codeRequest = {
  getAllCodes: "/codes",
  getCode: "/code",
  addMultiCodes: "/add-multi-code",
  updateMultiCodes: "/update-code",
  deleteMultiCodes: "/delete-multiple-code",
  redeemCode: "/users/redeem-code",
};

const AccountSettingRequest = {
  updateSetting: "/update-setting",
  getDisplayOrder: "/get-display-order",
  updateDisplayOrder: "/add-display-order",
  getSetting: "/get-setting",
};

const uploadImageRequest = {
  uploadImage: "/image-upload",
};
const codeHistoryRequest = {
  codeHistory: "/code-history",
  codeActivation: "/code-activation",
};

const makePaymentRequest = {
  checkoutApi: "/make-payment",
  cancelSubscription: "/cancel-subscription",
  createSetupIntent: "/create-setup-intent",
  listSetupIntent: "/list-setup-intent",
  removeSetupIntent: "/remove-setup-intent",
  updateDefaultPaymentMethod: "/update-default-payment-method",
  getDefaultPaymentMethod: "get-default-payment-method",
};

const apiKeysRequest = {
  getApiKeys: "/get-apikeys",
  generateApiKey: "/generate-apikey",
  deleteApiKey: "/delete-apikey",
};

const subscriptionRequest = {
  addSubscription: "/add-new-subscription",
  editSubscription: "/edit-subscription",
  getAllSubscriptions: "/subscriptions",
  deleteMultipleSubscription: "/delete-multiple-subscription",
  updateSubscription: "/update-subscription",
  info: "/info",
  infoUpdate: "/info-update",
  updateUserSubscription: "/subscriptions/update-user-subscription",
};

export {
  authRequest,
  AccountSettingRequest,
  userRequest,
  productRequest,
  codeRequest,
  uploadImageRequest,
  codeHistoryRequest,
  makePaymentRequest,
  apiKeysRequest,
  subscriptionRequest,
};
