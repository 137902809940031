import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeleteAdminApi, GetAdmins } from "../../services";
import {
  filterByKeyword,
  getPaginatedAdmins,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setAdminCount,
  deleteAdmins,
  changeRoleType,
} from "../../features/admin/adminListSlice";
import { userRoles } from "../utils/commonEnum";
import Button from "../general/Button";
import Pagination from "../layout/Pagination";
import Search from "../layout/Search";
import ItemPerPage from "../layout/ItemPerPage";
import Popup from "../general/Popup";
import UserForm from "../forms/UserForm";
import Loader from "../utils/Loader";
import { Helmet } from "react-helmet";
import {
  ADMIN,
  ADMINS,
  ADMIN_ACCOUNT,
  DATA_IS_NOT_AVAILABLE,
  DATA_IS_NOT_FOUND,
  SELECT_STATUS,
  STATUS,
  SUPER_ADMIN,
} from "../../Constant";
import TableComponent from "./TableComponent";


// Mui Import
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from "react-hot-toast";

const AdminAccounts = () => {

  const axios = require('axios').default;
  // State
  const admins = useSelector((state) => state.admins.paginatedAdmins);
  const totalAdmins = useSelector((state) => state.admins.list);
  const AdminsList = useSelector((state) => state.admins.adminlist);
  const superadminlist = useSelector((state) => state.admins.superadminlist);
  const loading = useSelector((state) => state.admins.loading);
  const adminCount = useSelector((state) => state.admins.adminCount);
  const typeRole = useSelector((state) => state.admins.typeRole);
  const filterKeyword = useSelector((state) => state.admins.filterKeyword);
  const pageNumber = useSelector((state) => state.admins.pageNumber);

  const [maxPages, setmaxPages] = useState(0);
  const [maxAdminsPerPage] = useState(10);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [disablePagination, setDisablePagination] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [changeAdmin, setChangeAdmin] = useState(null);
  const [OpenFilter, setOpenFilter] = useState(false);

  // Dispatch
  const dispatch = useDispatch();

  const changeRoleTypeSelect = (status) => {
    if (!loading) {
      dispatch(changeRoleType(status));
      setOpenFilter(false);
    }
  };

  //Fetch admin list and total admins
  useEffect(() => {
    const totalAdminData =
      typeRole === userRoles.ADMIN
        ? AdminsList
        : typeRole === userRoles.SUPERADMIN
          ? superadminlist
          : totalAdmins;
    if (typeof filterKeyword === "string") {
      if (filterKeyword.length > 0) {
        let regExp = new RegExp(`^${filterKeyword}`, `i`);
        const filterSearchData = totalAdminData.filter(
          (user) =>
            user.firstname.match(regExp) ||
            user.lastname.match(regExp) ||
            user.email.match(regExp)
        );
        const mPNum = Math.ceil(filterSearchData.length / adminCount);
        setmaxPages(mPNum);
      } else {
        const mPNum = Math.ceil(totalAdminData.length / adminCount);
        setmaxPages(mPNum);
      }
    } else {
      const mPNum = Math.ceil(totalAdminData.length / adminCount);
      setmaxPages(mPNum);
    }
  }, [
    totalAdmins,
    AdminsList,
    superadminlist,
    typeRole,
    adminCount,
    filterKeyword,
  ]);

  //get admin that are registered
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetAdmins());
      dispatch(getPaginatedAdmins());
    };
    getData();

    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelUserForm();
      }
    });

  }, [dispatch]);

  // Methods
  const selectRow = (e, adminId) => {
    if (e.target.checked) {
      setSelectedAdmins([...selectedAdmins, adminId]);
    } else {
      setSelectedAdmins(selectedAdmins.filter((id) => id !== adminId));
      setDisablePagination(false);
    }
  };

  //selected admin
  const selectAll = () => {
    setSelectedAdmins(admins.map((u) => u.id));
    setDisablePagination(true);
  };
  const selectNone = () => {
    setSelectedAdmins([]);
    setDisablePagination(false);
  };

  //if data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {filterKeyword ? DATA_IS_NOT_FOUND : DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  //for delete admin
  const deleteSelected = () => {
    dispatch(deleteAdmins(selectedAdmins));
    dispatch(DeleteAdminApi(selectedAdmins));
    setShowPopup(false);
    setSelectedAdmins([]);
    setDisablePagination(false);
  };

  const clearPopup = () => {
    setShowPopup(false);
  };

  //To cancelUserForm
  const cancelUserForm = () => {
    setShowUserForm(false);
    setChangeAdmin(null);
  };

  //To displayUserForm
  const displayUserForm = (admin) => {
    setShowUserForm(true);
    setChangeAdmin(admin);
  };

  // Confirmation dialog for clear deleted users
  const [openConfirmationDialogUserDelete, setOpenConfirmationDialogUserDelete] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleConfirmationDialogUserDelete = () => {
    setOpenConfirmationDialogUserDelete(true);
  };

  const handleDialogConfirmationUserDelete = () => {
    setOpenConfirmationDialogUserDelete(false);
  };

  const handleDialogConfirmationUserDeleteAgree = async () => {
    // Call the api to delete
    try {
      setOpenConfirmationDialogUserDelete(false);
      await axios.delete('/users/delete-all-already');
      toast.success("Users deleted successfully!");

    } catch (error) {
      // error
    }

  };


  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={openConfirmationDialogUserDelete}
          onClose={handleDialogConfirmationUserDelete}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Are you sure to delete all users that are already deleted?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please sure before confirm, this will delete all users and its related data in purchased, subscription, code history etc.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button text="Close" fn={handleDialogConfirmationUserDelete} />
            <Button text="Delete" fn={handleDialogConfirmationUserDeleteAgree} />
          </DialogActions>
        </Dialog>
      </div>
      <div className="p-16 h-full relative">
        <Helmet>
          <title>{ADMINS}</title>
        </Helmet>
        <h1 className="text-3xl font-bold text-blue-600 mb-8">{ADMIN_ACCOUNT}</h1>
        {/* Popup */}
        {showPopup ? (
          <Popup
            yesFn={deleteSelected}
            noFn={clearPopup}
            width="w-1/3"
            text={
              selectedAdmins.length === 1
                ? "Delete Selected Admin"
                : "Delete Selected Admins"
            }
          />
        ) : null}
        {/* UserForm */}
        {showUserForm ? (
          <UserForm
            user={changeAdmin}
            cancelUserForm={cancelUserForm}
            isAdmin={true}
          />
        ) : null}

        {/* Controls */}
        <div className="mb-8 flex justify-between">
          <div className="w-9/12">
            <Search
              placeholder="Search Admin Accounts"
              fn={filterByKeyword}
              clearFn={filterByKeyword}
            />
            {!disablePagination ? (
              <ItemPerPage
                itemCount={adminCount}
                maxItemsPerPage={maxAdminsPerPage}
                label="Admins"
                setCount={setAdminCount}
              />
            ) : null}
          </div>
          <div className="w-3/12">
            <Button text="Add Admin" fn={() => setShowUserForm(true)} />
            <Button
              text={
                selectedAdmins.length === 1
                  ? "Delete Selected Admin"
                  : "Delete Selected Admins"
              }
              bgColor="bg-red-900"
              fn={() => setShowPopup(true)}
              disabled={selectedAdmins.length === 0}
            />
            <Button text="Clear Deleted Users" fn={handleConfirmationDialogUserDelete} />
            <div>
              <p>{STATUS}</p>
              <div
                style={{ zIndex: "1" }}
                className="w-2/3 select-none bg-blue-200 cursor-pointer font-medium relative"
              >
                <div
                  onClick={() => {
                    if (!loading) {
                      setOpenFilter(!OpenFilter);
                    }
                  }}
                  className="px-4 py-2 text-lg border mt-1 border-blue-500 rounded font-medium flex justify-between"
                >
                  <div>
                    {typeRole === userRoles.ALL
                      ? "Select Status"
                      : typeRole === userRoles.ADMIN
                        ? "Admin"
                        : typeRole === userRoles.SUPERADMIN
                          ? "Super Admin"
                          : "Select Status"}
                  </div>
                  <div>▼</div>
                </div>
                {OpenFilter ? (
                  <div
                    style={{ marginTop: "-3px" }}
                    className="w-full absolute border mt-1 bg-blue-200 border-blue-500"
                  >
                    <div
                      onClick={() => {
                        changeRoleTypeSelect(userRoles.ALL);
                      }}
                      className={`px-4 py-2 ${typeRole === userRoles.ALL ? "bg-blue-400" : ""
                        }`}
                    >
                      {SELECT_STATUS}
                    </div>
                    <div
                      onClick={() => {
                        changeRoleTypeSelect(userRoles.ADMIN);
                      }}
                      className={`px-4 py-2 ${typeRole === userRoles.ADMIN ? "bg-blue-400" : ""
                        }`}
                    >
                      {ADMIN}
                    </div>
                    <div
                      onClick={() => {
                        changeRoleTypeSelect(userRoles.SUPERADMIN);
                      }}
                      className={`px-4 py-2 ${typeRole === userRoles.SUPERADMIN ? "bg-blue-400" : ""
                        }`}
                    >
                      {SUPER_ADMIN}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {/* Data */}
            <TableComponent
              selectAll={selectAll}
              selectNone={selectNone}
              selectedAdmins={selectedAdmins}
              selectRow={selectRow}
              displayUserForm={displayUserForm}
              noData={noData}
              admins={admins}
            />
            {/* Pagination */}
            {!disablePagination ? (
              <Pagination
                prevFn={() => dispatch(goToPreviousPage())}
                nextFn={() => dispatch(goToNextPage())}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                maxPages={maxPages}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default AdminAccounts;
