import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateCodes, EditCodes, ReactiveCodes, GetProducts, GetSubscriptions } from "../../services";
import { succesResponseMethod } from "../../features/admin/codeSlice";
import { CheckComponent } from "../utils/SVG";
import toast from "react-hot-toast";
import {
  ADD_MINIMUN_FIVE_CHAR, CODE_NOT_FOUND, PLEASE_ADD_CODE, REACTIVE, SAVE, SOMETHING_WENT_WRONG
} from "../../Constant";
import SubCodeForm from "./SubCodeForm";
import Apps from "./Apps";
import CodeFormHeader from "./CodeFormHeader";
import { initialCodeForm } from '../utils/commonVariable';


function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
let isApiCallStart = true;
const CodeForm = ({ code, multiCodes, cancelCodeForm, readOnly = false, isCodeAdd = false }) => {
  const [bulkMode, setBulkMode] = useState(false);
  const [codeLength, setCodeLength] = useState(13);
  const [codeError, setCodeError] = useState("");
  const [oldPrefix, setOldPrefix] = useState("");
  const [p2a, setP2a] = useState([]);
  const [subs, setSubs] = useState([]);
  const [numberOfCodes, setNumerOfCodes] = useState(10);
  const [prefix, setPrefix] = useState("");
  const succesResponseCode = useSelector(
    (state) => state.codes.succesResponseCode
  );
  const onGoingApiCall = useSelector(
    (state) => state.codes.loading
  )
  const [formData, setFormData] = useState(initialCodeForm);
  const productsState = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.list);
  const subscriptions = useSelector((state) => state.subscriptions.list);
  const loadingSubscriptionList = useSelector(
    (state) => state.subscriptions.loading
  );
  const productLoading = productsState.loading;
  const forceuUpdate = useForceUpdate();
  const [settingData, setSettingData] = useState(null);

  const exportToCsv = useCallback(() => {
    if (formData.code.length > 0) {
      let CsvString = "";
      formData.code.forEach(function (RowItem, RowIndex) {
        CsvString += RowItem + "\r\n";
      });
      CsvString = "data:application/csv," + encodeURIComponent(CsvString);
      let x = document.createElement("A");
      x.setAttribute("href", CsvString);
      x.setAttribute("download", "multipleCode.csv");
      document.body.appendChild(x);
      x.click();
    }
  }, [formData.code]);

  useEffect(() => {
    if (succesResponseCode) {
      dispatch(succesResponseMethod(false));
      isApiCallStart = true;
      cancelCodeForm();
      if (formData.code.length > 1) {
        exportToCsv();
      }
    }
    // if we add formData in this useEffect than this function goes into infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succesResponseCode, cancelCodeForm, dispatch, exportToCsv]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //For check localstoragedata
  useEffect(() => {
    const localStorageData = localStorage.getItem("uls");
    if (localStorageData) {
      const parseLocalDATA = JSON.parse(localStorageData);
      if (parseLocalDATA && parseLocalDATA.setting) {
        setSettingData(parseLocalDATA.setting);
      }
    }
    return () => {
      setSettingData(null);
    };
  }, []);

  useEffect(() => {
    if (code || multiCodes) {
    } else {
      if (settingData) {
        const NumberValue = Number(settingData.default_code_length);
        setCodeLength(isNaN(NumberValue) ? 13 : NumberValue);
        const NumberValueNC = Number(settingData.default_number_of_case);
        setNumerOfCodes(isNaN(NumberValueNC) ? 10 : NumberValueNC);
        setFormData({
          ...formData,
          purchase_location: settingData.default_purchase_location || "",
        });
      }
    }
    // if we add formData in this useEffect than this function goes into infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingData, code, multiCodes]);

  useEffect(() => {
    if (code) {
      setFormData({
        ...formData,
        code: [code?.code],
        codePrefix: code.codePrefix || prefix,
        purchase_location: code.purchase_location,
        notes: code.notes,
        duration: code.duration,
        subscription_group_id: code?.subscription_group_id || "",
        subscription_prod_id: code?.subscription_prod_id || "",
      });
      setPrefix(code?.code || "");
    }
    // if we add formData in this useEffect than this function goes into infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, prefix]);

  useEffect(() => {
    if (!code) {
      setFormData({ ...formData, code: [] });
    }
    // if we add formData in this useEffect than this function goes into infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkMode, code]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetProducts());
      await dispatch(GetSubscriptions());
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (products?.length > 0) {
      const filterData = products.filter((prod) => prod.type === "product" && prod.title !== "Subscriptions");

      let data = filterData
        ? filterData.map((p, i) => {
          const indexForExist =
            code && code.products_to_activate
              ? code.products_to_activate.findIndex(
                (fiValue) => fiValue.id === p.id
              )
              : -1;
          return {
            id: p.id,
            title: p.title,
            activeCount:
              indexForExist === -1
                ? 0
                : code.products_to_activate[indexForExist].activeCount,
            apps:
              p.apps?.map((a, i) => {
                const indexForExistApp =
                  indexForExist === -1
                    ? -1
                    : code.products_to_activate[indexForExist].apps.findIndex(
                      (fiaData) => fiaData.id === a.id
                    );
                return {
                  id: a.id,
                  title: a.title,
                  isChecked:
                    indexForExistApp === -1
                      ? false
                      : code.products_to_activate[indexForExist].apps[
                        indexForExistApp
                      ].isChecked,
                };
              }) || [],
            features:
              p.features?.map((a, i) => {
                const indexForExistFeature =
                  indexForExist === -1
                    ? -1
                    : code.products_to_activate[
                      indexForExist
                    ].features.findIndex((fiaData) => fiaData.id === a.id);
                return {
                  id: a.id,
                  title: a.title,
                  isChecked:
                    indexForExistFeature === -1
                      ? false
                      : code.products_to_activate[indexForExist].features[
                        indexForExistFeature
                      ].isChecked,
                };
              }) || [],
          };
        })
        : [];
      if (code) {
        setFormData({
          code: [code?.code],
          codePrefix: code.codePrefix || "",
          purchase_location: code.purchase_location || "",
          notes: code.notes,
          products_to_activate: data,
          duration: code.duration,
          subscription_group_id: code?.subscription_group_id || "",
          subscription_prod_id: code?.subscription_prod_id || "",
        });
      } else {
        setFormData({
          ...formData,
          products_to_activate: data,
        });
      }
      setP2a(data);
    }

    if (subscriptions.length < 0) {
      setSubs(subscriptions)
    }

    // if we add formData in this useEffect than this function goes into infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, code, setP2a, subscriptions, setSubs]);

  const CheckProductSelected = () => {
    return 1;
  };
  /** Logic to handle input element value change */
  const handleChange = (e) => {
    const tempFormData = { ...formData }
    if (e.target.name === "subscription_group_id") {
      tempFormData["subscription_prod_id"] = "";
    }
    setFormData({
      ...tempFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    isApiCallStart = false;

    const productLength = formData.products_to_activate.find(o => o.activeCount > 0);
    console.log("ProductCheck", productLength);
    if ((!formData.subscription_group_id && !formData.subscription_prod_id) && productLength === undefined) {
      toast.error('Please select atleast one product or subscription to activate.');
      return false;

    }
    const error = {}
    //if (!formData.subscription_group_id) error["subscription_group_id"] = "Please Select Subscription Group"
    //if (!formData.subscription_prod_id) error["subscription_prod_id"] = "Please Select Subscription Plan"
    setFormData({
      ...formData,
      error
    })
    if (Object.keys(error).length) {

      isApiCallStart = true;
      return false;
    }



    if (code && !readOnly) {
      const ProductCheck = CheckProductSelected();
      if (ProductCheck < 1) {
        isApiCallStart = true;
        return false;
      }
      if (code.id.length < 1) {
        isApiCallStart = true;
        toast.error(CODE_NOT_FOUND);
        return false;
      }
      dispatch(
        EditCodes({
          ...formData,
          id: [code.id],
          code: [prefix] || formData.code,
          type: "single",
        })
      );
      isApiCallStart = true;
    } else if (code && readOnly) {
      const ProductCheck = CheckProductSelected();
      if (ProductCheck < 1) {
        isApiCallStart = true;
        return false;
      }
      if (code.id.length < 1) {
        isApiCallStart = true;
        toast.error(CODE_NOT_FOUND);
        return false;
      }
      dispatch(
        ReactiveCodes({
          products_to_activate: formData.products_to_activate,
          id: [code.id],
          activation: true,
          already_used: false,
        })
      );
    } else if (multiCodes && readOnly) {
      const id = multiCodes ? multiCodes.map((codeValue) => codeValue) : [];
      const ProductCheck = CheckProductSelected();
      if (ProductCheck < 1) {
        isApiCallStart = true;
        return false;
      }
      if (id.length < 1) {
        isApiCallStart = true;
        toast.error(CODE_NOT_FOUND);
        return false;
      }
      dispatch(
        ReactiveCodes({
          products_to_activate: formData.products_to_activate,
          id: id,
          activation: true,
          already_used: false,
        })
      );
    } else if (multiCodes) {
      const id = multiCodes ? multiCodes.map((codeValue) => codeValue) : [];
      const ProductCheck = CheckProductSelected();
      if (ProductCheck < 1) {
        isApiCallStart = true;
        return false;
      }
      if (id.length < 1) {
        isApiCallStart = true;
        toast.error(CODE_NOT_FOUND);
        return false;
      }
      dispatch(
        EditCodes({
          notes: formData.notes,
          products_to_activate: formData.products_to_activate,
          id: id,
          type: "multiple",
          subscription_group_id: formData?.subscription_group_id || "",
          subscription_prod_id: formData?.subscription_prod_id || "",
        })
      );
    } else {
      const NewCreateData = formData;
      if (formData) {
        if (formData.codePrefix.length && formData.code.length < 1) {
          if (formData.codePrefix.length > 4) {
            NewCreateData.code = [formData.codePrefix];
          } else {
            isApiCallStart = true;
            toast.error(ADD_MINIMUN_FIVE_CHAR);
            return false;
          }
        } else if (!formData.codePrefix.length && formData.code.length > 0) {
        } else if (
          !(formData.code && formData.code.length && formData.codePrefix.length)
        ) {
          isApiCallStart = true;
          toast.error(PLEASE_ADD_CODE);
          return false;
        }
        const ProductCheck = CheckProductSelected();
        if (ProductCheck < 1) {
          isApiCallStart = true;
          return false;
        }
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      isApiCallStart = false;
      dispatch(CreateCodes(formData));
    }
  };

  const handleLengthChange = (e) => {
    setCodeLength(parseInt(e.target.value));
  };

  const getFooter = () => {
    if (code && readOnly) {
      return REACTIVE;
    }
    if (multiCodes && !readOnly) {
      return SAVE;
    }
    if (multiCodes && readOnly) {
      return REACTIVE;
    }
    if (code && !multiCodes && !readOnly) {
      return SAVE;
    }
    if (!code) {
      return SAVE;
    }
  };

  const renderApps = () => {
    const apps = Array.from(p2a)?.map((p, pi) => {
      return (
        <React.Fragment key={pi}>
          <Apps p={p} CheckComponent={CheckComponent} formData={formData} setP2a={setP2a} setFormData={setFormData} forceuUpdate={forceuUpdate} />
        </React.Fragment>
      );
    });
    return apps;
  };

  // const renderSubscriptions = () => {
  //   const apps = Array.from(subs)?.map((p, pi) => {
  //     return (
  //       <React.Fragment key={pi}>
  //         <Apps p={p} CheckComponent={CheckComponent} formData={formData} setP2a={setP2a} setFormData={setFormData} forceuUpdate={forceuUpdate} />
  //       </React.Fragment>
  //     );
  //   });
  //   return apps;
  // };

  return (
    <div
      className=" bg-black bg-opacity-20 overflow-hidden absolute top-0 left-0 h-full w-full"
      onClick={cancelCodeForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="mw-75 h-full-custom inset-x-0 z-10 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CodeFormHeader cancelCodeForm={cancelCodeForm} getFooter={getFooter} isApiCallStart={isApiCallStart}
          forceuUpdate={forceuUpdate} handleSubmit={handleSubmit} readOnly={readOnly} multiCodes={multiCodes} code={code} />

        <SubCodeForm code={code} multiCodes={multiCodes} setBulkMode={setBulkMode} setPrefix={setPrefix} bulkMode={bulkMode} prefix={prefix}
          codeLength={codeLength} setFormData={setFormData} formData={formData} numberOfCodes={numberOfCodes} setCodeError={setCodeError}
          handleLengthChange={handleLengthChange} handleChange={handleChange} setNumerOfCodes={setNumerOfCodes} codeError={codeError}
          readOnly={readOnly} productLoading={productLoading} p2a={p2a} renderApps={renderApps} onGoingApiCall={onGoingApiCall}
          cancelCodeForm={cancelCodeForm} getFooter={getFooter} isApiCallStart={isApiCallStart} forceuUpdate={forceuUpdate}
          handleSubmit={handleSubmit} settingData={settingData} oldPrefix={oldPrefix} setOldPrefix={setOldPrefix} subscriptions={subscriptions}
          loadingSubscriptionList={loadingSubscriptionList} isCodeAdd={isCodeAdd} />
      </div>
    </div>
  );
};

export default CodeForm;
