import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  ERROR_WHILE_EDIT_CODE,
  SUCCESSFULLY_ADDED_CODE,
  SUCCESSFULLY_REACTIVED_CODE,
  SUCCESSFULLY_UPDATED_CODE
} from "../../Constant";
import {
  GetCodes,
  CreateCodes,
  ReactiveCodes,
  EditCodes,
} from "../../services";

const paginatedCodes = (codes, pageNumber, codeCount, filterKeyword) => {
  if (filterKeyword) {
    return codes
      .filter((code) =>
        code.code.toLowerCase().includes(filterKeyword)
        || code.purchase_location.toLowerCase().includes(filterKeyword)
        || code.activated_by.toLowerCase().includes(filterKeyword)
        || `${code.firstname.toLowerCase()} ${code.lastname.toLowerCase()}`.includes(filterKeyword) || `${code.firstname.toLowerCase()}${code.lastname.toLowerCase()}`.includes(filterKeyword)
      )
      .slice((pageNumber - 1) * codeCount, pageNumber * codeCount);
  } else {
    return codes.slice((pageNumber - 1) * codeCount, pageNumber * codeCount);
  }
};

export const codeSlice = createSlice({
  name: "codes",
  initialState: {
    list: [],
    active: [],
    inactive: [],
    pageNumber: 1,
    codeCount: 10,
    paginatedCodes: [],
    codeType: "active",
    filterKeyword: "",
    loading: false,
    succesResponseCode: false,
  },
  reducers: {
    getPaginatedCodes: (state) => {
      state.paginatedCodes = paginatedCodes(
        state.codeType === "active" ? state.active : state.inactive,
        state.pageNumber,
        state.codeCount,
        state.filterKeyword
      );
    },
    getActiveCodes: (state) => {
      state.codeType = "active";
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    getInactiveCodes: (state) => {
      state.codeType = "inactive";
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    deleteCodes: (state, action) => {
      if (state.codeType === "active") {
        state.active = state.active.filter(
          (code) => !action.payload.includes(code.id)
        );
      }
      if (state.codeType === "inactive") {
        state.inactive = state.inactive.filter(
          (code) => !action.payload.includes(code.id)
        );
      }
      state.list = state.list.filter(
        (code) => !action.payload.includes(code.id)
      );
      state.pageNumber = 1;
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    setCodeType: (state, action) => {
      state.codeType = action.payload;
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    setPageNumber: (state, action) => {
      const currentList =
        state.codeType === "active" ? state.active : state.inactive;
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(currentList.length / state.codeCount) + 1
      ) {
        state.pageNumber = action.payload;
        codeSlice.caseReducers.getPaginatedCodes(state);
      }
    },
    setCodeCount: (state, action) => {
      if (action.payload > 0) {
        state.codeCount = action.payload;
        codeSlice.caseReducers.getPaginatedCodes(state);
      }
    },
    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        codeSlice.caseReducers.getPaginatedCodes(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(
        (state.codeType === "active"
          ? state.active.length
          : state.inactive.length) / state.codeCount
      );
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        codeSlice.caseReducers.getPaginatedCodes(state);
      }
    },
    succesResponseMethod: (state, action) => {
      state.succesResponseCode = action.payload;
    },
    sortByCodeName: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) => a.code.localeCompare(b.code));
        state.active = state.active.sort((a, b) =>
          a.code.localeCompare(b.code)
        );
        state.inactive = state.inactive.sort((a, b) =>
          a.code.localeCompare(b.code)
        );
      } else {
        state.list = state.list.sort((a, b) => b.code.localeCompare(a.code));
        state.active = state.active.sort((a, b) =>
          b.code.localeCompare(a.code)
        );
        state.inactive = state.inactive.sort((a, b) =>
          b.code.localeCompare(a.code)
        );
      }
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    sortByActivationDate: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort(
          (a, b) => new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000) - new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000)
        );
        state.active = state.active.sort(
          (a, b) => new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000) - new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000)
        );
        state.inactive = state.inactive.sort(
          (a, b) => new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000) - new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000) - new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000)
        );
        state.active = state.active.sort(
          (a, b) => new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000) - new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000)
        );
        state.inactive = state.inactive.sort(
          (a, b) => new Date(typeof b.activated_at === "string" ? b.activated_at : b.activated_at._seconds * 1000) - new Date(typeof a.activated_at === "string" ? a.activated_at : a.activated_at._seconds * 1000)
        );
      }
      codeSlice.caseReducers.getPaginatedCodes(state);
    },
    filterByKeyword: (state, action) => {
      if (action.payload) {
        state.filterKeyword = action.payload;
        state.pageNumber = 1;
        const currentList =
          state.codeType === "active" ? state.active : state.inactive;
        state.paginatedCodes = paginatedCodes(
          currentList,
          state.pageNumber,
          state.codeCount,
          state.filterKeyword
        );
      } else {
        state.filterKeyword = "";
        const currentList =
          state.codeType === "active" ? state.active : state.inactive;
        state.paginatedCodes = paginatedCodes(
          currentList,
          1,
          state.codeCount,
          ""
        );
      }
    },
  },
  extraReducers: {
    [GetCodes.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.active = state.list.filter((code) => code.activation);
      state.inactive = state.list.filter((code) => !code.activation);
      state.loading = false;
    },
    [GetCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [GetCodes.rejected]: (state, action) => {
      state.loading = false;
      state.list = [];
      state.active = [];
      state.inactive = [];
    },
    [CreateCodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = [...action.payload.data, ...state.list];

      state.active = [...action.payload.data, ...state.active];
      state.codeType = "active";
      state.pageNumber = 1;
      state.paginatedCodes = paginatedCodes(
        state.codeType === "active" ? state.active : state.inactive,
        state.pageNumber,
        state.codeCount,
        state.filterKeyword
      );
      state.succesResponseCode = true;
      toast.success(SUCCESSFULLY_ADDED_CODE);

    },
    [CreateCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [CreateCodes.rejected]: (state, action) => {
      state.loading = false;
    },
    [EditCodes.fulfilled]: (state, action) => {
      if (action.payload.success && action.payload.data) {
        action.payload.data.map((newData, index) => {
          const findIndexL = state.list.findIndex(
            (fdata) => fdata.id === newData.id
          );
          if (findIndexL !== -1) {
            state.list[findIndexL] = newData;
          }
          if (state.codeType === "active") {
            const findIndexA = state.active.findIndex(
              (fdata) => fdata.id === newData.id
            );
            if (findIndexA !== -1) {
              state.active[findIndexA] = newData;
            }
          } else {
            const findIndexIN = state.inactive.findIndex(
              (fdata) => fdata.id === newData.id
            );
            if (findIndexIN !== -1) {
              state.inactive[findIndexIN] = newData;
            }
          }

          return null;
        });
        state.paginatedCodes = paginatedCodes(
          state.codeType === "active" ? state.active : state.inactive,
          state.pageNumber,
          state.codeCount,
          state.filterKeyword
        );
      }
      state.loading = false;
      state.succesResponseCode = true;
      toast.success(SUCCESSFULLY_UPDATED_CODE);
    },

    [EditCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [EditCodes.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload) {
      } else {
        toast.error(ERROR_WHILE_EDIT_CODE);
      }
    },
    [ReactiveCodes.fulfilled]: (state, action) => {
      if (action.payload.success && action.payload.data) {
        action.payload.data.map((newData, index) => {
          const findIndexL = state.list.findIndex(
            (fdata) => fdata.id === newData.id
          );
          if (findIndexL !== -1) {
            state.list[findIndexL] = newData;
          }
          return null;
        });
        state.active = state.list.filter((code) => code.activation);
        state.inactive = state.list.filter((code) => !code.activation);
        state.paginatedCodes = paginatedCodes(
          state.codeType === "active" ? state.active : state.inactive,
          state.pageNumber,
          state.codeCount,
          state.filterKeyword
        );
      }
      state.loading = false;
      state.succesResponseCode = true;
      toast.success(SUCCESSFULLY_REACTIVED_CODE);
    },
    [ReactiveCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [ReactiveCodes.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initCodes,
  getPaginatedCodes,
  getActiveCodes,
  getInactiveCodes,
  deleteCodes,
  filterByKeyword,
  succesResponseMethod,
  setCodeCount,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setCodeType,
  sortByActivationDate,
  sortByCodeName,
} = codeSlice.actions;

export default codeSlice.reducer;
