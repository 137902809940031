import React from "react";

const UserFormHeader = ({ user, isAdmin, cancelUserForm, loadingUser, loadingAdmin, handlesubmit }) => {
  return (
    <>
      <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
        <h3 className="text-2xl">
          {user ? "Edit" : "Add"} {isAdmin ? "Admin" : "User"}
        </h3>


      </div>
    </>
  )
}
export default UserFormHeader;