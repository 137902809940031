import React, { useState } from "react";
//import { Eye, EyeOff } from "../utils/SVG";
//import { cancelSubscription } from "../../services";
import Button from "../general/Button";
import UserFormProduct from "../general/UserFormProduct";
import Loader from "../utils/Loader";
//import Toggle from "../general/Toggle";
//import Datepicker from "react-date-picker";
//import { ReactComponent as Calender } from "../../components/utils/SVG/assets/calendar.svg";
import {
  SUPER_ADMIN,
  ADMIN_TYPE,
  ADMIN,
  ACCOUNT_NOTES,
  ACTIVE_HISTORY,
  SEARCH,
} from "../../Constant";
import { toggleSubsVisibility } from "../../features/admin/userListSlice";
import { subToggle } from "../../apiStructure/index";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import UserFormDetailsTable from "./UserFormDetailsTable";
import PersonalDetails from "./PersonalDetails";
import AdminSubscriptionDetails from "./AdminSubscriptionDetails";
import SearchBar from "material-ui-search-bar";

const UserFormDetails = ({
  userActiveProducts,
  setUserActiveProducts,
  userProducts,
  setUserProducts,
  userProductsOriginal,
  setUserProductsOriginal,
  productToActive,
  setProductToActive,
  setChangeByAdmin,
  setformData,
  formData,
  productLoading,
  subscription,
  user,
  error,
  isAdmin,
  isPaymentCancel,
  setSubEdit,
  subscriptionData,
  codeInfo,
  setSubscriptionData,
  noData,
  codeInfoloading,
  cancelUserForm,
  loadingUser,
  loadingAdmin,
  handlesubmit,
  tableData,
  setTableData,
  subscriptions,
  loadingSubscriptionList
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  //Change Show user
  const handleChangeDepricated = (prod_id, event) => {
    const userPro = userProducts.map((p, i) => {
      return {
        ...p,
        hide_from_user: prod_id === p.id ? event.target.value : p.hide_from_user,
      };
    });
    setUserProducts(userPro);
    const userProOriginal = userProductsOriginal.map((p, i) => {
      return {
        ...p,
        hide_from_user: prod_id === p.id ? event.target.value : p.hide_from_user,
      };
    });
    setUserProductsOriginal(userProOriginal);
  };

  //handle notes change
  const handleNotesChange = (e, app, type) => {
    if (type === "app") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              return {
                ...ap,
                notes: e.target.value,
              };
            }
            return ap;
          }),
        };
      });
      setUserProducts(userPro);

      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              return {
                ...ap,
                notes: e.target.value,
              };
            }
            return ap;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);
    }
    if (type === "feature") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              return {
                ...ft,
                notes: e.target.value,
              };
            }
            return ft;
          }),
        };
      });
      setUserProducts(userPro);
      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              return {
                ...ft,
                notes: e.target.value,
              };
            }
            return ft;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);
    }


  };

  //App and Feature's depricated
  const handleAppFeatureDepriacted = (app, type, event = '') => {
    let hide_user_val = 'global';
    if (event !== "") {
      hide_user_val = event.target.value;
    }
    if (type === "app") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              let deprecatedValue =
                app.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ap,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }
            return ap;
          }),
        };
      });
      setUserProducts(userPro);

      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              let deprecatedValue =
                app.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ap,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }
            return ap;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);

      const userActive = userActiveProducts.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              let deprecatedValue =
                app.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ap,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }
            return ap;
          }),
        };
      });
      setUserActiveProducts(userActive);
    }
    if (type === "feature") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              let deprecatedValue =
                ft.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ft,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }

            return ft;
          }),
        };
      });
      setUserProducts(userPro);
      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              let deprecatedValue =
                ft.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ft,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }

            return ft;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);

      const userActive = userActiveProducts.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              let deprecatedValue =
                ft.deprecated === "undefined"
                  ? true
                  : app.deprecated === true
                    ? false
                    : true;

              return {
                ...ft,
                deprecated: deprecatedValue,
                hide_from_user: hide_user_val
              };
            }

            return ft;
          }),
        };
      });
      setUserActiveProducts(userActive);
    }
  };

  const handleActiveToggler = (app, type, value) => {
    if (type === "app") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              return {
                ...ap,
                code_used: !ap.active ? "activateByAdmin" : ap.code_used,
                active: !ap.active,
                activated_on: new Date(),
              };
            }
            return ap;
          }),
        };
      });
      setUserProducts(userPro);

      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          apps: p.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              return {
                ...ap,
                code_used: !ap.active ? "activateByAdmin" : ap.code_used,
                active: !ap.active,
                activated_on: new Date(),
              };
            }
            return ap;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);
      let userPTA = productToActive.map((ptaD, i) => {
        return {
          ...ptaD,
          activeCount:
            ptaD.apps.findIndex((ap, i) => {
              return ap.id === app.id;
            }) !== -1
              ? !value
                ? ptaD.activeCount == null ? 1 : ptaD.activeCount + 1
                : ptaD.activeCount == null ? 0 : ptaD.activeCount - 1
              : ptaD.activeCount,
          apps: ptaD.apps?.map((ap, i) => {
            if (ap.id === app.id) {
              return {
                ...ap,
                isChecked: !ap.isChecked,
                isDeActive: value === true ? 'Yes' : 'No'
              };
            }
            return ap;
          }),
        };
      });
      setProductToActive(userPTA);
      setChangeByAdmin(true);
    }
    if (type === "feature") {
      let userPro = userProducts.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              return {
                ...ft,
                code_used: !ft.active ? "activateByAdmin" : ft.code_used,
                active: !ft.active,
                activated_on: new Date(),
              };
            }
            return ft;
          }),
        };
      });
      setUserProducts(userPro);
      const userProOriginal = userProductsOriginal.map((p, i) => {
        return {
          ...p,
          features: p.features?.map((ft, i) => {
            if (ft.id === app.id) {
              return {
                ...ft,
                code_used: !ft.active ? "activateByAdmin" : ft.code_used,
                active: !ft.active,
                activated_on: new Date(),
              };
            }
            return ft;
          }),
        };
      });
      setUserProductsOriginal(userProOriginal);

      let userPTA = productToActive.map((ptaD, i) => {
        return {
          ...ptaD,
          activeCount:
            ptaD.features.findIndex((ft, i) => {
              return ft.id === app.id;
            }) !== -1
              ? !value
                ? ptaD.activeCount == null ? 1 : ptaD.activeCount + 1
                : ptaD.activeCount == null ? 0 : ptaD.activeCount - 1
              : ptaD.activeCount,
          features: ptaD.features?.map((ft, i) => {
            if (ft.id === app.id) {
              return {
                ...ft,
                isChecked: !ft.isChecked,
                isDeActive: value === true ? 'Yes' : 'No'
              };
            }
            return ft;
          }),
        };
      });
      setProductToActive(userPTA);
      setChangeByAdmin(true);
    }
  };

  const handleSelect = (e) => {
    setformData({
      ...formData,
      role: e.target.value,
    });
  };

  const handleChange = (e) => {
    let target = e.target;
    setformData({
      ...formData,
      [target.name]: target.value,
    });
  };

  //Disabled or enabled a toggle
  const ToggleStatus = async () => {
    if (subscription?.product?.available) {
      const data = {
        user_id: formData.id,
        is_hidden: !formData.is_hidden_subscription,
      };
      setformData({
        is_hidden_subscription: !formData.is_hidden_subscription,
      });
      try {
        if (user) {
          const res = await subToggle(data);
          dispatch(toggleSubsVisibility(res.data.data));
          toast.success("Subscription Visibility Updated Successfully");
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Subscription is globally disabled");
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
    const userProductsData = [...userProducts];

    const productList =
      userProductsOriginal === null
        ? [...userProducts]
        : [...userProductsOriginal];

    const filterData = productList.reduce(function (result, prod) {
      const appsProd = prod.apps.reduce((result, prodApps) => {
        if (
          prodApps.data.title
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          value === "" ||
          prod.data.title.toLowerCase().includes(value.toLowerCase())
        ) {
          delete prodApps.hide;
        } else {
          prodApps.hide = true;
        }
        result.push(prodApps);
        return result;
      }, []);

      const featuresProd = prod.features.reduce((result, prodFeature) => {
        if (
          prodFeature.data.title
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          value === "" ||
          prod.data.title.toLowerCase().includes(value.toLowerCase())
        ) {
          delete prodFeature.hide;
        } else {
          prodFeature.hide = true;
        }
        result.push(prodFeature);
        return result;
      }, []);

      prod.apps = appsProd;
      prod.features = featuresProd;

      const appsProdStatus = prod.apps.filter((prodApps) => !prodApps.hide);
      const featuresStatus = prod.features.filter(
        (prodFeature) => !prodFeature.hide
      );
      if (
        appsProdStatus.length ||
        featuresStatus.length ||
        prod.data.title.toLowerCase().includes(value.toLowerCase())
      )
        result.push(prod);
      return result;
    }, []);

    if (value) {
      setUserProducts(filterData);
    } else {
      setUserProducts(userProductsOriginal);
    }
  };

  const handleSubscriptionData = (data) => {
    // 👇️ take parameter passed from Child component
    // setCount(current => current + num);
    setformData({
      ...formData,
      subscriptionAddData: data,
    });
  };

  return (
    <>
      <div className="popup_body px-8 pt-8 pb-16">
        {/* User Details */}

        <div className="w-full mb-4">
          <div className="flex">
            <PersonalDetails
              handleChange={handleChange}
              formData={formData}
              error={error}
              user={user}
            />

            {isAdmin && (
              <div className="shadow p-4 w-1/2">
                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {ADMIN_TYPE}
                  </label>
                  <select
                    className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
                    value={formData?.role}
                    onChange={handleSelect}
                  >
                    <option value={2}>{ADMIN}</option>
                    <option value={1}>{SUPER_ADMIN}</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* account notes */}
        <div>
          <label className="block mr-4 mb-4 font-semibold" htmlFor="">
            {ACCOUNT_NOTES}
          </label>
          <textarea
            rows="4"
            name="account_notes"
            placeholder="Account Notes"
            onChange={handleChange}
            value={formData?.account_notes}
            className="w-full block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>

        {isAdmin ? null : loadingSubscriptionList ? <div className="h-20">
          <Loader />
        </div> : (
          <>
            <hr className="h-0.5 bg-gray-300 my-2" />
            <AdminSubscriptionDetails
              handleSubscriptionData={handleSubscriptionData}
              noData={noData}
              tableData={tableData}
              setTableData={setTableData}
              subscriptions={subscriptions}
            />
          </>
        )}

        {isAdmin ? (
          ""
        ) : (
          <div>
            <hr className="h-0.5 bg-gray-300 my-2" />

            {!productLoading ? (
              <div>
                <label
                  className=" block mr-4 mb-4 font-semibold"
                  htmlFor="search"
                >
                  {SEARCH}
                </label>
                <SearchBar
                  autoComplete="off"
                  name="search"
                  id="Search"
                  className="mb-4 w-full md:w-12/12 xl:w-12/12 my-0  block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
                  value={search}
                  onChange={(value) => handleSearch(value)}
                  placeholder={'Search'}
                  onCancelSearch={() => handleSearch("")}
                />
              </div>
            ) : null}

            {productLoading ? (
              <div className="h-20">
                <Loader />
              </div>
            ) : (
              userProducts?.map((userProd, idx) => {
                const filterUserActiveProducts = userActiveProducts.filter(o => o.id == userProd.id);
                return (
                  userProd?.data?.title !== "Subscriptions" ?
                    (
                      <React.Fragment key={idx}>
                        <UserFormProduct
                          user={user}
                          userProduct={userProd}
                          handleActiveToggler={handleActiveToggler}
                          handleNotesChange={handleNotesChange}
                          handleAppFeatureDepriacted={handleAppFeatureDepriacted}
                          handleChangeDepricated={handleChangeDepricated}
                          subscriptionData={subscriptionData}
                          isEdit={user ? true : false}
                          userActiveProducts={filterUserActiveProducts}
                        />
                      </React.Fragment>
                    ) : ''
                );
              })
            )}
            {user && codeInfo && codeInfo.length ? (
              <>
                <hr className="h-0.5 bg-gray-300 my-2" />
                {/* Code Information */}
                <div className="w-full mb-4">
                  <h3 className="font-semibold text-2xl mb-4">
                    {ACTIVE_HISTORY}
                  </h3>
                  <div className="shadow p-4">
                    {/* Data */}
                    {codeInfoloading ? (
                      <Loader />
                    ) : (
                      <UserFormDetailsTable
                        codeInfo={codeInfo}
                        noData={noData}
                        subscriptions={subscriptions}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
        <div className="flex custom-fix justify-center mt-16 mb-4">
          <Button
            text="Cancel"
            bgColor="bg-blue-100"
            fontSemiBold={true}
            bottomMargin={false}
            textColor="text-black"
            fn={() => {
              cancelUserForm();
            }}
            otherClass="mr-4"
          />
          <Button
            text="Save"
            disabled={loadingUser || loadingAdmin}
            fn={handlesubmit}
            fontSemiBold={true}
            bottomMargin={false}
          />
        </div>
      </div>
    </>
  );
};

export default UserFormDetails;
