export const initialAccountSetting = {
  store_button_link: "",
  store_button_text: "",
  active_product_button_text: "",
  inactive_product_button_text: "",
  active_product_text: "",
  inactive_product_text: "",
  product_display_order: "",
  promotional_text: "",
  promotional_text_font_size: "",
  success_message: "",
  default_number_of_case: "",
  default_code_length: "",
  code_characters: "",
  default_purchase_location: "",
  swagger_docs_link: "",
  default_subscription_plan: "",
};

export const initialCodeForm = {
  code: [],
  codePrefix: "",
  duration: "None",
  purchase_location: "",
  products_to_activate: [],
  notes: "",
};

export const initialMergeUser = {
  firstname: null,
  lastname: null,
  email: null,
  password: null,
  id: null,
  activated_at: null,
  purchased: [],
  user_id: [],
};

export const initialAppFeature = {
  active_url: "",
  inactive_url: "",
  shopify_SKU: "",
  icon: "",
  iconUploaded: false,
  deprecated: true,
  mailchip_tag: "",
  shopify_unfulfill_SKU: "",
  created_at: new Date(),
  updated_at: new Date(),
};

export const productFormData = {
  title: "",
  icon: "",
  description: "",
  learn_more: "",
  available: true,
  src: "",
  price: "",
  deprecated: false,
  apps: [],
  features: [],
  id: 0,
};
export const initialPricingOptions = {
  price: "",
  time_option: "",
};

export const initialSubFormData = {
  description: "",
  available: true,
  learn_more: "",
  pricing_options: [initialPricingOptions],
  active_url: "",
  inactive_url: "",
  mailchip_tag: "",
  icon: "",
};

export const initialUserFormData = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  account_notes: "",
  id: "",
  role: 2,
  purchased: [],
  products_to_activate: [],
  is_hidden_subscription: false,
  app_listening_id: "",
};
export const initialSubscriptionData = {
  active: false,
  activated_at: "",
  expiration_date: new Date(),
  comments: "",
};

export const initialUserError = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
};
export const initialActivateError = {
  firstname: "",
  lastname: "",
  email: "",
  confirm_email: "",
  password: "",
  code: "",
  retype_password: "",
};
export const initialActivateUser = {
  firstname: "",
  lastname: "",
  email: "",
  confirm_email: "",
  password: "",
  code: "",
  retype_password: "",
};

export const initialSubscriptionInfoFormData = {
  name: "",
  description: "",
  available: true,
  learn_more: "",
  pricing_options: [initialPricingOptions],
  show_price_comparisons_per: "",
  price_to_show_in_owners_portal: "",
  icon: "",
  selected_subscription: "All",
  subscription_pricing_options_visibility: [],
};
