import { useEffect } from "react";
import AppRouter from "./Router";
import * as ApiRequest from "./apiStructure";

const App = () => {
  const MINUTE_MS = 1800000;

  useEffect(() => {
    var auth = null;
    try {
      auth = JSON.parse(localStorage.getItem("uls"));
    } catch (e) { }
    if (auth) {
      try {
        const interval = setInterval(async () => {
          const response = await ApiRequest.refreshToken();
          if (response.data.data.access_token) {
            // update token
            localStorage.setItem(
              "uls",
              JSON.stringify({
                ...auth,
                token: response.data.data.access_token,
              })
            );
          }
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      } catch (e) { }
    }
  }, [])


  return (
    <AppRouter />
  );
};

export default App;
