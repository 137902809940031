import React from 'react'

const Apps = ({ p, CheckComponent, formData, setP2a, setFormData, forceuUpdate }) => {

  const handleP2A = (product, app, value, type) => {
    let data = formData.products_to_activate;
    data = data?.map((pro) => {
      if (pro.id === product.id) {
        pro.activeCount = value ? pro.activeCount + 1 : pro.activeCount - 1;
        if (type === "apps") {
          let oldidx = pro.apps.indexOf(app);
          pro.apps.splice(oldidx, 1, {
            ...app,
            isChecked: value,
          });
        }
        if (type === "features") {
          let oldidx = pro.features.indexOf(app);
          pro.features.splice(oldidx, 1, {
            ...app,
            isChecked: value,
          });
        }
      }
      return pro;
    });
    setP2a(data);

    setFormData({
      ...formData,
      products_to_activate: data,
    });

    forceuUpdate();
  };


  return (
    <div className="mb-4" key={p.id}>
      <h5 className="font-semibold mb-2 text-lg">{p.title}</h5>

      {p?.apps &&
        Array.from(p.apps)?.map((a, ai) => {
          return (
            <React.Fragment key={ai}>
              <div className="flex items-center" key={a.id}>

                <div
                  className="border border-black h-4 w-4 mr-2"
                  onClick={() => {
                    handleP2A(p, a, !a.isChecked, "apps");
                  }}
                >
                  {a.isChecked ? <CheckComponent /> : ""}
                </div>
                <label className="font-semibold">{a.title}</label>
              </div>
            </React.Fragment>
          );
        })}

      {p?.features &&
        Array.from(p.features)?.map((f, fi) => {
          return (
            <React.Fragment key={fi}>
              <div className="flex items-center" key={f.id}>
                <div
                  className="border border-black h-4 w-4 mr-2"
                  onClick={() => {
                    handleP2A(p, f, !f.isChecked, "features");
                  }}
                >
                  {f.isChecked ? <CheckComponent /> : ""}
                </div>
                <label className="font-semibold">{f.title}</label>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  )
}

export default Apps