import React, { useState, useEffect } from "react";
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
const Toggle = ({ label, onChange, isChecked, isdisabled = false, labelBeforeButton = true, classNameLable = '' }) => {
  const [status, setStatus] = useState(isChecked);
  const [bgColor, setbgColor] = useState(
    !isdisabled ? "bg-red-900" : "bg-red-200"
  );
  const forceuUpdate = useForceUpdate();

  //Toggle a action on change
  const toggleAction = (e) => {
    onChange && onChange(e);
  };

  //checked for enabled or disabled
  useEffect(() => {
    if (isChecked) {
      setbgColor(!isdisabled ? "bg-green-900" : "bg-green-200");
      setStatus(true);
      forceuUpdate();
    } else {
      setbgColor(!isdisabled ? "bg-red-900" : "bg-red-200");
      setStatus(false);
      forceuUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, isdisabled]);


  return (
    <div className="inline-block justify-center">
      <label className="flex items-center cursor-pointer">
        {labelBeforeButton && (<div className={classNameLable !== "" ? classNameLable : "mr-3 text-gray-700 font-medium"}>{label}</div>)}
        <div className="toggle relative">
          <input
            type="checkbox"
            className="sr-only z-20"
            checked={status}
            disabled={isdisabled}
            onChange={(e) => toggleAction(e)}
          />
          <div className={`block ${bgColor} w-8 h-5 rounded-full`}></div>
          <div
            className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition transform ${status ? "translate-x-full" : "translate-x-0"
              }`}
          ></div>
        </div>
        {!labelBeforeButton && (<div className="ml-3 text-gray-700 font-medium">{label}</div>)}
      </label>
    </div>
  );
};

export default Toggle;
