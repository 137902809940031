import React, { useEffect, useMemo, useCallback } from "react";
import Admin from "./pages/Admin";
import User from "./pages/User";
import NotFound from "./pages/NotFound";
import { history } from "./history";
import { Router, Route, Switch } from "react-router-dom";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import Login from "./components/users/Login";
import Activate from "./components/users/Activate";
import ForgotPassword from "./components/users/ForgotPassword";
import changePassword from "./components/users/changePassword";
// import AppListeningFunction from "./components/users/appListeningFunction";
import firebase from "./config/firebaseConfig";
import axios from "axios";
let intervalTimer = null;
const AppRouter = () => {
  var auth = null;
  try {
    auth = JSON.parse(localStorage.getItem("uls"));
  } catch (e) {}
  let current = firebase.auth.currentUser;

  const getToken = useCallback(async () => {
    try {
      if (firebase.auth.currentUser) {
        const token = await firebase.auth.currentUser.getIdToken(true);
        localStorage.setItem(
          "uls",
          JSON.stringify({
            ...auth,
            token: token,
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, [auth]);

  useEffect(() => {
    getToken();
  }, [getToken, current]);

  var publicRoutes = useMemo(() => {
    return ["/", "/forgotpassword", "/activate"];
  }, []);
  var admins = [1, 2];

  useEffect(() => {
    intervalTimer = setInterval(() => {
      if (
        publicRoutes.includes(history.location.pathname) ||
        history.location.pathname.includes("/changepassword")
      ) {
        localStorage.removeItem("uls");
      } else {
        const onIdTokenChangedFunction = async () => {
          await firebase.auth.onIdTokenChanged(function (user) {
            if (user) {
              localStorage.setItem(
                "uls",
                JSON.stringify({
                  ...auth,
                  token: user.accessToken,
                })
              );
              axios.defaults.headers.common["token"] = user.accessToken;
              // User is signed in or token was refreshed.
            } else {
              if (
                history.location.pathname !== "/" ||
                !history.location.pathname.includes("/changepassword")
              ) {
                localStorage.removeItem("uls");
                history.push("/");
              }
            }
          });
        };
        onIdTokenChangedFunction();
      }
    }, 1800000);

    return () => {
      clearInterval(intervalTimer);
    };
  }, [auth, publicRoutes]);

  return (
    <Router history={history}>
      <Toaster position="top-right" reverseOrder={true}>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button onClick={() => toast.dismiss(t.id)}>
                    <b>X</b>
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      {admins?.includes(auth?.role) && (
        <meta name="viewport" content="width=1920" />
      )}
      <Switch>
        <Route path="/changepassword" component={changePassword} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        {/* <Route path="/actionsapi/:token" component={AppListeningFunction} /> */}
        <Route path="/activate">
          <Activate />
        </Route>

        {admins?.includes(auth?.role) && (
          <Route path="/admin">
            <Admin role={auth.role} />
          </Route>
        )}
        <Route path="/user">
          <User role={auth?.role} />
        </Route>

        <Route exact path="/" component={Login} />
        {auth ? <Route component={NotFound} /> : <Route component={Login} />}
      </Switch>
    </Router>
  );
};

export default AppRouter;
