let storage = JSON.parse(localStorage.getItem("uls"));
class User {
  constructor(user) {
    this.firstname = user.firstname || null;
    this.lastname = user.lastname || null;
    this.email = user.email || null;
    this.password = user.password || null;
    this.devices = user.devices || [];
    this.purchased = user.purchased || [];
    this.role = user.role || null;
    this.id = user.id || null;
    this.recursive = user.recursive || null;
    this.activated_at = user.activated_at;
    this.created_at = user.created_at || null;
    this.subscription_data = user.subscription_data || null;
    this.account_notes = user.account_notes || "";
    this.active_subscription = user.active_subscription || "";
    this.is_hidden_subscription = user.is_hidden_subscription || false;
    this.userSubscriptions = user?.userSubscriptions || null;
    this.app_listening_id = user?.app_listening_id || null;
  }
}

class Subscription {
  constructor(product) {
    this.product = product.product;
    this.id = product.id;
    this.type = product?.type || "subscription";
    this.title = product.title || "";
    this.description = product.description || "";
    this.icon = product.icon || "/images/logo.webp";
    this.learn_more = product.learn_more || "";
    this.active_url = product.active_url || "";
    this.inactive_url = product.inactive_url || "";
    this.mailchip_tag = product.mailchip_tag || "";
    this.apps = product?.apps || "all";
    this.features = product?.features || "all";
    this.pricing_options = product.pricing_options || [];
    this.available = product.available || false;
    this.deprecated = product.deprecated || false;
    this.stripe_product_id = product.stripe_product_id || [];
    this.created_at = product?.created_at;
    this.updated_at = product?.updated_at;
    this.product_ids = product?.product_ids;
    this.products = product?.products;
  }
}
class Product {
  constructor(product) {
    this.id = product.id;
    this.product_id = product.product_id;
    this.type = product?.type || "product";
    this.title = product.title || "";
    this.description = product.description || "";
    this.icon = product.icon || "/images/logo.webp";
    this.learn_more = product.learn_more || "";
    this.price = product.price || 0;
    this.apps = product?.apps || [];
    this.features = product?.features || [];
    this.purchase_link = product.purchase_link || "";
    this.available = product.available || false;
    this.deprecated = product.deprecated || false;
    this.activated_at = product?.activated_at;
    this.created_at = product?.created_at;
    this.updated_at = product?.updated_at;
  }
}
class Settings {
  constructor(setting) {
    this.id = setting.id;
    this.active_product_text = setting?.active_product_text || "";
    this.promotional_text = setting.promotional_text || "";
    this.store_button_text = setting.store_button_text || "";
    this.store_button_link = setting.store_button_link || "";
    this.code_characters = setting.code_characters || "";
    this.inactive_product_text = setting.inactive_product_text || "";
    this.inactive_product_button_text =
      setting.inactive_product_button_text || "";
    this.success_message = setting.success_message || "";
    this.product_display_order = setting.product_display_order || "";
    this.promotional_text_font_size = setting.promotional_text_font_size || "";
    this.active_product_button_text = setting.promotional_text_font_size || "";
    this.default_number_of_case = setting.default_number_of_case || 0;
    this.default_code_length = setting.default_code_length || 0;
    this.parent_id = setting.parent_id || "";
    this.default_purchase_location = setting.default_purchase_location || "";
    this.is_deleted = setting.is_deleted || false;
    this.swagger_docs_link = setting.swagger_docs_link || "";
    this.available_subscription_plans =
      setting.available_subscription_plans || [];
    this.default_subscription_plan =
      setting.default_subscription_plan || "None";
  }
}

class Code {
  constructor(code) {
    this.id = code.id;
    this.activated_by =
      code.activated_by || (code.parent_id === storage.id ? storage.email : "");
    this.firstname =
      code.firstname ||
      (code.parent_id === storage.id ? storage.firstname : "");
    this.lastname =
      code.lastname || (code.parent_id === storage.id ? storage.lastname : "");
    this.codePrefix = code.codePrefix || "";
    this.code = code.code || "";
    this.notes = code.notes || "";
    this.activation = code.activation || false;
    this.purchase_location = code.purchase_location || "";
    this.user_id = code.user_id || "";
    this.products_to_activate = code.products_to_activate || [];
    this.activated_at = code?.activated_at;
    this.created_at = code?.created_at;
    this.updated_at = code?.updated_at;
    this.duration = code?.durationc === "1 year" ? "12 month" : code.duration;
    this.subscription_group_id = code?.subscription_group_id || "";
    this.subscription_prod_id = code?.subscription_prod_id || "";
  }
}

// Firestore user data converter
var userConverter = {
  toFirestore: function (user) {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      is_hidden_subscription: user.is_hidden_subscription || false,
      password: user.password,
      devices: user.devices || [],
      purchased: user.purchased || [],
      role: 3,
      subscription_data: user.subscriptionData || {
        active: false,
        activated_at: "",
        expiration_date: "",
        comments: "",
      },
      products_to_activate: user.products_to_activate,
      customer_id: user.customer_id ? user.customer_id : null,
      account_notes: user.account_notes,
      changes_by_admin: user.changes_by_admin,
      userSubscriptions: user?.userSubscriptions || null,
      activated_at: new Date(),
      is_deleted: false,
      created_at: new Date(),
      updated_at: new Date(),
      app_listening_id: user?.app_listening_id || null,
    };
  },
  updateToFirestore: function (user) {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      password: user.password,
      email: user.email,
      subscription_data: user.subscriptionData,
      purchased: user.purchased,
      changes_by_admin: user.changes_by_admin,
      products_to_activate: user.products_to_activate,
      account_notes: user.account_notes,
      userSubscriptions: user?.userSubscriptions,
      customer_id: user?.customer_id ? user.customer_id : null,
      updated_at: new Date(),
      app_listening_id: user?.app_listening_id || null,
    };
  },
  mergeUserToFirestore: function (user) {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: user.password,
      user_id: user.user_id,
      activated_at: user?.activated_at || null,
    };
  },
  deprecateProductUser: function (user) {
    return {
      user_id: user.user_id,
      product_id: user.product_id,
      deprecated: user.deprecated,
    };
  },
  fromFirestore: function (data) {
    let res = data.map((el) => {
      return new User(el);
    });
    return res;
  },
};

// Firestore admindata converter
var adminConverter = {
  toFirestore: function (user) {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: user.password,
      devices: user.devices || [],
      purchased: user.purchased || [],
      role: parseInt(user.role),
      activated_at: new Date(),
      is_deleted: false,
      created_at: new Date(),
      updated_at: new Date(),
      account_notes: user.account_notes || "",
    };
  },
  updateToFirestore: function (user) {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      account_notes: user.account_notes,
      role: user.role,
      updated_at: new Date(),
    };
  },
  fromFirestore: function (data) {
    let res = data.map((el) => {
      return new User(el);
    });
    return res;
  },
};

// Firestore admindata converter

const subscriptionConverter = {
  updateToFirestore: (subscription) => {
    return {
      type: subscription?.type || "subscription",
      description: subscription.description,
      learn_more: subscription.learn_more,
      active_url: subscription.active_url,
      inactive_url: subscription.inactive_url,
      mailchip_tag: subscription.mailchip_tag,
      apps: subscription?.apps || "all",
      icon: subscription.icon,
      features: subscription?.features || "all",
      pricing_options: subscription.pricing_options,
      available: subscription.available || false,
      deprecated: subscription.deprecated || false,
      updated_at: new Date(),
    };
  },
  fromFirestore: (data) => {
    let res = data.map((el) => {
      return new Subscription(el);
    });

    return res;
  },
};

const subscriptionInfoConverter = {
  fromFirestore: (data) => {
    let res = data.map((el) => {
      return el;
    });

    return res;
  },
};

// Firestore admindata converter

const subscriptionDataConverter = {
  updateToFirestore: (subscription) => {
    return {
      ...subscription,
      updated_at: new Date(),
    };
  },
  fromFirestore: (data) => {
    let res = data.map((el) => {
      return new Subscription(el);
    });

    return res;
  },
};

var productConverter = {
  toFirestore: function (product) {
    return {
      product_id: product.product_id,
      title: product.title || "",
      description: product.description || "",
      icon: product.icon || "/images/logo.webp",
      type: "product",
      learn_more: product.learn_more,
      price: product.price,
      purchase_link: null,
      parent_id: storage?.id,
      apps: product?.apps || [],
      features: product?.features || [],
      available: product.available,
      deprecated: product.deprecated,
      activated_at: new Date(),
      created_at: new Date(),
      updated_at: new Date(),
      is_deleted: false,
    };
  },
  updateToFirestore: function (product) {
    return {
      product_id: product.product_id,
      title: product.title,
      description: product.description,
      icon: product.icon,
      learn_more: product.learn_more,
      price: product.price,
      purchase_link: product.purchase_link,
      apps: product.apps || [],
      features: product.features || [],
      available: product.available,
      deprecated: product.deprecated,
      activated_at: product.activated_at,
      updated_at: new Date(),
    };
  },
  filterPurchased: function (data) {
    let res = data?.map((product) => {
      return {
        ...product,
        data: {},
        activeCount:
          (product?.apps?.filter((a) => a.active)).length +
          (product?.features?.filter((f) => f.active)).length,
        apps: product?.apps?.map((ap) => {
          return {
            ...ap,
            data: {},
          };
        }),
        features: product?.features?.map((ft) => {
          return {
            ...ft,
            data: {},
          };
        }),
      };
    });
    return res;
  },
  toEditUserForm: function (purchased, products, dataType) {
    const filterData = products
      ? products?.filter((prod) => prod.type === "product")
      : [];
    let res = filterData?.map((product) => {
      let up = purchased?.filter(
        (p, i) => p.id?.toString() === product.id?.toString()
      )[0];
      return {
        id: up ? up.id : product?.id,
        activeCount: up ? up.activeCount : 0,
        deprecated: up?.deprecated ? up?.deprecated : product.deprecated,
        hide_from_user: up?.hide_from_user ? up?.hide_from_user : "global",
        data: product,
        apps: product?.apps.map((uap) => {
          if (dataType) {
            //if (uap.deprecated !== true) {
            let app = up?.apps?.filter((a, i) => {
              return a.id?.toString() === uap.id?.toString();
            });
            if (app?.length > 0) {
              return {
                ...app[0],
                data: uap,
              };
            } else {
              return {
                id: uap?.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: uap,
                deprecated: uap.deprecated,
                hide_from_user: uap?.hide_from_user
                  ? uap?.hide_from_user
                  : "global",
              };
            }
            //}
          } else {
            let app = up?.apps?.filter((a, i) => {
              return a.id?.toString() === uap.id?.toString();
            });
            if (app?.length > 0) {
              return {
                ...app[0],
                data: uap,
              };
            } else {
              return {
                id: uap?.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: uap,
                deprecated: uap.deprecated,
                hide_from_user: uap?.hide_from_user
                  ? uap?.hide_from_user
                  : "global",
              };
            }
          }
          return {
            id: uap?.id,
            active: false,
            activated_on: new Date(),
            code_used: "",
            last_used: new Date(),
            launches_since_activation: 0,
            notes: "",
            data: uap,
            deprecated: uap.deprecated,
            hide_from_user: uap?.hide_from_user
              ? uap?.hide_from_user
              : "global",
          };
        }),
        features: product?.features?.map((ufp) => {
          if (dataType) {
            //if (ufp.deprecated !== true) {
            let feature = up?.features?.filter((f) => {
              return f.id?.toString() === ufp.id?.toString();
            });
            if (feature?.length > 0) {
              return {
                ...feature[0],
                data: ufp,
              };
            } else {
              return {
                id: ufp.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: ufp,
                deprecated: ufp.deprecated,
                hide_from_user: ufp?.hide_from_user
                  ? ufp?.hide_from_user
                  : "global",
              };
            }
            //}
          } else {
            let feature = up?.features?.filter((f) => {
              return f.id?.toString() === ufp.id?.toString();
            });
            if (feature?.length > 0) {
              return {
                ...feature[0],
                data: ufp,
              };
            } else {
              return {
                id: ufp.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: ufp,
                deprecated: ufp.deprecated,
                hide_from_user: ufp?.hide_from_user
                  ? ufp?.hide_from_user
                  : "global",
              };
            }
          }
          return {
            id: ufp.id,
            active: false,
            activated_on: new Date(),
            code_used: "",
            last_used: new Date(),
            launches_since_activation: 0,
            notes: "",
            data: ufp,
            deprecated: ufp.deprecated,
            hide_from_user: ufp?.hide_from_user
              ? ufp?.hide_from_user
              : "global",
          };
        }),
      };
    });
    return res;
  },
  toAddUserForm: function (data) {
    const onlyProduct = data
      ? data.filter((prod) => prod.type === "product")
      : [];
    let res = onlyProduct
      ? onlyProduct?.map((product) => {
          return {
            id: product.id,
            deprecated: false,
            data: product,
            apps: product?.apps?.map((app) => {
              return {
                id: app?.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: app,
                deprecated: app.deprecated,
              };
            }),
            features: product?.features?.map((feature) => {
              return {
                id: feature.id,
                active: false,
                activated_on: new Date(),
                code_used: "",
                last_used: new Date(),
                launches_since_activation: 0,
                notes: "",
                data: feature,
                deprecated: feature.deprecated,
              };
            }),
          };
        })
      : [];
    return res;
  },
  fromFirestore: function (data) {
    let res = data.map((el) => {
      return new Product(el);
    });

    return res;
  },
};

var codeConverter = {
  toFirestore: function (code) {
    const codeArray = code?.code.map((c) => {
      return {
        code: c || "",
        codePrefix: code.codePrefix || "",
        duration: code.duration === "1 year" ? "12 month" : code.duration || "",
        activation: code.activation || true,
        purchase_location: code.purchase_location || "",
        user_id: "",
        products_to_activate: code.products_to_activate || [],
        activated_at: new Date(),
        already_used: false,
        parent_id: storage?.id,
        activated_by: storage?.email || "",
        firstname: storage?.firstname || "",
        lastname: storage?.lastname || "",
        notes: code?.notes || "",
        subscription_group_id: code?.subscription_group_id || "",
        subscription_prod_id: code?.subscription_prod_id || "",
        is_deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
      };
    });
    return { data: codeArray };
  },
  updateToFirestore: function (code) {
    return {
      id: code.id || [],
      data: {
        notes: code?.notes || "",
        duration: code.duration === "1 year" ? "12 month" : code.duration,
        code: code.code[0],
        codePrefix: code.codePrefix || "",
        products_to_activate: code.products_to_activate || [],
        subscription_group_id: code?.subscription_group_id || "",
        subscription_prod_id: code?.subscription_prod_id || "",
        activated_at: new Date(),
        updated_at: new Date(),
      },
    };
  },
  ReactiveToFirestore: function (code) {
    return {
      id: code.id || [],
      data: {
        activation: code?.activation || false,
        already_used: code.already_used,
        activated_by: storage?.email || "",
        firstname: storage?.firstname || "",
        lastname: storage?.lastname || "",
        activated_at: new Date(),
        products_to_activate: code.products_to_activate || [],
        updated_at: new Date(),
      },
    };
  },
  updateMultipleToFirestore: function (code) {
    return {
      id: code.id || [],
      data: {
        notes: code?.notes || "",
        products_to_activate: code.products_to_activate || [],
        subscription_group_id: code?.subscription_group_id || "",
        subscription_prod_id: code?.subscription_prod_id || "",
        updated_at: new Date(),
      },
    };
  },
  fromFirestore: function (data) {
    let res = data.map((el) => {
      return new Code(el);
    });
    return res;
  },
  activateToFirestore: function (code) {
    return {
      user_id: code.user_id,
      activation: !code.activation,
      code: code.code,
    };
  },
};
let SettingConverter = {
  updateToFirestore: function (data) {
    return {
      active_product_text: data.active_product_text,
      promotional_text: data.promotional_text,
      store_button_text: data.store_button_text,
      store_button_link: data.store_button_link,
      inactive_product_button_text: data.inactive_product_button_text,
      inactive_product_text: data.inactive_product_text,
      code_characters: data.code_characters,
      default_number_of_case: data.default_number_of_case,
      active_product_button_text: data.active_product_button_text,
      promotional_text_font_size: data.promotional_text_font_size,
      product_display_order: data.product_display_order,
      success_message: data.success_message,
      default_purchase_location: data.default_purchase_location,
      default_code_length: data.default_code_length,
      updated_at: new Date(),
      swagger_docs_link: data.swagger_docs_link,
      stripe_mode: data.stripe_mode == "" ? "test" : data.stripe_mode,
      default_subscription_plan: data.default_subscription_plan,
      stripe_test_publishable_key: data.stripe_test_publishable_key,
      stripe_test_secret_key: data.stripe_test_secret_key,
      stripe_live_publishable_key: data.stripe_live_publishable_key,
      stripe_live_secret_key: data.stripe_live_secret_key,
    };
  },
  fromFirestore: function (data) {
    let res = data.map((el) => {
      return new Settings(el);
    });
    return res;
  },
};

const exports = {
  userConverter,
  adminConverter,
  productConverter,
  codeConverter,
  SettingConverter,
  subscriptionConverter,
  subscriptionDataConverter,
  subscriptionInfoConverter,
};

export default exports;
