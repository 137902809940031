import { createSlice } from "@reduxjs/toolkit";
import {
  GetProducts,
  EditProduct,
  CreateProduct,
  UpdateSubscription,
  DeleteProductApi,
} from "../../services";
import toast from "react-hot-toast";
import {
  ERROR_WHILE_ADDING_PRODUCT,
  SUCCESSFULLY_ADDED_PRODUCT,
  SUCCESSFULLY_DELETED_PRODUCT,
  SUCCESSFULLY_UPDATED_PRODUCT,
  UPDATED_SUBSCRIPTION
} from "../../Constant";

const paginatedProducts = (products, pageNumber, productCount) => {
  return products.slice(
    (pageNumber - 1) * productCount,
    pageNumber * productCount
  );
};

export const productSlice = createSlice({
  name: "products",
  initialState: {
    list: [],
    active: [],
    inactive: [],
    pageNumber: 1,
    productCount: 10,
    paginatedProduct: [],
    succesResponseProduct: false,
    loading: false,
    typeStatus: "All",
    loadingSubmit: false
  },
  reducers: {
    getPaginatedProducts: (state) => {
      state.paginatedProduct = paginatedProducts(
        state.typeStatus === "Active"
          ? state.active
          : state.typeStatus === "Inactive"
            ? state.inactive
            : state.list,
        state.pageNumber,
        state.productCount
      );
    },
    changeTypeStatus: (state, action) => {
      state.typeStatus = action.payload;
      state.pageNumber = 1;
      productSlice.caseReducers.getPaginatedProducts(state);
    },
    deleteProducts: (state, action) => {
      state.list = state.list.filter(
        (product) => !action.payload.includes(product.id)
      );
      return state;
    },
    setproductCount: (state, action) => {
      if (action.payload > 0) {
        state.productCount = action.payload;
        state.pageNumber = 1;
        productSlice.caseReducers.getPaginatedProducts(state);
      }
    },

    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        productSlice.caseReducers.getPaginatedProducts(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(state.list.length / state.productCount);
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        productSlice.caseReducers.getPaginatedProducts(state);
      }
    },
    setPageNumber: (state, action) => {
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(state.list.length / state.productCount) + 1
      ) {
        state.pageNumber = action.payload;
        productSlice.caseReducers.getPaginatedProducts(state);
      }
    },
    succesResponseMethod: (state, action) => {
      state.succesResponseProduct = action.payload;
    },
  },
  extraReducers: {
    [GetProducts.fulfilled]: (state, action) => {
      state.list = action.payload;

      state.active = action.payload
        ? action.payload.filter((data) => {
          return data.type === "subscription"
            ? data?.product?.available
            : data?.available;
        })
        : [];
      state.inactive = action.payload
        ? action.payload.filter((data) => {
          return data.type === "subscription"
            ? !data?.product?.available
            : !data?.available;
        })
        : [];
      state.loading = false;
    },
    [GetProducts.pending]: (state, action) => {
      state.list = [];
      state.active = [];
      state.inactive = [];
      state.loading = true;
    },
    [GetProducts.rejected]: (state, action) => {
      state.list = [];
      state.active = [];
      state.inactive = [];
      state.loading = false;
    },
    [CreateProduct.fulfilled]: (state, action) => {
      state.loadingSubmit = false;
      if (action.payload && action.payload.success) {
        state.list.push(action.payload.data);
        // const DataNew = [...state.list, action.payload.data ];
        // state.list = DataNew;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? data.product.available
              : data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? !data.product.available
              : !data.available;
          })
          : [];
        state.pageNumber = 1;
        state.paginatedProduct = paginatedProducts(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.productCount
        );
        toast.success(SUCCESSFULLY_ADDED_PRODUCT);
        state.succesResponseProduct = true;
      }

    },
    [CreateProduct.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [CreateProduct.rejected]: (state, action) => {
      state.loadingSubmit = false;
      toast.error(ERROR_WHILE_ADDING_PRODUCT);
    },
    [DeleteProductApi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.success) {
        state.list = state.list.filter(
          (product) => !action.payload.id.includes(product.id)
        );

        state.active = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? data.product.available
              : data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? !data.product.available
              : !data.available;
          })
          : [];

        state.pageNumber = 1;
        state.paginatedProduct = paginatedProducts(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.productCount
        );
        toast.success(SUCCESSFULLY_DELETED_PRODUCT);
      }
    },
    [EditProduct.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [EditProduct.rejected]: (state, action) => {
      state.loadingSubmit = false;
    },
    [EditProduct.fulfilled]: (state, action) => {
      if (action.payload) {
        let data = state.list.map((product) => {
          if (
            action.payload &&
            product.type === "product" &&
            product.id === action.payload.id
          ) {
            return action.payload;
          } else if (
            action.payload &&
            product.type === "subscription" &&
            product.product.id === action.payload.id
          ) {
            const oldData = product;
            oldData.product = { ...product.product, ...action.payload };
            return oldData;
          } else {
            return product;
          }
        });
        state.list = data;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? data.product.available
              : data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? !data.product.available
              : !data.available;
          })
          : [];
        state.paginatedProduct = paginatedProducts(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.productCount
        );
        state.succesResponseProduct = true;
        toast.success(SUCCESSFULLY_UPDATED_PRODUCT);
      }
      state.loadingSubmit = false;
    },
    [UpdateSubscription.fulfilled]: (state, action) => {
      if (action.payload) {
        let data = state.list.map((product) => {
          if (action.payload && product.type === "subscription") {
            const oldData = { ...product, ...action.payload.data };
            return oldData;
          } else {
            return product;
          }
        });
        state.list = data;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? data.product.available
              : data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return data.type === "subscription"
              ? !data.product.available
              : !data.available;
          })
          : [];
        state.paginatedProduct = paginatedProducts(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.productCount
        );
        toast.success(UPDATED_SUBSCRIPTION);
        state.succesResponseProduct = true;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  initProducts,
  setproductCount,
  deleteProducts,
  getPaginatedProducts,
  succesResponseMethod,
  changeTypeStatus,
} = productSlice.actions;

export default productSlice.reducer;
