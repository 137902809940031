import React, { useState, useEffect } from "react";
import { TabGroup } from "@statikly/funk";
import UserAppCard from "../general/UserAppCard";
import UserFeatureCard from "../general/UserFeatureCard";
import {
  ACTIVATE_A_CODE,
  EXPIRATION_DATE,
  SUBSCRIPTION_ACTIVE,
  SUBSCRIPTION_INACTIVE,
  HELP,
  HELP_EMAIL,
  OWNER_PORTAL,
  HELP_EMAIL_SUBJECT,
  CHANGE_RENWAWL_LENGTH,
  SUBSCRIBE_NOW,
  INACTIVE,
  ACTIVE,
  SELECT_TIME,
  WELCOME,
  AUTO_RENEW,
  SOMETHING_WENT_WRONG,
} from "../../Constant";
import Button from "../general/Button";
import Loader from "../utils/Loader";
import * as ApiRequest from "../../apiStructure";
import toast from "react-hot-toast";
import Toggle from "../general/Toggle";
import { UpdateUserSubscription } from "../../services";
import { useDispatch } from "react-redux";
import moment from "moment";
// Mui Import
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Demo = ({
  handleActivate,
  handleChange,
  subscriptionData,
  data,
  isSubScriptionA,
  userProducts,
  products,
  setShowModalCheckout,
  code,
  setSelectedPlan,
  subscriptionInfo,
  subscriptions,
  loadingSubscriptionList,
  handlePlanChange,
  userSubscriptions,
  setUserSubscriptions,
}) => {
  let isActionsAppActivated = false;
  userProducts?.map((product) => {
    product?.apps?.map((app) => {
      if (app?.active === true && app?.title == "Actions") {
        isActionsAppActivated = true;
      }
    });
  });

  const logo = "/images/logo.webp";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({});
  const [userSubscriptionFixed, setuserSubscriptionFixed] = useState(
    subscriptionInfo[0]?.user_subscriptions
  );
  const [appListeningId, setAppListeningId] = useState(data?.app_listening_id);

  function randomString() {
    //define a variable consisting alphabets in small and capital letter
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    //specify the length for the new string
    var lenString = 7;
    var randomstring = "";

    //loop to select a new character in each iteration
    for (var i = 0; i < lenString; i++) {
      var rnum = Math.floor(Math.random() * characters.length);
      randomstring += characters.substring(rnum, rnum + 1);
    }

    return randomstring;
  }

  //get userData from Localstorage
  useEffect(() => {
    let localstorage = localStorage.getItem("uls");
    if (localstorage) {
      const ParseData = JSON.parse(localstorage);
      if (ParseData) {
        setUserData(ParseData);
      }
    }
  }, []);

  // Mui dialog subscription confirmation
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState("");
  const [confirmDialogAgree, setConfirmDialogAgree] = useState();

  const ConfirmDialogOpen = (uSubsc, price_opt) => {
    let subscriptionName = uSubsc.subscription_name.split("_");
    subscriptionName = subscriptionName[0].replace("-subs", "");
    subscriptionName = subscriptionName.replaceAll("-", " ");
    if (uSubsc?.id) {
      let checkStatus = false;
      subscriptionInfo[0]?.user_subscriptions.forEach((subscription) => {
        if (subscription.id == uSubsc.id) {
          if (
            subscription.subscription_prod_id == price_opt[0].stripe_product_id
          ) {
            checkStatus = true;
          } else {
            checkStatus = subscription;
          }
        }
      });

      if (checkStatus === true) {
        if (price_opt[0]?.hide_from_user == "deactive") {
          alert("You cannot change trial subscription to auto renew on/off.");
          return false;
        } else {
          setConfirmDialogText(
            "You are changing your subscription to auto renew on/off"
          );
        }
      } else {
        setConfirmDialogText(
          `You are changing your subscription renewal length from $${checkStatus?.stripe_plan_amount}/${checkStatus?.stripe_plan_interval} to $${price_opt[0].price}/${price_opt[0].time_unit}. Please note that this change will effect automatically after your current subscription will expires.`
        );
      }
    } else {
      setConfirmDialogText(
        "Your are subscribing to " +
          subscriptionName +
          " for $" +
          price_opt[0].price +
          "/" +
          price_opt[0].time_unit
      );
    }
    setConfirmDialogOpen(true);
  };

  const clickConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const clickConfirmDialogAgreeClose = async () => {
    const { uSubsc, uSubscIndex } = confirmDialogAgree;
    // Process subscription
    //if (uSubsc.is_updated) delete uSubsc.is_updated;

    setLoading(true);
    const responseData = await dispatch(UpdateUserSubscription(uSubsc));
    setLoading(false);
    setConfirmDialogOpen(false);
    if (responseData.payload?.subscription) {
      if (responseData.payload?.subscription) {
        // const userSubscriptionsData = [...userSubscriptions]
        // userSubscriptionsData[uSubscIndex] = responseData.payload?.subscription
        setUserSubscriptions(responseData.payload?.subscription);
        setuserSubscriptionFixed(responseData.payload?.subscription);
        console.log("userSubscriptionFixed", userSubscriptionFixed);
      }
      if (responseData.payload?.data) {
        toast.success(responseData.payload?.data);
      }
    } else {
      if (responseData?.payload?.error == "Default payment method not set") {
        setShowModalCheckout(true);
        toast.error(responseData.payload.error);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
    setConfirmDialogAgree("");
  };

  const getRenews = (
    pricing_options,
    uSubscIndex,
    subscription_group_id = "",
    uSubsc = {}
  ) => {
    let renews = "";
    let renews_stripe = "";
    let renews_formated = "";
    let is_expired = false;
    let is_stripe_active = false;
    if (Object.keys(uSubsc).length) {
      renews = uSubsc.expiration_date.split("/");
      renews = new Date(`${renews[1]}/${renews[0]}/${renews[2]}`);
      renews_formated = renews;
      renews = `${
        monthNames[renews.getMonth()]
      } ${renews.getDate()},  ${renews.getFullYear()}`;
      const varDate = new Date(renews).getTime();
      const today = new Date().getTime();
      if (today >= varDate) {
        is_expired = true;
      }
      // stripe
      if (uSubsc?.stripe_current_period_end) {
        renews_stripe = new Date(
          uSubsc.stripe_current_period_end._seconds * 1000
        ).getTime();
        if (renews_stripe >= today) {
          is_stripe_active = true;
        }
      }
    }

    return (
      <>
        {is_expired === false && subscription_group_id && renews_formated ? (
          <p className="font-medium text-gray-500 pb-6">
            {/* {RENEWS_ON} {moment(renews_formated).format("MMM D, YYYY")}{" "} */}
          </p>
        ) : (
          ""
        )}
        <div className="font-medium text-gray-500 pb-0 flex mobWrap">
          <div className="w-1/2 mobWidth">
            <p className="font-medium text-gray-500 pb-3">
              {is_stripe_active === true ? (
                <div className="font-bold text-green-500 pbcustom ">
                  {ACTIVE}
                </div>
              ) : !is_expired && uSubsc.stripe_status !== "active" ? (
                <div className="font-bold text-red-500 pbcustom ">
                  {INACTIVE}
                </div>
              ) : (
                <div className="font-bold text-red-500 pb-1">{INACTIVE}</div>
              )}

              {pricing_options.length && (
                <>
                  {" "}
                  <select
                    value={
                      is_expired === false ? uSubsc.subscription_prod_id : ""
                    }
                    name="subscription_prod_id"
                    //        key={`${subscPricingIndex}-subscription-pricing`}
                    onChange={(event) =>
                      handlePlanChange(
                        event,
                        uSubscIndex,
                        subscription_group_id,
                        uSubsc
                      )
                    }
                    className="w-1/2 SelectField"
                  >
                    <option key="select-time-key" value="">
                      {SELECT_TIME}
                    </option>
                    {pricing_options.length &&
                      pricing_options.map((pricing_option, pIndex) => {
                        if (pricing_option?.hide_from_user == "active") {
                          return (
                            <option
                              key={`${pIndex}-subscription-option-pricing`}
                              value={pricing_option.stripe_product_id}
                            >
                              {pricing_option.time} {pricing_option.time_unit}
                              {pricing_option.time > 1 ? "s" : ""}
                            </option>
                          );
                        }
                      })}
                  </select>
                  <small className="text-red-600 font-semibold flex">
                    {/* {formData?.error?.subscription_prod_id} */}
                  </small>
                </>
              )}
            </p>
          </div>

          <div className="w-1/2 SelectFieldMob">
            <p className="font-medium text-gray-500 pb-3">
              <Toggle
                label={AUTO_RENEW}
                labelBeforeButton={false}
                isChecked={uSubsc.auto_subscription}
                onChange={(event) => {
                  handlePlanChange(
                    event,
                    uSubscIndex,
                    subscription_group_id,
                    uSubsc,
                    true
                  );
                }}
              />
              <Button
                text={uSubsc?.id ? CHANGE_RENWAWL_LENGTH : SUBSCRIBE_NOW}
                fn={async () => {
                  const price_option = pricing_options.filter(
                    (pricing_option) =>
                      pricing_option.stripe_product_id ==
                      uSubsc.subscription_prod_id
                  );
                  setConfirmDialogAgree({ uSubsc, uSubscIndex });
                  ConfirmDialogOpen(uSubsc, price_option);
                }}
                otherClass="mr-2"
                disabled={uSubsc?.is_updated === undefined}
              />
            </p>
          </div>
          <div className="w-1/2 SelectFieldMobfull">
            <div
              class="ml-3 text-gray-700 font-medium mobHidefield"
              style={{ height: "28px" }}
            ></div>
            {/* <a href="" className="viewSubscription bg-gray-400 text-white px-4 py-2 mb-4 rounded bg-blue-600">View subscription history</a> */}
          </div>
        </div>
        <div className="W-full bottomCardtext">
          <ul class="ListInline">
            {pricing_options.length &&
              pricing_options.map((ps, pIndex) => {
                if (ps?.hide_from_user == "active") {
                  return (
                    <li>
                      <span class="svgIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          class="bi bi-check-all text-teal-400"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"></path>
                        </svg>
                      </span>{" "}
                      {ps.time} {ps.time_unit} - ${ps.price}{" "}
                      <small>
                        {ps.plan_string ? "(" + ps.plan_string + ")" : ""}
                      </small>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <Dialog
          loading={loading}
          open={confirmDialogOpen}
          onClose={clickConfirmDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            ""
          )}
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {confirmDialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fn={clickConfirmDialogClose} text="Cancel" />
            <Button fn={clickConfirmDialogAgreeClose} text="Confirm" />
          </DialogActions>
        </Dialog>
      </div>
      <div className="p-4">
        <div className="w-full lg:w-3/4 xl:w-1/2 mx-auto my-8">
          <h1 className="text-4xl font-bold my-4">{OWNER_PORTAL}</h1>
          <p className="font-semibold text-xl">
            {WELCOME} {data?.firstname} {data?.lastname} - {data?.email}
          </p>
          <br></br>
          {isActionsAppActivated === true ? (
            <p className="font-semibold text-xl">
              Actions API URL:{" "}
              <a
                style={{ color: "blue" }}
                target={"_blank"}
                href={
                  "https://thoughtcastowners.com/actionsapi/" + appListeningId
                }
                rel="noreferrer"
              >
                https://thoughtcastowners.com/actionsapi/{appListeningId}
              </a>
            </p>
          ) : (
            ""
          )}
          <div className="w-12/12 md:w-12/12 lg:w-12/12 flex items-center mt-8 mb-2">
            <Button
              text="Reset API Token"
              fn={() => {
                if (data?.email) {
                  ApiRequest.resetAppsListeningToken({
                    id: data?.id,
                    app_listening_id: randomString(),
                  })
                    .then((res) => {
                      if (res?.data?.success) {
                        setAppListeningId(res?.data?.data);
                        toast.success(
                          "Apps actions url token reset successfully"
                        );
                      }
                    })
                    .catch((err) => {});
                } else {
                  toast.error(
                    "Something went wrong. Please contact administrator."
                  );
                }
              }}
              otherClass="mr-4"
            />
            <Button
              text="Reset Password"
              fn={() => {
                if (data?.email) {
                  ApiRequest.forgotPassword(data?.email)
                    .then((res) => {
                      if (res?.data?.success) {
                        toast.success("email sent to " + data?.email);
                      }
                    })
                    .catch((err) => {});
                } else {
                  toast.error("Email not found");
                }
              }}
              otherClass="mr-4"
            />
            <a href={data?.setting?.store_button_link}>
              <Button
                bgColor="bg-blue-500"
                otherClass="mr-4"
                text={
                  data?.setting?.store_button_text.charAt(0).toUpperCase() +
                  data?.setting?.store_button_text.slice(1)
                }
              />
            </a>

            <a href={`mailto:${HELP_EMAIL}?subject=${HELP_EMAIL_SUBJECT}`}>
              <Button bgColor="bg-blue-500" text={HELP} />
            </a>
          </div>
          <div>
            <p
              style={{
                fontSize: `${data?.setting?.promotional_text_font_size}px`,
              }}
            >
              {data?.setting?.promotional_text}
            </p>
          </div>
        </div>
        <div className="w-full lg:w-3/4 xl:w-1/2 mx-auto my-8">
          <p className="font-semibold text-2xl my-2">{ACTIVATE_A_CODE}</p>
          <p
            style={{
              fontSize: `${data?.setting?.promotional_text_font_size}px`,
            }}
          >
            Code Activation is for if you purchased your ThoughtCast Magic
            product from a 3rd party, magic shop, or dealer. If you bought it
            from thoughtcastmagic.com, your products are activated
            automatically.
          </p>
          <div className="flex items-baseline mb-4">
            <input
              className="w-full md:w-9/12 xl:w-6/12 my-0 mr-4 block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
              type="text"
              value={code}
              placeholder="123456"
              onChange={handleChange}
            />
            <Button
              small={true}
              text="Activate"
              bottomMargin={false}
              fn={handleActivate}
            />
          </div>
        </div>
        <div className="card bg-blue-50 w-full lg:w-3/4 xl:w-1/2 p-2 md:p-4 text-center shadow-xl rounded-xl my-4 mx-auto flex flex-col justify-center items-center overflow-hidden">
          <TabGroup
            numTabs={
              isSubScriptionA &&
              subscriptionData &&
              subscriptionData.product.available &&
              !data.is_hidden_subscription
                ? userProducts?.length - 1
                : userProducts?.length - 2
            }
            direction={TabGroup.direction.HORIZONTAL}
          >
            <TabGroup.TabList>
              {products?.loading && userProducts && loadingSubscriptionList ? (
                <div style={{ minHeight: "120px" }}>
                  <Loader />
                </div>
              ) : (
                // {loadingSubscriptionList? ("null")}

                <>
                  {/* <TabGroup.Tab
                    index={0}
                    className="h-10 px-8 transition-colors duration-150 focus:outline-none focus:ring-0 focus:border-transparent"
                    activeClassName="bg-blue-500 text-white font-semibold"
                    inactiveClassName="text-black"
                  >
                    {SUBSCRIPTIONS}
                  </TabGroup.Tab> */}

                  {isSubScriptionA &&
                  subscriptionData &&
                  subscriptionData.product.available &&
                  !data.is_hidden_subscription ? (
                    <TabGroup.Tab
                      index={1}
                      className="h-10 px-8 transition-colors duration-150 focus:outline-none focus:ring-0 focus:border-transparent"
                      activeClassName="bg-blue-500 text-white font-semibold"
                      inactiveClassName="text-black"
                    >
                      Subscription
                    </TabGroup.Tab>
                  ) : null}
                  {userProducts?.map((p, i) => {
                    return (
                      <TabGroup.Tab
                        key={i + 1}
                        index={
                          p?.data?.title == "Subscriptions"
                            ? 0
                            : isSubScriptionA &&
                              subscriptionData &&
                              subscriptionData.product.available &&
                              !data.is_hidden_subscription
                            ? i + 2
                            : i + 1
                        }
                        className="h-10 px-8 transition-colors duration-150 focus:outline-none focus:ring-0 focus:border-transparent"
                        activeClassName="bg-blue-500 text-white font-semibold"
                        inactiveClassName="text-black"
                      >
                        {p?.data?.title}
                      </TabGroup.Tab>
                    );
                  })}
                </>
              )}
            </TabGroup.TabList>
            {subscriptionInfo.length && !loadingSubscriptionList ? (
              <TabGroup.TabPanel
                key={0}
                index={0}
                className="w-full"
                activeClassName="block"
                inactiveClassName="hidden"
              >
                <article className="subs">
                  <header className="my-4 flex-row flex">
                    <img
                      className="w-32 h-32 object-cover border border-blue-500 rounded shadow block my-2"
                      src={subscriptionInfo[0]?.icon || logo}
                      alt=""
                    />
                    <div className="text-left my-4 ml-4">
                      <h3 className="font-bold text-2xl ">
                        {subscriptionInfo[0]?.name || "Subscription"}
                      </h3>
                      <p className="mb-6">
                        {subscriptionInfo[0]?.description ||
                          "Subscription Description"}
                      </p>
                      {subscriptionInfo[0]?.learn_more ? (
                        <Button
                          text={`Update Billing Info`}
                          fn={() => {
                            //  toast.success("In progress work");
                            setShowModalCheckout(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </header>
                  <div className="app_card_grid flex justify-start text-left my-8">
                    <p
                      className={`my-${
                        data.recursive || data?.subscription_data?.active
                          ? "0"
                          : "4"
                      } font-semibold items-center text-${
                        data.recursive || data?.subscription_data?.active
                          ? "green"
                          : "red"
                      }-900`}
                    ></p>
                  </div>
                  <div className="mainBox">
                    {subscriptions.length
                      ? subscriptions.map((data, indexSBS) => {
                          return (
                            <React.Fragment key={indexSBS}>
                              <div className="plancardInline rounded-md p-6 flex">
                                <div className="w-1/4 subscriptionBox">
                                  <img
                                    className="w-32 h-32 object-cover border border-blue-500 rounded shadow block my-2"
                                    src={data?.icon || logo}
                                    alt=""
                                  />
                                </div>
                                <div className="w-full">
                                  <p className=" text-3xl font-normal text-gray-900">
                                    {data?.title || `Plan ${indexSBS}`}
                                  </p>
                                  <p className="font-medium text-gray-500 pb-6">
                                    {data?.description || null}
                                  </p>
                                  <p className="font-medium text-gray-500 pb-6">
                                    {userSubscriptionFixed?.length
                                      ? userSubscriptionFixed.map((uSubsc) => {
                                          if (
                                            uSubsc.subscription_group_id ===
                                              data.id &&
                                            uSubsc.is_deleted !== true
                                          ) {
                                            let date =
                                              uSubsc?.stripe_current_period_end
                                                ?._seconds;
                                            date = new Date(date * 1000);
                                            if (
                                              date.getTime() >=
                                              new Date().getTime()
                                            ) {
                                              if (
                                                uSubsc?.auto_subscription ===
                                                true
                                              ) {
                                                const indexValue =
                                                  data?.pricing_options.findIndex(
                                                    (p) =>
                                                      p.stripe_product_id ==
                                                      uSubsc.subscription_prod_id
                                                  );
                                                return (
                                                  <p>
                                                    Current Subscription will
                                                    renew on{" "}
                                                    {moment(date).format(
                                                      "MMM D, YYYY"
                                                    )}{" "}
                                                    <br></br>
                                                    <br></br> Renewal Length is
                                                    set to $
                                                    {
                                                      data?.pricing_options[
                                                        indexValue
                                                      ].price
                                                    }
                                                    /
                                                    {
                                                      data?.pricing_options[
                                                        indexValue
                                                      ].time_unit
                                                    }
                                                  </p>
                                                );
                                              } else {
                                                return (
                                                  "Current Subscription will expire on " +
                                                  moment(date).format(
                                                    "MMM D, YYYY"
                                                  )
                                                );
                                              }
                                            }
                                          } else {
                                            return null;
                                          }
                                        })
                                      : null}
                                  </p>

                                  {userSubscriptions.length
                                    ? userSubscriptions.map(
                                        (uSubsc, uSubscIndex) => {
                                          if (
                                            uSubsc.subscription_group_id ===
                                              data.id &&
                                            uSubsc.is_deleted !== true
                                          ) {
                                            return getRenews(
                                              data.pricing_options,
                                              uSubscIndex,
                                              data.id,
                                              uSubsc
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )
                                    : null}
                                  {userSubscriptions.filter(
                                    (uSubsc) =>
                                      uSubsc.subscription_group_id ===
                                        data.id && uSubsc.is_deleted !== true
                                  ).length
                                    ? null
                                    : getRenews(
                                        data.pricing_options,
                                        undefined,
                                        data.id
                                      )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })
                      : null}
                  </div>
                </article>
              </TabGroup.TabPanel>
            ) : null}
            {isSubScriptionA &&
            subscriptionData &&
            subscriptionData.product.available &&
            !data.is_hidden_subscription ? (
              <>
                <TabGroup.TabPanel
                  key={1}
                  index={1}
                  className="w-full"
                  activeClassName="block"
                  inactiveClassName="hidden"
                >
                  <article className="subs">
                    <header className="my-4 flex-row flex">
                      <img
                        className="w-32 h-32 object-cover border border-blue-500 rounded shadow block my-2"
                        src={subscriptionData?.icon || logo}
                        alt=""
                      />
                      <div className="text-left my-4 ml-4">
                        <h3 className="font-bold text-2xl ">
                          {subscriptionData?.title || "Subscription"}
                        </h3>
                        <p className="mb-6">
                          {subscriptionData?.description ||
                            "Subscription Description"}
                        </p>
                        {subscriptionData?.learn_more ? (
                          <Button
                            text={`Learn More About ${
                              subscriptionData?.title?.charAt(0).toUpperCase() +
                                subscriptionData?.title?.slice(1) ||
                              "Subscription"
                            }`}
                            fn={() => {
                              window.location =
                                subscriptionData.learn_more ||
                                process.env.REACT_APP_BASE_URL +
                                  "/user/dashboard";
                            }}
                          />
                        ) : null}
                      </div>
                    </header>
                    <div className="app_card_grid flex justify-start text-left my-8">
                      <p
                        className={`my-${
                          data.recursive || data?.subscription_data?.active
                            ? "0"
                            : "4"
                        } font-semibold items-center text-${
                          data.recursive || data?.subscription_data?.active
                            ? "green"
                            : "red"
                        }-900`}
                      >
                        {data.recursive || data?.subscription_data?.active ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 inline-block mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            {" "}
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 inline-block mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            {" "}
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        )}
                        {data.recursive || data?.subscription_data?.active
                          ? SUBSCRIPTION_ACTIVE
                          : SUBSCRIPTION_INACTIVE}
                      </p>
                    </div>

                    {data?.subscription_data?.active ? (
                      data?.recursive ? (
                        <>
                          <div className="text-left font-medium text-xl">
                            {EXPIRATION_DATE} :{" "}
                            {typeof data?.subscription_data?.expiration_date ===
                            "string"
                              ? data?.subscription_data?.expiration_date
                                ? `${new Date(
                                    data?.subscription_data?.expiration_date
                                  ).toLocaleDateString()}`
                                : `N/A`
                              : data?.subscription_data?.expiration_date
                                  ?._seconds
                              ? `${new Date(
                                  data?.subscription_data?.date._seconds * 1000
                                ).toLocaleDateString()}`
                              : `N/A`}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-left font-medium text-xl">
                            {EXPIRATION_DATE} :{" "}
                            {typeof data?.subscription_data?.expiration_date ===
                            "string"
                              ? data?.subscription_data?.expiration_date
                                ? `${new Date(
                                    data?.subscription_data?.expiration_date
                                  ).toLocaleDateString()}`
                                : `N/A`
                              : data?.subscription_data?.expiration_date
                                  ?._seconds
                              ? `${new Date(
                                  data?.subscription_data?.expiration_date
                                    ._seconds * 1000
                                ).toLocaleDateString()}`
                              : `N/A`}
                          </div>
                        </>
                      )
                    ) : null}
                  </article>
                </TabGroup.TabPanel>
              </>
            ) : null}
            {!userProducts
              ? ""
              : userProducts?.map((p, i) => {
                  return (
                    <TabGroup.TabPanel
                      key={
                        isSubScriptionA &&
                        subscriptionData &&
                        subscriptionData.product.available &&
                        !data.is_hidden_subscription
                          ? i + 2
                          : i + 1
                      }
                      index={
                        isSubScriptionA &&
                        subscriptionData &&
                        subscriptionData.product.available &&
                        !data.is_hidden_subscription
                          ? i + 2
                          : i + 1
                      }
                      className="w-full"
                      activeClassName="block"
                      inactiveClassName="hidden"
                    >
                      <article className="subs">
                        <header className="my-4 flex-row flex">
                          <img
                            className="w-32 h-32 object-cover border border-blue-500 rounded shadow block my-2"
                            src={p?.data?.icon || logo}
                            alt=""
                          />
                          <div className="text-left my-4 ml-4">
                            <h3 className="font-bold text-2xl">
                              {p?.data?.title}
                            </h3>
                            <p className="mb-6">{p?.data?.description}</p>
                            {p?.data?.learn_more ? (
                              <Button
                                fn={() => {
                                  if (p?.data?.learn_more) {
                                    window.location = p?.data?.learn_more;
                                  }
                                }}
                                text={`Learn More About ${
                                  p?.data?.title.charAt(0).toUpperCase() +
                                  p?.data?.title.slice(1)
                                }`}
                              />
                            ) : null}
                          </div>
                        </header>
                        <div>
                          {subscriptions.length
                            ? subscriptions.map((data, indexSBS) => {
                                return data.product_ids.includes(p.id) ? (
                                  <React.Fragment key={indexSBS}>
                                    <div className="plancardInline rounded-md p-6 flex">
                                      <div className="w-1/4 11 subscriptionBox">
                                        <img
                                          className="w-32 h-32 object-cover border border-blue-500 rounded shadow block my-2"
                                          src={data?.icon || logo}
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-full">
                                        <p className=" text-3xl font-normal text-gray-900">
                                          {data?.title || `Plan ${indexSBS}`}
                                        </p>
                                        <p className="font-medium text-gray-500 pb-6">
                                          {data?.description || null}
                                        </p>
                                        <p className="font-medium text-gray-500 pb-6">
                                          {subscriptionInfo[0]
                                            ?.user_subscriptions?.length
                                            ? subscriptionInfo[0].user_subscriptions.map(
                                                (uSubsc, uSubscIndex) => {
                                                  if (
                                                    uSubsc.subscription_group_id ===
                                                      data.id &&
                                                    uSubsc.is_deleted !== true
                                                  ) {
                                                    let date =
                                                      uSubsc
                                                        ?.stripe_current_period_end
                                                        ?._seconds;
                                                    date = new Date(
                                                      date * 1000
                                                    );
                                                    if (
                                                      date.getTime() >=
                                                      new Date().getTime()
                                                    ) {
                                                      if (
                                                        uSubsc?.auto_subscription ===
                                                        true
                                                      ) {
                                                        const indexValue =
                                                          data?.pricing_options.findIndex(
                                                            (p) =>
                                                              p.stripe_product_id ==
                                                              uSubsc.subscription_prod_id
                                                          );
                                                        return (
                                                          <p>
                                                            Current Subscription
                                                            will renew on{" "}
                                                            {moment(
                                                              date
                                                            ).format(
                                                              "MMM D, YYYY"
                                                            )}{" "}
                                                            <br></br>
                                                            <br></br> Renewal
                                                            Length is set to $
                                                            {
                                                              data
                                                                ?.pricing_options[
                                                                indexValue
                                                              ].price
                                                            }
                                                            /
                                                            {
                                                              data
                                                                ?.pricing_options[
                                                                indexValue
                                                              ].time_unit
                                                            }
                                                          </p>
                                                        );
                                                      } else {
                                                        return (
                                                          "Current Subscription will expire on " +
                                                          moment(date).format(
                                                            "MMM D, YYYY"
                                                          )
                                                        );
                                                      }
                                                    }
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              )
                                            : null}
                                        </p>

                                        {userSubscriptions.length
                                          ? userSubscriptions.map(
                                              (uSubsc, uSubscIndex) => {
                                                if (
                                                  uSubsc.subscription_group_id ===
                                                    data.id &&
                                                  uSubsc.is_deleted !== true
                                                ) {
                                                  return getRenews(
                                                    data.pricing_options,
                                                    uSubscIndex,
                                                    data.id,
                                                    uSubsc
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )
                                          : null}

                                        {userSubscriptions.filter(
                                          (uSubsc) =>
                                            uSubsc.subscription_group_id ===
                                              data.id &&
                                            uSubsc.is_deleted !== true
                                        ).length
                                          ? null
                                          : getRenews(
                                              data.pricing_options,
                                              undefined,
                                              data.id
                                            )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : null;
                              })
                            : null}
                          <h2 className="text-4xl text-left font-medium">
                            {p?.apps
                              ? p?.apps.filter((paa) => paa)?.length > 0
                                ? p?.apps.filter((paa) => !paa.deprecated)
                                    ?.length > 0
                                  ? "Apps"
                                  : ""
                                : ""
                              : ""}
                          </h2>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-left my-8">
                          {p?.apps?.map((app, aInd) => {
                            return app && !app?.deprecated ? (
                              <React.Fragment key={aInd}>
                                <div className="">
                                  <UserAppCard
                                    app={app}
                                    btnText={
                                      app?.active
                                        ? data?.setting
                                            ?.active_product_button_text ||
                                          "Purchase"
                                        : data?.setting
                                            ?.inactive_product_button_text ||
                                          "Inactive"
                                    }
                                    status={
                                      app?.active
                                        ? data?.setting?.active_product_text ||
                                          "Activated"
                                        : data?.setting
                                            ?.inactive_product_text ||
                                          "Inactive"
                                    }
                                  />
                                </div>
                              </React.Fragment>
                            ) : null;
                          })}
                        </div>
                        <div>
                          <h2 className="text-4xl text-left font-medium">
                            {p?.features
                              ? p?.features.filter((pff) => pff)?.length > 0
                                ? p?.features.filter((pff) => !pff.deprecated)
                                    ?.length > 0
                                  ? "Features"
                                  : ""
                                : ""
                              : ""}
                            {/* Features */}
                          </h2>
                        </div>
                        <div className="feature_card_grid grid gap-4  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-start flex text-left my-8">
                          {p?.features?.map((feature, fInd) => {
                            return feature && !feature?.deprecated ? (
                              <React.Fragment key={fInd}>
                                <UserFeatureCard
                                  feature={feature}
                                  btnText={
                                    feature?.active
                                      ? data?.setting
                                          ?.active_product_button_text ||
                                        "Purchase"
                                      : data?.setting
                                          ?.inactive_product_button_text ||
                                        "Inactive"
                                  }
                                  status={
                                    feature?.active
                                      ? data?.setting?.active_product_text ||
                                        "Activated"
                                      : data?.setting?.inactive_product_text ||
                                        "Inactive"
                                  }
                                />
                              </React.Fragment>
                            ) : null;
                          })}
                        </div>
                      </article>
                    </TabGroup.TabPanel>
                  );
                })}
          </TabGroup>
        </div>
      </div>
    </>
  );
};
export default Demo;
