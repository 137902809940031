import React from 'react';
import moment from 'moment';
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
//import { useDispatch } from "react-redux";

const UserFormDetailsTable = ({ codeInfo, noData, subscriptions }) => {

  //const dispatch = useDispatch();

  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader sortable={false} text="Code Used" />
            <TableHeader
              sortable={false}
              text="Purchase Location"
            />
            <TableHeader
              sortable={false}
              text="Product(s) Activated"
            />
            <TableHeader
              sortable={false}
              text="Activated On"
            />
            {/* <TableHeader
              sortable={false}
              text="Deactivate / Release"
            /> */}
          </tr>
        </thead>
        <tbody>
          {codeInfo.length
            ? codeInfo.map((data, index) => {
              //console.log("data", data);
              let prodAct = '';
              let countProdAct = 0;
              let subscriptionDetail = '';
              if (data.type == "Subscription created" || data.type == "Subscription deleted" || data.type == "Subscription updated" || data.type == "SubscriptionScheduled") {
                // we will find the subscription title
                const indexValue = subscriptions.findIndex(p => p.id == data.subscription_activated.subscription_group_id);
                if (indexValue >= 0 && indexValue !== undefined && indexValue !== null && indexValue !== "") {
                  subscriptionDetail = data.type + ' ( ' + subscriptions[indexValue].title + ' )';
                } else {
                  subscriptionDetail = data.type;
                }
              } else {
                if (data.type == "Product deactivated") {
                  prodAct = data.product_activated
                    ? data.product_activated.map((paData) => {

                      countProdAct = countProdAct + 1;
                      const appFilter = paData.apps
                        ? paData.apps.filter((adpData) => {
                          if (adpData?.isDeActive == "Yes") {
                            return (
                              adpData.title
                            );
                          }
                        })
                        : [];
                      const appMapJoin = appFilter
                        ? appFilter
                          .map((afdata) => {
                            return afdata.title;
                          })
                          .join()
                        : [];
                      const featuresFilter = paData.features
                        ? paData.features.filter(
                          (adpData) => {
                            if (adpData?.isDeActive == "Yes") {
                              return (
                                adpData.title
                              );
                            }
                          }
                        )
                        : [];
                      const featuresMapJoin = featuresFilter
                        ? featuresFilter
                          .map((afdata) => {
                            return afdata.title;
                          })
                          .join()
                        : [];
                      return `${appMapJoin && featuresMapJoin
                        ? `${paData.title}${"( "}${appMapJoin},${featuresMapJoin}${" )"}`
                        : appMapJoin
                          ? `${paData.title}${"( "}${appMapJoin}${" )"}`
                          : featuresMapJoin
                            ? `${paData.title}${"( "}${featuresMapJoin}${" )"} `
                            : ""
                        } ${appMapJoin || featuresMapJoin > 1 ? "," : ""
                        }`;

                    }) : "";
                } else {
                  prodAct = data.product_activated
                    ? data.product_activated.map((paData) => {
                      if (paData.activeCount > 0) {
                        countProdAct = countProdAct + 1;
                        const appFilter = paData.apps
                          ? paData.apps.filter((adpData) => {
                            return (
                              adpData.title
                            );
                          })
                          : [];
                        const appMapJoin = appFilter
                          ? appFilter
                            .map((afdata) => {
                              return afdata.title;
                            })
                            .join()
                          : [];
                        const featuresFilter = paData.features
                          ? paData.features.filter(
                            (adpData) => {
                              return (
                                adpData.title
                              );
                            }
                          )
                          : [];
                        const featuresMapJoin = featuresFilter
                          ? featuresFilter
                            .map((afdata) => {
                              return afdata.title;
                            })
                            .join()
                          : [];
                        return `${paData.title}${appMapJoin && featuresMapJoin
                          ? `${"( "}${appMapJoin},${featuresMapJoin}${" )"}`
                          : appMapJoin
                            ? `${"( "}${appMapJoin}${" )"}`
                            : featuresMapJoin
                              ? `${"( "}${featuresMapJoin}${" )"} `
                              : ""
                          } ${countProdAct > 1 ? "," : ""
                          }`;
                      }
                      return "";
                    }) : "";
                }
              }
              return (
                <tr className="p-16" key={index}>
                  <TableData text={data.code || "N/A"} />
                  <TableData
                    text={data.purchase_location || "thoughtcastmagic.com"}
                  />
                  <TableData text={data?.subscription_activated === undefined ? prodAct : subscriptionDetail} />
                  <TableData
                    text={
                      data.activated_at
                        ? typeof data.activated_at?._seconds ===
                          "number"
                          ? moment(
                            data.activated_at?._seconds * 1000
                          ).format("MMM D, YYYY, HH:mm:s")
                          : "N/A"
                        : "N/A"
                    }
                  />
                  {/* <TableData
                    child={
                      (data.special_code || data?.subscription_activated !== undefined) ? null : <Button
                        text={
                          data.activation
                            ? "Deactivate"
                            : "Reactivate"
                        }
                        fn={() => {
                          dispatch(codeActivation(data));
                        }}
                        small={true}
                        bottomMargin={false}
                      />
                    }
                  /> */}
                </tr>
              );
            })
            : noData()}
        </tbody>
      </table>
    </>
  )
}

export default UserFormDetailsTable