import React from "react";
import Button from "../general/Button";
import { useDispatch } from "react-redux";
import { PAGE_NUM } from "../../Constant";

const Pagination = ({
  prevFn,
  nextFn,
  maxPages,
  pageNumber,
  setPageNumber,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="my-4 flex justify-around">
      <Button small={true} disabled={pageNumber === 1} text="Previous" fn={prevFn} />
      <div className="flex items-center">
        <label className="mr-4 text-blue-600 font-bold" htmlFor="pageNumber">
          {PAGE_NUM}
        </label>
        <input
          className="rounded-lg px-2 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
          type="number"
          name=""
          id="pageNumber"
          value={pageNumber}
          step="1"
          min="1"
          max={maxPages}
          onChange={(e) => dispatch(setPageNumber(parseInt(e.target.value)))}
        />
        <label className="ml-2 text-blue-600 font-bold" htmlFor="pageNumber">
          of {maxPages}
        </label>
      </div>

      <Button small={true} disabled={pageNumber === maxPages} text="Next" fn={nextFn} />
    </div>
  );
};

export default Pagination;
