import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../general/Button";
import { history } from "../../history";
import { changePassword } from "../../apiStructure";
import { CHANGE_PASSWORD, NEED_HELP_EMAIL_US, PASSWORD_DOESNT_MATCH, PLEASE_ENTER_A_VALID_PASS, PASSWORD_TOO_SHORT, PASSWORD_LENGTH } from "../../Constant";

const ChangePassword = () => {
  const [retypePassword, setRetypePassword] = useState("");
  const [token, setToken] = useState(null);
  const [apiCalling, setApiCalling] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const logo_img_web = "/images/logo.webp";
  useEffect(() => {
    const path = history?.location?.pathname;
    const tokenFromUrl = path?.split("/")[path?.split("/")?.length - 1];
    setToken(tokenFromUrl)
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        var submitButtton = document.getElementById("submitButtton");
        submitButtton.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  //Validate a reset password
  const validateResetPassword = async () => {
    if (retypePassword && password && retypePassword === password) {
      setApiCalling(true);
      const data = {
        password: password,
        resetPasswordToken: token,
        confirmPassword: retypePassword
      }
      let res = false;
      res = await changePassword(data);
      if (res) {
        setTimeout(() => {
          history.push("/")
        }, 500);
      }
      setApiCalling(false);
      setError({});
    } else {
      setError({
        username: validateRePassword(retypePassword),
        password: validatePassword(password),
      });
    }
  };
  //Validate a re-password
  const validateRePassword = (pwd) => {
    if (!pwd) {
      return PLEASE_ENTER_A_VALID_PASS;
    }
    if (pwd !== password) {
      return PASSWORD_DOESNT_MATCH;
    }
    return "";
  };
  //validate a password
  const validatePassword = (password) => {
    if (!password) {
      return PLEASE_ENTER_A_VALID_PASS;
    }else if(password.length<PASSWORD_LENGTH) return PASSWORD_TOO_SHORT
    
    return "";
  };
  return (
    <section className="min-h-screen bg-blue-200 flex justify-center items-center p-4 xl:p-16">
      <div className="card bg-blue-50 w-full lg:w-3/4 xl:w-2/5 p-4 text-center shadow-xl rounded-xl">
        <img
          className="block mx-auto my-4"
          src={logo_img_web}
          alt="Thoughtcast Logo"
        />
        <h1 className="text-4xl font-bold mt-4 mb-8">{CHANGE_PASSWORD}</h1>

        <div className="my-4">
          <input
            className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error.password ? (
            <small className="text-red-600 font-semibold">
              {error.password}
            </small>
          ) : null}
        </div>
        <div className="my-4">
          <input
            className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            type="password"
            placeholder="Confirm Password"
            value={retypePassword}
            onChange={(e) => setRetypePassword(e.target.value)}
          />
          {error.username ? (
            <small className="text-red-600 font-semibold">
              {error.username}
            </small>
          ) : null}
        </div>

        <div className="w-9/12 md:w-2/5 lg:w-1/2 mx-auto flex justify-around items-center mt-8 mb-2">
          <NavLink to="/">
            <Button
              text="Back To Login"
              bgColor="bg-blue-200"
              textColor="text-blue-600"
              small={true}
            />
          </NavLink>
          <Button
            id="submitButtton"
            text="Set Password"
            small={true}
            disabled={apiCalling}
            fn={() => validateResetPassword()}
          />
        </div>

        <div className="w-9/12 md:w-2/5 lg:w-1/2 mx-auto flex justify-around items-center mt-4 mb-4">
        </div>
        <a className="text-blue-600 my-4" href="mailto:support@thoughtcastmagic.com?subject=Change Password Help">
          {NEED_HELP_EMAIL_US}
        </a>
      </div>
    </section>
  );
};

export default ChangePassword;
