import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import firebase from "../../config/firebaseConfig";
import * as ApiRequest from "../../apiStructure";
import { history } from "../../history";
import toast from "react-hot-toast";
import { INVALID_USERNAME_PASSWORD, ROLE_IS_NOT_DEFINED, SOMETHING_WENT_WRONG, TEMPORARY_DISABLE, TOO_MANY_REQ, USER_NOT_FOUND_, WRONG_PASSWORD } from "../../Constant";
const md5 = require('md5');
//For User Login
export const Signin = async (email, password) => {
  try {
    // encrypt the password
    password = md5(password);
    const resss = await signInWithEmailAndPassword(
      firebase.auth,
      email,
      password
    );
    if (resss) {
      let token = await firebase.auth.currentUser.getIdToken(true);
      let res = await ApiRequest.login(token, resss._tokenResponse.refreshToken);
      if (res?.data?.success) {
        let user = res.data.data;
        localStorage.setItem("uls", JSON.stringify(user));
        if (user?.role) {
          toast.success("welcome to ULS");
          if (user.role === 1) {
            history.push("/admin");
          }
          if (user.role === 2) {
            history.push("/admin");
          }
          if (user.role === 3) {
            history.push("/user/dashboard");
          }
        } else {
          toast.error(ROLE_IS_NOT_DEFINED);
        }
      }
    }
  } catch (error) {
    const getErrorMessage = () => {
      if (error?.message?.includes("Firebase")) {
        if (
          error.message.includes(USER_NOT_FOUND_) ||
          error.message.includes(WRONG_PASSWORD)
        ) {
          return INVALID_USERNAME_PASSWORD;
        }
        else if (error.message.includes(TOO_MANY_REQ)) {
          return TEMPORARY_DISABLE;
        } else {
          return SOMETHING_WENT_WRONG;
        }
      } else {
        return error?.message;
      }
    };
    toast.error(error?.response?.data?.error || getErrorMessage());
    return {
      status: false,
      data: error?.response?.data?.error || error?.message,
    };
  }
};

//get a user if available token from local storage
export const getUser = async () => {
  try {
    let storage = JSON.parse(localStorage.getItem("uls"));
    let res = await ApiRequest.login(storage.token);
    if (res.data?.success) {
      let user = res.data.data;
      localStorage.setItem("uls", JSON.stringify(user));
      return user;
    } else {
      throw Error(SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error || error?.message);
    return false;
  }
};

//To signOut user
export const Signout = async () => {
  try {
    await signOut(firebase.auth);
    localStorage.removeItem("uls");
    await ApiRequest.logout();
    history.push("/");
  } catch (error) {
    toast.error(error?.response?.data?.error || error?.message);
    return {
      status: false,
      data: error?.response?.data?.error || error?.message,
    };
  }
};
