import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { SUCCESSFULLY_ADDED_ADMIN, SUCCESSFULLY_DELETED_USER, SUCCESSFULLY_UPDATED_USER } from "../../Constant";
import { EditAdmin, GetAdmins, CreateAdmin, DeleteAdminApi } from "../../services";

const paginatedAdmins = (admins, pageNumber, adminCount, filterKeyword) => {
  if (filterKeyword) {
    return admins
      .filter(
        (admin) =>
          admin.firstname.toLowerCase().includes(filterKeyword) || admin.lastname.toLowerCase().includes(filterKeyword) ||
          `${admin.firstname.toLowerCase()} ${admin.lastname.toLowerCase()}`.includes(filterKeyword) || `${admin.firstname.toLowerCase()}${admin.lastname.toLowerCase()}`.includes(filterKeyword) ||
          admin.email.toLowerCase().includes(filterKeyword)
      )
      .slice((pageNumber - 1) * adminCount, pageNumber * adminCount);
  } else {
    return admins.slice((pageNumber - 1) * adminCount, pageNumber * adminCount);
  }
};

export const adminListSlice = createSlice({
  name: "admins",
  initialState: {
    list: [],
    adminlist: [],
    superadminlist: [],
    typeRole: "All",
    pageNumber: 1,
    adminCount: 10,
    loading: false,
    filterKeyword: "",
    succesResponseAdmin: false,
    paginatedAdmins: [],
    loadingAdmin: false
  },
  reducers: {
    changeRoleType: (state, action) => {
      state.typeRole = action.payload;
      state.pageNumber = 1;
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },
    getPaginatedAdmins: (state) => {
      state.paginatedAdmins = paginatedAdmins(
        state.typeRole === "admin"
          ? state.adminlist
          : state.typeRole === "superadmin"
            ? state.superadminlist
            : state.list,
        state.pageNumber,
        state.adminCount,
        state.filterKeyword
      );
    },
    deleteAdmins: (state, action) => {
      state.list = state.list.filter(
        (admin) => !action.payload.includes(admin.id)
      );
      state.superadminlist = state.list
        ? state.list.filter((data) => {
          return Number(data.role) === 1;
        })
        : [];
      state.adminlist = state.list
        ? state.list.filter((data) => {
          return Number(data.role) === 2;
        })
        : [];
      state.pageNumber = 1;
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },
    succesResponseMethodAdmin: (state, action) => {
      state.succesResponseAdmin = action.payload;
    },
    setPageNumber: (state, action) => {
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(state.list.length / state.adminCount) + 1
      ) {
        state.pageNumber = action.payload;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      }
    },
    setAdminCount: (state, action) => {
      if (action.payload > 0) {
        state.adminCount = action.payload;
        state.pageNumber = 1;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      }
    },
    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(state.list.length / state.adminCount);
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      }
    },
    sortByFirstName: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        );
        state.adminlist = state.adminlist.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        );
      } else {
        state.list = state.list.sort((a, b) =>
          b.firstname.localeCompare(a.firstname)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          b.firstname.localeCompare(a.firstname)
        );
        state.adminlist = state.adminlist.sort((a, b) =>
          b.firstname.localeCompare(a.firstname)
        );
      }
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },
    sortByLastName: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) =>
          a.lastname.localeCompare(b.lastname)
        );
        state.adminlist = state.adminlist.sort((a, b) =>
          a.lastname.localeCompare(b.lastname)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          a.lastname.localeCompare(b.lastname)
        );
      } else {
        state.list = state.list.sort((a, b) =>
          b.lastname.localeCompare(a.lastname)
        );
        state.adminlist = state.adminlist.sort((a, b) =>
          b.lastname.localeCompare(a.lastname)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          b.lastname.localeCompare(a.lastname)
        );
      }
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },
    sortByEmail: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) => a.email.localeCompare(b.email));
        state.adminlist = state.adminlist.sort((a, b) =>
          a.email.localeCompare(b.email)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          a.email.localeCompare(b.email)
        );
      } else {
        state.list = state.list.sort((a, b) => b.email.localeCompare(a.email));
        state.adminlist = state.adminlist.sort((a, b) =>
          b.email.localeCompare(a.email)
        );
        state.superadminlist = state.superadminlist.sort((a, b) =>
          b.email.localeCompare(a.email)
        );
      }
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },

    sortById: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort(
          (a, b) => new Date(a.activated_at) - new Date(b.activated_at)
        );
        state.adminlist = state.adminlist.sort(
          (a, b) => new Date(a.activated_at) - new Date(b.activated_at)
        );
        state.superadminlist = state.superadminlist.sort(
          (a, b) => new Date(a.activated_at) - new Date(b.activated_at)
        );
      } else {
        state.list = state.adminlist.sort(
          (a, b) => new Date(b.activated_at) - new Date(a.activated_at)
        );
        state.adminlist = state.adminlist.sort(
          (a, b) => new Date(b.activated_at) - new Date(a.activated_at)
        );
        state.superadminlist = state.superadminlist.sort(
          (a, b) => new Date(b.activated_at) - new Date(a.activated_at)
        );
      }
      adminListSlice.caseReducers.getPaginatedAdmins(state);
    },
    filterByKeyword: (state, action) => {
      if (action.payload) {

        state.filterKeyword = action.payload;
        state.pageNumber = 1;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      } else {
        state.filterKeyword = "";
        state.pageNumber = 1;
        adminListSlice.caseReducers.getPaginatedAdmins(state);
      }
    },
  },
  extraReducers: {
    [GetAdmins.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
      state.superadminlist = action.payload
        ? action.payload.filter((data) => {
          return Number(data.role) === 1;
        })
        : [];
      state.adminlist = action.payload
        ? action.payload.filter((data) => {
          return Number(data.role) === 2;
        })
        : [];
    },
    [GetAdmins.pending]: (state, action) => {
      state.loading = true;
    },
    [GetAdmins.rejected]: (state, action) => {
      state.loading = false;
      state.list = [];
    },
    [CreateAdmin.fulfilled]: (state, action) => {
      let res = action.payload;
      state.loadingAdmin = false;
      if (res?.success) {
        toast.success(SUCCESSFULLY_ADDED_ADMIN);
        state.list = [res.data, ...state.list];
        state.superadminlist = state.list
          ? state.list.filter((data) => {
            return Number(data.role) === 1;
          })
          : [];
        state.adminlist = state.list
          ? state.list.filter((data) => {
            return Number(data.role) === 2;
          })
          : [];
        state.pageNumber = 1;
        state.paginatedAdmins = paginatedAdmins(
          state.typeRole === "admin"
            ? state.adminlist
            : state.typeRole === "superadmin"
              ? state.superadminlist
              : state.list,
          state.pageNumber,
          state.adminCount,
          state.filterKeyword
        );
        state.succesResponseAdmin = true;
      }
    },
    [CreateAdmin.rejected]: (state, action) => {
      state.loadingAdmin = false;
      toast.error(action?.payload);
    },
    [CreateAdmin.pending]: (state, action) => {
      state.loadingAdmin = true;
    },
    [DeleteAdminApi.fulfilled]: (state, action) => {
      if (action?.payload?.data?.success) {
        toast.success(SUCCESSFULLY_DELETED_USER);
      }
    },
    [EditAdmin.fulfilled]: (state, action) => {
      state.loadingAdmin = false;
      let data = state.list.map((admin) => {
        if (admin?.id === action.payload?.id) {
          return action.payload;
        } else {
          return admin;
        }
      });
      state.list = data;
      state.superadminlist = state.list
        ? state.list.filter((data) => {
          return Number(data.role) === 1;
        })
        : [];
      state.adminlist = state.list
        ? state.list.filter((data) => {
          return Number(data.role) === 2;
        })
        : [];
      state.paginatedAdmins = paginatedAdmins(
        state.typeRole === "admin"
          ? state.adminlist
          : state.typeRole === "superadmin"
            ? state.superadminlist
            : state.list,
        state.pageNumber,
        state.adminCount,
        state.filterKeyword
      );
      state.succesResponseAdmin = true;
      toast.success(SUCCESSFULLY_UPDATED_USER);
    },
    [EditAdmin.pending]: (state, action) => {
      state.loadingAdmin = true;
    },
    [EditAdmin.rejected]: (state, action) => {
      state.loadingAdmin = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  getPaginatedAdmins,
  sortByFirstName,
  sortByLastName,
  sortByEmail,
  sortById,
  filterByKeyword,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setAdminCount,
  deleteAdmins,
  succesResponseMethodAdmin,
  changeRoleType,
} = adminListSlice.actions;

export default adminListSlice.reducer;
