import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaginatedProducts,
  setproductCount,
  goToNextPage,
  goToPreviousPage,
  setPageNumber,
} from "../../features/admin/productSlice";
import toast from "react-hot-toast";
import Button from "../general/Button";
import Popup from "../general/Popup";
import ProductForm from "../forms/ProductForm";
import SubForm from "../forms/SubForm";
import { DeleteProductApi, GetProducts, EditProduct } from "../../services";
import Loader from "../utils/Loader";
import Pagination from "../layout/Pagination";
import ItemPerPage from "../layout/ItemPerPage";
import { changeTypeStatus } from "../../features/admin/productSlice";
import { Helmet } from "react-helmet";
import {
  ACTIVE,
  CANNOT_DELETE_SUBSCRIPTION,
  DATA_IS_NOT_AVAILABLE,
  DELETE_SELECTED_PRODUCT,
  DELETE_SELECTED_PRODUCTS,
  INACTIVE,
  PRODUCTS,
  SELECT_STATUS,
  STATUS
} from "../../Constant";
import ProductTable from "./ProductTable";
let isGetApiCall = false;
const Products = () => {
  // State
  const products = useSelector((state) => state.products.paginatedProduct);
  const activeProducts = useSelector(
    (state) => state.products.paginatedProduct
  );
  //To get inactive products
  const inactiveProducts = useSelector(
    (state) => state.products.paginatedProduct
  );
  const typeStatus = useSelector((state) => state.products.typeStatus);
  const totalProducts = useSelector((state) => state.products.list);
  const productCount = useSelector((state) => state.products.productCount);
  const pageNumber = useSelector((state) => state.products.pageNumber);
  const loading = useSelector((state) => state.products.loading);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showSubForm, setShowSubForm] = useState(false);
  const [productToChange, setproductToChange] = useState(null);
  const [disablePagination, setDisablePagination] = useState(false);
  const [maxPages, setmaxPages] = useState(0);
  const [maxProductPerPage,] = useState(10);
  const [OpenFilter, setOpenFilter] = useState(false);

  // Dispatch
  const dispatch = useDispatch();
  //For active/inactive products
  useEffect(() => {
    const totalProductsData =
      typeStatus === "Active"
        ? activeProducts
        : typeStatus === "Inactive"
          ? inactiveProducts
          : totalProducts;
    const mPNum = Math.ceil(totalProductsData.length / productCount);
    setmaxPages(mPNum);
  }, [totalProducts, typeStatus, productCount, activeProducts, inactiveProducts]);

  //dispatch action for products 
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetProducts());
      isGetApiCall = true
    };
    getData();

    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelProductForm();
        cancelSubForm();
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (isGetApiCall) {
      const getData = async () => {
        dispatch(getPaginatedProducts());
        isGetApiCall = false
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isGetApiCall]);

  // Methods
  const selectRow = (e, productId) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, productId]);
      setDisablePagination(true);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
      setDisablePagination(false);
    }
  };

  //SelectAll Product
  const selectAll = () => {
    setSelectedProducts(products.map((p) => p.id));
    setDisablePagination(true);
  };
  // select None product
  const selectNone = () => {
    setSelectedProducts([]);
    setDisablePagination(false);
  };
  //If data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
  //delete selected product
  const deleteSelected = () => {
    dispatch(DeleteProductApi(selectedProducts));
    setShowPopup(false);
    setSelectedProducts([]);
    setDisablePagination(false);
  };
  //edit selected product
  const handleEdit = (product, e) => {
    e.preventDefault();
    if (product.type === "product") {
      dispatch(
        EditProduct({
          ...product,
          available: e.target.checked,
          deprecated: e.target.checked === true ? false : true,
        })
      );
    } else if (product.type === "subscription") {
      dispatch(
        EditProduct({
          id:
            product && product.product && product.product.id
              ? product.product.id
              : "",
          available: e.target.checked,
          deprecated: e.target.checked === true ? false : true,
        })
      );
    }
  };
  //To cancel popup
  const clearPopup = () => {
    setShowPopup(false);
  };
  //To cancel Product form
  const cancelProductForm = () => {
    setShowProductForm(false);
    setproductToChange(null);
  };
  //To cancel SubProduct form
  const cancelSubForm = () => {
    setShowSubForm(false);
    setproductToChange(null);
  };

  //To displayProductform
  const displayProductForm = (product) => {
    if (product) {
      setproductToChange(product);
    }
    setShowProductForm(true);
  };

  //to displaysubform
  const displaySubForm = (product) => {
    if (product) {
      setproductToChange(product);
    }
    setShowSubForm(true);
  };

  const OnStatusChange = (status) => {
    if (!loading) {
      dispatch(changeTypeStatus(status));
      setOpenFilter(false);
    }
  };

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>
          {PRODUCTS}
        </title>
      </Helmet>
      <h1 className="text-3xl font-bold text-blue-600 mb-8">Products</h1>
      {/* Popup */}
      {showPopup ? (
        <Popup
          yesFn={deleteSelected}
          noFn={clearPopup}
          text={
            selectedProducts.length > 1
              ? DELETE_SELECTED_PRODUCTS
              : DELETE_SELECTED_PRODUCT
          }
        />
      ) : null}
      {/* Product Form */}
      {showProductForm ? (
        <ProductForm
          product={productToChange}
          cancelProductForm={cancelProductForm}
        />
      ) : null}
      {showSubForm ? (
        <SubForm product={productToChange} cancelSubForm={cancelSubForm} />
      ) : null}

      {/* Controls */}
      <div className="mb-8 flex justify-between">
        <div className="w-9/12">
          {!disablePagination ? (
            <ItemPerPage
              itemCount={productCount}
              maxItemsPerPage={maxProductPerPage}
              label={PRODUCTS}
              setCount={setproductCount}
            />
          ) : null}
        </div>
        <div className="w-3/12">
          <Button text="Add Product" fn={() => setShowProductForm(true)} />
          <Button
            text={
              selectedProducts.length > 1
                ? DELETE_SELECTED_PRODUCTS
                : DELETE_SELECTED_PRODUCT
            }
            bgColor="bg-red-900"
            fn={() => {
              if (selectedProducts.includes("CqGqGd2uVyA0RfT5nVyJ")) {
                toast.error(CANNOT_DELETE_SUBSCRIPTION);
              } else {
                setShowPopup(true);
              }
            }}
            disabled={selectedProducts.length === 0}
          />
          <div>
            <p>{STATUS}</p>
            <div
              style={{ zIndex: "1" }}
              className="w-2/3 select-none bg-blue-200 cursor-pointer font-medium relative"
            >
              <div
                onClick={() => {
                  if (!loading) {
                    setOpenFilter(!OpenFilter);
                  }
                }}
                className="px-4 py-2 text-lg border mt-1 border-blue-500 rounded font-medium flex justify-between"
              >
                <div>{typeStatus === "All" ? "Select Status" : typeStatus}</div>
                <div>▼</div>
              </div>
              {OpenFilter ? (
                <div
                  style={{ marginTop: "-3px" }}
                  className="w-full absolute border mt-1 bg-blue-200 border-blue-500"
                >
                  <div
                    onClick={() => {
                      OnStatusChange("All");
                    }}
                    className={`px-4 py-2 ${typeStatus === "All" ? "bg-blue-400" : ""
                      }`}
                  >
                    {SELECT_STATUS}
                  </div>
                  <div
                    onClick={() => {
                      OnStatusChange("Active");
                    }}
                    className={`px-4 py-2 ${typeStatus === "Active" ? "bg-blue-400" : ""
                      }`}
                  >
                    {ACTIVE}
                  </div>
                  <div
                    onClick={() => {
                      OnStatusChange("Inactive");
                    }}
                    className={`px-4 py-2 ${typeStatus === "Inactive" ? "bg-blue-400" : ""
                      }`}
                  >
                    {INACTIVE}
                  </div>
                </div>
              ) : null}
            </div>

          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Data */}
          <ProductTable selectAll={selectAll} selectNone={selectNone} selectedProducts={selectedProducts} products={products}
            selectRow={selectRow} handleEdit={handleEdit} displayProductForm={displayProductForm} displaySubForm={displaySubForm} noData={noData} />

          {!disablePagination ? (
            <Pagination
              prevFn={() => dispatch(goToPreviousPage())}
              nextFn={() => dispatch(goToNextPage())}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              maxPages={maxPages}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Products;
