export const ProductActiveString = (ProductActive) => {
  const ProductActiveValue = ProductActive;
  const stringValue = ProductActiveValue
    ? ProductActiveValue.filter((data) => data.activeCount !== 0).map(
      (p, i) => {
        const activatedApp = p.apps
          ? p.apps
            ?.filter((e) => e.isChecked && e.isChecked === true)
            .map((a) => a.title)
          : [];
        const activatedFeature = p.features
          ? p.features
            ?.filter((e) => e.isChecked && e.isChecked === true)
            .map((a) => a.title)
          : [];

        const AppFeatureString =
          activatedFeature.length && activatedApp.length
            ? `${activatedFeature.join()}, ${activatedApp.join()}`
            : activatedFeature.length
              ? activatedFeature.join()
              : activatedApp.length
                ? activatedApp.join()
                : "";
        return p.activeCount === 0
          ? null
          : i + 1 + ". " + p.title + ": " + AppFeatureString;
      }
    )
    : [];
  return stringValue.length ? stringValue : [];
};

export const showDateToShow = (type = "week", timeNumber = 1) => {
  const dt = new Date();
  let dateToshow = "";
  if (type === "week" || type === "day") {
    dateToshow = new Date(
      dt.setDate(dt.getDate() + timeNumber * (type === "week" ? 7 : 1))
    );
    dateToshow = `${dateToshow.getDate()}/${dateToshow.getMonth() + 1
      }/${dateToshow.getFullYear()}`;
  }
  if (type === "year" || type === "month") {
    dateToshow = new Date(
      dt.setMonth(
        new Date().getMonth() + timeNumber * (type === "year" ? 12 : 1)
      )
    );
    dateToshow = `${dateToshow.getDate()}/${dateToshow.getMonth() + 1
      }/${dateToshow.getFullYear()}`;
  }

  return dateToshow;
};

export const calculateCost = (type = "week", timeNumber = 1, price = 0) => {
  let pricesToshow = "";
  let oneDayPrice;
  if (type === "year") {
    oneDayPrice = +price / 365;
  }

  if (type === "month") {
    oneDayPrice = +price / (timeNumber * 30);
  }

  if (type === "week") {
    oneDayPrice = +price / (timeNumber * 7);
  }
  if (type === "day") {
    oneDayPrice = +price / timeNumber;
  }

  pricesToshow = (
    <>
      <p>
        ${(oneDayPrice * 365).toFixed(2)}/year (${(oneDayPrice * 30).toFixed(2)}
        /month)
      </p>
      <p>
        ${(oneDayPrice * 6 * 30).toFixed(2)}/6 Months ($
        {(oneDayPrice * 30).toFixed(2)}/Month)
      </p>
      <p>
        ${(oneDayPrice * 7).toFixed(2)}/Week (${oneDayPrice.toFixed(2)}/Day)
      </p>
    </>
  );

  return pricesToshow;
};
