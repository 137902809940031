import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Select from "react-select";

import Toggle from "../general/Toggle";
import Button from "../general/Button";
import {
  GetProducts,
  AddNewSubscription,
  EditSubscriptions,
} from "../../services";
import { uploadImage } from "../../apiStructure";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import {
  getPaginatedProducts,
} from "../../features/admin/productSlice";
import { productFormData } from "../utils/commonVariable";
import {
  SOMETHING_WENT_WRONG,
  PHOTO_TOO_BIG,
  PLEASE_ENTER_TITLE,
  PLEASE_ENTER_DESCRIPTION,
} from "../../Constant";

import SubscriptionFormHeader from "./SubscriptionFormHeader";

let isGetApiCall = false;

const SubscriptionForm = ({ subscriptionEdit, cancelSubscriptionForm, updateSubscriptionList }) => {
  // let storage = JSON;

  const generateId = useCallback((len) => {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }, []);
  const logo = "/images/logo.webp";
  const inputFile = useRef(null);
  // const succesResponseProduct = useSelector(
  //   (state) => state.products.succesResponseProduct
  // );
  const [loadingData, setLoadingData] = useState(false)
  //const loadingUser = useSelector((state) => state.users.loadingUser);
  const products = useSelector((state) => state.products.paginatedProduct);

  const activeProducts = useSelector(
    (state) => state.products.paginatedProduct
  );
  //To get inactive products
  const inactiveProducts = useSelector(
    (state) => state.products.paginatedProduct
  );

  const typeStatus = useSelector((state) => state.products.typeStatus);
  const totalProducts = useSelector((state) => state.products.list);
  const productCount = useSelector((state) => state.products.productCount);
  //const pageNumber = useSelector((state) => state.products.pageNumber);
  const [maxPages, setmaxPages] = useState(0);
  // const [maxProductPerPage] = useState(10);
  const [submitting, setSubmitting] = useState(false);
  //const [appFeatureUpdated, setappFeatureUpdated] = useState(false);
  const [iconUpload, setIconUpload] = useState(false);
  //const [appiconUpload, setAppIconUpload] = useState(false);
  //const [featuresiconUpload, setFeaturesIconUpload] = useState(false);

  const [subscriptionData, setSubscriptionData] = useState({
    available: false,
    title: "",
    description: "",
    icon: "",
    pricing_options: [
      {
        price: "",
        is_free_for_first_time: false,
        hide_from_user: 'active',
        shopify_ful_fill: "",
        shopify_no_ful_fill: "",
        stripe_product_id: "",
        stripe_product_name: "",
        time: 1,
        time_unit: "",
      },
    ],
    features: [],
    apps: [],
    mailchimp_tag: "",
    product_ids: [],
    products: [],
  });

  const [productsData, setProductsData] = useState([]);
  const [appsList, setAppsList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [tableDataValidation, setTableValidation] = useState([]);

  useEffect(() => {
    let appList = [];
    let featureList = [];
    if (products.length) {
      let temp = products.map((item, index) => {
        if (item.type === "product" && item.title !== "Subscriptions") {
          if (Object.keys(subscriptionEdit).length > 0 && subscriptionEdit?.product_ids.includes(item.id)) {
            item.apps["product_id"] = item.id
            item.apps.map(app => (app["product_id"] = item.id))
            appList.push(...item.apps)
          }
          if (Object.keys(subscriptionEdit).length > 0 && subscriptionEdit?.product_ids.includes(item.id)) {

            item.features.map(feature => (feature["product_id"] = item.id))

            featureList.push(...item.features)

          }

          return {
            ...item,
            value: item.id,
            label: item.title,
          };
        }
      });
      temp = temp.filter((data) => data);
      if (Object.keys(subscriptionEdit).length > 0) {
        let appListNew = appList.map((item) => ({

          product_id: item.product_id,
          id: item.id,
          title: item.title,

          value: item.title,
          label: item.title,
        }));


        let featureListNew = featureList.map((item) => ({
          product_id: item.product_id,
          id: item.id,
          title: item.title,
          value: item.title,
          label: item.title,
        }));
        setAppsList(appListNew);
        setFeaturesList(featureListNew);
      }

      setProductsData(temp);
    }
  }, [products]);

  useEffect(() => {
    if (subscriptionEdit && Object.keys(subscriptionEdit).length > 0) {
      setSubscriptionData({
        available: subscriptionEdit.available,
        title: subscriptionEdit.title,
        description: subscriptionEdit.description,
        icon: subscriptionEdit.icon,
        pricing_options: subscriptionEdit.pricing_options,
        features: subscriptionEdit.features,
        apps: subscriptionEdit.apps,
        mailchip_tag: subscriptionEdit.mailchip_tag,
        product_ids: subscriptionEdit.product_ids,
        products: subscriptionEdit.products,
      });
      setAppsList(subscriptionEdit.apps);
      setFeaturesList(subscriptionEdit.features)
    }
  }, [subscriptionEdit]);



  const [formData, setFormData] = useState({
    product_id: new Date().getTime().toString() + generateId(10).toString(),
    ...productFormData,
  });
  useEffect(() => {
    const totalProductsData =
      typeStatus === "Active"
        ? activeProducts
        : typeStatus === "Inactive"
          ? inactiveProducts
          : totalProducts;
    const mPNum = Math.ceil(totalProductsData.length / productCount);
    setmaxPages(mPNum);
  }, [
    totalProducts,
    typeStatus,
    productCount,
    activeProducts,
    inactiveProducts,
  ]);

  const loadingSubmit = useSelector((state) => state.subscriptions.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetProducts());
      isGetApiCall = true;
    };

    getData();
  }, [dispatch]);

  useEffect(() => {
    if (isGetApiCall) {
      const getData = async () => {
        dispatch(getPaginatedProducts());
        isGetApiCall = false;
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isGetApiCall]);



  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  //handle submit of form for app and feature
  const handleSubmit = async (event) => {
    event.preventDefault();
    let flag = true;
    let errorData = [];

    if (!subscriptionData.title) {
      toast.error(PLEASE_ENTER_TITLE);
      flag = false;
    }
    if (!subscriptionData.description) {
      toast.error(PLEASE_ENTER_DESCRIPTION);
      flag = false;
    }

    for (let item of subscriptionData.pricing_options) {
      let error = {};
      if (item.time_unit === "year" && item.time > 1) {
        error["time"] = "Maximum 1 Year allowed";
        flag = false;
      }
      if (!item.price) {
        error["price"] = "Please enter price";
        flag = false;
      }
      if (!item.time || !item.stripe_product_name) {
        error["time"] = "Please select time";
        flag = false;
      }
      errorData.push(error);
    }
    setTableValidation(errorData);
    if (flag) {
      setLoadingData(true)
      let res;
      if (subscriptionEdit && Object.keys(subscriptionEdit).length > 0) {
        res = await dispatch(EditSubscriptions({ data: subscriptionData, id: subscriptionEdit.id }));
      } else {
        res = await dispatch(AddNewSubscription(subscriptionData));
      }
      if (res?.payload?.success) {
        updateSubscriptionList();
        cancelSubscriptionForm(event);
      }
      setLoadingData(false)
    }
  };

  //for validation of App Ids and Feature Ids
  const submitDispatch = async (formData) => {
    setSubmitting(true);
    let alreadyExistId = [];
    let checkUnique = [];

    formData.apps.forEach((app) => {
      if (!alreadyExistId.includes(app.custom_id)) {
        checkUnique.push(app.custom_id);
      }
    });

    formData.features.forEach((feature) => {
      if (!alreadyExistId.includes(feature.custom_id)) {
        checkUnique.push(feature.custom_id);
      }
    });

    let validateIds = [];

    const errId = alreadyExistId.filter(
      (item, index) => alreadyExistId.indexOf(item) !== index
    );
    const errUniqId = checkUnique.filter(
      (item, index) => checkUnique.indexOf(item) !== index
    );

    if (validateIds.length > 0) {
      toast.error("id: " + validateIds.join(" \n id : ") + " already exist ");
    }
    else {
    }
    setSubmitting(false);
    // clearPopup()
  };

  //request To upload a Photo
  const uploadFileAndgetURL = async (data) => {
    if (data) {
      const res = await uploadImage(data);
      if (res && res.data && res.data.publicUrl) {
        setSubscriptionData({
          ...subscriptionData,
          icon: res.data.publicUrl,
        });
        // setFormData({ ...formData, icon: res.data.publicUrl });
      }
      setIconUpload(false);
    } else {
      toast.error(SOMETHING_WENT_WRONG);
      setIconUpload(false);
    }
  };

  //Validation of photo
  const handleChangeFile2 = (e) => {
    e.preventDefault();
    let fileUploadData = inputFile.current.files[0];
    let Data = new FormData();

    if (fileUploadData.size > 2e6) {
      toast.error(PHOTO_TOO_BIG);
      return false;
    } else {
      setIconUpload(true);
      Data.append("image", fileUploadData);
      uploadFileAndgetURL(Data);
    }
  };

  const handleChange = (field, event, id) => {

    if (field === "title") {
      const temp = subscriptionData.pricing_options;
      for (const tempIndex in temp) {
        if (!temp[tempIndex]["stripe_product_id"])
          temp[tempIndex]["stripe_product_name"] = `${event.target.value.replace(/ /g, "-")}-subs_${temp[tempIndex]["time"]}_${temp[tempIndex]["time_unit"]}`;
      }
      setSubscriptionData({
        ...subscriptionData,
        pricing_options: temp,
      });
    }
    if (field === "available") {
      setSubscriptionData({
        ...subscriptionData,
        [field]: event.target.checked,
      });
    }
    else {
      setSubscriptionData({
        ...subscriptionData,
        [field]: event.target.value,
      });
    }

    // }
  };

  const handleTableChange = (key, event, index) => {
    const temp = subscriptionData.pricing_options;
    if (key == "is_free_for_first_time") {
      temp[index][key] = event.target.checked;
    } else {
      temp[index][key] = event.target.value;
    }
    if (key == "time_unit" || key == "time") {
      temp[index]["stripe_product_name"] = `${subscriptionData["title"].replace(/ /g, "-")}-subs_${temp[index]["time"]}_${temp[index]["time_unit"]}`;
    }


    setSubscriptionData({
      ...subscriptionData,
      pricing_options: temp,
    });
  };

  const addRow = (event) => {
    event.preventDefault();
    // alert("add")
    let temp = subscriptionData.pricing_options;
    temp.push({
      price: "",
      is_free_for_first_time: false,
      hide_from_user: 'active',
      shopify_ful_fill: "",
      shopify_no_ful_fill: "",
      stripe_product_id: "",
      stripe_product_name: "",
      time: 1,
      time_unit: "",
    });
    setSubscriptionData({
      ...subscriptionData,
      pricing_options: temp,
    });
  };

  const deleteRow = (event, index) => {
    event.preventDefault();
    let temp = subscriptionData.pricing_options;
    temp.splice(index, 1);
    setSubscriptionData({
      ...subscriptionData,
      pricing_options: temp,
    });
  };

  const handleSelectChange = (event, { action, removedValue }) => {

    if (removedValue) {

      let removeProductId = removedValue.id;
      let tempAppList = appsList.filter(
        (item) => item.product_id != removeProductId
      );
      let tempFeatureList = featuresList.filter(
        (item) => item.product_id != removeProductId
      );

      let newApps = subscriptionData.apps.filter(
        (item) => item.product_id != removeProductId
      );
      let newFeatures = subscriptionData.features.filter(
        (item) => item.product_id != removeProductId
      );
      setAppsList(tempAppList);
      setFeaturesList(tempFeatureList);
      const tempProdList = subscriptionData.products.filter(
        (item) => item.id != removeProductId
      );
      const tempProdIds = subscriptionData.product_ids.filter(
        (item) => item != removeProductId
      );
      setSubscriptionData({
        ...subscriptionData,
        apps: newApps,
        features: newFeatures,
        products: tempProdList,
        product_ids: tempProdIds,
      });
    } else {
      let temp = event.slice(event.length - 1);
      let app = temp[0].apps;
      let features = temp[0].features;

      let toAddApp = app.map((item) => ({
        product_id: temp[0].id,
        id: item.id,
        value: item.title,
        label: item.title,
        title: item.title,
      }));

      let toAddFeature = features.map((item) => ({
        product_id: temp[0].id,
        id: item.id,
        value: item.title,
        label: item.title,
        title: item.title,
      }));

      setAppsList([...appsList, ...toAddApp]);
      setFeaturesList([...featuresList, ...toAddFeature]);

      const eventData = [];

      let prodId = event.map(function (item) {
        return item.id;
      });
      setSubscriptionData({
        ...subscriptionData,
        product_ids: prodId,
        products: event,
        //  apps:[...subscriptionData.apps,...temp1],
        // features:[...subscriptionData.features,...temp2]
      });
    }
  };

  const handleSelectAppChange = (event, { action, removedValue }) => {
    if (removedValue) {
      let removeProductId = removedValue.app_id;
      let tempAppList = subscriptionData.apps.filter(
        (item) => item.app_id != removeProductId
      );
      setSubscriptionData({
        ...subscriptionData,
        apps: tempAppList,
      });
      //  setAppsList(tempAppList)
    } else {
      const eventData = [];
      setSubscriptionData({
        ...subscriptionData,
        apps: event,
      });
    }
  };

  const handleSelectFeatureChange = (event, { action, removedValue }) => {
    if (removedValue) {
      let removeProductId = removedValue.feature_id;
      let tempFeature = subscriptionData.features.filter(
        (item) => item.feature_id != removeProductId
      );
      setSubscriptionData({
        ...subscriptionData,
        features: tempFeature,
      });
    } else {
      const eventData = [];

      setSubscriptionData({
        ...subscriptionData,
        features: event,
      });
    }
  };
  return (
    <div
      className=" bg-black bg-opacity-20  z-10 overflow-hidden absolute top-0 left-0 h-full w-full"
      onClick={() => {
        cancelSubscriptionForm();
        // resetValue();
      }}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="h-full-custom w-3/4 inset-x-0 z-10 -translate-x-1/2 my-4 mx-auto absolute top-0 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <SubscriptionFormHeader
          isEdit={Object.keys(subscriptionEdit).length ? true : false}
        />

        <form className="popup_body px-8 pb-28 pt-2 subscribe_form">
          {/* <div className="toggle mb-3"> */}
          <Toggle
            label={"Visible"}
            // isdisabled={isDisabled}
            isChecked={subscriptionData.available}
            onChange={(event) => handleChange("available", event)}
          />
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Name
            </label>
            <input
              type="text"
              value={subscriptionData.title}
              onChange={(event) => handleChange("title", event)}
              className="w-full block rounded-lg p-3 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  text-blue-900 font-semibold"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Description
            </label>
            <textarea
              value={subscriptionData.description}
              onChange={(event) => handleChange("description", event)}
              className="w-full block rounded-lg p-3 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  text-blue-900 font-semibold"
            ></textarea>
          </div>
          <div className="flex items-center">
            <p className="font-semibold mr-2">Icon</p>
            <img
              src={subscriptionData.icon || logo}
              className="w-12 h-12 object-cover border border-black rounded mr-8"
              alt=""
            />
            <input
              type="file"
              id="file"
              ref={inputFile}
              onChange={(e) => handleChangeFile2(e)}
              accept="image/png, image/jpg, image/jpeg, image/jfif, image/webp"
              style={{ display: "none" }}
            />
            <Button
              text="Change Icon"
              fn={(e) => {
                e.preventDefault();
                inputFile.current.click();
              }}
              small={true}
              bottomMargin={false}
            />
          </div>
          <div className="mb-3 overflow-x-auto">
            <p className="font-semibold mb-2 mt-4">Pricing Options</p>

            <table className="table-auto w-full text-left border border-blue-500 mt-0">
              <thead className="bg-blue-500 text-white p-16">
                <tr>
                  <TableHeader text="Time Option" sortable={false} />
                  <TableHeader text="Price" sortable={false} />
                  <TableHeader text="Free With New Account?" sortable={false} />
                  <TableHeader text="Visibility" sortable={false} />
                  <TableHeader text="Shopify SKU - Fulfill" sortable={false} />
                  <TableHeader
                    text="Shopify SKU - No Fulfill"
                    sortable={false}
                  />
                  <TableHeader text="Plan Details" sortable={false} />
                  <TableHeader text="" sortable={false} />
                </tr>
              </thead>
              <tbody>
                {subscriptionData.pricing_options &&
                  subscriptionData.pricing_options.map((item, id) => (
                    <tr key={id}>
                      <TableData
                        child={
                          <div className="flex select_input">
                            <input
                              type="text text-center"
                              className="p-1"
                              name=""
                              value={item.time}
                              onChange={(event) =>
                                handleTableChange("time", event, id)
                              }
                            />
                            <select
                              className="ml-2 p-1"
                              value={item.time_unit == "" ? "year" : item.time_unit}
                              onChange={(event) =>
                                handleTableChange("time_unit", event, id)
                              }
                            >
                              <option value="">Select</option>
                              <option value="day">Day</option>
                              <option value="week">Week</option>
                              <option value="month">Month</option>
                              <option value="year">Year</option>
                            </select>
                            <span style={{ color: "red", fontSize: "10px", fontWeight: "500" }}>{tableDataValidation[id]?.time}</span>
                          </div>

                        }
                      />
                      {/* <TableData child={<div className="w-1/2"></div>} /> */}
                      <TableData
                        child={
                          <div style={{ display: "grid" }}>
                            <div
                              className="w-1/2 flex align-center select_input"

                            >
                              <span className="mr-1 pt-1">$</span>
                              <input
                                className="p-1"
                                type="number"

                                value={item.price}
                                onChange={(e) =>
                                  handleTableChange("price", e, id)
                                }
                              />
                            </div>
                            <span style={{ color: "red", fontSize: "10px", fontWeight: "500" }}>{tableDataValidation[id]?.price}</span>
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div className="w-1/2">
                            <input
                              className="p-1"
                              checked={item?.is_free_for_first_time === true ? true : false}
                              type="checkbox"
                              value={item.is_free_for_first_time == "true" || item.is_free_for_first_time ? true : false}
                              onChange={(e) =>
                                handleTableChange(
                                  "is_free_for_first_time",
                                  e,
                                  id
                                )
                              }
                            />
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div style={{ display: "grid" }}>
                            <div
                              className="w-1/2 flex align-center select_input"

                            >
                              <select
                                style={{ color: item?.hide_from_user == "active" ? "green" : item?.hide_from_user == "deactive" ? "red" : "grey", fontWeight: "bolder" }}
                                value={item?.hide_from_user ? item.hide_from_user : 'visible'}
                                onChange={(e) =>
                                  handleTableChange(
                                    "hide_from_user",
                                    e,
                                    id
                                  )
                                }
                              >
                                <option value="active" style={{ color: "green", fontWeight: "bolder" }}> Visible</option>
                                <option value="deactive" style={{ color: "red", fontWeight: "bolder" }}> Hidden</option>
                              </select>
                            </div>
                            <span style={{ color: "red", fontSize: "10px", fontWeight: "500" }}>{tableDataValidation[id]?.price}</span>
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div className="w-1/2">
                            <input
                              className="p-1"
                              type="text"
                              name=""
                              value={item.shopify_ful_fill}
                              onChange={(e) =>
                                handleTableChange("shopify_ful_fill", e, id)
                              }
                            />
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div className="w-1/2">
                            <input
                              className="p-1"
                              type="text"
                              name=""
                              value={item.shopify_no_ful_fill}
                              onChange={(e) =>
                                handleTableChange("shopify_no_ful_fill", e, id)
                              }
                            />
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div className="w-1/2">
                            <input
                              className="p-1"
                              type="text"
                              name=""
                              value={item.plan_string}
                              onChange={(e) =>
                                handleTableChange("plan_string", e, id)
                              }
                            />
                          </div>
                        }
                      />
                      <TableData
                        child={
                          <div className="w-1/2 icon_btn relative">
                            <Button
                              text=""
                              small={true}
                              bottomMargin={false}
                              fn={(event) => {
                                deleteRow(event, id);
                              }}
                            />
                            <i className="fa-solid fa-trash-can"></i>

                          </div>
                        }
                      />
                    </tr>
                  ))}
                <tr style={{ "background-color": "white" }}>
                  <div className="icon_btn relative m-4 add_btn">
                    <Button
                      text=""
                    />
                    <i className="fa-solid fa-plus"></i>
                    <Button
                      text=""
                      small={true}
                      bottomMargin={false}
                      fn={(event) => {
                        addRow(event);
                      }}
                    />
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Product(s) Shown In
            </label>
            <Select
              defaultValue={
                subscriptionEdit && Object.keys(subscriptionEdit).length > 0
                  ? subscriptionEdit.products.filter((item) => item)
                  : null
              }
              isMulti
              name="colors"
              options={productsData}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectChange}
            />
          </div>
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Apps Included
            </label>
            <Select
              defaultValue={
                subscriptionEdit && Object.keys(subscriptionEdit).length > 0
                  ? subscriptionEdit.apps.filter((item) => item)
                  : null
              }
              isMulti
              name="colors"
              options={appsList}
              className="basic-multi-select"
              classNamePrefix="select"
              getValue={(event) => console.log("ooo", event)}
              onChange={handleSelectAppChange}
            />

          </div>
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Features Included
            </label>
            <Select
              defaultValue={
                subscriptionEdit && Object.keys(subscriptionEdit).length > 0
                  ? subscriptionEdit.features.filter((item) => item)
                  : null
              }
              isMulti
              name="colors"
              options={featuresList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectFeatureChange}
            />
          </div>
          <div className="mb-3">
            <label
              for="exampleInputEmail1"
              className="block mr-0 mb-2 font-semibold"
            >
              Mailchimp/Shopify Tag
            </label>
            <input
              type="email"
              value={subscriptionData.mailchip_tag}
              onChange={(event) => handleChange("mailchip_tag", event)}
              className="w-full block rounded-lg p-3 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  text-blue-900 font-semibold"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="flex custom-fix justify-center mt-16 mb-4">


            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelSubscriptionForm}
              otherClass="mr-4"
            />
            <Button
              text="Save Changes"
              disabled={loadingData}
              fn={handleSubmit}
              fontSemiBold={true}
              bottomMargin={false}
            />

          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionForm;
