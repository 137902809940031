import React from "react";
import { useDispatch } from "react-redux";

const TableHeader = ({ text, child, sortable = true, fn }) => {
  const dispatch = useDispatch();
  if (!child) {
    return (
      <th className="font-bold px-4 py-2">
        <div className="flex items-center">
          <span>{text}</span>
          {sortable ? (
            <span className="ml-4">
              {/* Up Chevron */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 cursor-pointer"
                fill="none"
                viewBox="0 -4 24 24"
                stroke="currentColor"
                onClick={() => dispatch(fn("ASC"))}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 15l7-7 7 7"
                />
              </svg>
              {/* Down Chevron */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 cursor-pointer"
                fill="none"
                viewBox="0 -4 24 24"
                stroke="currentColor"
                onClick={() => dispatch(fn("DSC"))}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          ) : null}
        </div>
      </th>
    );
  }
  return <th className="font-bold px-4 py-2">{child}</th>;
};

export default TableHeader;
