import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../general/Button";
import { forgotPassword } from "../../apiStructure";
import {
  ENTER_A_VALID_EMAIL,
  ENTER_EMAIL_FOR_PASS_RESET_TOKEN,
  FORGET_PASSWORD,
  MAIL_REGEX,
  NEED_HELP_EMAIL_US
} from "../../Constant";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState({});
  const [apiCalling, setApiCalling] = useState(false);
  const mailRegex = MAIL_REGEX;
  const logo_img_web = "/images/logo.webp";
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        var submitButtton = document.getElementById("submitButtton");
        submitButtton.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  //Send Email 
  const sendEmail = async () => {
    if (username && mailRegex.test(username)) {
      setApiCalling(true);
      const res = await forgotPassword(username);
      if (res) {
        setUsername("")
      }
      setApiCalling(false);
      setError({});
    } else {
      setApiCalling(false);
      setError({
        username: validateEmail(username),
      });
    }
  };

  //Email Validation
  const validateEmail = (username) => {
    if (!username || !mailRegex.test(username)) {
      return ENTER_A_VALID_EMAIL;
    }
    return "";
  };

  return (
    <section className="min-h-screen bg-blue-200 flex justify-center items-center p-4 xl:p-16">

      <div className="card bg-blue-50 w-full lg:w-3/4 xl:w-2/5 p-4 text-center shadow-xl rounded-xl">
        <img
          className="block mx-auto my-4"
          src={logo_img_web}
          alt="Thoughtcast Logo"
        />
        <h1 className="text-4xl font-bold mt-4 mb-8">{FORGET_PASSWORD}</h1>
        <p className="text-lg font-bold mt-2 mb-4">
          {ENTER_EMAIL_FOR_PASS_RESET_TOKEN}
        </p>
        <div className="my-4">
          <input
            className="w-full md:w-9/12 xl:w-6/12 my-0 mx-auto block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-blue-900 font-semibold"
            type="text"
            placeholder="hello@thoughtcastmagic.com"
            value={username}
            onChange={(e) => {
              let value = e.target.value.replace(/ /g, "");
              setUsername(value);
            }}
            autoFocus
          />
          {error.username ? (
            <small className="text-red-600 font-semibold">
              {error.username}
            </small>
          ) : null}
        </div>

        <div className="w-9/12 md:w-2/5 lg:w-1/2 mx-auto flex justify-around items-center mt-8 mb-2">
          <NavLink to="/">
            <Button
              text="Back To Login"
              bgColor="bg-blue-200"
              textColor="text-blue-600"
              small={true}
            />
          </NavLink>

          <Button
            text="Send Email"
            disabled={apiCalling}
            small={true}
            fn={() => sendEmail()}
            id="submitButtton"
          />
        </div>
        <div className="w-9/12 md:w-2/5 lg:w-1/2 mx-auto flex justify-around items-center mt-4 mb-4">
          <NavLink to="/activate">
            <Button
              text="Activate New Account"
              bgColor="bg-blue-500"
              small={true}
              fn={() => { }}
            />
          </NavLink>
        </div>
        <a className="text-blue-600 my-4" href="mailto:support@thoughtcastmagic.com?subject=Forgot Password Help">
          {NEED_HELP_EMAIL_US}
        </a>
      </div>
    </section>
  );
};

export default ForgotPassword;
