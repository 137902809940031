import React from 'react'
import {
  DESCRIPTION,
  LEARN_MORE,
  PRODUCT_NAME,
  PRODUCT_ID
} from "../../Constant";
import Button from '../general/Button';
import Toggle from '../general/Toggle';
import AppFeatureCard from "../products/AppFeatureCard";

const ProductFormDetails = ({ features, generateId, setFeatures, apps, setApps,
  formData, handleToggle, handleChangeFile2, setFormData, addApp,
  inputFile, addFeatures, handleAppChange, handleFeatureChange,
  cancelProductForm, resetValue, loading, submitting, iconUpload,
  appiconUpload, featuresiconUpload, handleSubmit }) => {

  const logo = "/images/logo.webp";
  const handleChange = (e) => {
    let target = e.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };
  //remove feature
  const removeFeature = (id) => {
    let currentFeatures = features;
    currentFeatures = currentFeatures.filter((app) => app.id !== id);
    setFeatures([...currentFeatures]);
  };
  //To delete app from product
  const removeApp = (id) => {
    let currentApps = apps;
    currentApps = currentApps.filter((app) => app.id !== id);
    setApps([...currentApps]);
  };

  //For duplicate feature
  const duplicateFeature = (data, i) => {
    let currentApps = features;
    const newData = Object.assign({}, data);
    newData.id = new Date().getTime().toString() + generateId(10).toString();
    newData.custom_id =
      new Date().getTime().toString() + generateId(10).toString();
    console.log(
      newData,
      data,
      new Date().getTime().toString() + generateId(10).toString()
    );
    currentApps.splice(i + 1, 0, newData);
    setFeatures([...currentApps]);
  };

  //For duplicate App
  const duplicateApp = (data, i) => {
    let currentApps = apps;
    const newData = Object.assign({}, data);
    newData.id = new Date().getTime().toString() + generateId(10).toString();
    newData.custom_id =
      new Date().getTime().toString() + generateId(10).toString();
    console.log(
      newData,
      data,
      new Date().getTime().toString() + generateId(10).toString()
    );
    currentApps.splice(i + 1, 0, newData);
    setApps([...currentApps]);
  };

  return (<div className="popup_body px-8 pt-8 pb-16">
    {/* Product Details */}
    <div className="mb-4">
      <div className="shadow p-4">
        {/* Product Name */}
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {PRODUCT_NAME}
          </label>
          <input
            type="text"
            autoFocus
            placeholder="Product 1"
            onChange={handleChange}
            name="title"
            value={formData.title}
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {PRODUCT_ID}
          </label>
          <input
            type="text"
            autoFocus
            placeholder="Product Id"
            onChange={handleChange}
            name="product_id"
            value={formData.product_id}
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
          />
        </div>
        <div className="flex justify-between">
          <Toggle
            classNameLable="block mr-4 font-semibold"
            label="Product visible"
            onChange={handleToggle}
            isChecked={formData?.available}
          />
        </div>
        <div className="flex justify-between mt-4">
          <label className="block mr-4 font-semibold" htmlFor="">
            {DESCRIPTION}
          </label>
          <textarea
            type="text"
            placeholder="Enter description here..."
            className="w-1/2 block rounded-lg px-4 py-2 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
            onChange={handleChange}
            value={formData.description}
            name="description"
          >
            {formData.description}
          </textarea>
        </div>
        <div className="flex justify-between">
          <label className="block mr-4 font-semibold" htmlFor="">
            {LEARN_MORE}
          </label>
          <input
            type="text"
            placeholder="https://www.thoughtcastmagic.com/productone"
            className="w-1/2 block rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent mb-4 text-blue-900 font-semibold"
            name="learn_more"
            onChange={handleChange}
            value={formData.learn_more}
          />
        </div>
        <div className="flex items-center">
          <p className="font-semibold mr-2">Icon</p>
          <img
            src={formData.icon || logo}
            className="w-12 h-12 object-cover border border-black rounded mr-8"
            alt=""
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={handleChangeFile2}
            accept="image/png, image/jpg, image/jpeg, image/webp"
            style={{ display: "none" }}
          />
          <Button
            text="Change Icon"
            fn={() => {
              inputFile.current.click();
            }}
            small={true}
            bottomMargin={false}
          />
        </div>
      </div>
    </div>
    {/* Buttons */}
    <div className="mb-4 flex justify-center items-center">
      <Button
        text="+ Add App"
        bottomMargin={false}
        otherClass="mr-4"
        fn={addApp}
      />
      <Button
        text="+ Add Feature"
        bottomMargin={false}
        fn={addFeatures}
      />
    </div>
    {/* App Details */}
    <div className="mb-4">
      {apps
        ? apps?.map((app, i) => {
          return (
            <React.Fragment key={i}>
              <AppFeatureCard
                key={app.id}
                id={app.id}
                idx={i}
                data={app}
                ChangeFn={(e, i, type) => handleAppChange(e, i, type)}
                deleteFn={removeApp}
                title={app.title}
                type="app"
                duplicateFn={duplicateApp}
              />
            </React.Fragment>
          );
        })
        : null}
    </div>
    {/* Feature Details */}
    <div className="mb-4">
      {features
        ? features?.map((f, i) => {
          return (
            <React.Fragment key={i}>
              <AppFeatureCard
                key={f.id}
                id={f.id}
                data={f}
                idx={i}
                ChangeFn={(e, i, type) =>
                  handleFeatureChange(e, i, type)
                }
                deleteFn={removeFeature}
                duplicateFn={duplicateFeature}
                title={f.title}
                type="feature"
              />
            </React.Fragment>
          );
        })
        : null}
    </div>
    <div className="mb-16"></div>
    <div className="flex custom-fix justify-center mt-16 mb-4">
      <Button
        text="+ Add App"
        bottomMargin={false}
        otherClass="mr-4"
        fn={addApp}
      />
      <Button
        text="+ Add Feature"
        bottomMargin={false}
        fn={addFeatures}
        otherClass="mr-4"
      />
      <Button
        text="Cancel"
        bgColor="bg-blue-100"
        fontSemiBold={true}
        bottomMargin={false}
        textColor="text-black"
        fn={() => {
          cancelProductForm();
          resetValue();
        }}
        otherClass="mr-4"
      />
      <Button
        text="Save"
        disabled={
          loading ||
          submitting ||
          iconUpload ||
          appiconUpload ||
          featuresiconUpload
        }
        fn={handleSubmit}
        fontSemiBold={true}
        bottomMargin={false}
      />
    </div>
  </div>
  )
}

export default ProductFormDetails