import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  goToPreviousPage,
  setPageNumber,
  goToNextPage,
  getPaginatedApikeys,
  setapiKeysCount,
} from "../../features/admin/apiKeySlice";
import { DeleteApikey, GetApiKeys } from "../../services";
import Button from "../general/Button";
import Pagination from "../layout/Pagination";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import Loader from "../utils/Loader";
import Helmet from "react-helmet";
import Popup from "../general/Popup";
import ItemPerPage from "../layout/ItemPerPage";
import GenerateApiKeyForm from "../forms/GenerateApiKeyForm";
import { API_KEYS, DATA_IS_NOT_AVAILABLE } from "../../Constant";

const Apikey = () => {
  const [disablePagination,] = useState(false);
  const [maxPages, setmaxPages] = useState(0);
  const [selectedForDelete, setselectedForDelete] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [maxApiKeysPerPage,] = useState(10);
  const [generateApiKeyForm, setGenerateAPIkeyForm] = useState(false);
  const [APIDocLink, setAPIDocLink] = useState(null);
  const apiKeys = useSelector((state) => state.apikeys.paginatedApikeys);
  const loading = useSelector((state) => state.apikeys.loading);
  const pageNumber = useSelector((state) => state.apikeys.pageNumber);
  const apiKeysCount = useSelector((state) => state.apikeys.apiKeysCount);
  const totalApikeys = useSelector((state) => state.apikeys.list);

  const dispatch = useDispatch();

  useEffect(() => {
    const mPNum = Math.ceil(totalApikeys.length / apiKeysCount);
    setmaxPages(mPNum);
  }, [totalApikeys, apiKeysCount, maxApiKeysPerPage])

  //To get Api keys
  useEffect(() => {
    const getData = async () => {
      await dispatch(GetApiKeys());
      dispatch(getPaginatedApikeys());
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    try {
      const ULSlocalStorage = localStorage.getItem("uls");
      const JSONData = JSON.parse(ULSlocalStorage);
      if (JSONData?.setting?.swagger_docs_link) {
        setAPIDocLink(JSONData?.setting?.swagger_docs_link)
      }
    } catch (e) {

    }
  }
  //For deleteApi
  const deleteApi = async (id) => {
    dispatch(DeleteApikey(selectedForDelete));
    clearPopup();
  };

  //if data is not available
  const noData = () => (
    <tr>
      <td></td>
      <td className="p-16 text-center font-bold text-lg">
        {DATA_IS_NOT_AVAILABLE}
      </td>
      <td></td>
    </tr>
  );

  const clearPopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="p-16 h-full relative">
      <Helmet>
        <title>{API_KEYS}</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-blue-600 mb-8">{API_KEYS}</h1>

      {/* Popup */}
      {showPopup ? (
        <Popup
          yesFn={() => deleteApi()}
          noFn={clearPopup}
          text={"Delete API key"}
        />
      ) : null}

      {generateApiKeyForm && <GenerateApiKeyForm cancelUserForm={() => setGenerateAPIkeyForm(false)} />}

      {/* Controls */}
      <div className="mb-8 flex justify-between">
        <div className="w-9/12">
          {!disablePagination ? (
            <ItemPerPage
              itemCount={apiKeysCount}
              maxItemsPerPage={maxApiKeysPerPage}
              label="API keys"
              setCount={setapiKeysCount}
            />
          ) : null}
        </div>
        <div className="w-3/12">
          <Button
            text="Generate API Key"
            fn={() => setGenerateAPIkeyForm(true)}
          />
          <Button text="View API docs" fn={() => APIDocLink && window.open(APIDocLink)} />
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Data */}
          <table className="table-auto w-full text-left border border-blue-500">
            <thead className="bg-blue-500 text-white p-16">
              <tr>
                <TableHeader text="Email" sortable={false} />
                <TableHeader text="API Key" sortable={false} />
                <TableHeader text="Action" sortable={false} />
              </tr>
            </thead>
            <tbody>
              {apiKeys && apiKeys.length
                ? apiKeys.map((apiKey) => (
                  <tr key={apiKey.id} className="p-16">
                    <TableData text={apiKey.email || "N/A"} />
                    <TableData text={apiKey.api_key} />
                    <TableData
                      child={
                        <Button
                          text="Delete"
                          small={true}
                          bgColor="bg-red-800"
                          bottomMargin={false}
                          fn={() => {
                            setselectedForDelete(apiKey.email);
                            setShowPopup(true);
                          }}
                        />
                      }
                    />
                  </tr>
                ))
                : noData()}
            </tbody>
          </table>
          {!disablePagination ? (
            <Pagination
              prevFn={() => dispatch(goToPreviousPage())}
              nextFn={() => dispatch(goToNextPage())}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              maxPages={maxPages}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Apikey;
