import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  CANCEL_SUBSCRIPTION,
  SUCCESSFULLY_DELETED_USER,
  SUCCESSFULLY_UPDATED_USER
} from "../../Constant";
import {
  EditUser,
  GetUsers,
  CreateUser,
  MergeUserApi,
  codeActivation,
  cancelSubscription,
  deprecateProductUserApi,
  DeleteUserApi
} from "../../services";

const paginatedUsers = (users, pageNumber, userCount, filterKeyword) => {
  if (filterKeyword) {
    return users
      .filter(
        (user) =>
          user?.firstname?.toLowerCase().includes(filterKeyword) || user?.lastname?.toLowerCase().includes(filterKeyword) ||
          `${user?.firstname?.toLowerCase()} ${user?.lastname?.toLowerCase()}`.includes(filterKeyword) || `${user?.firstname?.toLowerCase()}${user?.lastname?.toLowerCase()}`.includes(filterKeyword) ||
          user.email.toLowerCase().includes(filterKeyword)
      )
      .slice((pageNumber - 1) * userCount, pageNumber * userCount);
  } else {
    return users.slice((pageNumber - 1) * userCount, pageNumber * userCount);
  }
};

export const userListSlice = createSlice({
  name: "users",
  initialState: {
    list: [],
    pageNumber: 1,
    userCount: 10,
    paginatedUsers: [],
    filterKeyword: "",
    loading: false,
    succesResponseUser: false,
    loadingUser: false
  },
  reducers: {
    getPaginatedUsers: (state) => {
      state.paginatedUsers = paginatedUsers(
        state.list,
        state.pageNumber,
        state.userCount,
        state.filterKeyword
      );
    },
    deleteUsers: (state, action) => {
      state.list = state.list.filter(
        (user) => !action.payload.includes(user.id)
      );
      state.pageNumber = 1;
      userListSlice.caseReducers.getPaginatedUsers(state);
    },
    succesResponseMethod: (state, action) => {
      state.succesResponseUser = action.payload;
    },
    setPageNumber: (state, action) => {
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(state.list.length / state.userCount) + 1
      ) {
        state.pageNumber = action.payload;
        userListSlice.caseReducers.getPaginatedUsers(state);
      }
    },
    toggleSubsVisibility: (state, action) => {

      state.list = state.list.map((a) => {
        if (a.id === action.payload.user_id) {
          return {
            ...a,
            is_hidden_subscription: action.payload.is_hidden_subscription
          }
        } else {
          return a;
        }
      })
      userListSlice.caseReducers.getPaginatedUsers(state);
    },



    setUserCount: (state, action) => {
      if (action.payload > 0) {
        state.userCount = action.payload;
        state.pageNumber = 1;
        userListSlice.caseReducers.getPaginatedUsers(state);
      }
    },
    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        userListSlice.caseReducers.getPaginatedUsers(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(state.list.length / state.userCount);
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        userListSlice.caseReducers.getPaginatedUsers(state);
      }
    },
    sortByFirstName: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        );
      } else {
        state.list = state.list.sort((a, b) =>
          b.firstname.localeCompare(a.firstname)
        );
      }
      userListSlice.caseReducers.getPaginatedUsers(state);
    },
    sortByLastName: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) =>
          a.lastname.localeCompare(b.lastname)
        );
      } else {
        state.list = state.list.sort((a, b) =>
          b.lastname.localeCompare(a.lastname)
        );
      }
      userListSlice.caseReducers.getPaginatedUsers(state);
    },
    sortByEmail: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort((a, b) => a.email.localeCompare(b.email));
      } else {
        state.list = state.list.sort((a, b) => b.email.localeCompare(a.email));
      }
      userListSlice.caseReducers.getPaginatedUsers(state);
    },
    sortByActivationDate: (state, action) => {
      if (action.payload === "ASC") {
        state.list = state.list.sort(
          (a, b) => new Date(a.activated_at) - new Date(b.activated_at)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(b.activated_at) - new Date(a.activated_at)
        );
      }
      userListSlice.caseReducers.getPaginatedUsers(state);
    },
    filterByKeyword: (state, action) => {
      if (action.payload) {
        state.filterKeyword = action.payload;
        state.pageNumber = 1;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount,
          state.filterKeyword
        );
      } else {
        state.filterKeyword = "";
        state.paginatedUsers = paginatedUsers(
          state.list,
          1,
          state.userCount,
          ""
        );
      }
    },
    updateWhenCodeHistoryUpdate: (state, action) => {
      if (action.payload) {
      }
    },
  },
  extraReducers: {
    [GetUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [GetUsers.pending]: (state, action) => {
      state.loading = true;
      state.list = [];
    },
    [GetUsers.rejected]: (state, action) => {
      state.loading = false;
      state.list = [];
    },
    [DeleteUserApi.success]: (state, action) => {
      if (action?.payload?.data?.success) {
        toast.success(SUCCESSFULLY_DELETED_USER);
      }
    },
    [CreateUser.fulfilled]: (state, action) => {
      state.loadingUser = false;
      if (action.payload) {
        state.list = [action.payload, ...state.list];
        state.pageNumber = 1;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount,
          state.filterKeyword
        );

        state.succesResponseUser = true;
        toast.success("successfully added user");
      }
    },
    [CreateUser.rejected]: (state, action) => {
      state.loadingUser = false;
      toast.error(action?.payload);
    },
    [CreateUser.pending]: (state, action) => {
      state.loadingUser = true;
    },
    [MergeUserApi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.userId) {
        let data = state.list.filter((user) => {
          return !action.payload.userId.includes(user.id);
        });
        data.unshift(action.payload.data);
        state.list = data;
        state.pageNumber = 1;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount
        );
        state.succesResponseUser = true;
        state.loadingUserMerge = false;
        toast.success("successfully merged users");
      }
    },
    [MergeUserApi.pending]: (state, action) => {
      state.loadingUserMerge = true;
    },
    [MergeUserApi.rejected]: (state, action) => {
      state.loadingUserMerge = false;
    },
    [deprecateProductUserApi.fulfilled]: (state, action) => {
      if (action?.payload?.data?.success) {
        const NewData = action?.payload?.data?.data;
        let data = state.list.map((user) => {
          if (user?.id === NewData?.id) {
            return NewData;
          } else {
            return user;
          }
        });
        state.list = data;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount,
          state.filterKeyword
        );
      }
    },
    [EditUser.fulfilled]: (state, action) => {
      state.loadingUser = false;
      if (action.payload) {
        let data = state.list.map((user) => {
          if (user?.id === action.payload?.id) {
            return action.payload;
          } else {
            return user;
          }
        });
        state.list = data;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount,
          state.filterKeyword
        );
        state.succesResponseUser = true;
        toast.success(SUCCESSFULLY_UPDATED_USER);
      }
    },
    [EditUser.rejected]: (state, action) => {
      state.loadingUser = false;
    },
    [EditUser.pending]: (state, action) => {
      state.loadingUser = true;
    },
    [cancelSubscription.fulfilled]: (state, action) => {
      if (action?.payload?.data?.success) {
        if (action?.payload?.data?.data) {
          const userActionData = action?.payload?.data?.data.uData;
          let data = state.list.map((user) => {
            if (user?.id === userActionData?.id) {
              return userActionData;
            } else {
              return user;
            }
          });
          state.list = data;
          state.paginatedUsers = paginatedUsers(
            state.list,
            state.pageNumber,
            state.userCount,
            state.filterKeyword
          );
          toast.success(CANCEL_SUBSCRIPTION);
        }
      }
    },
    [codeActivation.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data && action.payload.data.user) {
        const userActionData = action.payload.data.user;
        let data = state.list.map((user) => {
          if (user?.id === userActionData?.id) {
            return userActionData;
          } else {
            return user;
          }
        });
        state.list = data;
        state.paginatedUsers = paginatedUsers(
          state.list,
          state.pageNumber,
          state.userCount,
          state.filterKeyword
        );
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPaginatedUsers,
  sortByFirstName,
  sortByLastName,
  sortByEmail,
  sortByActivationDate,
  filterByKeyword,
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  setUserCount,
  deleteUsers,
  succesResponseMethod,
  updateWhenCodeHistoryUpdate,
  toggleSubsVisibility
} = userListSlice.actions;

export default userListSlice.reducer;
