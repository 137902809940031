import { createSlice } from "@reduxjs/toolkit";
import {
  GetSubscriptions,
  EditSubscription,
  CreateSubscription,
  UpdateSubscription,
  DeleteSubscriptionApi,
  GetSubscriptionInfo,
  EditSubscriptionInfo,
  EditSubscriptions,
  AddNewSubscription
} from "../../services";
import toast from "react-hot-toast";
import {
  ERROR_WHILE_ADDING_SUBSCRIPTION,
  SUCCESSFULLY_ADDED_SUBSCRIPTION,
  SUCCESSFULLY_CREATED_SUBSCRIPTION,
  SUCCESSFULLY_DELETED_SUBSCRIPTION,
  SUCCESSFULLY_UPDATED_SUBSCRIPTION,
  SUCCESSFULLY_UPDATED_SUBSCRIPTION_USER_PORTAL_INFO,
  UPDATED_SUBSCRIPTION
} from "../../Constant";

const paginatedSubscriptions = (subscriptions, pageNumber, subscriptionCount) => {
  return subscriptions.slice(
    (pageNumber - 1) * subscriptionCount,
    pageNumber * subscriptionCount
  );
};

export const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState: {
    list: [],
    active: [],
    inactive: [],
    pageNumber: 1,
    subscriptionCount: 10,
    paginatedSubscription: [],
    succesResponseSubscription: false,
    loading: false,
    typeStatus: "All",
    loadingSubmit: false,
    info:[]
  },
  reducers: {
    getPaginatedSubscriptions: (state) => {
      state.paginatedSubscription = paginatedSubscriptions(
        state.typeStatus === "Active"
          ? state.active
          : state.typeStatus === "Inactive"
            ? state.inactive
            : state.list,
        state.pageNumber,
        state.subscriptionCount
      );
    },
    changeTypeStatus: (state, action) => {
      console.log(state, action,"(state, action) ") 
      state.typeStatus = action.payload;
      state.pageNumber = 1;
      subscriptionSlice.caseReducers.getPaginatedSubscriptions(state);
    },
    deleteSubscriptions: (state, action) => {
      state.list = state.list.filter(
        (subscription) => !action.payload.includes(subscription.id)
      );
      return state;
    },
    setsubscriptionCount: (state, action) => {
      if (action.payload > 0) {
        state.subscriptionCount = action.payload;
        state.pageNumber = 1;
        subscriptionSlice.caseReducers.getPaginatedSubscriptions(state);
      }
    },

    goToPreviousPage: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber -= 1;
        subscriptionSlice.caseReducers.getPaginatedSubscriptions(state);
      }
    },
    goToNextPage: (state) => {
      let maxPages = Math.ceil(state.list.length / state.subscriptionCount);
      if (state.pageNumber < maxPages) {
        state.pageNumber += 1;
        subscriptionSlice.caseReducers.getPaginatedSubscriptions(state);
      }
    },
    setPageNumber: (state, action) => {
      if (
        action.payload > 0 &&
        action.payload < Math.ceil(state.list.length / state.subscriptionCount) + 1
      ) {
        state.pageNumber = action.payload;
        subscriptionSlice.caseReducers.getPaginatedSubscriptions(state);
      }
    },
    succesResponseMethod: (state, action) => {
      state.succesResponseSubscription = action.payload;
    },
  },
  extraReducers: {
    [GetSubscriptions.fulfilled]: (state, action) => {
      state.list = action.payload;

      state.active = action.payload
        ? action.payload.filter((data) => {
          return data?.available;
        })
        : [];
      state.inactive = action.payload
        ? action.payload.filter((data) => {
          return !data?.available;
        })
        : [];
      state.loading = false;
    },
    [GetSubscriptions.pending]: (state, action) => {
      state.list = [];
      state.active = [];
      state.inactive = [];
      state.loading = true;
    },
    [GetSubscriptions.rejected]: (state, action) => {
      state.list = [];
      state.active = [];
      state.inactive = [];
      state.loading = false;
    },
    [CreateSubscription.fulfilled]: (state, action) => {
      state.loadingSubmit = false;
      if (action.payload && action.payload.success) {
        state.list.push(action.payload.data);
        // const DataNew = [...state.list, action.payload.data ];
        // state.list = DataNew;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return !data.available;
          })
          : [];
        state.pageNumber = 1;
        state.paginatedSubscription = paginatedSubscriptions(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.subscriptionCount
        );
        toast.success(SUCCESSFULLY_ADDED_SUBSCRIPTION);
        state.succesResponseSubscription = true;
      }

    },
    [CreateSubscription.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [CreateSubscription.rejected]: (state, action) => {
      state.loadingSubmit = false;
      toast.error(ERROR_WHILE_ADDING_SUBSCRIPTION);
    },
    [DeleteSubscriptionApi.fulfilled]: (state, action) => {
      if (action.payload && action.payload.success) {
        state.list = state.list.filter(
          (subscription) => !action.payload.id.includes(subscription.id)
        );

        state.active = state.list
          ? state.list.filter((data) => {
            return data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return !data.available;
          })
          : [];

        state.pageNumber = 1;
        state.paginatedSubscription = paginatedSubscriptions(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.subscriptionCount
        );
        toast.success(SUCCESSFULLY_DELETED_SUBSCRIPTION);
      }
    },
    [EditSubscription.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [EditSubscription.rejected]: (state, action) => {
      state.loadingSubmit = false;
    },
    [EditSubscription.fulfilled]: (state, action) => {
      if (action.payload) {
        let data = state.list.map((subscription) => {
          if (
            action.payload &&
            subscription.type === "subscription" &&
            subscription.id === action.payload.id
          ) {
            return action.payload;
          } else if (
            action.payload 
           
          ) {
            const oldData = subscription;
            oldData.subscription = { ...subscription.subscription, ...action.payload };
            return oldData;
          } else {
            return subscription;
          }
        });
        state.list = data;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return !data.available;
          })
          : [];
        state.paginatedSubscription = paginatedSubscriptions(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.subscriptionCount
        );
        state.succesResponseSubscription = true;
        toast.success(SUCCESSFULLY_UPDATED_SUBSCRIPTION);
      }
      state.loadingSubmit = false;
    },
    [UpdateSubscription.fulfilled]: (state, action) => {
      if (action.payload) {
        let data = state.list.map((subscription) => {
          if (action.payload && subscription.type === "subscription") {
            const oldData = { ...subscription, ...action.payload.data };
            return oldData;
          } else {
            return subscription;
          }
        });
        state.list = data;
        state.active = state.list
          ? state.list.filter((data) => {
            return data.available;
          })
          : [];
        state.inactive = state.list
          ? state.list.filter((data) => {
            return !data.available;
          })
          : [];
        state.paginatedSubscription = paginatedSubscriptions(
          state.typeStatus === "Active"
            ? state.active
            : state.typeStatus === "Inactive"
              ? state.inactive
              : state.list,
          state.pageNumber,
          state.subscriptionCount
        );
        toast.success(UPDATED_SUBSCRIPTION);
        state.succesResponseSubscription = true;
      }
    },
    [GetSubscriptionInfo.fulfilled]: (state, action) => {
      state.info = action.payload;

    },
    [GetSubscriptionInfo.pending]: (state, action) => {
      state.info = [];
    },
    [GetSubscriptionInfo.rejected]: (state, action) => {
      state.info = [];
    },
    [EditSubscriptionInfo.fulfilled]: (state, action) => {
      toast.success(SUCCESSFULLY_UPDATED_SUBSCRIPTION_USER_PORTAL_INFO);
      state.loadingSubmit = false;

    },
    [EditSubscriptionInfo.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [EditSubscriptionInfo.rejected]: (state, action) => {
      state.loadingSubmit = false;
    },

    [EditSubscriptions.fulfilled]: (state, action) => {
      toast.success(SUCCESSFULLY_UPDATED_SUBSCRIPTION);
      state.loadingSubmit = false;

    },
    [EditSubscriptions.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [EditSubscriptions.rejected]: (state, action) => {
      state.loadingSubmit = false;
    },

    [AddNewSubscription.fulfilled]: (state, action) => {
      toast.success(SUCCESSFULLY_CREATED_SUBSCRIPTION);
      state.loadingSubmit = false;

    },
    [AddNewSubscription.pending]: (state, action) => {
      state.loadingSubmit = true;
    },
    [AddNewSubscription.rejected]: (state, action) => {
      state.loadingSubmit = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  goToPreviousPage,
  goToNextPage,
  setPageNumber,
  initSubscriptions,
  setsubscriptionCount,
  deleteSubscriptions,
  getPaginatedSubscriptions,
  succesResponseMethod,
  changeTypeStatus,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
