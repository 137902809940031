import React from 'react';
import {
  VIEW_INACTIVE_CODE, EDIT_MULTIPLE_CODE, EDIT_CODE, ADD_CODE
} from "../../Constant";

const CodeFormHeader = ({ cancelCodeForm, getFooter, isApiCallStart, code, readOnly, multiCodes,
  forceuUpdate, handleSubmit }) => {

  const getHeader = () => {
    if (code && readOnly) {
      return VIEW_INACTIVE_CODE;
    }
    if (multiCodes && !readOnly) {
      return EDIT_MULTIPLE_CODE;
    }
    if (multiCodes && readOnly) {
      return VIEW_INACTIVE_CODE;
    }
    if (code && !multiCodes && !readOnly) {
      return EDIT_CODE;
    }
    if (!code) {
      return ADD_CODE;
    }
  };
  return (
    <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
      <h3 className="text-2xl">{getHeader()}</h3>

    </div>
  )
}

export default CodeFormHeader;