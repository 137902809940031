import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_IS_INVALID, EMAIL_LABEL, MAIL_REGEX } from "../../Constant";
import { GenerateApiKey } from "../../services";
import Button from "../general/Button";

const GenerateApiKeyForm = ({ cancelUserForm }) => {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);

  const mailRegex = MAIL_REGEX;
  const generateLoading = useSelector((state) => state.apikeys.generateLoading);

  const dispatch = useDispatch();

  //To scroll window
  useEffect(() => {
    window.scrollTo(0, 0);

    // event for esc key
    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        cancelUserForm();
      }
    });
  }, []);

  //For submit a userForm
  const handlesubmit = async () => {
    if (mailRegex.test(email)) {
      await dispatch(GenerateApiKey(email));
      cancelUserForm();
    } else {
      setError(EMAIL_IS_INVALID);
    }
  };

  //Email Change handle
  const handleChange = (e) => {
    let target = e.target;
    setEmail(target.value.trim());
  };

  return (
    <div
      className="bg-black overflow-hidden align-middle content-center bg-opacity-20 absolute top-0 left-0 h-full w-full"
      onClick={cancelUserForm}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="w-3/4 h-5/12 inset-x-0 -translate-x-1/2 my-4 mx-auto absolute top-40 overflow-y-scroll bg-white rounded-xl shadow-xl"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="popup_header text-blue-600 font-bold p-4 flex justify-between border-b border-blue-500">
          <h3 className="text-2xl">Generate API key</h3>
          <div className="w-1/5 flex justify-end">
            <Button
              text="Cancel"
              bgColor="bg-blue-100"
              otherClass="mr-2"
              fontSemiBold={true}
              bottomMargin={false}
              textColor="text-black"
              fn={cancelUserForm}
            />
            <Button
              text="Generate"
              fn={handlesubmit}
              disabled={generateLoading}
              fontSemiBold={true}
              bottomMargin={false}
            />
          </div>
        </div>
        <div className="popup_body px-8 pt-8 pb-16 ">
          {/* User Details */}

          <div className="w-full mb-4">
            <div className="flex">
              <div className="shadow w-full p-4">
                {/* Email */}

                <div className="flex justify-between">
                  <label className="block mr-4 font-semibold" htmlFor="">
                    {EMAIL_LABEL}
                  </label>
                  <div className="w-1/2">
                    <input
                      type="text"
                      name="email"
                      placeholder="example@gmail.com"
                      onChange={handleChange}
                      value={email}
                      className="block w-full rounded-lg px-2 py-1 border border-black focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent  text-blue-900 font-semibold"
                    />
                    {error ? (
                      <small className="text-red-600 font-semibold ">
                        {error}
                      </small>
                    ) : null}
                  </div>
                </div>

                <Button
                  text="Generate API Key"
                  fn={handlesubmit}
                  disabled={generateLoading}
                  fontSemiBold={true}
                  bottomMargin={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateApiKeyForm;
