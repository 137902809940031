import React from "react";
import TableData from "../table/TableData";
import TableHeader from "../table/TableHeader";
import moment from "moment";
import { ProductActiveString } from "../utils/commonFunction";
import {
  sortByActivationDate,
  sortByCodeName,
} from "../../features/admin/codeSlice";
import {
  ACTIVATED_BY,
  DATE_ACTIVATED,
  DATE_ADDED,
  PRODUCTS_ACTIVATED
} from "../../Constant";
import Button from "../general/Button";

const TableComponent2 = ({
  selectedCodes,
  selectAll,
  selectNone,
  selectRow,
  showActiveCodes,
  codes,
  displayCodeForm,
  noData,
  setSelectedCodes,
  subscriptions
}) => {
  const showSubscription = (code) => {
    let res = '';
    const indexValue = subscriptions.findIndex(p => p.id == code.subscription_group_id);
    if (indexValue >= 0 && indexValue !== undefined && indexValue !== null && indexValue !== "") {
      res += subscriptions[indexValue].title;
      const indexValuePricingOptions = subscriptions[indexValue]?.pricing_options.findIndex(p => p.stripe_product_id == code.subscription_prod_id);
      if (indexValuePricingOptions >= 0 && indexValuePricingOptions !== undefined && indexValuePricingOptions !== null && indexValuePricingOptions !== "") {

        res += ' ( $' + subscriptions[indexValue].pricing_options[indexValuePricingOptions].price + '/' + subscriptions[indexValue].pricing_options[indexValuePricingOptions].time_unit + ')';
      }
    }

    return res;
  };
  return (
    <>
      <table className="table-auto w-full text-left border border-blue-500">
        <thead className="bg-blue-500 text-white p-16">
          <tr>
            <TableHeader
              child={
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) =>
                    e.target.checked ? selectAll() : selectNone()
                  }
                  checked={
                    codes.length && selectedCodes.length === codes.length
                  }
                />
              }
              sortable={false}
            />
            <TableHeader text="Code" fn={sortByCodeName} />
            <TableHeader
              text={showActiveCodes ? PRODUCTS_ACTIVATED : ACTIVATED_BY}
              sortable={false}
            />
            <TableHeader
              text={showActiveCodes ? DATE_ADDED : DATE_ACTIVATED}
              fn={sortByActivationDate}
            />
            <TableHeader text="Place of Purchase" sortable={false} />
            <TableHeader
              text={showActiveCodes ? "Action" : "Action"}
              sortable={false}
            />
          </tr>
        </thead>
        <tbody>
          {codes.length > 0
            ? codes.map((code, codeIndex) => {
              const ProductActiveData = ProductActiveString(
                code.products_to_activate
              );
              return (
                <React.Fragment key={code.id}>
                  <tr key={code.id.toString()} className="p-16">
                    <TableData
                      child={
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={(e) =>
                            selectRow(e, code.id.toString())
                          }
                          checked={
                            selectedCodes.findIndex(
                              (id) => id === code.id.toString()
                            ) !== -1
                              ? true
                              : false
                          }
                        />
                      }
                    />
                    <TableData text={code.code || "N/A"} />

                    <TableData
                      text={
                        showActiveCodes ? (
                          <div className="break-words">
                            {ProductActiveData.length
                              ? ProductActiveData.map(
                                (padDataVal, PADindex) => {
                                  return (
                                    <React.Fragment key={PADindex}>
                                      <span>
                                        {padDataVal}
                                        <br />
                                      </span>
                                    </React.Fragment>
                                  );
                                }
                              )
                              : `Subscription - ${code.duration === "None" ? showSubscription(code) : code.duration}`}
                          </div>
                        ) : (
                          code.activated_by
                        )
                      }
                    />
                    <TableData
                      text={showActiveCodes ? code.created_at
                        ? typeof code.created_at === "string"
                          ? moment(code.activated_at, [moment.ISO_8601,]).format(
                            "MMM D, YYYY"
                          )
                          : "N/A"
                        : "N/A"
                        : typeof code.activated_at === "string" ? moment(code.activated_at, [moment.ISO_8601,]).format(
                          "MMM D, YYYY") : moment(code.activated_at._seconds * 1000).format(
                            "MMM D, YYYY")
                      }
                    />

                    <TableData text={code.purchase_location || "N/A"} />
                    <TableData
                      child={
                        <Button
                          text={showActiveCodes ? "Edit" : "View"}
                          small={true}
                          bottomMargin={false}
                          fn={() => {
                            if (!selectedCodes.length) {
                              setSelectedCodes([code]);
                            }
                            displayCodeForm();
                          }}
                        />
                      }
                    />
                  </tr>
                </React.Fragment>
              );
            })
            : noData()}
        </tbody>
      </table>
    </>
  )
}
export default TableComponent2;
