import React, { useState, useEffect, Fragment } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { createSetupIntent, listSetupIntent, removeSetupIntent, updateDefaultPaymentMethod } from "../../../apiStructure";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Mui Imports
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

//Material UI Icons
import ClearIcon from '@mui/icons-material/Clear';

//Material UI Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const CardPaymentModal = ({
  setShowModal,
}) => {
  const [addressData, setAddressData] = useState({
    line1: "",
    line2: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
  });

  const [selectedValue, setSelectedValue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [UserData, setUserData] = useState({});
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [stripeOptions, setStripeOptions] = useState({});
  const [rows, setRows] = React.useState([]);
  const [stripePromise, setStripePromise] = useState(null);

  //get userData from Localstorage
  useEffect(() => {
    let localstorage = localStorage.getItem("uls");
    if (localstorage) {
      const ParseData = JSON.parse(localstorage);
      if (ParseData) {
        setUserData(ParseData);
        const fetchStripeObject = async () => {
          const res = await loadStripe(ParseData.setting.stripe_mode == "test" ? ParseData.setting.stripe_test_publishable_key : ParseData.setting.stripe_live_publishable_key);
          // When we have got the Stripe object, pass it into our useState.
          setStripePromise(res);
        };
        fetchStripeObject();
        // get already save payment methods
        listSetupIntents(ParseData.id);
      }
    }
  }, []);
  // New code
  // get setup intent from API
  const createSetupIntents = async (userid) => {
    createSetupIntent(userid)
      .then((res) => {
        if (res) {
          setStripeOptions({ clientSecret: res.data.data, appearance: {/*...*/ } });
          setShowCheckoutForm(true);
          return res.data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const listSetupIntents = async (userid) => {

    listSetupIntent({ user_id: userid })
      .then((res) => {
        if (res) {
          setRows(res.data.data.data);
          setLoading(false);
          // show checkout form when modal opens
          showCheckout(userid)
          return res.data.data;
        } else {
          // show checkout form when modal opens
          showCheckout(userid)
          return false;

        }
      })
      .catch((err) => {
        return false;
      });
  }

  const CheckoutForm = () => {
    ;
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {

      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      // setAddressData({
      //   line1: event.target.elements.line1.value,
      //   line2: event.target.elements.line2.value,
      //   postal_code: event.target.elements.postal_code.value,
      //   city: event.target.elements.city.value,
      //   state: event.target.elements.state.value,
      //   country: event.target.elements.country.value,
      // });

      if (!stripe || !Elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const result = await stripe.confirmCardSetup(stripeOptions.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${UserData.firstname} ${UserData.lastname}`,
            // address: addressData
          },
        }
      });
      if (result.error) {
        // Display result.error.message in your UI.
        toast.error('Failed to process payment details. Please try another payment method.');
      } else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        toast.success('Success! Your payment method has been saved.');
        await listSetupIntents(UserData.id);
        showCheckout();
      }

    };
    const [isPaymentLoading, setPaymentLoading] = useState(false);


    return (
      <form onSubmit={handleSubmit} style={{ marginTop: "50px" }}>

        <CardElement />
        <button
          disabled={!stripe}
          style={{
            backgroundColor: '#1976d2',
            color: 'white',
            border: '1px solid #1976d2',
            padding: '6px',
            borderRadius: '5px',
            marginTop: '30px',
            width: '10%',
            float: 'right',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          }}>
          Save Card
        </button>
        {/* Show error message to your customers */}
      </form >
    );
  }
  const showCheckout = async (user_id = '') => {
    await createSetupIntents({ user_id: user_id ? user_id : UserData.id });
  }
  const handleRemovePaymentIndent = async (params) => {
    setLoading(true);
    removeSetupIntent({ user_id: UserData.id, payment_id: params.row.id })
      .then((res) => {
        if (res) {
          listSetupIntents(UserData.id);
        } else {
          setLoading(false);
          return false;
        }
      })
      .catch((err) => {
        setLoading(false);
        return false;
      });
  };
  const handleChangeDefault = (event, params) => {
    let dop = JSON.parse(JSON.stringify(rows));
    dop.map((value) => {
      if (params.id == value.id) {
        value.default = true;
        return value;
      } else {
        value.default = false;
        return value;
      }
    });
    setRows(dop);
    setSelectedValue(true);
    // set
    setLoading(true);
    updateDefaultPaymentMethod({ user_id: UserData.id, collection_id: params.row.collection_id })
      .then((res) => {
        if (res) {
          setLoading(false);
          toast.success('Success! Your payment method has set default.');
        } else {
          setLoading(false);
          toast.error('Something went wrong.');
          return false;
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong.');
        return false;
      });
  };
  // Mui data grid
  let columns = [
    {
      field: 'customer',
      headerName: 'Card',
      width: 150,
      editable: true,
      valueGetter: function (params) {
        return `XXXX-${params.row.card.last4}`;
      },
    },
    {
      field: 'id',
      headerName: 'Card Brand',
      width: 150,
      editable: true,
      valueGetter: function (params) {
        return params.row.card.brand;
      },
    },
    {
      field: 'object',
      headerName: 'Card type',
      type: 'number',
      width: 150,
      editable: true,
      valueGetter: function (params) {
        return params.row.card.funding;
      },
    },
    {
      field: 'created',
      headerName: 'Exp Month/Year',
      type: 'number',
      width: 150,
      editable: true,
      valueGetter: function (params) {
        return `${params.row.card.exp_month}/${params.row.card.exp_year}`;
      },
    },
    {
      field: 'default',
      headerName: 'Default',
      type: 'number',
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <Radio
              checked={selectedValue === params.row.default}
              onChange={(e) => handleChangeDefault(e, params)}
              value={params.row.default}
              name="radio-buttons"
              inputProps={{ 'aria-label': `${selectedValue}` }}
            />
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleRemovePaymentIndent(params)}
              startIcon={<ClearIcon />}
            >
              Remove
            </Button>
          </>
        );
      },
    }
  ];
  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <DialogTitle>Manage Payment Methods</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <Container maxWidth="xl">
            <Typography variant="h4"></Typography>
            <div style={{ display: 'flex', height: '100%', marginTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'default', sort: 'desc' }],
                    },
                  }}
                  autoHeight
                  components={{
                    NoRowsOverlay: () => (
                      <Stack height="auto" paddingTop="40px" alignItems="center" justifyContent="center">
                        No saved cards
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                      <Stack height="auto" paddingTop="40px" alignItems="center" justifyContent="center">
                        No saved cards
                      </Stack>
                    )
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  loading={loading}
                  rowsPerPageOptions={[5]}
                  NoRowsOverlay
                  id="paymentMethodGrid"
                  disableSelectionOnClick
                />
              </div>
            </div>
            {/* <Box textAlign='center'>
              <Button onClick={showCheckout} variant="contained" sx={{ mt: 2 }}>Add Card</Button>
            </Box> */}
            {showCheckoutForm ?
              <Elements stripe={stripePromise} options={stripeOptions} >
                <CheckoutForm />
              </Elements>
              : null}
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowModal(false);
          }}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CardPaymentModal;

